import React, { useLayoutEffect, useState, useEffect } from 'react'
import reqwest from 'reqwest'
import FileSaver from 'file-saver'
import ResizableTitle from '../../Common/ResizableTitle'
import axios from 'axios'
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  Input,
  Button,
  Table,
  Affix,
  message,
  Popconfirm,
} from 'antd'

import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import FileUploadForm from './FileUploadForm'
import UpdateForm from './UpdateForm'

//translation
import { useTranslation } from 'react-i18next'

const { Title } = Typography
const { Search } = Input

const FileDownloads = ({ store }) => {
  const { t } = useTranslation('common') //translation

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowFiles(selectedRows)
    },
  }

  const initialcolumns = [
    {
      title: t('file_download.title'),
      dataIndex: 'title',
      key: 'title',
      width: 300,
    },
    {
      title: t('file_download.description'),
      dataIndex: 'description',
      key: 'description',
      width: 600,
    },

    {
      title: t('file_download.action'),
      key: 'action',
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => downloadFile(record.key)} type="primary">
            {t('file_download.download')}
          </Button>
          {store.company.type ? (
            ''
          ) : (
            <>
              <Button onClick={() => arrangeForEdit(record)} type="primary">
                {t('file_download.update')}
              </Button>
              <Popconfirm
                title={t('file_download.are_you_sure_delete_this_file')}
                onConfirm={() => handleDelete(record.key)}
                okText={t('file_download.yes')}
                placement="leftTop"
                cancelText={t('file_download.no')}
              >
                <Button loading={updating} danger icon={<DeleteOutlined />}>
                  {t('file_download.delete')}
                </Button>
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ]
  const [columns, setColumns] = useState(initialcolumns)
  const handleResize = (index) => (e, { size }) => {
    const nextColumns = [...columns]
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    }

    setColumns(nextColumns)
  }

  const components = {
    header: {
      cell: ResizableTitle,
    },
  }

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }))
  const [isAffixed, setIsAffixed] = useState(false)
  const [visible, setVisible] = useState(false)
  const [titleExist, setTitleExistState] = useState(false)
  const [showCheckBoxes, setShowCheckBoxes] = useState(false)
  const [visibleUpdate, setVisibleUpdate] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [documents, setFiles] = useState([])
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [allFiles, setExistingFiles] = useState([])
  const [filteredFiles, setFilteredFiles] = useState([])
  const [selectedRowFiles, setSelectedRowFiles] = useState([])
  const [fileForEdit, setFileForEdit] = useState({})

  useEffect(() => {
    const fetchAllFiles = async () => {
      const res = await axios.get('/api/uploads')

      setExistingFiles((prevState) => {
        const _allFiles = res.data.map((_rd) => {
          return {
            key: _rd.id,
            title: _rd.title,
            description: _rd.description,
            ..._rd,
          }
        })

        setFilteredFiles(_allFiles)

        return _allFiles
      })
    }

    fetchAllFiles()
  }, [setExistingFiles])

  useEffect(() => {
    const newFiles = [...allFiles]

    setFilteredFiles(newFiles)
  }, [allFiles])

  useLayoutEffect(() => {
    setIsAffixed(false)

    return () => {
      setIsAffixed(false)
    }
  }, [])

  const showModal = () => {
    setVisible(true)
  }

  const arrangeForEdit = (record) => {
    setVisibleUpdate(true)
    setFileForEdit(record)
  }

  const handleOk = async () => {
    if (title !== '' && description !== '' && documents.length > 0) {
      setTitleExistState(false)

      try {
        const checkFile = await axios.get(`/api/uploads/${title}`)

        if (!checkFile.data.found) {
          const fileList = documents
          const formData = new FormData()

          fileList.forEach((file) => {
            formData.append('files', file)
            formData.append('title', title)
            formData.append('description', description)
          })

          setConfirmLoading(true)

          // You can use any AJAX library you like
          reqwest({
            url: '/api/uploads/add',
            method: 'POST',
            processData: false,
            data: formData,
            success: (res) => {
              setFiles([])
              setVisible(false)
              setConfirmLoading(false)
              setTitle('')
              setDescription('')

              setExistingFiles((prevState) => [
                ...prevState,
                {
                  key: res.id,
                  title: res.title,
                  description: res.description,
                },
              ])
              setFilteredFiles((prevState) => [
                ...prevState,
                {
                  key: res.id,
                  title: res.title,
                  description: res.description,
                },
              ])

              message.success(t('file_download.uploaded_successfully'))
            },
            error: () => {
              setConfirmLoading(false)
              message.error(t('file_download.upload_failed'))
            },
          })

          setVisible(false)
          setConfirmLoading(false)
        } else {
          setTitleExistState(true)
        }
      } catch (error) {
        console.log(error)
        setConfirmLoading(false)
      }
    }
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const onSearch = (e) => {
    let value = e.currentTarget.value

    if (value !== '') {
      setFilteredFiles((prevState) => {
        const newList = allFiles.filter((_pS) => {
          if (_pS.title.includes(value) || _pS.description.includes(value)) {
            return _pS
          }
        })

        return newList
      })
    } else {
      setFilteredFiles(allFiles)
    }
  }

  const handleDelete = async (id) => {
    try {
      setUpdating(true)
      await axios.delete(`/api/uploads/delete/${id}`)

      setExistingFiles((prevState) => {
        const newList = prevState.filter((_pS) => _pS.key !== id)
        setFilteredFiles(newList)
        return newList
      })

      setUpdating(false)
      message.success(t('file_download.deleted_successfully'))
    } catch (error) {
      setUpdating(false)
      message.error(t('file_download.delete_failed'))
    }
  }

  const downloadFile = async (id) => {
    try {
      const res = await axios({
        method: 'post',
        url: `/api/uploads/downloads`,
        responseType: 'arraybuffer',
        data: {
          id,
        },
      })

      const tempName = res.headers['content-disposition']
      const fileName = tempName.split('"')[1]

      const blob = new Blob([res.data], { type: 'application/octet-stream' })

      FileSaver.saveAs(blob, fileName)

      message.success(t('file_download.downloaded_successfully'))
    } catch (error) {
      message.error(t('file_download.download_failed'))
    }
  }

  const handleDeleteAll = async () => {
    setUpdating(true)

    for (const _sRF of selectedRowFiles) {
      await axios.delete(`/api/uploads/delete/${_sRF.key}`)

      setExistingFiles((prevState) => {
        const newList = prevState.filter((_pS) => _pS.key !== _sRF.key)
        setFilteredFiles(newList)
        return newList
      })
    }

    setShowCheckBoxes(false)
    setSelectedRowFiles([])
    setUpdating(false)
    message.success(t('file_download.selected_files_deleted'))
  }

  const clearSelected = () => {
    setShowCheckBoxes(false)
    setSelectedRowFiles([])
  }

  return (
    <>
      <Affix offsetTop={0}>
        <Row
          className={'page-title'}
          gutter={[32, 24]}
          style={{
            backgroundColor: isAffixed
              ? 'rgba(255,255,255,0.8)'
              : 'rgba(255,255,255,0.0)',
            boxShadow: isAffixed
              ? '10px 1px 15px rgba(128,128,128,0.5)'
              : 'none',
          }}
        >
          <Col flex="auto">
            <Title className={isAffixed ? 'page-title' : ''}>
              {' '}
              {t('file_download.documents')}
            </Title>
          </Col>
          <Col></Col>
        </Row>
      </Affix>
      <Row className>
        <Col span={24}>
          <Card>
            <Row style={{ marginBottom: '8px' }}>
              <Col span={12}>
                <Space>
                  {store.company.type ? (
                    ''
                  ) : (
                    <>
                      <Button
                        type="primary"
                        onClick={showModal}
                        icon={<PlusOutlined />}
                      >
                        {t('file_download.new_document')}
                      </Button>
                      {showCheckBoxes ? (
                        <Button type="danger" onClick={clearSelected}>
                          {t('file_download.close')}
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() => setShowCheckBoxes(true)}
                        >
                          {t('file_download.edit')}
                        </Button>
                      )}
                      {selectedRowFiles.length > 0 && (
                        <Popconfirm
                          title={t(
                            'file_download.hey_this_is_dangerous_action'
                          )}
                          onConfirm={handleDeleteAll}
                          okText={t('file_download.yes')}
                          placement="leftTop"
                          cancelText={t('file_download.no')}
                        >
                          <Button
                            loading={updating}
                            icon={<DeleteOutlined />}
                            danger
                          >
                            {t('file_download.delete_selected_files')}
                          </Button>
                        </Popconfirm>
                      )}
                    </>
                  )}
                </Space>
              </Col>
              <Col span={12}>
                <div style={{ float: 'right' }}>
                  <Search
                    placeholder={t('file_download.search')}
                    allowClear
                    onChange={onSearch}
                    style={{ width: '100%' }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  bordered
                  rowSelection={
                    showCheckBoxes
                      ? {
                          type: 'checkbox',
                          ...rowSelection,
                        }
                      : null
                  }
                  size="small"
                  className={'table-resizeable'}
                  components={components}
                  columns={resizableColumns}
                  loading={updating}
                  dataSource={filteredFiles}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        {visible && (
          <FileUploadForm
            visible={visible}
            handleOk={handleOk}
            confirmLoading={confirmLoading}
            handleCancel={handleCancel}
            setFiles={setFiles}
            setTitle={setTitle}
            documents={documents}
            title={title}
            setDescription={setDescription}
            description={description}
            titleExist={titleExist}
          />
        )}
        {visibleUpdate && (
          <UpdateForm
            confirmLoading={confirmLoading}
            setConfirmLoading={setConfirmLoading}
            setVisibleUpdate={setVisibleUpdate}
            visibleUpdate={visibleUpdate}
            fileForEdit={fileForEdit}
            setUpdating={setUpdating}
            setExistingFiles={setExistingFiles}
            setFilteredFiles={setFilteredFiles}
          />
        )}
      </Row>
    </>
  )
}

export default FileDownloads
