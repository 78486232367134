import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import PropertyModel from "../../../models/PropertyModel";
import YearInput from "../../Common/YearInput";

import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  AutoComplete,
  // Tooltip,
  Modal,
  Select,
  InputNumber,
  message,
  // Popconfirm,
} from "antd";
import {
  postal_codes,
  cities,
  cantons,
  regions,
} from "../../../data/AddressBook";
//translation
import { useTranslation } from "react-i18next";
import { criteria } from "../../../data/RatingCriteria";
import { ageRatingsByCriteria } from "../../../data/clienPropertyDetailModalData";
import ClientPropertyDetailController from "../../../controllers/ClientPropertyDetailController";
const { Option } = Select;

const { Title, Text } = Typography;

const ClientPropertyDetailModal = (props) => {
  const { t } = useTranslation("common"); //translation

  const {
    store,
    visible,
    setVisible,
    id,
    properties,
    portfolioId,
    // popConfirmRatingvisible,
    // setPopConfirmRatingvisible,
  } = props;

  const propertyAttributes = store.propertyAttributeMasters;

  const portfolio = store.portfolios.find((p) => p.id === portfolioId);

  const plzs = postal_codes
    .map((r) => {
      return { value: r.plz };
    })
    .filter(function (obj, index, self) {
      return (
        index ===
        self.findIndex(function (t) {
          return t["value"] === obj["value"];
        })
      );
    });
  const [form] = Form.useForm();
  const [plzOptions, setPlzOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [cantonOptions, setCantonOptions] = useState([]);

  const [address, setAddress] = useState("");
  const [plz, setPlz] = useState("");
  const [city, setCity] = useState("");
  const [canton, setCanton] = useState("");

  const [propertyNameFound, setPropertyNameFound] = useState(false);
  const [currentProperty, setCurrentProperty] = useState(
    PropertyModel.create({})
  );
  const [originalPropertyAttributes, setOriginalPropertyAttributes] = useState(
    []
  );
  const [InvalidRatings, setInvalidRatings] = useState([]);
  const [modalMessage, setModalMessage] = useState("");
  const [areaTotal, setAreaTotal] = useState(0.0);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const { getMissingRatingKeys } = ClientPropertyDetailController({
    t,
    form,
  });

  useEffect(() => {
    if (propertyAttributes.length == 0) {
      store.getPropertyAttributeList();
    }
    setPlzOptions([]);

    setCityOptions([]);

    setCantonOptions(
      cantons.map((r) => {
        return { value: r.id, name: r.name };
      })
    );

    form.resetFields();

    form.setFieldsValue({
      name: "",
      address: "",
      plz: "",
      city: "",
      canton: "",
      region: "",
      age: "",
      lastTotalRenovation: "",
      areaOffice: "",
      areaResidential: "",
      areaCommercial: "",
      areaBuilding: "",
      areaHealth: "",
    });
    setModalMessage("");
    return () => {};
  }, []);

  useEffect(() => {
    setModalMessage("");
    if (id === 0) {
      setCurrentProperty(PropertyModel.create({}));
    } else {
      setCurrentProperty(properties.find((p) => p.id === id));
    }

    return () => {
      setCurrentProperty(PropertyModel.create({}));
    };
  }, [props.id]);

  useEffect(() => {
    resetFields();
    if (currentProperty.id > 0) {
      currentProperty.getRatings().then((x) => {
        var m = getMissingRatingKeys(currentProperty.ratings);
        setInvalidRatings(m);
      });
      setOriginalPropertyAttributes(
        currentProperty.propertyAttributes.map((x) => x)
      );
    }

    return () => {};
  }, [currentProperty]);
  useEffect(() => {
    var m = getMissingRatingKeys(currentProperty.ratings);
    setInvalidRatings(m);
  }, [currentProperty.ratings, store.clientListUpdateOn, areaTotal]);

  const resetFields = () => {
    if (currentProperty.id === 0) {
      setPlzOptions([]);

      setCityOptions([]);

      setCantonOptions(
        cantons.map((r) => {
          return { value: r.id, name: r.name };
        })
      );

      form.resetFields();

      form.setFieldsValue({
        name: "",
        address: "",
        plz: "",
        city: "",
        canton: "",
        region: "",
        age: "",
        lastTotalRenovation: "",
        areaOffice: "",
        areaResidential: "",
        areaCommercial: "",
        areaBuilding: "",
        areaHealth: "",
        attributes: [],
      });
    } else {
      const {
        name,
        address,
        plz,
        city,
        canton,
        region,
        age,
        lastTotalRenovation,
        areaOffice,
        areaResidential,
        areaCommercial,
        areaBuilding,
        areaHealth,
        propertyAttributes,
      } = currentProperty;
      form.setFieldsValue({
        name,
        address,
        plz,
        city,
        canton,
        region,
        age: age == 0 ? "" : age,
        lastTotalRenovation:
          lastTotalRenovation == 1 ? "" : lastTotalRenovation,
        areaOffice: parseInt(areaOffice),
        areaResidential: parseInt(areaResidential),
        areaCommercial: parseInt(areaCommercial),
        areaBuilding: parseInt(areaBuilding),
        areaHealth: parseInt(areaHealth),
        attributes: propertyAttributes,
      });

      onChangePLZ(plz);
    }
    setAreaTotal(
      form.getFieldValue("areaOffice") +
        form.getFieldValue("areaResidential") +
        form.getFieldValue("areaCommercial") +
        form.getFieldValue("areaBuilding") +
        form.getFieldValue("areaHealth")
    );
    setModalMessage("");
  };

  const saveInitialRatings = (currentProperty, toRecalc = false) => {
    //transform form fields to array of {key,value,comment}
    var defaults = [];
    if (currentProperty.propertyAttributes.length > 0) {
      defaults = store.propertyDefaultRatings.filter((def) =>
        currentProperty.propertyAttributes.includes(def.attribute_id)
      );
    }
    const getDefaultInitialValue = (key) => {
      var ret = false;

      if (currentProperty.propertyAttributes.length > 0) {
        // this assumes that there could be multiple matches
        var defaultKeyRating = defaults
          .filter((def) => def.key === key && def.value > -1)
          .sort((a, b) => parseInt(a.value) - parseInt(b.value));

        if (defaultKeyRating && defaultKeyRating.length) {
          if (defaultKeyRating.length > 0) {
            // take the last element's .value
            var thisKeyRating = defaultKeyRating[defaultKeyRating.length - 1];

            if (ret === false || ret < thisKeyRating.value) {
              ret = thisKeyRating.value;

              console.log(
                "Matched:" +
                  key +
                  " = " +
                  thisKeyRating.attribute_id +
                  ":" +
                  thisKeyRating.key +
                  "=>" +
                  thisKeyRating.value
              );
            }
          }
        }
      }

      // age based rating calculation
      // if (ret == 0) {
      var thiskey = "";
      if (key.split(".".length == 3)) {
        thiskey = key.split(".").splice(0, 2).join(".");
      } else thiskey = key;

      //which age bracket does this property belong?
      var age_bracket = "";
      var this_age = currentProperty.age;
      if (this_age > 1) age_bracket = "> 1";
      // if (this_age < 1960) age_bracket = "< 1960";
      // if (this_age >= 1960 && this_age <= 1969) age_bracket = "1960-1969";
      // if (this_age >= 1970 && this_age <= 1987) age_bracket = "1970-1987";
      // if (this_age >= 1988 && this_age <= 1990) age_bracket = "1988-1990";
      // if (this_age >= 1991 && this_age <= 1994) age_bracket = "1991-1994";
      // if (this_age >= 1995 && this_age <= 2002) age_bracket = "1995-2002";
      // if (this_age >= 2003 && this_age <= 2006) age_bracket = "2003-2006";
      // if (this_age > 2006) age_bracket = "> 2006";

      // if (currentProperty.propertyAttributes.indexOf(14) > -1) {
      //   // Gebäude mit Abschattung
      //   if (this_age >= 1995 && this_age <= 2002) {
      //     age_bracket = "1995-2002 mit Abschattung";
      //   } else if (this_age >= 2003 && this_age <= 2006) {
      //     age_bracket = "2003-2006 mit Abschattung";
      //   } else if (this_age > 2006) {
      //     age_bracket = "> 2006 mit Abschattung";
      //   }
      // }

      // Rating Checker
      if (age_bracket !== "" && !toRecalc) {
        var bracket_criteria =
          ageRatingsByCriteria(currentProperty)[age_bracket];
        if (key in bracket_criteria) {
          if (ret === false || ret < bracket_criteria[key]) {
            ret = bracket_criteria[key];
            console.log("Matched:" + key + " = " + age_bracket + "=>" + ret);
          }
        }
      }
      // }

      return ret;
    };

    var ratings = [];
    var p_id = currentProperty.id;

    criteria.forEach((group) => {
      group.children.forEach((rating) => {
        if (rating.children) {
          rating.children.forEach((subrating) => {
            var newValue = getDefaultInitialValue(subrating.key);
            if (newValue !== false) {
              ratings.push({
                propertyId: p_id,
                key: subrating.key,
                value: newValue,
                comment: "",
              });
            }
          });
        } else {
          var newValue = getDefaultInitialValue(rating.key);
          if (newValue !== false) {
            ratings.push({
              propertyId: p_id,
              key: rating.key,
              value: newValue,
              comment: "",
            });
          }
        }
      });
    });

    var alldefaults = store.propertyDefaultRatings;
    originalPropertyAttributes.forEach((orig) => {
      // if the previously selected attrubte is no longer selected now,,,
      if (
        currentProperty.propertyAttributes.length == 0 ||
        currentProperty.propertyAttributes.includes(orig) == false
      ) {
        //then any rating connected to this attribute must be removed,
        var dr = alldefaults.filter(
          (d) => d.attribute_id == orig && d.value > -1
        );
        if (dr && dr.length) {
          dr.forEach((dre) => {
            ratings.push({
              propertyId: p_id,
              key: dre.key,
              value: -1,
              comment:
                "Related attribute was unchecked so remove related ratings",
            });
          });
        }
      }
    });

    if (ratings.find((r) => isNaN(r.value))) {
      console.log("Cannot save has missing rating value.");
    } else {
      if (ratings.find((r) => isNaN(r.value))) {
        console.log("Cannot save has missing rating value.");
      } else {
        ratings = [
          ...ratings,
          {
            propertyId: p_id,
            comment: "",
            key: "307.1",
          },
        ];
        currentProperty.updateRatings(ratings, toRecalc).then((success) => {
          console.log("Default ratings applied to property.");

          setOriginalPropertyAttributes(
            currentProperty.propertyAttributes.map((x) => x)
          );

          currentProperty.getRatings().then((success) => {
            var m = getMissingRatingKeys(currentProperty.ratings);
            setInvalidRatings(m);
          });
        });
      }
    }
  };

  const onPropertyFormFinish = async (values) => {
    await clearRatingsAndUpdate();
    if (values.lastTotalRenovation === "") values.lastTotalRenovation = 1;
    var totalArea =
      values.areaOffice +
      values.areaResidential +
      values.areaCommercial +
      values.areaBuilding +
      values.areaHealth;
    if (totalArea <= 0) {
      setModalMessage(t("client_portfolio.insufficient_area_defined_for_use"));
      return;
    }

    if (id === undefined || id === 0) {
      portfolio.addProperty({ portfolioId, ...values }).then((success) => {
        if (success) {
          saveInitialRatings(success);
          message.success(t("client_portfolio.new_property_added"));
          form.resetFields();
          form.setFieldsValue({
            name: "",
            address: "",
            plz: "",
            city: "",
            canton: "",
            region: "",
            age: "",
            lastTotalRenovation: "",
            areaOffice: 0,
            areaResidential: 0,
            areaCommercial: 0,
            areaBuilding: 0,
            areaHealth: 0,
            attributes: [],
          });
          // refreshList();
        } else {
          message.error(
            t("client_portfolio.property_info_could_not_be_updated")
          );
        }
      });
    } else {
      //currentProperty.setValues(values);
      currentProperty
        .update({ portfolioId: currentProperty.portfolioId, ...values })
        .then((success) => {
          if (success) {
            // saveInitialRatings(currentProperty)
            setModalMessage(t("client_property.property_info_updated"));
            message.success(t("client_property.property_info_updated"));
            // refreshList(true);
          } else {
            setModalMessage(
              t("client_portfolio.property_information_could_not_be_updated")
            );
            message.error(
              t("client_portfolio.property_information_could_not_be_updated")
            );
          }
        });
    }
  };
  const onPropertyFormFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [propertyIdValidationState, setPropertyIdValidationState] =
    useState("");
  const onSelectPLZ = (data) => {};
  const onChangePLZ = (data) => {
    setPlz(data);
    // if exact match then filter city autocomplete list
    if (plzs.find((r) => r.value === data)) {
      //set cantons
      var plzdata = postal_codes.find((p) => p.plz === data);
      if (plzdata) {
        var filteredCantons = cantons.filter(
          (canton) => canton.id === plzdata.cantonId
        );

        setCantonOptions(
          filteredCantons.length > 0
            ? filteredCantons.map((r) => {
                return { value: r.id, name: r.name };
              })
            : []
        );

        if (filteredCantons.length > 0) {
          setCanton(filteredCantons[0].id);

          form.setFieldsValue({ canton: filteredCantons[0].id });

          if (filteredCantons.length === 1) {
            var region = regions.find(
              (r) => r.id === filteredCantons[0].regionId
            );
            if (region) form.setFieldsValue({ region: region.name });
          }
        }
      } else {
        setCantonOptions(
          cantons.map((r) => {
            return { value: r.id, name: r.name };
          })
        );
      }

      var filteredCities = cities.filter((c) => c.plz === data);

      setCityOptions(
        filteredCities.map((r) => {
          return { value: r.city };
        })
      );

      if (filteredCities.length > 0) {
        setCity(filteredCities[0].city);
        form.setFieldsValue({ city: filteredCities[0].city });
      }
    } else {
      setCantonOptions(
        cantons.map((r) => {
          return { value: r.id, name: r.name };
        })
      );
      setCityOptions([]);
      if (currentProperty.id < 1)
        form.setFieldsValue({ city: "", canton: "", region: "" });
    }
  };
  const onSearchPLZ = (searchText) => {
    setPlzOptions(plzs.filter((r) => r.value.indexOf(searchText) > -1));
  };
  const onChangeCity = (data) => {
    setCity(data);
  };
  const onChangeCanton = (data) => {
    /* console.log("onChangeCanton", data); */
    var canton = cantons.find((c) => c.id === data);
    if (canton) {
      var region = regions.find((r) => r.id === canton.regionId);
      if (region) form.setFieldsValue({ region: region.name });
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setAreaTotal(
      form.getFieldValue("areaOffice") +
        form.getFieldValue("areaResidential") +
        form.getFieldValue("areaCommercial") +
        form.getFieldValue("areaBuilding") +
        form.getFieldValue("areaHealth")
    );

    if (
      changedValues.attributes ||
      allValues.areaResidential === 0 ||
      allValues.areaOffice === 0 ||
      allValues.areaCommercial === 0 ||
      allValues.areaBuilding === 0 ||
      allValues.areaHealth === 0
    ) {
      // check for Property Attribute exclusivity if there are changes in selected attributes

      var CheckedAttributes = allValues.attributes;

      // allow check if area  is non-zero
      //Wohenen: 2-areaResidential,BUrro: 3-areaOffice,Gewerbe 4-areaCommercial -

      // if (CheckedAttributes.indexOf(2) > -1) {
      //   if (allValues.areaResidential === 0) {
      //     CheckedAttributes.splice(CheckedAttributes.indexOf(2), 1);
      //   }
      // }
      // if (CheckedAttributes.indexOf(3) > -1) {
      //   if (allValues.areaOffice === 0) {
      //     CheckedAttributes.splice(CheckedAttributes.indexOf(3), 1);
      //   }
      // }
      // if (CheckedAttributes.indexOf(4) > -1) {
      //   if (allValues.areaCommercial === 0) {
      //     CheckedAttributes.splice(CheckedAttributes.indexOf(4), 1);
      //   }
      // }

      // exclusive ORs

      if (changedValues.attributes) {
        //[6:Radonwert <2% gemäss Radonkarte (weiss markiert),7:Radonmessung / Nachweis Einhaltung Grenzwert]
        // if (
        //   changedValues.attributes.indexOf(6) > -1 &&
        //   CheckedAttributes.indexOf(7) > -1
        // ) {
        //   CheckedAttributes.splice(CheckedAttributes.indexOf(6), 1);
        // }
        // if (
        //   changedValues.attributes.indexOf(7) > -1 &&
        //   CheckedAttributes.indexOf(6) > -1
        // ) {
        //   CheckedAttributes.splice(CheckedAttributes.indexOf(7), 1);
        // }
        //[8:Nach Minergie zertifiziert , 9:Nach Minergie-Eco zertifiziert]
        // if (
        //   CheckedAttributes.indexOf(8) > -1 &&
        //   CheckedAttributes.indexOf(9) > -1
        // ) {
        //   CheckedAttributes.splice(CheckedAttributes.indexOf(8), 1);
        // }
        //[10:GEAK A, 11:GEAK B/C]
        // if (
        //   CheckedAttributes.indexOf(10) > -1 &&
        //   CheckedAttributes.indexOf(11) > -1
        // ) {
        //   CheckedAttributes.splice(CheckedAttributes.indexOf(10), 1);
        // }
        //[12:Nach Minergie (A) zertifiziert, 13:Nach Minergie (P) zertifiziert]
        // if (
        //   CheckedAttributes.indexOf(12) > -1 &&
        //   CheckedAttributes.indexOf(13) > -1
        // ) {
        //   CheckedAttributes.splice(CheckedAttributes.indexOf(12), 1);
        // }
      }
      form.setFieldsValue({ attributes: CheckedAttributes });
    }
  };

  useEffect(() => {
    (async () => {
      if (
        currentProperty.ratingChanged > 1 &&
        currentProperty.ratings.length === 0
      ) {
        // await onPropertyFormFinish(form.getFieldsValue());
        setTimeout(() => {
          saveInitialRatings(currentProperty, 1);
          setConfirmLoading(false);
          // setPopConfirmRatingvisible(false);
        }, 1000);
      }
    })();
  }, [currentProperty.ratingChanged, currentProperty.ratings.length]);

  const clearRatingsAndUpdate = async () => {
    // setConfirmLoading(true);
    currentProperty.clearRatings();
  };

  const checkExistingProperty = (e) => {
    const _name = e.currentTarget.value;

    const _portfolio = store.portfolios.find((_p) => _p.id === portfolioId);

    const found = _portfolio.properties.findIndex(
      (_pp) => _pp.name.toLowerCase() === _name.toLowerCase()
    );

    if (found > -1) {
      setPropertyNameFound(true);
    } else {
      setPropertyNameFound(false);
    }
  };

  const CURRENT_YEAR = new Date().getFullYear();

  return (
    <Modal
      title={false}
      centered
      visible={visible}
      okText={t("client_portfolio.submit")}
      closable={true}
      onCancel={() => {
        // setPopConfirmRatingvisible(false);
        setTimeout(() => {
          setVisible(false);
        }, 100);
      }}
      footer={false}
      width={1300}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onPropertyFormFinish}
        onFinishFailed={onPropertyFormFinishFailed}
        onValuesChange={onValuesChange}
      >
        <Row type="flex" align="left">
          <Title
            style={{
              display: "block !important",
              textAlign: "left",
              width: "100%",
            }}
            level={3}
          >
            {!id || id === 0
              ? t("client_property.new")
              : t("client_property.existing")}{" "}
            {t("client_property.property")}
          </Title>
        </Row>

        <Row gutter={16}>
          <Col span={14}>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item
                  labelCol={16}
                  label={
                    <>
                      {t("client_property.property_id")}
                      {propertyNameFound ? (
                        <small style={{ color: "red", marginLeft: "10px" }}>
                          {t("propertyExist")}
                        </small>
                      ) : (
                        ""
                      )}
                    </>
                  }
                  name="name"
                  // validateStatus={propertyIdValidationState}
                  // hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t(
                        "client_portfolio.please_enter_the_property_id"
                      ),
                    },
                  ]}
                >
                  <Input onChange={checkExistingProperty} />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label={t("client_portfolio.year")}
                  name="age"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "client_property.please_enter_construction_year"
                      ),
                    },
                  ]}
                >
                  <YearInput />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label={t("client_portfolio.last_total_renovation")}
                  name="lastTotalRenovation"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: t(
                  //       "client_portfolio.please_enter_total_renovation_year"
                  //     ),
                  //   },
                  // ]}
                >
                  <YearInput />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  labelCol={16}
                  label={t("client_portfolio.address")}
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: t("client_property.please_enter_client_add"),
                    },
                  ]}
                >
                  <Input value={address} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  label={t("client_property.plz")}
                  name="plz"
                  rules={[
                    {
                      required: true,
                      message: t("client_property.please_enter_postal_code"),
                    },
                  ]}
                >
                  <AutoComplete
                    options={plzOptions}
                    onSelect={onSelectPLZ}
                    onSearch={onSearchPLZ}
                    onChange={onChangePLZ}
                    placeholder={t("client_property.plz")}
                  >
                    <Input value={plz} />
                  </AutoComplete>
                </Form.Item>
                {/* <Form.Item
                  label={t("client_property.canton")}
                  name="canton"
                  rules={[
                    {
                      required: true,
                      message: t("client_property.please_enter_canton"),
                    },
                  ]}
                >
                  <Select
                    onChange={onChangeCanton}
                    placeholder={t("client_property.canton")}
                  >
                    {cantonOptions.map((canton) => (
                      <Option key={canton.value} value={canton.value}>
                        {canton.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item> */}
              </Col>
              <Col span={16}>
                <Form.Item
                  label={t("client_property.city")}
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: t("client_property.please_enter_city"),
                    },
                  ]}
                >
                  <AutoComplete
                    options={cityOptions}
                    onChange={onChangeCity}
                    placeholder={t("client_property.city")}
                  >
                    <Input value={city} />
                  </AutoComplete>
                </Form.Item>

                <Form.Item
                  style={{ display: "none" }}
                  label={t("client_property.region")}
                  name="region"
                >
                  <Input readOnly={true} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={t("client_property.canton")}
                  name="canton"
                  rules={[
                    {
                      required: true,
                      message: t("client_property.please_enter_canton"),
                    },
                  ]}
                >
                  <Select
                    onChange={onChangeCanton}
                    placeholder={t("client_property.canton")}
                  >
                    {cantonOptions.map((canton) => (
                      <Option key={canton.value} value={canton.value}>
                        {canton.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <label>{t("client_property.area_per_usage_form")} </label>
                <div className="ant-table-wrapper">
                  <div
                    className="ant-table ant-table-small ant-table-bordered"
                    style={{ borderRight: "solid 1px rgb(240,240,240)" }}
                  >
                    <div className="ant-table-container">
                      <table style={{ tableLayout: "auto" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_portfolio.wohnen")} (m<sup>2</sup>)
                            </th>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_portfolio.büro")} (m<sup>2</sup>)
                            </th>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_portfolio.gewerbe")} (m<sup>2</sup>)
                            </th>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_portfolio.bildung")} (m<sup>2</sup>)
                            </th>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_portfolio.gesundheit")} (m<sup>2</sup>)
                            </th>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_portfolio.total")} (m<sup>2</sup>)
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ant-table-tbody">
                          <tr className="ant-table-row ant-table-row-level-0">
                            <td className="ant-table-cell">
                              {" "}
                              <Form.Item
                                style={{ marginBottom: "0px" }}
                                name="areaResidential"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "client_property.please_enter_residential_space"
                                    ),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={999999}
                                  step={1}
                                  formatter={(value) =>
                                    !isNaN(value) &&
                                    value &&
                                    parseInt(value).toLocaleString("de-ch")
                                  }
                                />
                              </Form.Item>
                            </td>
                            <td className="ant-table-cell">
                              <Form.Item
                                style={{ marginBottom: "0px" }}
                                name="areaOffice"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "client_property.please_enter_office_space"
                                    ),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={999999}
                                  step={1}
                                  formatter={(value) =>
                                    !isNaN(value) &&
                                    value &&
                                    parseInt(value).toLocaleString("de-ch")
                                  }
                                />
                              </Form.Item>
                            </td>
                            <td className="ant-table-cell">
                              <Form.Item
                                style={{ marginBottom: "0px" }}
                                name="areaCommercial"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "client_property.please_enter_commercial_space"
                                    ),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={999999}
                                  step={1}
                                  formatter={(value) =>
                                    !isNaN(value) &&
                                    value &&
                                    parseInt(value).toLocaleString("de-ch")
                                  }
                                />
                              </Form.Item>
                            </td>
                            <td className="ant-table-cell">
                              <Form.Item
                                style={{ marginBottom: "0px" }}
                                name="areaBuilding"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "client_property.please_enter_bildung_space"
                                    ),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={999999}
                                  step={1}
                                  formatter={(value) =>
                                    !isNaN(value) &&
                                    value &&
                                    parseInt(value).toLocaleString("de-ch")
                                  }
                                />
                              </Form.Item>
                            </td>
                            <td className="ant-table-cell">
                              <Form.Item
                                style={{ marginBottom: "0px" }}
                                name="areaHealth"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "client_property.please_enter_gesundheit_space"
                                    ),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={999999}
                                  step={1}
                                  formatter={(value) =>
                                    !isNaN(value) &&
                                    value &&
                                    parseInt(value).toLocaleString("de-ch")
                                  }
                                />
                              </Form.Item>
                            </td>
                            <td className="ant-table-cell" align={"center"}>
                              {!isNaN(areaTotal) &&
                                areaTotal &&
                                parseInt(areaTotal).toLocaleString("de-ch", {
                                  minimumFractionDigits: 0,
                                })}
                              m<sup>2</sup>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Form.Item
              labelCol={24}
              label={t("property.attributes")}
              name="attributes"
            >
              <Checkbox.Group style={{ width: "100%", marginLeft: "15px" }}>
                <Row gutter={[0, 10]}>
                  <Text>{t("property.attribute_category.allgemein")}</Text>
                  {propertyAttributes.map((att) => {
                    if (att.active && att.attributeCategoryId === 1) {
                      return (
                        <Col key={att.id} span={24}>
                          <Checkbox value={att.id}>
                            {t("property.attributenames." + att.id)}
                          </Checkbox>
                        </Col>
                      );
                    }
                  })}
                  <Text style={{ marginTop: "10px" }}>
                    {t("property.attribute_category.zertifizierungen")}
                  </Text>
                  {propertyAttributes.map((att) => {
                    if (att.active && att.attributeCategoryId === 2) {
                      return (
                        <Col key={att.id} span={24}>
                          <Checkbox value={att.id}>
                            {t("property.attributenames." + att.id)}
                          </Checkbox>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            {/* {currentProperty.ratings.length < 13 ? (
              <h3 style={{ color: "red" }}>
                {t("property.rating_values_missing")}
              </h3>
            ) : (
              ""
            )} */}
            {id > 0 && InvalidRatings.length > 0 && (
              // <Card
              //   style={{
              //     color: "red",
              //     marginLeft: "20px",
              //     marginRight: "20px",
              //   }}
              // >
              <>
                <h3 style={{ color: "red" }}>
                  {t("property.rating_values_missing")}
                </h3>
              </>
              // {InvalidRatings.length > 6 ? (
              //   <ol>
              //     {InvalidRatings.map((i, _i) => (
              //       <li key={_i} style={{ textTransform: "uppercase" }}>
              //         {i}
              //       </li>
              //     ))}
              //   </ol>
              // ) : (
              //   ""
              // )}
              // </Card>
            )}
          </Col>
        </Row>
        <Row gutter={[16, 0]} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <div align={"right"}>
              <Form.Item>
                <Button
                  type="default"
                  htmlType="button"
                  style={{
                    display: "inline-block",
                    width: "calc(14% - 16px)",
                    margin: "0 8px 0",
                  }}
                  onClick={() => {
                    // setPopConfirmRatingvisible(false);
                    setTimeout(() => {
                      setVisible(false);
                    }, 100);
                  }}
                >
                  {t("client_portfolio.dismiss")}
                </Button>
                <Button
                  type="default"
                  htmlType="button"
                  style={{
                    display: "inline-block",
                    width: "calc(14% - 16px)",
                    margin: "0 8px 0",
                  }}
                  onClick={() => {
                    resetFields();
                  }}
                >
                  {" "}
                  {t("client_portfolio.reset")}
                </Button>

                {/* <Popconfirm
                className="mv-popup"
                title={() => (
                  <>
                    <p style={{ paddingTop: "1px" }}>
                      {t("recalculateConfirm")}
                    </p>
                    {t("client_property.property_info_updated")}
                  </>
                )}
                visible={popConfirmRatingvisible}
                onConfirm={() => clearRatingsAndUpdate(currentProperty)}
                okButtonProps={{ loading: confirmLoading }}
                onCancel={() => setPopConfirmRatingvisible(false)}
              >
                <Button
                  type="danger"
                  onClick={() => setPopConfirmRatingvisible(true)}
                  style={{
                    display: "inline-block",
                    width: "calc(24% - 16px)",
                    margin: "0 8px",
                  }}
                >
                  {t("recalculate")}
                </Button>
              </Popconfirm> */}
                <Button
                  type="primary"
                  // onClick={() => clearRatingsAndUpdate(currentProperty)}
                  htmlType="submit"
                  style={{
                    display: "inline-block",
                    width: "calc(14% - 16px)",
                    margin: "0 8px",
                  }}
                >
                  {t("client_portfolio.save")}
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
      <div>{/* <p align={"middle"}>{modalMessage}</p> */}</div>
    </Modal>
  );
};

export default observer(ClientPropertyDetailModal);
