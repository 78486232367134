import { types, flow, getParent } from "mobx-state-tree";
import axios from "axios";
import PropertyRatingModel from "./PropertyRatingModel";
import PotentialPropertyRatingModel from "./PotentialPropertyRatingModel";
import { getCompanyRating } from "../data/RatingCriteria";
import PropertyAuditLogModel from "./PropertyAuditLogModel";

const PropertyModel = types
  .model("PropertyModel", {
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),

    //temporal
    age: types.optional(types.number, 0),
    lastTotalRenovation: types.optional(types.number, 1),

    //geo
    address: types.optional(types.string, ""),
    plz: types.optional(types.string, ""),
    city: types.optional(types.string, ""),
    canton: types.optional(types.string, ""),
    region: types.optional(types.string, ""),
    // In Square Meters
    areaOffice: types.optional(types.number, 0),
    areaCommercial: types.optional(types.number, 0),
    areaResidential: types.optional(types.number, 0),
    areaBuilding: types.optional(types.number, 0),
    areaHealth: types.optional(types.number, 0),

    // a Property is part of a Portfolio
    portfolioId: types.optional(types.number, 0),

    ratings: types.optional(types.array(PropertyRatingModel), []),
    potentialRatings: types.optional(
      types.array(PotentialPropertyRatingModel),
      []
    ),
    ratingChanged: 1,
    auditLogs: types.optional(types.array(PropertyAuditLogModel), []),

    propertyAttributes: types.optional(types.array(types.number), []),
  })
  .actions((self) => ({
    update: flow(function* (values) {
      const {
        name,
        portfolioId,
        address,
        age,
        lastTotalRenovation,
        plz,
        city,
        canton,
        region,
        areaOffice,
        areaResidential,
        areaCommercial,
        areaBuilding,
        areaHealth,
        attributes,
      } = values;

      if (areaCommercial > 0 && !attributes.includes(21)) attributes.push(21);

      const { id } = self;
      const { data: result, status: responseStatus } = yield axios
        .put(`/api/properties/${id}`, {
          name,
          portfolioId,
          age,
          lastTotalRenovation,
          address,
          plz,
          city,
          canton,
          region,
          areaOffice,
          areaResidential,
          areaCommercial,
          areaBuilding,
          areaHealth,
          attributes: attributes ? attributes : [],
        })
        .then((response) => response)
        .catch((error) => error.response);

      if (responseStatus !== 200) return false;

      if (result.property) {
        const p = result.property;
        self.name = p.name;
        self.address = p.address;
        self.age = p.age;
        self.lastTotalRenovation = p.lastTotalRenovation;
        self.plz = p.plz;
        self.city = p.city;
        self.canton = p.canton;
        self.region = p.region;
        self.areaOffice = parseFloat(p.areaOffice);
        self.areaResidential = parseFloat(p.areaResidential);
        self.areaCommercial = parseFloat(p.areaCommercial);
        self.areaBuilding = parseFloat(p.areaBuilding);
        self.areaHealth = parseFloat(p.areaHealth);
        self.portfolioId = p.portfolioId;
        self.propertyAttributes = p.propertyAttributeDetails.map(
          (pa) => pa.attribute_id
        );
      }
      return true;
    }),

    clearRatings: function () {
      self.ratings = [];
      self.ratingChanged++;
    },

    getRatings: flow(function* () {
      try {
        const {
          data: result,
          status: responseStatus,
          // statusText,
        } = yield axios
          .get(`/api/ratings/${self.id}`)

          .then((response) => response)
          .catch((error) => error.response);

        //setTimeout(function(){
        // self.status =isNaN(result)?1:result;
        if (responseStatus !== 200) return false;

        if (result.ratings) {
          self.ratings = [];
          result.ratings.forEach((c) => {
            const { id, key, value, comment, potential_comment, indexChanged } =
              c;
            var propertyRating = PropertyRatingModel.create({
              id,
              key,
              value: parseFloat(value),
              comment,
              potential_comment,
              indexChanged,
            });
            self.ratings.push(propertyRating);
          });

          return true;
        } else return false;
      } catch (error) {
        return false;
      }
    }),

    getIndexedRatings: flow(function* () {
      try {
        const {
          data: result,
          status: responseStatus,
          // statusText,
        } = yield axios
          .get(`/api/ratings/indexed/${self.id}`)

          .then((response) => response)
          .catch((error) => error.response);

        //setTimeout(function(){
        // self.status =isNaN(result)?1:result;
        if (responseStatus !== 200) return false;

        if (result.ratings) {
          return result.ratings;
        } else return false;
      } catch (error) {
        return false;
      }
    }),

    updateRatings: flow(function* (values, toRecalc = false) {
      //values is an array of {key:string,value:number,comment:string}
      const { id } = self;

      let _url = "/api/ratings/";

      if (toRecalc) {
        _url = "/api/ratings/recalculate/";
      }

      const { data: result, status: responseStatus } = yield axios
        .post(`${_url}${id}`, {
          values,
        })
        .then((response) => response)
        .catch((error) => error.response);

      if (responseStatus !== 200) return false;

      if (result.ratings) {
        self.ratings = [];
        result.ratings.forEach((c) => {
          const { id, key, value, comment, potential_comment, indexChanged } =
            c;
          var propertyRating = PropertyRatingModel.create({
            id,
            key,
            value: parseFloat(value),
            comment,
            potential_comment,
            indexChanged,
          });
          self.ratings.push(propertyRating);
        });

        return true;
      }
      return false;
    }),

    resetIndexChanged: flow(function* (values) {
      //values is an array of property ids [1000,1001...]

      try {
        const res = yield axios.put("/api/ratings/resetIndexChanged", {
          values,
        });

        const _updatedRatings = [];
        self.ratings.forEach((c) => {
          const { id, key, value, comment, potential_comment } = c;
          var propertyRating = PropertyRatingModel.create({
            id,
            key,
            value: parseFloat(value),
            comment,
            potential_comment,
            indexChanged: 0,
          });
          _updatedRatings.push(propertyRating);
        });
        self.ratings = _updatedRatings;

        return res;
      } catch (error) {
        return error;
      }
    }),

    getPotentialRatings: flow(function* () {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .get(`/api/potentialratings/${self.id}`)

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.potentialRatings) {
        self.potentialRatings = [];
        result.potentialRatings.forEach((c) => {
          const { id, key, value, comment } = c;
          var potentialPropertyRating = PotentialPropertyRatingModel.create({
            id,
            key,
            value: parseFloat(value),
            comment,
          });
          self.potentialRatings.push(potentialPropertyRating);
        });

        return true;
      } else return false;
    }),
    updatePotentialRatings: flow(function* (values) {
      //values is an array of {key:string,value:number,comment:string}
      const { id } = self;
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .post(`/api/potentialratings/${id}`, {
          values,
        })
        .then((response) => response)
        .catch((error) => error.response);

      if (responseStatus !== 200) return false;

      if (result.potentialRatings) {
        self.potentialRatings = [];
        result.potentialRatings.forEach((c) => {
          const { id, key, value, comment } = c;
          var potentialPropertyRating = PotentialPropertyRatingModel.create({
            id,
            key,
            value: parseFloat(value),
            comment,
          });
          self.potentialRatings.push(potentialPropertyRating);
        });

        return true;
      }
      return false;
    }),

    getAuditLogs: flow(function* () {
      try {
        const {
          data: result,
          status: responseStatus,
          // statusText,
        } = yield axios
          .get(`/api/auditlogs/${self.id}`)

          .then((response) => response)
          .catch((error) => error.response);

        //setTimeout(function(){
        // self.status =isNaN(result)?1:result;
        if (responseStatus !== 200) return false;

        if (result.auditLogs) {
          self.auditLogs = [];
          result.auditLogs.forEach((log) => {
            self.auditLogs.push(PropertyAuditLogModel.create({ ...log }));
          });

          return true;
        } else return false;
      } catch (error) {
        return false;
      }
    }),

    addAuditLog: flow(function* (values) {
      //values is an array of {key:string,value:number,comment:string}
      const { id } = self;
      const { data: result, status: responseStatus } = yield axios
        .post(`/api/auditlogs`, {
          propertyId: id,
          ...values,
        })
        .then((response) => response)
        .catch((error) => error.response);

      if (responseStatus !== 200) return false;

      if (result.auditLog) {
        self.auditLogs.push(
          PropertyAuditLogModel.create({ ...result.auditLog })
        );

        return true;
      }
      return false;
    }),
    removeAuditLog: flow(function* (id) {
      //values is an array of {key:string,value:number,comment:string}

      const { data: result, status: responseStatus } = yield axios
        .post(`/api/auditlogs/delete/${id}`, {})
        .then((response) => response)
        .catch((error) => error.response);

      if (responseStatus !== 200) return false;

      if (result) {
        self.auditLogs.splice(
          self.auditLogs.findIndex((u) => u.id === id),
          1
        );

        return true;
      }
      return false;
    }),

    remove: flow(function* () {
      var parent = getParent(self, 2);
      return parent.removeProperty(self);
    }),

    setName(value) {
      self.name = value;
    },

    setAddress(value) {
      self.address = value;
    },

    setPlz(value) {
      self.plz = value;
    },

    setCity(value) {
      self.city = value;
    },

    setCanton(value) {
      self.canton = value;
    },

    setPortfolioId(value) {
      self.portfolioId = value;
    },

    SetAreaOffice(value) {
      self.areaOffice = value;
    },
    SetAreaCommercial(value) {
      self.areaCommercial = value;
    },
    SetAreaResidential(value) {
      self.areaResidential = value;
    },
    SetAreaBuilding(value) {
      self.areaBuilding = value;
    },
    SetAreaHealth(value) {
      self.areaHealth = value;
    },

    setValues(values) {
      for (var p in values) {
        self[p] = values[p];
      }
    },
  }))
  .views((self) => {
    return {
      get key() {
        return self.id;
      },

      get rating() {
        return getCompanyRating(self);
        /*  if (!self.isAlive) return 0;
        var ratingCount = 0;
        var sumRating = 0;
        try {
          criteria.forEach((g) => {
            g.children.forEach((c) => {
              ratingCount++;
              // var value = 0;
              if (c.children) {
                //compute avg from subratings first
                var sumSubrating = 0;
                c.children.forEach((s) => {
                  var subrate = self.ratings.find((r) => r.key === s.key);
                  //subrate key will always be xxx.yyy.zzz,, get z then subtract 1 to get equiv factor 1- areaOfficePercentage...
                  var factor = [
                    self.areaOfficePercentage,
                    self.areaResidentialPercentage,
                    self.areaCommercialPercentage,
                  ][parseInt(subrate.key.split(".")[2]) - 1];

                  if (subrate) {
                    sumSubrating += parseFloat(subrate.value) * factor;
                  }
                });
                //sumRating += parseFloat(sumSubrating / c.children.length);
              } else {
                var ar = self.ratings.find((r) => r.key === c.key);
                if (ar) {
                  sumRating += parseFloat(ar.value);
                }
              }
            });
          });

          sumRating = parseFloat((sumRating / ratingCount).toFixed(2));
        } catch (err) {
          console.log(
            "ERROR: While Calculating property rating." + JSON.stringify(err)
          );
        }
        return sumRating; */
      },

      get totalArea() {
        return (
          self.areaOffice +
          self.areaResidential +
          self.areaCommercial +
          self.areaBuilding +
          self.areaHealth
        );
      },
      get areaOfficePercentage() {
        if (this.totalArea === 0) return 0;

        return self.areaOffice / self.totalArea;
      },
      get areaResidentialPercentage() {
        if (this.totalArea === 0) return 0;

        return self.areaResidential / self.totalArea;
      },
      get areaCommercialPercentage() {
        if (this.totalArea === 0) return 0;

        return self.areaCommercial / self.totalArea;
      },
      get areaBuildingPercentage() {
        if (this.totalArea === 0) return 0;

        return self.areaBuilding / self.totalArea;
      },
      get areaHealthPercentage() {
        if (this.totalArea === 0) return 0;

        return self.areaHealth / self.totalArea;
      },
    };
  });

export default PropertyModel;
