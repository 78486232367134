import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Form, Input, InputNumber, Tooltip } from "antd";

//translation
import { useTranslation } from "react-i18next";
import { COLORS_SERIES } from "../../../data/constants";

const { TextArea } = Input;

const RatingRowPerUsage = ({
  criteria,
  setHelpDescription,
  form,
  indexedRatings,
  groupName,
  index,
  blackOrWhiteText,
  currentProperty,
  setIsFormChanged,
}) => {
  const { t } = useTranslation("common"); //translation

  // const COLORS_SERIES = { G: "#ff5555", U: "#aacc88", W: "#99ccff" };
  const row_code = groupName[0] + (index + 1);
  const code_color = COLORS_SERIES[groupName[0]];
  const [selectedRow, setSelectedRow] = useState("");
  const [inputRows, setInputRows] = useState(1);
  const [initialRating, setInitialRating] = useState(0);
  const [initialComment, setInitialComment] = useState("");

  // eslint-disable-next-line
  // useEffect(() => {
  //   setTimeout(() => {
  //     setInitialRating(
  //       parseFloat(form.getFieldValue(criteria.key + "_rating"))
  //     );
  //   }, 1000);
  // }, [currentProperty]);

  const checkForRatingChanges = (key) => {
    const newRating = parseFloat(form.getFieldValue(key + "_rating"));
    console.log(initialRating, " = initialRating |", newRating, " = newRating");

    if (isNaN(initialRating) && isNaN(newRating)) {
      // setIsFormChanged(false);
      console.log("unchanged");
    } else if (initialRating !== newRating) {
      console.log("value changed!");
      setIsFormChanged(true);
    } else {
      console.log("unchanged");
      // setIsFormChanged(false);
    }
  };

  const checkForCommentChanges = (key) => {
    const newComment = form.getFieldValue(key + "_comment");
    console.log(
      initialComment,
      " = initialComment |",
      newComment,
      " = newComment"
    );

    if (initialComment !== newComment) {
      console.log("value changed");
      setIsFormChanged(true);
    } else {
      console.log("unchanged");
    }
  };
  const updateRows = (selected, row) => {
    setSelectedRow(selected);
    setInputRows(row);
  };

  const isIndexed = (key) => {
    if (!indexedRatings || indexedRatings.length === 0) return true;

    var this_rating = parseFloat(form.getFieldValue(key + "_rating"));
    var found = false;
    var matchRating = indexedRatings.find((ir) => {
      return ir.key === key && parseFloat(ir.value) === parseFloat(this_rating);
    });

    if (matchRating) {
      found = true;
    } else {
      found = false;
    }
    return found;
  };

  return (
    <React.Fragment key={criteria.key}>
      <tr key={criteria.key} className="ant-table-row ant-table-row-level-0">
        <td
          rowSpan={
            6 -
            [
              currentProperty.areaCommercial,
              currentProperty.areaOffice,
              currentProperty.areaResidential,
              currentProperty.areaBuilding,
              currentProperty.areaHealth,
            ].filter((_) => _ === 0).length
          }
          style={{
            textAlign: "center",
            maxWidth: "50px",
            backgroundColor: code_color,
            color: "white",
            // color: `${blackOrWhiteText(code_color)}`,
          }}
          className="ant-table-cell"
        >
          {row_code}
        </td>
        <td
          className="ant-table-cell"
          onClick={() => {
            setHelpDescription(criteria.key);
          }}
        >
          {t("criteria." + criteria.key.split(".").join("_") + ".title")}
        </td>
        <td className="ant-table-cell" style={{ textAlign: "center" }}>
          {parseFloat(
            form.getFieldValue(criteria.key + "_computedRating")
          ).toFixed(2)}
          {/*   {getAverage(criteria.key)} */}
          {/*  <Form.Item
              key={criteria.key + "_rating"}
              style={{ marginBottom: "0px" }}
              name={criteria.key + "_rating"}
             
            >
              <Input readOnly />
            </Form.Item> */}
        </td>
        <td className="ant-table-cell"></td>
        <td className="ant-table-cell"></td>
        {/* <td className="ant-table-cell">{criteria.key}</td> */}
      </tr>
      {criteria.children &&
        criteria.children.map((c, _i) => {
          if (
            (_i === 0 && currentProperty.areaResidential > 0) ||
            (_i === 1 && currentProperty.areaOffice > 0) ||
            (_i === 2 && currentProperty.areaCommercial > 0) ||
            (_i === 3 && currentProperty.areaBuilding > 0) ||
            (_i === 4 && currentProperty.areaHealth > 0)
          ) {
            return (
              <tr key={c.key} className="ant-table-row ant-table-row-level-0">
                <td
                  className="ant-table-cell"
                  onClick={() => {
                    setHelpDescription(c.key);
                  }}
                >
                  <div style={{ marginLeft: "50px" }}>
                    {" "}
                    {t("criteria." + c.key.split(".").join("_") + ".title")}
                  </div>
                </td>
                <td className="ant-table-cell" style={{ textAlign: "center" }}>
                  <Form.Item
                    key={c.key + "_rating"}
                    style={{ marginBottom: "0px" }}
                    name={c.key + "_rating"}
                    // Raffi 24.03.2021
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: t("admin_rating.please_enter_value"),
                    //   },
                    // ]}
                  >
                    <InputNumber
                      className={
                        "text-center" +
                        (!isIndexed(c.key) ? " rating_not_indexed" : "")
                      }
                      formatter={(value) =>
                        `${
                          value.toString().length == 0
                            ? ""
                            : isNaN(Math.round(value * 100) / 100)
                            ? ""
                            : Math.round(value * 100) / 100
                        }`
                      }
                      min={0}
                      max={3}
                      step={1}
                      onClick={() =>
                        setInitialRating(
                          parseFloat(form.getFieldValue(c.key + "_rating"))
                        )
                      }
                      onChange={() => checkForRatingChanges(c.key)}
                      onFocus={() => {
                        setHelpDescription(c.key);
                        setInitialRating(
                          parseFloat(form.getFieldValue(c.key + "_rating"))
                        );
                      }}
                    />
                  </Form.Item>
                </td>
                <td
                  className="ant-table-cell"
                  onClick={() => {
                    setHelpDescription(c.key);
                  }}
                >
                  <Tooltip
                    placement="right"
                    title={form.getFieldValue(c.key + "_comment")}
                  >
                    <Form.Item
                      style={{ marginBottom: "0px" }}
                      name={c.key + "_comment"}
                    >
                      {/* <TextArea
                      autoSize={{ minRows: rows, maxRows: rows }}
                      onClick={() => updateRows(10)}
                      onBlur={() => updateRows(1)}
                    /> */}
                      <textarea
                        key={c.key}
                        rows={selectedRow === c.key ? inputRows : 1}
                        className="textarea"
                        onClick={() => {
                          setInitialComment(
                            form.getFieldValue(c.key + "_comment")
                          );
                          updateRows(c.key, 10);
                        }}
                        onBlur={() => updateRows(c.key, 1)}
                        onChange={() => checkForCommentChanges(c.key)}
                        tabIndex={-1}
                      ></textarea>
                    </Form.Item>
                  </Tooltip>
                </td>
                <td className="ant-table-cell"></td>
              </tr>
            );
          }

          return;
        })}
    </React.Fragment>
  );
};

export default observer(RatingRowPerUsage);
