const postal_codes = [
  { plz: "1000", cantonId: "VD" },
  { plz: "1003", cantonId: "VD" },
  { plz: "1004", cantonId: "VD" },
  { plz: "1005", cantonId: "VD" },
  { plz: "1006", cantonId: "VD" },
  { plz: "1007", cantonId: "VD" },
  { plz: "1008", cantonId: "VD" },
  { plz: "1008", cantonId: "VD" },
  { plz: "1009", cantonId: "VD" },
  { plz: "1010", cantonId: "VD" },
  { plz: "1011", cantonId: "VD" },
  { plz: "1012", cantonId: "VD" },
  { plz: "1015", cantonId: "VD" },
  { plz: "1018", cantonId: "VD" },
  { plz: "1020", cantonId: "VD" },
  { plz: "1022", cantonId: "VD" },
  { plz: "1023", cantonId: "VD" },
  { plz: "1024", cantonId: "VD" },
  { plz: "1025", cantonId: "VD" },
  { plz: "1026", cantonId: "VD" },
  { plz: "1026", cantonId: "VD" },
  { plz: "1027", cantonId: "VD" },
  { plz: "1028", cantonId: "VD" },
  { plz: "1029", cantonId: "VD" },
  { plz: "1030", cantonId: "VD" },
  { plz: "1031", cantonId: "VD" },
  { plz: "1032", cantonId: "VD" },
  { plz: "1033", cantonId: "VD" },
  { plz: "1034", cantonId: "VD" },
  { plz: "1035", cantonId: "VD" },
  { plz: "1036", cantonId: "VD" },
  { plz: "1037", cantonId: "VD" },
  { plz: "1038", cantonId: "VD" },
  { plz: "1040", cantonId: "VD" },
  { plz: "1040", cantonId: "VD" },
  { plz: "1040", cantonId: "VD" },
  { plz: "1041", cantonId: "VD" },
  { plz: "1041", cantonId: "VD" },
  { plz: "1041", cantonId: "VD" },
  { plz: "1041", cantonId: "VD" },
  { plz: "1041", cantonId: "VD" },
  { plz: "1041", cantonId: "VD" },
  { plz: "1042", cantonId: "VD" },
  { plz: "1042", cantonId: "VD" },
  { plz: "1042", cantonId: "VD" },
  { plz: "1043", cantonId: "VD" },
  { plz: "1044", cantonId: "VD" },
  { plz: "1045", cantonId: "VD" },
  { plz: "1046", cantonId: "VD" },
  { plz: "1047", cantonId: "VD" },
  { plz: "1052", cantonId: "VD" },
  { plz: "1053", cantonId: "VD" },
  { plz: "1053", cantonId: "VD" },
  { plz: "1054", cantonId: "VD" },
  { plz: "1055", cantonId: "VD" },
  { plz: "1058", cantonId: "VD" },
  { plz: "1059", cantonId: "VD" },
  { plz: "1061", cantonId: "VD" },
  { plz: "1062", cantonId: "VD" },
  { plz: "1063", cantonId: "VD" },
  { plz: "1063", cantonId: "VD" },
  { plz: "1063", cantonId: "VD" },
  { plz: "1063", cantonId: "VD" },
  { plz: "1066", cantonId: "VD" },
  { plz: "1068", cantonId: "VD" },
  { plz: "1070", cantonId: "VD" },
  { plz: "1071", cantonId: "VD" },
  { plz: "1071", cantonId: "VD" },
  { plz: "1071", cantonId: "VD" },
  { plz: "1072", cantonId: "VD" },
  { plz: "1073", cantonId: "VD" },
  { plz: "1073", cantonId: "VD" },
  { plz: "1076", cantonId: "VD" },
  { plz: "1077", cantonId: "VD" },
  { plz: "1078", cantonId: "VD" },
  { plz: "1080", cantonId: "VD" },
  { plz: "1081", cantonId: "VD" },
  { plz: "1082", cantonId: "VD" },
  { plz: "1083", cantonId: "VD" },
  { plz: "1084", cantonId: "VD" },
  { plz: "1085", cantonId: "VD" },
  { plz: "1088", cantonId: "VD" },
  { plz: "1090", cantonId: "VD" },
  { plz: "1091", cantonId: "VD" },
  { plz: "1091", cantonId: "VD" },
  { plz: "1091", cantonId: "VD" },
  { plz: "1092", cantonId: "VD" },
  { plz: "1093", cantonId: "VD" },
  { plz: "1094", cantonId: "VD" },
  { plz: "1095", cantonId: "VD" },
  { plz: "1096", cantonId: "VD" },
  { plz: "1096", cantonId: "VD" },
  { plz: "1097", cantonId: "VD" },
  { plz: "1098", cantonId: "VD" },
  { plz: "1110", cantonId: "VD" },
  { plz: "1112", cantonId: "VD" },
  { plz: "1113", cantonId: "VD" },
  { plz: "1114", cantonId: "VD" },
  { plz: "1115", cantonId: "VD" },
  { plz: "1116", cantonId: "VD" },
  { plz: "1117", cantonId: "VD" },
  { plz: "1121", cantonId: "VD" },
  { plz: "1122", cantonId: "VD" },
  { plz: "1123", cantonId: "VD" },
  { plz: "1124", cantonId: "VD" },
  { plz: "1125", cantonId: "VD" },
  { plz: "1126", cantonId: "VD" },
  { plz: "1127", cantonId: "VD" },
  { plz: "1128", cantonId: "VD" },
  { plz: "1131", cantonId: "VD" },
  { plz: "1132", cantonId: "VD" },
  { plz: "1134", cantonId: "VD" },
  { plz: "1134", cantonId: "VD" },
  { plz: "1135", cantonId: "VD" },
  { plz: "1136", cantonId: "VD" },
  { plz: "1141", cantonId: "VD" },
  { plz: "1142", cantonId: "VD" },
  { plz: "1143", cantonId: "VD" },
  { plz: "1144", cantonId: "VD" },
  { plz: "1145", cantonId: "VD" },
  { plz: "1146", cantonId: "VD" },
  { plz: "1147", cantonId: "VD" },
  { plz: "1148", cantonId: "VD" },
  { plz: "1148", cantonId: "VD" },
  { plz: "1148", cantonId: "VD" },
  { plz: "1148", cantonId: "VD" },
  { plz: "1148", cantonId: "VD" },
  { plz: "1148", cantonId: "VD" },
  { plz: "1148", cantonId: "VD" },
  { plz: "1148", cantonId: "VD" },
  { plz: "1148", cantonId: "VD" },
  { plz: "1149", cantonId: "VD" },
  { plz: "1162", cantonId: "VD" },
  { plz: "1163", cantonId: "VD" },
  { plz: "1164", cantonId: "VD" },
  { plz: "1165", cantonId: "VD" },
  { plz: "1166", cantonId: "VD" },
  { plz: "1167", cantonId: "VD" },
  { plz: "1168", cantonId: "VD" },
  { plz: "1169", cantonId: "VD" },
  { plz: "1170", cantonId: "VD" },
  { plz: "1172", cantonId: "VD" },
  { plz: "1173", cantonId: "VD" },
  { plz: "1174", cantonId: "VD" },
  { plz: "1174", cantonId: "VD" },
  { plz: "1175", cantonId: "VD" },
  { plz: "1176", cantonId: "VD" },
  { plz: "1180", cantonId: "VD" },
  { plz: "1180", cantonId: "VD" },
  { plz: "1182", cantonId: "VD" },
  { plz: "1183", cantonId: "VD" },
  { plz: "1184", cantonId: "VD" },
  { plz: "1184", cantonId: "VD" },
  { plz: "1185", cantonId: "VD" },
  { plz: "1186", cantonId: "VD" },
  { plz: "1187", cantonId: "VD" },
  { plz: "1188", cantonId: "VD" },
  { plz: "1188", cantonId: "VD" },
  { plz: "1189", cantonId: "VD" },
  { plz: "1195", cantonId: "VD" },
  { plz: "1195", cantonId: "VD" },
  { plz: "1196", cantonId: "VD" },
  { plz: "1197", cantonId: "VD" },
  { plz: "1200", cantonId: "GE" },
  { plz: "1201", cantonId: "GE" },
  { plz: "1202", cantonId: "GE" },
  { plz: "1203", cantonId: "GE" },
  { plz: "1204", cantonId: "GE" },
  { plz: "1205", cantonId: "GE" },
  { plz: "1206", cantonId: "GE" },
  { plz: "1207", cantonId: "GE" },
  { plz: "1208", cantonId: "GE" },
  { plz: "1209", cantonId: "GE" },
  { plz: "1212", cantonId: "GE" },
  { plz: "1213", cantonId: "GE" },
  { plz: "1213", cantonId: "GE" },
  { plz: "1214", cantonId: "GE" },
  { plz: "1215", cantonId: "GE" },
  { plz: "1216", cantonId: "GE" },
  { plz: "1217", cantonId: "GE" },
  { plz: "1218", cantonId: "GE" },
  { plz: "1219", cantonId: "GE" },
  { plz: "1219", cantonId: "GE" },
  { plz: "1219", cantonId: "GE" },
  { plz: "1220", cantonId: "GE" },
  { plz: "1222", cantonId: "GE" },
  { plz: "1223", cantonId: "GE" },
  { plz: "1224", cantonId: "GE" },
  { plz: "1225", cantonId: "GE" },
  { plz: "1226", cantonId: "GE" },
  { plz: "1227", cantonId: "GE" },
  { plz: "1227", cantonId: "GE" },
  { plz: "1228", cantonId: "GE" },
  { plz: "1231", cantonId: "GE" },
  { plz: "1232", cantonId: "GE" },
  { plz: "1233", cantonId: "GE" },
  { plz: "1234", cantonId: "GE" },
  { plz: "1236", cantonId: "GE" },
  { plz: "1237", cantonId: "GE" },
  { plz: "1239", cantonId: "GE" },
  { plz: "1241", cantonId: "GE" },
  { plz: "1242", cantonId: "GE" },
  { plz: "1243", cantonId: "GE" },
  { plz: "1244", cantonId: "GE" },
  { plz: "1245", cantonId: "GE" },
  { plz: "1246", cantonId: "GE" },
  { plz: "1247", cantonId: "GE" },
  { plz: "1248", cantonId: "GE" },
  { plz: "1251", cantonId: "GE" },
  { plz: "1252", cantonId: "GE" },
  { plz: "1253", cantonId: "GE" },
  { plz: "1254", cantonId: "GE" },
  { plz: "1255", cantonId: "GE" },
  { plz: "1256", cantonId: "GE" },
  { plz: "1257", cantonId: "GE" },
  { plz: "1258", cantonId: "GE" },
  { plz: "1260", cantonId: "VD" },
  { plz: "1261", cantonId: "VD" },
  { plz: "1261", cantonId: "VD" },
  { plz: "1261", cantonId: "VD" },
  { plz: "1262", cantonId: "VD" },
  { plz: "1263", cantonId: "VD" },
  { plz: "1264", cantonId: "VD" },
  { plz: "1265", cantonId: "VD" },
  { plz: "1266", cantonId: "VD" },
  { plz: "1267", cantonId: "VD" },
  { plz: "1267", cantonId: "VD" },
  { plz: "1268", cantonId: "VD" },
  { plz: "1268", cantonId: "VD" },
  { plz: "1269", cantonId: "VD" },
  { plz: "1270", cantonId: "VD" },
  { plz: "1271", cantonId: "VD" },
  { plz: "1272", cantonId: "VD" },
  { plz: "1273", cantonId: "VD" },
  { plz: "1273", cantonId: "VD" },
  { plz: "1274", cantonId: "VD" },
  { plz: "1274", cantonId: "VD" },
  { plz: "1275", cantonId: "VD" },
  { plz: "1276", cantonId: "VD" },
  { plz: "1277", cantonId: "VD" },
  { plz: "1277", cantonId: "VD" },
  { plz: "1278", cantonId: "VD" },
  { plz: "1279", cantonId: "VD" },
  { plz: "1279", cantonId: "VD" },
  { plz: "1281", cantonId: "GE" },
  { plz: "1283", cantonId: "GE" },
  { plz: "1283", cantonId: "GE" },
  { plz: "1284", cantonId: "GE" },
  { plz: "1285", cantonId: "GE" },
  { plz: "1286", cantonId: "GE" },
  { plz: "1287", cantonId: "GE" },
  { plz: "1288", cantonId: "GE" },
  { plz: "1290", cantonId: "GE" },
  { plz: "1290", cantonId: "GE" },
  { plz: "1291", cantonId: "VD" },
  { plz: "1292", cantonId: "GE" },
  { plz: "1293", cantonId: "GE" },
  { plz: "1294", cantonId: "GE" },
  { plz: "1295", cantonId: "VD" },
  { plz: "1295", cantonId: "VD" },
  { plz: "1296", cantonId: "VD" },
  { plz: "1297", cantonId: "VD" },
  { plz: "1298", cantonId: "GE" },
  { plz: "1299", cantonId: "VD" },
  { plz: "1302", cantonId: "VD" },
  { plz: "1303", cantonId: "VD" },
  { plz: "1304", cantonId: "VD" },
  { plz: "1304", cantonId: "VD" },
  { plz: "1304", cantonId: "VD" },
  { plz: "1304", cantonId: "VD" },
  { plz: "1305", cantonId: "VD" },
  { plz: "1306", cantonId: "VD" },
  { plz: "1307", cantonId: "VD" },
  { plz: "1308", cantonId: "VD" },
  { plz: "1312", cantonId: "VD" },
  { plz: "1313", cantonId: "VD" },
  { plz: "1315", cantonId: "VD" },
  { plz: "1316", cantonId: "VD" },
  { plz: "1317", cantonId: "VD" },
  { plz: "1318", cantonId: "VD" },
  { plz: "1321", cantonId: "VD" },
  { plz: "1322", cantonId: "VD" },
  { plz: "1323", cantonId: "VD" },
  { plz: "1324", cantonId: "VD" },
  { plz: "1325", cantonId: "VD" },
  { plz: "1326", cantonId: "VD" },
  { plz: "1329", cantonId: "VD" },
  { plz: "1337", cantonId: "VD" },
  { plz: "1338", cantonId: "VD" },
  { plz: "1341", cantonId: "VD" },
  { plz: "1342", cantonId: "VD" },
  { plz: "1343", cantonId: "VD" },
  { plz: "1344", cantonId: "VD" },
  { plz: "1345", cantonId: "VD" },
  { plz: "1346", cantonId: "VD" },
  { plz: "1347", cantonId: "VD" },
  { plz: "1347", cantonId: "VD" },
  { plz: "1348", cantonId: "VD" },
  { plz: "1350", cantonId: "VD" },
  { plz: "1352", cantonId: "VD" },
  { plz: "1353", cantonId: "VD" },
  { plz: "1354", cantonId: "VD" },
  { plz: "1355", cantonId: "VD" },
  { plz: "1355", cantonId: "VD" },
  { plz: "1356", cantonId: "VD" },
  { plz: "1356", cantonId: "VD" },
  { plz: "1357", cantonId: "VD" },
  { plz: "1358", cantonId: "VD" },
  { plz: "1372", cantonId: "VD" },
  { plz: "1373", cantonId: "VD" },
  { plz: "1374", cantonId: "VD" },
  { plz: "1375", cantonId: "VD" },
  { plz: "1376", cantonId: "VD" },
  { plz: "1376", cantonId: "VD" },
  { plz: "1376", cantonId: "VD" },
  { plz: "1377", cantonId: "VD" },
  { plz: "1400", cantonId: "VD" },
  { plz: "1400", cantonId: "VD" },
  { plz: "1404", cantonId: "VD" },
  { plz: "1404", cantonId: "VD" },
  { plz: "1405", cantonId: "VD" },
  { plz: "1406", cantonId: "VD" },
  { plz: "1407", cantonId: "VD" },
  { plz: "1407", cantonId: "VD" },
  { plz: "1407", cantonId: "VD" },
  { plz: "1407", cantonId: "VD" },
  { plz: "1408", cantonId: "VD" },
  { plz: "1409", cantonId: "VD" },
  { plz: "1410", cantonId: "FR" },
  { plz: "1410", cantonId: "FR" },
  { plz: "1410", cantonId: "FR" },
  { plz: "1410", cantonId: "FR" },
  { plz: "1410", cantonId: "FR" },
  { plz: "1412", cantonId: "VD" },
  { plz: "1412", cantonId: "VD" },
  { plz: "1413", cantonId: "VD" },
  { plz: "1415", cantonId: "VD" },
  { plz: "1415", cantonId: "VD" },
  { plz: "1416", cantonId: "VD" },
  { plz: "1417", cantonId: "VD" },
  { plz: "1417", cantonId: "VD" },
  { plz: "1418", cantonId: "VD" },
  { plz: "1420", cantonId: "VD" },
  { plz: "1421", cantonId: "VD" },
  { plz: "1421", cantonId: "VD" },
  { plz: "1422", cantonId: "VD" },
  { plz: "1423", cantonId: "VD" },
  { plz: "1423", cantonId: "VD" },
  { plz: "1423", cantonId: "VD" },
  { plz: "1423", cantonId: "VD" },
  { plz: "1424", cantonId: "VD" },
  { plz: "1425", cantonId: "VD" },
  { plz: "1426", cantonId: "VD" },
  { plz: "1426", cantonId: "VD" },
  { plz: "1427", cantonId: "VD" },
  { plz: "1428", cantonId: "VD" },
  { plz: "1428", cantonId: "VD" },
  { plz: "1429", cantonId: "VD" },
  { plz: "1430", cantonId: "VD" },
  { plz: "1431", cantonId: "VD" },
  { plz: "1431", cantonId: "VD" },
  { plz: "1432", cantonId: "VD" },
  { plz: "1432", cantonId: "VD" },
  { plz: "1433", cantonId: "VD" },
  { plz: "1434", cantonId: "VD" },
  { plz: "1435", cantonId: "VD" },
  { plz: "1436", cantonId: "VD" },
  { plz: "1436", cantonId: "VD" },
  { plz: "1437", cantonId: "VD" },
  { plz: "1438", cantonId: "VD" },
  { plz: "1439", cantonId: "VD" },
  { plz: "1441", cantonId: "VD" },
  { plz: "1442", cantonId: "VD" },
  { plz: "1443", cantonId: "VD" },
  { plz: "1443", cantonId: "VD" },
  { plz: "1443", cantonId: "VD" },
  { plz: "1445", cantonId: "VD" },
  { plz: "1446", cantonId: "VD" },
  { plz: "1450", cantonId: "VD" },
  { plz: "1450", cantonId: "VD" },
  { plz: "1450", cantonId: "VD" },
  { plz: "1452", cantonId: "VD" },
  { plz: "1453", cantonId: "VD" },
  { plz: "1453", cantonId: "VD" },
  { plz: "1454", cantonId: "VD" },
  { plz: "1454", cantonId: "VD" },
  { plz: "1462", cantonId: "VD" },
  { plz: "1463", cantonId: "VD" },
  { plz: "1464", cantonId: "VD" },
  { plz: "1464", cantonId: "VD" },
  { plz: "1468", cantonId: "FR" },
  { plz: "1470", cantonId: "FR" },
  { plz: "1470", cantonId: "FR" },
  { plz: "1470", cantonId: "FR" },
  { plz: "1470", cantonId: "FR" },
  { plz: "1473", cantonId: "FR" },
  { plz: "1473", cantonId: "FR" },
  { plz: "1474", cantonId: "FR" },
  { plz: "1475", cantonId: "FR" },
  { plz: "1475", cantonId: "FR" },
  { plz: "1475", cantonId: "FR" },
  { plz: "1482", cantonId: "FR" },
  { plz: "1483", cantonId: "FR" },
  { plz: "1483", cantonId: "FR" },
  { plz: "1483", cantonId: "FR" },
  { plz: "1484", cantonId: "FR" },
  { plz: "1484", cantonId: "FR" },
  { plz: "1485", cantonId: "FR" },
  { plz: "1486", cantonId: "FR" },
  { plz: "1489", cantonId: "FR" },
  { plz: "1509", cantonId: "VD" },
  { plz: "1510", cantonId: "VD" },
  { plz: "1510", cantonId: "VD" },
  { plz: "1512", cantonId: "VD" },
  { plz: "1513", cantonId: "VD" },
  { plz: "1513", cantonId: "VD" },
  { plz: "1514", cantonId: "VD" },
  { plz: "1515", cantonId: "VD" },
  { plz: "1515", cantonId: "VD" },
  { plz: "1521", cantonId: "VD" },
  { plz: "1522", cantonId: "VD" },
  { plz: "1522", cantonId: "VD" },
  { plz: "1523", cantonId: "VD" },
  { plz: "1524", cantonId: "VD" },
  { plz: "1525", cantonId: "VD" },
  { plz: "1525", cantonId: "VD" },
  { plz: "1526", cantonId: "VD" },
  { plz: "1526", cantonId: "VD" },
  { plz: "1527", cantonId: "FR" },
  { plz: "1528", cantonId: "FR" },
  { plz: "1528", cantonId: "FR" },
  { plz: "1529", cantonId: "FR" },
  { plz: "1530", cantonId: "VD" },
  { plz: "1532", cantonId: "FR" },
  { plz: "1533", cantonId: "FR" },
  { plz: "1534", cantonId: "FR" },
  { plz: "1534", cantonId: "FR" },
  { plz: "1535", cantonId: "VD" },
  { plz: "1536", cantonId: "VD" },
  { plz: "1537", cantonId: "VD" },
  { plz: "1538", cantonId: "VD" },
  { plz: "1541", cantonId: "FR" },
  { plz: "1541", cantonId: "FR" },
  { plz: "1541", cantonId: "FR" },
  { plz: "1542", cantonId: "FR" },
  { plz: "1543", cantonId: "VD" },
  { plz: "1544", cantonId: "FR" },
  { plz: "1545", cantonId: "VD" },
  { plz: "1551", cantonId: "VD" },
  { plz: "1552", cantonId: "VD" },
  { plz: "1553", cantonId: "FR" },
  { plz: "1554", cantonId: "VD" },
  { plz: "1554", cantonId: "VD" },
  { plz: "1555", cantonId: "VD" },
  { plz: "1562", cantonId: "VD" },
  { plz: "1563", cantonId: "FR" },
  { plz: "1564", cantonId: "FR" },
  { plz: "1565", cantonId: "FR" },
  { plz: "1565", cantonId: "FR" },
  { plz: "1566", cantonId: "FR" },
  { plz: "1566", cantonId: "FR" },
  { plz: "1567", cantonId: "FR" },
  { plz: "1568", cantonId: "FR" },
  { plz: "1580", cantonId: "VD" },
  { plz: "1580", cantonId: "VD" },
  { plz: "1582", cantonId: "VD" },
  { plz: "1583", cantonId: "FR" },
  { plz: "1584", cantonId: "VD" },
  { plz: "1585", cantonId: "VD" },
  { plz: "1585", cantonId: "VD" },
  { plz: "1585", cantonId: "VD" },
  { plz: "1586", cantonId: "VD" },
  { plz: "1587", cantonId: "VD" },
  { plz: "1587", cantonId: "VD" },
  { plz: "1588", cantonId: "VD" },
  { plz: "1589", cantonId: "VD" },
  { plz: "1595", cantonId: "BE" },
  { plz: "1595", cantonId: "BE" },
  { plz: "1607", cantonId: "VD" },
  { plz: "1607", cantonId: "VD" },
  { plz: "1607", cantonId: "VD" },
  { plz: "1607", cantonId: "VD" },
  { plz: "1608", cantonId: "FR" },
  { plz: "1608", cantonId: "FR" },
  { plz: "1608", cantonId: "FR" },
  { plz: "1608", cantonId: "FR" },
  { plz: "1609", cantonId: "FR" },
  { plz: "1609", cantonId: "FR" },
  { plz: "1609", cantonId: "FR" },
  { plz: "1609", cantonId: "FR" },
  { plz: "1610", cantonId: "VD" },
  { plz: "1610", cantonId: "VD" },
  { plz: "1610", cantonId: "VD" },
  { plz: "1611", cantonId: "FR" },
  { plz: "1612", cantonId: "VD" },
  { plz: "1613", cantonId: "VD" },
  { plz: "1614", cantonId: "FR" },
  { plz: "1615", cantonId: "FR" },
  { plz: "1616", cantonId: "FR" },
  { plz: "1617", cantonId: "FR" },
  { plz: "1617", cantonId: "FR" },
  { plz: "1618", cantonId: "FR" },
  { plz: "1619", cantonId: "FR" },
  { plz: "1623", cantonId: "FR" },
  { plz: "1624", cantonId: "FR" },
  { plz: "1624", cantonId: "FR" },
  { plz: "1624", cantonId: "FR" },
  { plz: "1625", cantonId: "FR" },
  { plz: "1625", cantonId: "FR" },
  { plz: "1626", cantonId: "FR" },
  { plz: "1626", cantonId: "FR" },
  { plz: "1626", cantonId: "FR" },
  { plz: "1627", cantonId: "FR" },
  { plz: "1628", cantonId: "FR" },
  { plz: "1630", cantonId: "FR" },
  { plz: "1632", cantonId: "FR" },
  { plz: "1633", cantonId: "FR" },
  { plz: "1633", cantonId: "FR" },
  { plz: "1634", cantonId: "FR" },
  { plz: "1635", cantonId: "FR" },
  { plz: "1636", cantonId: "FR" },
  { plz: "1637", cantonId: "FR" },
  { plz: "1638", cantonId: "FR" },
  { plz: "1642", cantonId: "FR" },
  { plz: "1643", cantonId: "FR" },
  { plz: "1644", cantonId: "FR" },
  { plz: "1645", cantonId: "FR" },
  { plz: "1646", cantonId: "FR" },
  { plz: "1647", cantonId: "FR" },
  { plz: "1648", cantonId: "FR" },
  { plz: "1649", cantonId: "FR" },
  { plz: "1651", cantonId: "FR" },
  { plz: "1652", cantonId: "FR" },
  { plz: "1652", cantonId: "FR" },
  { plz: "1653", cantonId: "FR" },
  { plz: "1653", cantonId: "FR" },
  { plz: "1654", cantonId: "FR" },
  { plz: "1656", cantonId: "FR" },
  { plz: "1656", cantonId: "FR" },
  { plz: "1657", cantonId: "BE" },
  { plz: "1658", cantonId: "VD" },
  { plz: "1658", cantonId: "VD" },
  { plz: "1659", cantonId: "VD" },
  { plz: "1659", cantonId: "VD" },
  { plz: "1660", cantonId: "VD" },
  { plz: "1660", cantonId: "VD" },
  { plz: "1660", cantonId: "VD" },
  { plz: "1660", cantonId: "VD" },
  { plz: "1661", cantonId: "FR" },
  { plz: "1663", cantonId: "FR" },
  { plz: "1663", cantonId: "FR" },
  { plz: "1663", cantonId: "FR" },
  { plz: "1663", cantonId: "FR" },
  { plz: "1665", cantonId: "FR" },
  { plz: "1666", cantonId: "FR" },
  { plz: "1666", cantonId: "FR" },
  { plz: "1667", cantonId: "FR" },
  { plz: "1669", cantonId: "FR" },
  { plz: "1669", cantonId: "FR" },
  { plz: "1669", cantonId: "FR" },
  { plz: "1669", cantonId: "FR" },
  { plz: "1669", cantonId: "FR" },
  { plz: "1670", cantonId: "FR" },
  { plz: "1670", cantonId: "FR" },
  { plz: "1670", cantonId: "FR" },
  { plz: "1673", cantonId: "FR" },
  { plz: "1673", cantonId: "FR" },
  { plz: "1673", cantonId: "FR" },
  { plz: "1673", cantonId: "FR" },
  { plz: "1673", cantonId: "FR" },
  { plz: "1674", cantonId: "FR" },
  { plz: "1674", cantonId: "FR" },
  { plz: "1674", cantonId: "FR" },
  { plz: "1675", cantonId: "FR" },
  { plz: "1675", cantonId: "FR" },
  { plz: "1675", cantonId: "FR" },
  { plz: "1676", cantonId: "FR" },
  { plz: "1677", cantonId: "FR" },
  { plz: "1678", cantonId: "FR" },
  { plz: "1679", cantonId: "FR" },
  { plz: "1680", cantonId: "FR" },
  { plz: "1680", cantonId: "FR" },
  { plz: "1681", cantonId: "FR" },
  { plz: "1681", cantonId: "FR" },
  { plz: "1682", cantonId: "VD" },
  { plz: "1682", cantonId: "VD" },
  { plz: "1682", cantonId: "VD" },
  { plz: "1682", cantonId: "VD" },
  { plz: "1682", cantonId: "VD" },
  { plz: "1683", cantonId: "VD" },
  { plz: "1683", cantonId: "VD" },
  { plz: "1683", cantonId: "VD" },
  { plz: "1684", cantonId: "FR" },
  { plz: "1685", cantonId: "FR" },
  { plz: "1686", cantonId: "FR" },
  { plz: "1686", cantonId: "FR" },
  { plz: "1687", cantonId: "FR" },
  { plz: "1687", cantonId: "FR" },
  { plz: "1687", cantonId: "FR" },
  { plz: "1688", cantonId: "FR" },
  { plz: "1688", cantonId: "FR" },
  { plz: "1689", cantonId: "FR" },
  { plz: "1690", cantonId: "FR" },
  { plz: "1690", cantonId: "FR" },
  { plz: "1691", cantonId: "FR" },
  { plz: "1692", cantonId: "FR" },
  { plz: "1694", cantonId: "FR" },
  { plz: "1694", cantonId: "FR" },
  { plz: "1694", cantonId: "FR" },
  { plz: "1694", cantonId: "FR" },
  { plz: "1695", cantonId: "FR" },
  { plz: "1695", cantonId: "FR" },
  { plz: "1695", cantonId: "FR" },
  { plz: "1695", cantonId: "FR" },
  { plz: "1696", cantonId: "FR" },
  { plz: "1697", cantonId: "FR" },
  { plz: "1697", cantonId: "FR" },
  { plz: "1699", cantonId: "FR" },
  { plz: "1699", cantonId: "FR" },
  { plz: "1699", cantonId: "FR" },
  { plz: "1700", cantonId: "FR" },
  { plz: "1708", cantonId: "FR" },
  { plz: "1712", cantonId: "FR" },
  { plz: "1713", cantonId: "FR" },
  { plz: "1714", cantonId: "FR" },
  { plz: "1715", cantonId: "FR" },
  { plz: "1716", cantonId: "FR" },
  { plz: "1716", cantonId: "FR" },
  { plz: "1716", cantonId: "FR" },
  { plz: "1717", cantonId: "FR" },
  { plz: "1718", cantonId: "FR" },
  { plz: "1719", cantonId: "FR" },
  { plz: "1719", cantonId: "FR" },
  { plz: "1720", cantonId: "FR" },
  { plz: "1720", cantonId: "FR" },
  { plz: "1721", cantonId: "FR" },
  { plz: "1721", cantonId: "FR" },
  { plz: "1721", cantonId: "FR" },
  { plz: "1722", cantonId: "FR" },
  { plz: "1723", cantonId: "FR" },
  { plz: "1723", cantonId: "FR" },
  { plz: "1723", cantonId: "FR" },
  { plz: "1724", cantonId: "FR" },
  { plz: "1724", cantonId: "FR" },
  { plz: "1724", cantonId: "FR" },
  { plz: "1724", cantonId: "FR" },
  { plz: "1724", cantonId: "FR" },
  { plz: "1724", cantonId: "FR" },
  { plz: "1724", cantonId: "FR" },
  { plz: "1724", cantonId: "FR" },
  { plz: "1725", cantonId: "FR" },
  { plz: "1726", cantonId: "FR" },
  { plz: "1726", cantonId: "FR" },
  { plz: "1726", cantonId: "FR" },
  { plz: "1726", cantonId: "FR" },
  { plz: "1727", cantonId: "FR" },
  { plz: "1727", cantonId: "FR" },
  { plz: "1728", cantonId: "FR" },
  { plz: "1730", cantonId: "FR" },
  { plz: "1731", cantonId: "FR" },
  { plz: "1732", cantonId: "FR" },
  { plz: "1733", cantonId: "FR" },
  { plz: "1734", cantonId: "FR" },
  { plz: "1735", cantonId: "FR" },
  { plz: "1736", cantonId: "FR" },
  { plz: "1737", cantonId: "FR" },
  { plz: "1738", cantonId: "BE" },
  { plz: "1740", cantonId: "FR" },
  { plz: "1741", cantonId: "FR" },
  { plz: "1742", cantonId: "FR" },
  { plz: "1744", cantonId: "FR" },
  { plz: "1745", cantonId: "FR" },
  { plz: "1746", cantonId: "FR" },
  { plz: "1747", cantonId: "FR" },
  { plz: "1748", cantonId: "FR" },
  { plz: "1749", cantonId: "FR" },
  { plz: "1752", cantonId: "FR" },
  { plz: "1753", cantonId: "FR" },
  { plz: "1754", cantonId: "FR" },
  { plz: "1754", cantonId: "FR" },
  { plz: "1754", cantonId: "FR" },
  { plz: "1754", cantonId: "FR" },
  { plz: "1756", cantonId: "FR" },
  { plz: "1756", cantonId: "FR" },
  { plz: "1757", cantonId: "FR" },
  { plz: "1762", cantonId: "FR" },
  { plz: "1763", cantonId: "FR" },
  { plz: "1772", cantonId: "FR" },
  { plz: "1772", cantonId: "FR" },
  { plz: "1772", cantonId: "FR" },
  { plz: "1773", cantonId: "FR" },
  { plz: "1773", cantonId: "FR" },
  { plz: "1773", cantonId: "FR" },
  { plz: "1774", cantonId: "FR" },
  { plz: "1774", cantonId: "FR" },
  { plz: "1775", cantonId: "FR" },
  { plz: "1775", cantonId: "FR" },
  { plz: "1776", cantonId: "FR" },
  { plz: "1782", cantonId: "FR" },
  { plz: "1782", cantonId: "FR" },
  { plz: "1782", cantonId: "FR" },
  { plz: "1782", cantonId: "FR" },
  { plz: "1782", cantonId: "FR" },
  { plz: "1782", cantonId: "FR" },
  { plz: "1783", cantonId: "FR" },
  { plz: "1783", cantonId: "FR" },
  { plz: "1784", cantonId: "FR" },
  { plz: "1784", cantonId: "FR" },
  { plz: "1784", cantonId: "FR" },
  { plz: "1785", cantonId: "FR" },
  { plz: "1786", cantonId: "FR" },
  { plz: "1787", cantonId: "FR" },
  { plz: "1787", cantonId: "FR" },
  { plz: "1787", cantonId: "FR" },
  { plz: "1788", cantonId: "FR" },
  { plz: "1789", cantonId: "FR" },
  { plz: "1791", cantonId: "FR" },
  { plz: "1792", cantonId: "FR" },
  { plz: "1792", cantonId: "FR" },
  { plz: "1793", cantonId: "FR" },
  { plz: "1794", cantonId: "FR" },
  { plz: "1795", cantonId: "FR" },
  { plz: "1796", cantonId: "FR" },
  { plz: "1797", cantonId: "BE" },
  { plz: "1800", cantonId: "VD" },
  { plz: "1801", cantonId: "VD" },
  { plz: "1802", cantonId: "VD" },
  { plz: "1803", cantonId: "VD" },
  { plz: "1804", cantonId: "VD" },
  { plz: "1805", cantonId: "VD" },
  { plz: "1806", cantonId: "VD" },
  { plz: "1807", cantonId: "VD" },
  { plz: "1808", cantonId: "VD" },
  { plz: "1809", cantonId: "VD" },
  { plz: "1814", cantonId: "VD" },
  { plz: "1815", cantonId: "VD" },
  { plz: "1816", cantonId: "VD" },
  { plz: "1817", cantonId: "VD" },
  { plz: "1820", cantonId: "VD" },
  { plz: "1820", cantonId: "VD" },
  { plz: "1820", cantonId: "VD" },
  { plz: "1822", cantonId: "VD" },
  { plz: "1823", cantonId: "VD" },
  { plz: "1824", cantonId: "VD" },
  { plz: "1832", cantonId: "VD" },
  { plz: "1832", cantonId: "VD" },
  { plz: "1833", cantonId: "VD" },
  { plz: "1844", cantonId: "VD" },
  { plz: "1845", cantonId: "VD" },
  { plz: "1846", cantonId: "VD" },
  { plz: "1847", cantonId: "VD" },
  { plz: "1852", cantonId: "VD" },
  { plz: "1853", cantonId: "VD" },
  { plz: "1854", cantonId: "VD" },
  { plz: "1856", cantonId: "VD" },
  { plz: "1860", cantonId: "VD" },
  { plz: "1862", cantonId: "VD" },
  { plz: "1862", cantonId: "VD" },
  { plz: "1863", cantonId: "VD" },
  { plz: "1864", cantonId: "VD" },
  { plz: "1865", cantonId: "VD" },
  { plz: "1866", cantonId: "VD" },
  { plz: "1867", cantonId: "VD" },
  { plz: "1867", cantonId: "VD" },
  { plz: "1867", cantonId: "VD" },
  { plz: "1868", cantonId: "VS" },
  { plz: "1869", cantonId: "VS" },
  { plz: "1870", cantonId: "VS" },
  { plz: "1871", cantonId: "VS" },
  { plz: "1871", cantonId: "VS" },
  { plz: "1872", cantonId: "VS" },
  { plz: "1873", cantonId: "VS" },
  { plz: "1873", cantonId: "VS" },
  { plz: "1873", cantonId: "VS" },
  { plz: "1874", cantonId: "VS" },
  { plz: "1875", cantonId: "VS" },
  { plz: "1880", cantonId: "VD" },
  { plz: "1880", cantonId: "VD" },
  { plz: "1880", cantonId: "VD" },
  { plz: "1880", cantonId: "VD" },
  { plz: "1882", cantonId: "VD" },
  { plz: "1882", cantonId: "VD" },
  { plz: "1884", cantonId: "VD" },
  { plz: "1884", cantonId: "VD" },
  { plz: "1884", cantonId: "VD" },
  { plz: "1885", cantonId: "VD" },
  { plz: "1890", cantonId: "VS" },
  { plz: "1890", cantonId: "VS" },
  { plz: "1891", cantonId: "VS" },
  { plz: "1892", cantonId: "VD" },
  { plz: "1892", cantonId: "VD" },
  { plz: "1892", cantonId: "VD" },
  { plz: "1893", cantonId: "VS" },
  { plz: "1895", cantonId: "VS" },
  { plz: "1896", cantonId: "VS" },
  { plz: "1896", cantonId: "VS" },
  { plz: "1897", cantonId: "VS" },
  { plz: "1897", cantonId: "VS" },
  { plz: "1898", cantonId: "VS" },
  { plz: "1899", cantonId: "VS" },
  { plz: "1902", cantonId: "VS" },
  { plz: "1903", cantonId: "VS" },
  { plz: "1904", cantonId: "VS" },
  { plz: "1905", cantonId: "VS" },
  { plz: "1906", cantonId: "VS" },
  { plz: "1907", cantonId: "VS" },
  { plz: "1908", cantonId: "VS" },
  { plz: "1911", cantonId: "VS" },
  { plz: "1911", cantonId: "VS" },
  { plz: "1912", cantonId: "VS" },
  { plz: "1912", cantonId: "VS" },
  { plz: "1912", cantonId: "VS" },
  { plz: "1912", cantonId: "VS" },
  { plz: "1913", cantonId: "VS" },
  { plz: "1914", cantonId: "VS" },
  { plz: "1914", cantonId: "VS" },
  { plz: "1918", cantonId: "VS" },
  { plz: "1920", cantonId: "VS" },
  { plz: "1921", cantonId: "VS" },
  { plz: "1922", cantonId: "VS" },
  { plz: "1922", cantonId: "VS" },
  { plz: "1923", cantonId: "VS" },
  { plz: "1923", cantonId: "VS" },
  { plz: "1925", cantonId: "VS" },
  { plz: "1925", cantonId: "VS" },
  { plz: "1926", cantonId: "VS" },
  { plz: "1927", cantonId: "VS" },
  { plz: "1928", cantonId: "VS" },
  { plz: "1929", cantonId: "VS" },
  { plz: "1932", cantonId: "VS" },
  { plz: "1932", cantonId: "VS" },
  { plz: "1933", cantonId: "VS" },
  { plz: "1933", cantonId: "VS" },
  { plz: "1933", cantonId: "VS" },
  { plz: "1933", cantonId: "VS" },
  { plz: "1934", cantonId: "VS" },
  { plz: "1934", cantonId: "VS" },
  { plz: "1934", cantonId: "VS" },
  { plz: "1934", cantonId: "VS" },
  { plz: "1934", cantonId: "VS" },
  { plz: "1934", cantonId: "VS" },
  { plz: "1936", cantonId: "VS" },
  { plz: "1937", cantonId: "VS" },
  { plz: "1938", cantonId: "VS" },
  { plz: "1941", cantonId: "VS" },
  { plz: "1941", cantonId: "VS" },
  { plz: "1942", cantonId: "VS" },
  { plz: "1943", cantonId: "VS" },
  { plz: "1944", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1945", cantonId: "VS" },
  { plz: "1946", cantonId: "VS" },
  { plz: "1947", cantonId: "VS" },
  { plz: "1947", cantonId: "VS" },
  { plz: "1947", cantonId: "VS" },
  { plz: "1947", cantonId: "VS" },
  { plz: "1947", cantonId: "VS" },
  { plz: "1947", cantonId: "VS" },
  { plz: "1948", cantonId: "VS" },
  { plz: "1948", cantonId: "VS" },
  { plz: "1948", cantonId: "VS" },
  { plz: "1948", cantonId: "VS" },
  { plz: "1948", cantonId: "VS" },
  { plz: "1950", cantonId: "VS" },
  { plz: "1955", cantonId: "VS" },
  { plz: "1955", cantonId: "VS" },
  { plz: "1955", cantonId: "VS" },
  { plz: "1955", cantonId: "VS" },
  { plz: "1955", cantonId: "VS" },
  { plz: "1957", cantonId: "VS" },
  { plz: "1958", cantonId: "VS" },
  { plz: "1958", cantonId: "VS" },
  { plz: "1961", cantonId: "VS" },
  { plz: "1962", cantonId: "VS" },
  { plz: "1963", cantonId: "VS" },
  { plz: "1964", cantonId: "VS" },
  { plz: "1965", cantonId: "VS" },
  { plz: "1965", cantonId: "VS" },
  { plz: "1965", cantonId: "VS" },
  { plz: "1965", cantonId: "VS" },
  { plz: "1965", cantonId: "VS" },
  { plz: "1965", cantonId: "VS" },
  { plz: "1965", cantonId: "VS" },
  { plz: "1965", cantonId: "VS" },
  { plz: "1965", cantonId: "VS" },
  { plz: "1966", cantonId: "VS" },
  { plz: "1966", cantonId: "VS" },
  { plz: "1966", cantonId: "VS" },
  { plz: "1966", cantonId: "VS" },
  { plz: "1966", cantonId: "VS" },
  { plz: "1966", cantonId: "VS" },
  { plz: "1966", cantonId: "VS" },
  { plz: "1966", cantonId: "VS" },
  { plz: "1966", cantonId: "VS" },
  { plz: "1966", cantonId: "VS" },
  { plz: "1967", cantonId: "VS" },
  { plz: "1968", cantonId: "VS" },
  { plz: "1969", cantonId: "VS" },
  { plz: "1969", cantonId: "VS" },
  { plz: "1969", cantonId: "VS" },
  { plz: "1969", cantonId: "VS" },
  { plz: "1969", cantonId: "VS" },
  { plz: "1971", cantonId: "VS" },
  { plz: "1971", cantonId: "VS" },
  { plz: "1971", cantonId: "VS" },
  { plz: "1972", cantonId: "VS" },
  { plz: "1973", cantonId: "VS" },
  { plz: "1974", cantonId: "VS" },
  { plz: "1975", cantonId: "VS" },
  { plz: "1976", cantonId: "VS" },
  { plz: "1976", cantonId: "VS" },
  { plz: "1976", cantonId: "VS" },
  { plz: "1977", cantonId: "VS" },
  { plz: "1978", cantonId: "VS" },
  { plz: "1981", cantonId: "VS" },
  { plz: "1982", cantonId: "VS" },
  { plz: "1983", cantonId: "VS" },
  { plz: "1983", cantonId: "VS" },
  { plz: "1984", cantonId: "VS" },
  { plz: "1985", cantonId: "VS" },
  { plz: "1986", cantonId: "VS" },
  { plz: "1987", cantonId: "VS" },
  { plz: "1987", cantonId: "VS" },
  { plz: "1988", cantonId: "VS" },
  { plz: "1988", cantonId: "VS" },
  { plz: "1991", cantonId: "VS" },
  { plz: "1991", cantonId: "VS" },
  { plz: "1991", cantonId: "VS" },
  { plz: "1991", cantonId: "VS" },
  { plz: "1991", cantonId: "VS" },
  { plz: "1992", cantonId: "VS" },
  { plz: "1992", cantonId: "VS" },
  { plz: "1992", cantonId: "VS" },
  { plz: "1992", cantonId: "VS" },
  { plz: "1993", cantonId: "VS" },
  { plz: "1993", cantonId: "VS" },
  { plz: "1994", cantonId: "VS" },
  { plz: "1996", cantonId: "VS" },
  { plz: "1996", cantonId: "VS" },
  { plz: "1996", cantonId: "VS" },
  { plz: "1996", cantonId: "VS" },
  { plz: "1996", cantonId: "VS" },
  { plz: "1996", cantonId: "VS" },
  { plz: "1996", cantonId: "VS" },
  { plz: "1996", cantonId: "VS" },
  { plz: "1996", cantonId: "VS" },
  { plz: "1997", cantonId: "VS" },
  { plz: "1997", cantonId: "VS" },
  { plz: "2000", cantonId: "NE" },
  { plz: "2012", cantonId: "NE" },
  { plz: "2013", cantonId: "NE" },
  { plz: "2014", cantonId: "NE" },
  { plz: "2015", cantonId: "NE" },
  { plz: "2016", cantonId: "NE" },
  { plz: "2017", cantonId: "NE" },
  { plz: "2019", cantonId: "NE" },
  { plz: "2019", cantonId: "NE" },
  { plz: "2022", cantonId: "NE" },
  { plz: "2023", cantonId: "NE" },
  { plz: "2024", cantonId: "NE" },
  { plz: "2025", cantonId: "NE" },
  { plz: "2027", cantonId: "NE" },
  { plz: "2027", cantonId: "NE" },
  { plz: "2028", cantonId: "NE" },
  { plz: "2034", cantonId: "NE" },
  { plz: "2035", cantonId: "NE" },
  { plz: "2036", cantonId: "NE" },
  { plz: "2037", cantonId: "NE" },
  { plz: "2037", cantonId: "NE" },
  { plz: "2042", cantonId: "NE" },
  { plz: "2043", cantonId: "NE" },
  { plz: "2046", cantonId: "NE" },
  { plz: "2052", cantonId: "NE" },
  { plz: "2052", cantonId: "NE" },
  { plz: "2053", cantonId: "NE" },
  { plz: "2054", cantonId: "NE" },
  { plz: "2054", cantonId: "NE" },
  { plz: "2056", cantonId: "NE" },
  { plz: "2057", cantonId: "NE" },
  { plz: "2058", cantonId: "NE" },
  { plz: "2063", cantonId: "NE" },
  { plz: "2063", cantonId: "NE" },
  { plz: "2063", cantonId: "NE" },
  { plz: "2063", cantonId: "NE" },
  { plz: "2065", cantonId: "NE" },
  { plz: "2067", cantonId: "NE" },
  { plz: "2068", cantonId: "NE" },
  { plz: "2072", cantonId: "NE" },
  { plz: "2073", cantonId: "NE" },
  { plz: "2074", cantonId: "NE" },
  { plz: "2075", cantonId: "NE" },
  { plz: "2075", cantonId: "NE" },
  { plz: "2087", cantonId: "NE" },
  { plz: "2088", cantonId: "NE" },
  { plz: "2103", cantonId: "NE" },
  { plz: "2105", cantonId: "NE" },
  { plz: "2108", cantonId: "NE" },
  { plz: "2112", cantonId: "NE" },
  { plz: "2113", cantonId: "NE" },
  { plz: "2114", cantonId: "NE" },
  { plz: "2115", cantonId: "NE" },
  { plz: "2116", cantonId: "NE" },
  { plz: "2117", cantonId: "NE" },
  { plz: "2123", cantonId: "NE" },
  { plz: "2124", cantonId: "NE" },
  { plz: "2126", cantonId: "NE" },
  { plz: "2127", cantonId: "NE" },
  { plz: "2149", cantonId: "NE" },
  { plz: "2149", cantonId: "NE" },
  { plz: "2149", cantonId: "NE" },
  { plz: "2206", cantonId: "NE" },
  { plz: "2207", cantonId: "NE" },
  { plz: "2208", cantonId: "NE" },
  { plz: "2300", cantonId: "NE" },
  { plz: "2300", cantonId: "NE" },
  { plz: "2314", cantonId: "NE" },
  { plz: "2316", cantonId: "NE" },
  { plz: "2316", cantonId: "NE" },
  { plz: "2318", cantonId: "NE" },
  { plz: "2322", cantonId: "NE" },
  { plz: "2325", cantonId: "NE" },
  { plz: "2325", cantonId: "NE" },
  { plz: "2333", cantonId: "BE" },
  { plz: "2333", cantonId: "BE" },
  { plz: "2336", cantonId: "JU" },
  { plz: "2338", cantonId: "JU" },
  { plz: "2338", cantonId: "JU" },
  { plz: "2340", cantonId: "JU" },
  { plz: "2345", cantonId: "JU" },
  { plz: "2345", cantonId: "JU" },
  { plz: "2345", cantonId: "JU" },
  { plz: "2350", cantonId: "JU" },
  { plz: "2353", cantonId: "JU" },
  { plz: "2354", cantonId: "JU" },
  { plz: "2360", cantonId: "JU" },
  { plz: "2362", cantonId: "JU" },
  { plz: "2362", cantonId: "JU" },
  { plz: "2363", cantonId: "JU" },
  { plz: "2364", cantonId: "JU" },
  { plz: "2400", cantonId: "NE" },
  { plz: "2400", cantonId: "NE" },
  { plz: "2405", cantonId: "NE" },
  { plz: "2406", cantonId: "NE" },
  { plz: "2406", cantonId: "NE" },
  { plz: "2406", cantonId: "NE" },
  { plz: "2406", cantonId: "NE" },
  { plz: "2414", cantonId: "NE" },
  { plz: "2416", cantonId: "NE" },
  { plz: "2500", cantonId: "BE" },
  { plz: "2502", cantonId: "BE" },
  { plz: "2503", cantonId: "BE" },
  { plz: "2504", cantonId: "BE" },
  { plz: "2505", cantonId: "BE" },
  { plz: "2512", cantonId: "BE" },
  { plz: "2513", cantonId: "BE" },
  { plz: "2514", cantonId: "BE" },
  { plz: "2515", cantonId: "BE" },
  { plz: "2516", cantonId: "BE" },
  { plz: "2517", cantonId: "BE" },
  { plz: "2518", cantonId: "BE" },
  { plz: "2520", cantonId: "BE" },
  { plz: "2523", cantonId: "NE" },
  { plz: "2525", cantonId: "NE" },
  { plz: "2532", cantonId: "BE" },
  { plz: "2533", cantonId: "BE" },
  { plz: "2534", cantonId: "BE" },
  { plz: "2534", cantonId: "BE" },
  { plz: "2535", cantonId: "BE" },
  { plz: "2536", cantonId: "BE" },
  { plz: "2537", cantonId: "BE" },
  { plz: "2538", cantonId: "BE" },
  { plz: "2540", cantonId: "SO" },
  { plz: "2542", cantonId: "BE" },
  { plz: "2543", cantonId: "BE" },
  { plz: "2544", cantonId: "SO" },
  { plz: "2545", cantonId: "SO" },
  { plz: "2552", cantonId: "BE" },
  { plz: "2553", cantonId: "BE" },
  { plz: "2554", cantonId: "BE" },
  { plz: "2555", cantonId: "BE" },
  { plz: "2556", cantonId: "BE" },
  { plz: "2556", cantonId: "BE" },
  { plz: "2557", cantonId: "BE" },
  { plz: "2558", cantonId: "BE" },
  { plz: "2560", cantonId: "BE" },
  { plz: "2562", cantonId: "BE" },
  { plz: "2563", cantonId: "BE" },
  { plz: "2564", cantonId: "BE" },
  { plz: "2565", cantonId: "BE" },
  { plz: "2572", cantonId: "BE" },
  { plz: "2572", cantonId: "BE" },
  { plz: "2575", cantonId: "BE" },
  { plz: "2575", cantonId: "BE" },
  { plz: "2575", cantonId: "BE" },
  { plz: "2576", cantonId: "BE" },
  { plz: "2577", cantonId: "BE" },
  { plz: "2577", cantonId: "BE" },
  { plz: "2603", cantonId: "BE" },
  { plz: "2604", cantonId: "BE" },
  { plz: "2605", cantonId: "BE" },
  { plz: "2606", cantonId: "BE" },
  { plz: "2607", cantonId: "BE" },
  { plz: "2608", cantonId: "BE" },
  { plz: "2608", cantonId: "BE" },
  { plz: "2610", cantonId: "BE" },
  { plz: "2610", cantonId: "BE" },
  { plz: "2610", cantonId: "BE" },
  { plz: "2610", cantonId: "BE" },
  { plz: "2612", cantonId: "BE" },
  { plz: "2613", cantonId: "BE" },
  { plz: "2615", cantonId: "BE" },
  { plz: "2615", cantonId: "BE" },
  { plz: "2616", cantonId: "BE" },
  { plz: "2616", cantonId: "BE" },
  { plz: "2710", cantonId: "BE" },
  { plz: "2712", cantonId: "BE" },
  { plz: "2713", cantonId: "BE" },
  { plz: "2714", cantonId: "JU" },
  { plz: "2714", cantonId: "JU" },
  { plz: "2715", cantonId: "BE" },
  { plz: "2715", cantonId: "BE" },
  { plz: "2716", cantonId: "BE" },
  { plz: "2717", cantonId: "BE" },
  { plz: "2717", cantonId: "BE" },
  { plz: "2718", cantonId: "JU" },
  { plz: "2718", cantonId: "JU" },
  { plz: "2720", cantonId: "BE" },
  { plz: "2720", cantonId: "BE" },
  { plz: "2722", cantonId: "BE" },
  { plz: "2723", cantonId: "BE" },
  { plz: "2732", cantonId: "BE" },
  { plz: "2732", cantonId: "BE" },
  { plz: "2732", cantonId: "BE" },
  { plz: "2732", cantonId: "BE" },
  { plz: "2733", cantonId: "BE" },
  { plz: "2735", cantonId: "BE" },
  { plz: "2735", cantonId: "BE" },
  { plz: "2735", cantonId: "BE" },
  { plz: "2736", cantonId: "BE" },
  { plz: "2738", cantonId: "BE" },
  { plz: "2740", cantonId: "BE" },
  { plz: "2742", cantonId: "BE" },
  { plz: "2743", cantonId: "BE" },
  { plz: "2744", cantonId: "BE" },
  { plz: "2745", cantonId: "BE" },
  { plz: "2746", cantonId: "BE" },
  { plz: "2747", cantonId: "BE" },
  { plz: "2747", cantonId: "BE" },
  { plz: "2748", cantonId: "BE" },
  { plz: "2748", cantonId: "BE" },
  { plz: "2762", cantonId: "BE" },
  { plz: "2800", cantonId: "JU" },
  { plz: "2802", cantonId: "JU" },
  { plz: "2803", cantonId: "JU" },
  { plz: "2805", cantonId: "JU" },
  { plz: "2806", cantonId: "JU" },
  { plz: "2807", cantonId: "JU" },
  { plz: "2807", cantonId: "JU" },
  { plz: "2812", cantonId: "JU" },
  { plz: "2813", cantonId: "JU" },
  { plz: "2814", cantonId: "BL" },
  { plz: "2822", cantonId: "JU" },
  { plz: "2823", cantonId: "JU" },
  { plz: "2824", cantonId: "JU" },
  { plz: "2825", cantonId: "JU" },
  { plz: "2826", cantonId: "JU" },
  { plz: "2827", cantonId: "BE" },
  { plz: "2827", cantonId: "BE" },
  { plz: "2828", cantonId: "JU" },
  { plz: "2829", cantonId: "JU" },
  { plz: "2830", cantonId: "JU" },
  { plz: "2830", cantonId: "JU" },
  { plz: "2832", cantonId: "JU" },
  { plz: "2842", cantonId: "JU" },
  { plz: "2843", cantonId: "JU" },
  { plz: "2852", cantonId: "JU" },
  { plz: "2853", cantonId: "JU" },
  { plz: "2854", cantonId: "JU" },
  { plz: "2855", cantonId: "JU" },
  { plz: "2856", cantonId: "JU" },
  { plz: "2857", cantonId: "JU" },
  { plz: "2863", cantonId: "JU" },
  { plz: "2864", cantonId: "JU" },
  { plz: "2873", cantonId: "JU" },
  { plz: "2874", cantonId: "JU" },
  { plz: "2874", cantonId: "JU" },
  { plz: "2875", cantonId: "JU" },
  { plz: "2875", cantonId: "JU" },
  { plz: "2877", cantonId: "JU" },
  { plz: "2882", cantonId: "JU" },
  { plz: "2883", cantonId: "JU" },
  { plz: "2884", cantonId: "JU" },
  { plz: "2885", cantonId: "JU" },
  { plz: "2886", cantonId: "JU" },
  { plz: "2887", cantonId: "JU" },
  { plz: "2888", cantonId: "JU" },
  { plz: "2889", cantonId: "JU" },
  { plz: "2900", cantonId: "JU" },
  { plz: "2902", cantonId: "JU" },
  { plz: "2903", cantonId: "JU" },
  { plz: "2904", cantonId: "JU" },
  { plz: "2905", cantonId: "JU" },
  { plz: "2906", cantonId: "JU" },
  { plz: "2907", cantonId: "JU" },
  { plz: "2908", cantonId: "JU" },
  { plz: "2912", cantonId: "JU" },
  { plz: "2912", cantonId: "JU" },
  { plz: "2914", cantonId: "JU" },
  { plz: "2915", cantonId: "JU" },
  { plz: "2916", cantonId: "JU" },
  { plz: "2922", cantonId: "JU" },
  { plz: "2923", cantonId: "JU" },
  { plz: "2924", cantonId: "JU" },
  { plz: "2925", cantonId: "JU" },
  { plz: "2926", cantonId: "JU" },
  { plz: "2932", cantonId: "JU" },
  { plz: "2933", cantonId: "JU" },
  { plz: "2933", cantonId: "JU" },
  { plz: "2935", cantonId: "JU" },
  { plz: "2942", cantonId: "JU" },
  { plz: "2943", cantonId: "JU" },
  { plz: "2944", cantonId: "JU" },
  { plz: "2946", cantonId: "JU" },
  { plz: "2947", cantonId: "JU" },
  { plz: "2950", cantonId: "JU" },
  { plz: "2950", cantonId: "JU" },
  { plz: "2952", cantonId: "JU" },
  { plz: "2953", cantonId: "JU" },
  { plz: "2953", cantonId: "JU" },
  { plz: "2954", cantonId: "JU" },
  { plz: "3000", cantonId: "BE" },
  { plz: "3004", cantonId: "BE" },
  { plz: "3005", cantonId: "BE" },
  { plz: "3006", cantonId: "BE" },
  { plz: "3007", cantonId: "BE" },
  { plz: "3008", cantonId: "BE" },
  { plz: "3010", cantonId: "BE" },
  { plz: "3011", cantonId: "BE" },
  { plz: "3012", cantonId: "BE" },
  { plz: "3013", cantonId: "BE" },
  { plz: "3014", cantonId: "BE" },
  { plz: "3015", cantonId: "BE" },
  { plz: "3018", cantonId: "BE" },
  { plz: "3019", cantonId: "BE" },
  { plz: "3020", cantonId: "BE" },
  { plz: "3027", cantonId: "BE" },
  { plz: "3032", cantonId: "BE" },
  { plz: "3033", cantonId: "BE" },
  { plz: "3034", cantonId: "BE" },
  { plz: "3035", cantonId: "BE" },
  { plz: "3036", cantonId: "BE" },
  { plz: "3037", cantonId: "BE" },
  { plz: "3038", cantonId: "BE" },
  { plz: "3042", cantonId: "BE" },
  { plz: "3043", cantonId: "BE" },
  { plz: "3044", cantonId: "BE" },
  { plz: "3045", cantonId: "BE" },
  { plz: "3046", cantonId: "BE" },
  { plz: "3047", cantonId: "BE" },
  { plz: "3048", cantonId: "BE" },
  { plz: "3049", cantonId: "BE" },
  { plz: "3052", cantonId: "BE" },
  { plz: "3053", cantonId: "BE" },
  { plz: "3053", cantonId: "BE" },
  { plz: "3053", cantonId: "BE" },
  { plz: "3053", cantonId: "BE" },
  { plz: "3053", cantonId: "BE" },
  { plz: "3054", cantonId: "BE" },
  { plz: "3063", cantonId: "BE" },
  { plz: "3065", cantonId: "BE" },
  { plz: "3066", cantonId: "BE" },
  { plz: "3067", cantonId: "BE" },
  { plz: "3068", cantonId: "BE" },
  { plz: "3072", cantonId: "BE" },
  { plz: "3073", cantonId: "BE" },
  { plz: "3074", cantonId: "BE" },
  { plz: "3075", cantonId: "BE" },
  { plz: "3076", cantonId: "BE" },
  { plz: "3077", cantonId: "BE" },
  { plz: "3078", cantonId: "BE" },
  { plz: "3082", cantonId: "BE" },
  { plz: "3083", cantonId: "BE" },
  { plz: "3084", cantonId: "BE" },
  { plz: "3086", cantonId: "BE" },
  { plz: "3086", cantonId: "BE" },
  { plz: "3087", cantonId: "BE" },
  { plz: "3088", cantonId: "BE" },
  { plz: "3088", cantonId: "BE" },
  { plz: "3089", cantonId: "BE" },
  { plz: "3095", cantonId: "BE" },
  { plz: "3096", cantonId: "BE" },
  { plz: "3097", cantonId: "BE" },
  { plz: "3098", cantonId: "BE" },
  { plz: "3098", cantonId: "BE" },
  { plz: "3099", cantonId: "BE" },
  { plz: "3110", cantonId: "BE" },
  { plz: "3111", cantonId: "BE" },
  { plz: "3112", cantonId: "BE" },
  { plz: "3113", cantonId: "BE" },
  { plz: "3114", cantonId: "BE" },
  { plz: "3115", cantonId: "BE" },
  { plz: "3116", cantonId: "BE" },
  { plz: "3116", cantonId: "BE" },
  { plz: "3116", cantonId: "BE" },
  { plz: "3122", cantonId: "BE" },
  { plz: "3123", cantonId: "BE" },
  { plz: "3124", cantonId: "BE" },
  { plz: "3125", cantonId: "BE" },
  { plz: "3126", cantonId: "BE" },
  { plz: "3126", cantonId: "BE" },
  { plz: "3127", cantonId: "BE" },
  { plz: "3127", cantonId: "BE" },
  { plz: "3128", cantonId: "BE" },
  { plz: "3128", cantonId: "BE" },
  { plz: "3132", cantonId: "BE" },
  { plz: "3144", cantonId: "BE" },
  { plz: "3145", cantonId: "BE" },
  { plz: "3147", cantonId: "BE" },
  { plz: "3148", cantonId: "BE" },
  { plz: "3150", cantonId: "BE" },
  { plz: "3152", cantonId: "BE" },
  { plz: "3153", cantonId: "BE" },
  { plz: "3154", cantonId: "BE" },
  { plz: "3155", cantonId: "BE" },
  { plz: "3156", cantonId: "BE" },
  { plz: "3157", cantonId: "BE" },
  { plz: "3158", cantonId: "BE" },
  { plz: "3159", cantonId: "BE" },
  { plz: "3172", cantonId: "BE" },
  { plz: "3173", cantonId: "BE" },
  { plz: "3174", cantonId: "BE" },
  { plz: "3175", cantonId: "FR" },
  { plz: "3176", cantonId: "BE" },
  { plz: "3177", cantonId: "BE" },
  { plz: "3178", cantonId: "FR" },
  { plz: "3179", cantonId: "BE" },
  { plz: "3182", cantonId: "FR" },
  { plz: "3183", cantonId: "BE" },
  { plz: "3184", cantonId: "FR" },
  { plz: "3185", cantonId: "FR" },
  { plz: "3186", cantonId: "FR" },
  { plz: "3202", cantonId: "BE" },
  { plz: "3203", cantonId: "BE" },
  { plz: "3204", cantonId: "BE" },
  { plz: "3205", cantonId: "BE" },
  { plz: "3206", cantonId: "BE" },
  { plz: "3206", cantonId: "BE" },
  { plz: "3206", cantonId: "BE" },
  { plz: "3206", cantonId: "BE" },
  { plz: "3206", cantonId: "BE" },
  { plz: "3207", cantonId: "BE" },
  { plz: "3207", cantonId: "BE" },
  { plz: "3208", cantonId: "BE" },
  { plz: "3210", cantonId: "FR" },
  { plz: "3212", cantonId: "FR" },
  { plz: "3212", cantonId: "FR" },
  { plz: "3213", cantonId: "FR" },
  { plz: "3213", cantonId: "FR" },
  { plz: "3214", cantonId: "FR" },
  { plz: "3215", cantonId: "FR" },
  { plz: "3215", cantonId: "FR" },
  { plz: "3215", cantonId: "FR" },
  { plz: "3216", cantonId: "FR" },
  { plz: "3216", cantonId: "FR" },
  { plz: "3225", cantonId: "BE" },
  { plz: "3226", cantonId: "BE" },
  { plz: "3232", cantonId: "BE" },
  { plz: "3233", cantonId: "BE" },
  { plz: "3234", cantonId: "BE" },
  { plz: "3235", cantonId: "BE" },
  { plz: "3236", cantonId: "BE" },
  { plz: "3237", cantonId: "BE" },
  { plz: "3238", cantonId: "BE" },
  { plz: "3250", cantonId: "BE" },
  { plz: "3251", cantonId: "BE" },
  { plz: "3251", cantonId: "BE" },
  { plz: "3252", cantonId: "BE" },
  { plz: "3253", cantonId: "SO" },
  { plz: "3254", cantonId: "SO" },
  { plz: "3254", cantonId: "SO" },
  { plz: "3255", cantonId: "BE" },
  { plz: "3256", cantonId: "BE" },
  { plz: "3256", cantonId: "BE" },
  { plz: "3256", cantonId: "BE" },
  { plz: "3257", cantonId: "BE" },
  { plz: "3257", cantonId: "BE" },
  { plz: "3262", cantonId: "BE" },
  { plz: "3263", cantonId: "BE" },
  { plz: "3264", cantonId: "BE" },
  { plz: "3266", cantonId: "BE" },
  { plz: "3267", cantonId: "BE" },
  { plz: "3268", cantonId: "BE" },
  { plz: "3270", cantonId: "BE" },
  { plz: "3271", cantonId: "BE" },
  { plz: "3272", cantonId: "BE" },
  { plz: "3272", cantonId: "BE" },
  { plz: "3273", cantonId: "BE" },
  { plz: "3274", cantonId: "BE" },
  { plz: "3274", cantonId: "BE" },
  { plz: "3274", cantonId: "BE" },
  { plz: "3280", cantonId: "FR" },
  { plz: "3280", cantonId: "FR" },
  { plz: "3280", cantonId: "FR" },
  { plz: "3282", cantonId: "BE" },
  { plz: "3283", cantonId: "BE" },
  { plz: "3283", cantonId: "BE" },
  { plz: "3284", cantonId: "FR" },
  { plz: "3285", cantonId: "FR" },
  { plz: "3286", cantonId: "FR" },
  { plz: "3292", cantonId: "BE" },
  { plz: "3293", cantonId: "BE" },
  { plz: "3294", cantonId: "BE" },
  { plz: "3294", cantonId: "BE" },
  { plz: "3295", cantonId: "BE" },
  { plz: "3296", cantonId: "BE" },
  { plz: "3297", cantonId: "BE" },
  { plz: "3298", cantonId: "BE" },
  { plz: "3302", cantonId: "BE" },
  { plz: "3303", cantonId: "BE" },
  { plz: "3303", cantonId: "BE" },
  { plz: "3303", cantonId: "BE" },
  { plz: "3303", cantonId: "BE" },
  { plz: "3305", cantonId: "BE" },
  { plz: "3305", cantonId: "BE" },
  { plz: "3306", cantonId: "BE" },
  { plz: "3307", cantonId: "SO" },
  { plz: "3308", cantonId: "BE" },
  { plz: "3309", cantonId: "BE" },
  { plz: "3309", cantonId: "BE" },
  { plz: "3312", cantonId: "BE" },
  { plz: "3313", cantonId: "BE" },
  { plz: "3314", cantonId: "BE" },
  { plz: "3315", cantonId: "BE" },
  { plz: "3315", cantonId: "BE" },
  { plz: "3317", cantonId: "BE" },
  { plz: "3317", cantonId: "BE" },
  { plz: "3322", cantonId: "BE" },
  { plz: "3322", cantonId: "BE" },
  { plz: "3323", cantonId: "BE" },
  { plz: "3324", cantonId: "BE" },
  { plz: "3324", cantonId: "BE" },
  { plz: "3325", cantonId: "BE" },
  { plz: "3326", cantonId: "BE" },
  { plz: "3360", cantonId: "BE" },
  { plz: "3362", cantonId: "BE" },
  { plz: "3363", cantonId: "BE" },
  { plz: "3365", cantonId: "BE" },
  { plz: "3365", cantonId: "BE" },
  { plz: "3366", cantonId: "BE" },
  { plz: "3366", cantonId: "BE" },
  { plz: "3367", cantonId: "BE" },
  { plz: "3367", cantonId: "BE" },
  { plz: "3368", cantonId: "BE" },
  { plz: "3372", cantonId: "BE" },
  { plz: "3373", cantonId: "BE" },
  { plz: "3373", cantonId: "BE" },
  { plz: "3374", cantonId: "BE" },
  { plz: "3375", cantonId: "BE" },
  { plz: "3376", cantonId: "BE" },
  { plz: "3376", cantonId: "BE" },
  { plz: "3377", cantonId: "BE" },
  { plz: "3380", cantonId: "BE" },
  { plz: "3380", cantonId: "BE" },
  { plz: "3400", cantonId: "BE" },
  { plz: "3412", cantonId: "BE" },
  { plz: "3413", cantonId: "BE" },
  { plz: "3414", cantonId: "BE" },
  { plz: "3415", cantonId: "BE" },
  { plz: "3415", cantonId: "BE" },
  { plz: "3415", cantonId: "BE" },
  { plz: "3416", cantonId: "BE" },
  { plz: "3417", cantonId: "BE" },
  { plz: "3418", cantonId: "BE" },
  { plz: "3419", cantonId: "BE" },
  { plz: "3421", cantonId: "BE" },
  { plz: "3421", cantonId: "BE" },
  { plz: "3422", cantonId: "BE" },
  { plz: "3422", cantonId: "BE" },
  { plz: "3422", cantonId: "BE" },
  { plz: "3423", cantonId: "BE" },
  { plz: "3424", cantonId: "BE" },
  { plz: "3424", cantonId: "BE" },
  { plz: "3425", cantonId: "BE" },
  { plz: "3425", cantonId: "BE" },
  { plz: "3426", cantonId: "BE" },
  { plz: "3427", cantonId: "BE" },
  { plz: "3428", cantonId: "BE" },
  { plz: "3429", cantonId: "BE" },
  { plz: "3429", cantonId: "BE" },
  { plz: "3432", cantonId: "BE" },
  { plz: "3433", cantonId: "BE" },
  { plz: "3434", cantonId: "BE" },
  { plz: "3435", cantonId: "BE" },
  { plz: "3436", cantonId: "BE" },
  { plz: "3437", cantonId: "BE" },
  { plz: "3438", cantonId: "BE" },
  { plz: "3439", cantonId: "BE" },
  { plz: "3452", cantonId: "BE" },
  { plz: "3453", cantonId: "BE" },
  { plz: "3454", cantonId: "BE" },
  { plz: "3455", cantonId: "BE" },
  { plz: "3456", cantonId: "BE" },
  { plz: "3457", cantonId: "BE" },
  { plz: "3462", cantonId: "BE" },
  { plz: "3463", cantonId: "BE" },
  { plz: "3464", cantonId: "BE" },
  { plz: "3465", cantonId: "BE" },
  { plz: "3472", cantonId: "BE" },
  { plz: "3472", cantonId: "BE" },
  { plz: "3473", cantonId: "BE" },
  { plz: "3474", cantonId: "BE" },
  { plz: "3475", cantonId: "BE" },
  { plz: "3475", cantonId: "BE" },
  { plz: "3476", cantonId: "BE" },
  { plz: "3503", cantonId: "BE" },
  { plz: "3504", cantonId: "BE" },
  { plz: "3504", cantonId: "BE" },
  { plz: "3506", cantonId: "BE" },
  { plz: "3507", cantonId: "BE" },
  { plz: "3508", cantonId: "BE" },
  { plz: "3510", cantonId: "BE" },
  { plz: "3510", cantonId: "BE" },
  { plz: "3510", cantonId: "BE" },
  { plz: "3512", cantonId: "BE" },
  { plz: "3513", cantonId: "BE" },
  { plz: "3531", cantonId: "BE" },
  { plz: "3532", cantonId: "BE" },
  { plz: "3532", cantonId: "BE" },
  { plz: "3533", cantonId: "BE" },
  { plz: "3534", cantonId: "BE" },
  { plz: "3535", cantonId: "BE" },
  { plz: "3536", cantonId: "BE" },
  { plz: "3537", cantonId: "BE" },
  { plz: "3538", cantonId: "BE" },
  { plz: "3543", cantonId: "BE" },
  { plz: "3550", cantonId: "BE" },
  { plz: "3551", cantonId: "BE" },
  { plz: "3552", cantonId: "BE" },
  { plz: "3553", cantonId: "BE" },
  { plz: "3555", cantonId: "BE" },
  { plz: "3556", cantonId: "BE" },
  { plz: "3557", cantonId: "BE" },
  { plz: "3600", cantonId: "BE" },
  { plz: "3603", cantonId: "BE" },
  { plz: "3604", cantonId: "BE" },
  { plz: "3608", cantonId: "BE" },
  { plz: "3612", cantonId: "BE" },
  { plz: "3613", cantonId: "BE" },
  { plz: "3614", cantonId: "BE" },
  { plz: "3615", cantonId: "BE" },
  { plz: "3616", cantonId: "BE" },
  { plz: "3617", cantonId: "BE" },
  { plz: "3618", cantonId: "BE" },
  { plz: "3619", cantonId: "BE" },
  { plz: "3619", cantonId: "BE" },
  { plz: "3622", cantonId: "BE" },
  { plz: "3623", cantonId: "BE" },
  { plz: "3623", cantonId: "BE" },
  { plz: "3623", cantonId: "BE" },
  { plz: "3624", cantonId: "BE" },
  { plz: "3624", cantonId: "BE" },
  { plz: "3625", cantonId: "BE" },
  { plz: "3626", cantonId: "BE" },
  { plz: "3627", cantonId: "BE" },
  { plz: "3628", cantonId: "BE" },
  { plz: "3628", cantonId: "BE" },
  { plz: "3629", cantonId: "BE" },
  { plz: "3629", cantonId: "BE" },
  { plz: "3629", cantonId: "BE" },
  { plz: "3631", cantonId: "BE" },
  { plz: "3632", cantonId: "BE" },
  { plz: "3632", cantonId: "BE" },
  { plz: "3633", cantonId: "BE" },
  { plz: "3634", cantonId: "BE" },
  { plz: "3635", cantonId: "BE" },
  { plz: "3636", cantonId: "BE" },
  { plz: "3636", cantonId: "BE" },
  { plz: "3638", cantonId: "BE" },
  { plz: "3638", cantonId: "BE" },
  { plz: "3645", cantonId: "BE" },
  { plz: "3645", cantonId: "BE" },
  { plz: "3646", cantonId: "BE" },
  { plz: "3647", cantonId: "BE" },
  { plz: "3652", cantonId: "BE" },
  { plz: "3653", cantonId: "BE" },
  { plz: "3654", cantonId: "BE" },
  { plz: "3655", cantonId: "BE" },
  { plz: "3656", cantonId: "BE" },
  { plz: "3656", cantonId: "BE" },
  { plz: "3656", cantonId: "BE" },
  { plz: "3657", cantonId: "BE" },
  { plz: "3658", cantonId: "BE" },
  { plz: "3661", cantonId: "BE" },
  { plz: "3662", cantonId: "BE" },
  { plz: "3663", cantonId: "BE" },
  { plz: "3664", cantonId: "BE" },
  { plz: "3665", cantonId: "BE" },
  { plz: "3671", cantonId: "BE" },
  { plz: "3671", cantonId: "BE" },
  { plz: "3672", cantonId: "BE" },
  { plz: "3672", cantonId: "BE" },
  { plz: "3673", cantonId: "BE" },
  { plz: "3674", cantonId: "BE" },
  { plz: "3700", cantonId: "BE" },
  { plz: "3702", cantonId: "BE" },
  { plz: "3703", cantonId: "BE" },
  { plz: "3703", cantonId: "BE" },
  { plz: "3704", cantonId: "BE" },
  { plz: "3705", cantonId: "BE" },
  { plz: "3706", cantonId: "BE" },
  { plz: "3707", cantonId: "BE" },
  { plz: "3711", cantonId: "BE" },
  { plz: "3711", cantonId: "BE" },
  { plz: "3713", cantonId: "BE" },
  { plz: "3714", cantonId: "BE" },
  { plz: "3714", cantonId: "BE" },
  { plz: "3715", cantonId: "BE" },
  { plz: "3716", cantonId: "BE" },
  { plz: "3717", cantonId: "BE" },
  { plz: "3718", cantonId: "BE" },
  { plz: "3722", cantonId: "BE" },
  { plz: "3723", cantonId: "BE" },
  { plz: "3724", cantonId: "BE" },
  { plz: "3725", cantonId: "BE" },
  { plz: "3752", cantonId: "BE" },
  { plz: "3753", cantonId: "BE" },
  { plz: "3754", cantonId: "BE" },
  { plz: "3755", cantonId: "BE" },
  { plz: "3756", cantonId: "BE" },
  { plz: "3757", cantonId: "BE" },
  { plz: "3758", cantonId: "BE" },
  { plz: "3762", cantonId: "BE" },
  { plz: "3763", cantonId: "BE" },
  { plz: "3764", cantonId: "BE" },
  { plz: "3765", cantonId: "BE" },
  { plz: "3766", cantonId: "BE" },
  { plz: "3770", cantonId: "BE" },
  { plz: "3771", cantonId: "BE" },
  { plz: "3772", cantonId: "BE" },
  { plz: "3773", cantonId: "BE" },
  { plz: "3775", cantonId: "BE" },
  { plz: "3776", cantonId: "BE" },
  { plz: "3777", cantonId: "BE" },
  { plz: "3778", cantonId: "BE" },
  { plz: "3780", cantonId: "BE" },
  { plz: "3781", cantonId: "BE" },
  { plz: "3782", cantonId: "BE" },
  { plz: "3783", cantonId: "BE" },
  { plz: "3784", cantonId: "BE" },
  { plz: "3785", cantonId: "BE" },
  { plz: "3792", cantonId: "BE" },
  { plz: "3800", cantonId: "BE" },
  { plz: "3800", cantonId: "BE" },
  { plz: "3800", cantonId: "BE" },
  { plz: "3800", cantonId: "BE" },
  { plz: "3801", cantonId: "VS" },
  { plz: "3803", cantonId: "BE" },
  { plz: "3804", cantonId: "BE" },
  { plz: "3805", cantonId: "BE" },
  { plz: "3806", cantonId: "BE" },
  { plz: "3807", cantonId: "BE" },
  { plz: "3812", cantonId: "BE" },
  { plz: "3813", cantonId: "BE" },
  { plz: "3814", cantonId: "BE" },
  { plz: "3815", cantonId: "BE" },
  { plz: "3815", cantonId: "BE" },
  { plz: "3816", cantonId: "BE" },
  { plz: "3816", cantonId: "BE" },
  { plz: "3818", cantonId: "BE" },
  { plz: "3822", cantonId: "BE" },
  { plz: "3822", cantonId: "BE" },
  { plz: "3823", cantonId: "BE" },
  { plz: "3823", cantonId: "BE" },
  { plz: "3823", cantonId: "BE" },
  { plz: "3824", cantonId: "BE" },
  { plz: "3825", cantonId: "BE" },
  { plz: "3826", cantonId: "BE" },
  { plz: "3852", cantonId: "BE" },
  { plz: "3853", cantonId: "BE" },
  { plz: "3854", cantonId: "BE" },
  { plz: "3855", cantonId: "BE" },
  { plz: "3855", cantonId: "BE" },
  { plz: "3855", cantonId: "BE" },
  { plz: "3856", cantonId: "BE" },
  { plz: "3857", cantonId: "BE" },
  { plz: "3858", cantonId: "BE" },
  { plz: "3860", cantonId: "BE" },
  { plz: "3860", cantonId: "BE" },
  { plz: "3860", cantonId: "BE" },
  { plz: "3860", cantonId: "BE" },
  { plz: "3862", cantonId: "BE" },
  { plz: "3863", cantonId: "BE" },
  { plz: "3864", cantonId: "BE" },
  { plz: "3900", cantonId: "VS" },
  { plz: "3900", cantonId: "VS" },
  { plz: "3900", cantonId: "VS" },
  { plz: "3901", cantonId: "VS" },
  { plz: "3902", cantonId: "VS" },
  { plz: "3903", cantonId: "VS" },
  { plz: "3903", cantonId: "VS" },
  { plz: "3904", cantonId: "VS" },
  { plz: "3905", cantonId: "VS" },
  { plz: "3906", cantonId: "VS" },
  { plz: "3907", cantonId: "VS" },
  { plz: "3907", cantonId: "VS" },
  { plz: "3907", cantonId: "VS" },
  { plz: "3907", cantonId: "VS" },
  { plz: "3908", cantonId: "VS" },
  { plz: "3910", cantonId: "VS" },
  { plz: "3911", cantonId: "VS" },
  { plz: "3912", cantonId: "VS" },
  { plz: "3913", cantonId: "VS" },
  { plz: "3914", cantonId: "VS" },
  { plz: "3914", cantonId: "VS" },
  { plz: "3916", cantonId: "VS" },
  { plz: "3917", cantonId: "VS" },
  { plz: "3917", cantonId: "VS" },
  { plz: "3918", cantonId: "VS" },
  { plz: "3919", cantonId: "VS" },
  { plz: "3920", cantonId: "VS" },
  { plz: "3922", cantonId: "VS" },
  { plz: "3922", cantonId: "VS" },
  { plz: "3922", cantonId: "VS" },
  { plz: "3923", cantonId: "VS" },
  { plz: "3924", cantonId: "VS" },
  { plz: "3924", cantonId: "VS" },
  { plz: "3925", cantonId: "VS" },
  { plz: "3926", cantonId: "VS" },
  { plz: "3927", cantonId: "VS" },
  { plz: "3928", cantonId: "VS" },
  { plz: "3929", cantonId: "VS" },
  { plz: "3930", cantonId: "VS" },
  { plz: "3930", cantonId: "VS" },
  { plz: "3931", cantonId: "VS" },
  { plz: "3932", cantonId: "VS" },
  { plz: "3933", cantonId: "VS" },
  { plz: "3934", cantonId: "VS" },
  { plz: "3935", cantonId: "VS" },
  { plz: "3937", cantonId: "VS" },
  { plz: "3938", cantonId: "VS" },
  { plz: "3939", cantonId: "VS" },
  { plz: "3940", cantonId: "VS" },
  { plz: "3942", cantonId: "VS" },
  { plz: "3942", cantonId: "VS" },
  { plz: "3942", cantonId: "VS" },
  { plz: "3943", cantonId: "VS" },
  { plz: "3944", cantonId: "VS" },
  { plz: "3945", cantonId: "VS" },
  { plz: "3945", cantonId: "VS" },
  { plz: "3946", cantonId: "VS" },
  { plz: "3946", cantonId: "VS" },
  { plz: "3947", cantonId: "VS" },
  { plz: "3948", cantonId: "VS" },
  { plz: "3948", cantonId: "VS" },
  { plz: "3949", cantonId: "VS" },
  { plz: "3951", cantonId: "VS" },
  { plz: "3952", cantonId: "VS" },
  { plz: "3953", cantonId: "VS" },
  { plz: "3953", cantonId: "VS" },
  { plz: "3953", cantonId: "VS" },
  { plz: "3954", cantonId: "VS" },
  { plz: "3955", cantonId: "VS" },
  { plz: "3956", cantonId: "VS" },
  { plz: "3957", cantonId: "VS" },
  { plz: "3957", cantonId: "VS" },
  { plz: "3960", cantonId: "VS" },
  { plz: "3960", cantonId: "VS" },
  { plz: "3960", cantonId: "VS" },
  { plz: "3960", cantonId: "VS" },
  { plz: "3960", cantonId: "VS" },
  { plz: "3961", cantonId: "VS" },
  { plz: "3961", cantonId: "VS" },
  { plz: "3961", cantonId: "VS" },
  { plz: "3961", cantonId: "VS" },
  { plz: "3961", cantonId: "VS" },
  { plz: "3961", cantonId: "VS" },
  { plz: "3961", cantonId: "VS" },
  { plz: "3963", cantonId: "VS" },
  { plz: "3963", cantonId: "VS" },
  { plz: "3963", cantonId: "VS" },
  { plz: "3965", cantonId: "VS" },
  { plz: "3966", cantonId: "VS" },
  { plz: "3966", cantonId: "VS" },
  { plz: "3967", cantonId: "VS" },
  { plz: "3968", cantonId: "VS" },
  { plz: "3970", cantonId: "VS" },
  { plz: "3971", cantonId: "VS" },
  { plz: "3971", cantonId: "VS" },
  { plz: "3971", cantonId: "VS" },
  { plz: "3972", cantonId: "VS" },
  { plz: "3973", cantonId: "VS" },
  { plz: "3974", cantonId: "VS" },
  { plz: "3975", cantonId: "VS" },
  { plz: "3976", cantonId: "VS" },
  { plz: "3976", cantonId: "VS" },
  { plz: "3977", cantonId: "VS" },
  { plz: "3978", cantonId: "VS" },
  { plz: "3979", cantonId: "VS" },
  { plz: "3982", cantonId: "VS" },
  { plz: "3983", cantonId: "VS" },
  { plz: "3983", cantonId: "VS" },
  { plz: "3983", cantonId: "VS" },
  { plz: "3983", cantonId: "VS" },
  { plz: "3983", cantonId: "VS" },
  { plz: "3984", cantonId: "VS" },
  { plz: "3984", cantonId: "VS" },
  { plz: "3985", cantonId: "VS" },
  { plz: "3985", cantonId: "VS" },
  { plz: "3986", cantonId: "VS" },
  { plz: "3987", cantonId: "VS" },
  { plz: "3988", cantonId: "VS" },
  { plz: "3988", cantonId: "VS" },
  { plz: "3989", cantonId: "VS" },
  { plz: "3989", cantonId: "VS" },
  { plz: "3989", cantonId: "VS" },
  { plz: "3989", cantonId: "VS" },
  { plz: "3989", cantonId: "VS" },
  { plz: "3991", cantonId: "VS" },
  { plz: "3992", cantonId: "VS" },
  { plz: "3993", cantonId: "VS" },
  { plz: "3994", cantonId: "VS" },
  { plz: "3994", cantonId: "VS" },
  { plz: "3995", cantonId: "VS" },
  { plz: "3995", cantonId: "VS" },
  { plz: "3995", cantonId: "VS" },
  { plz: "3995", cantonId: "VS" },
  { plz: "3996", cantonId: "VS" },
  { plz: "3997", cantonId: "VS" },
  { plz: "3998", cantonId: "VS" },
  { plz: "3998", cantonId: "VS" },
  { plz: "3999", cantonId: "VS" },
  { plz: "4000", cantonId: "BS" },
  { plz: "4001", cantonId: "BS" },
  { plz: "4031", cantonId: "BS" },
  { plz: "4051", cantonId: "BS" },
  { plz: "4052", cantonId: "BS" },
  { plz: "4053", cantonId: "BS" },
  { plz: "4054", cantonId: "BS" },
  { plz: "4055", cantonId: "BS" },
  { plz: "4056", cantonId: "BS" },
  { plz: "4057", cantonId: "BS" },
  { plz: "4058", cantonId: "BS" },
  { plz: "4059", cantonId: "BS" },
  { plz: "4101", cantonId: "BL" },
  { plz: "4102", cantonId: "BL" },
  { plz: "4103", cantonId: "BL" },
  { plz: "4104", cantonId: "BL" },
  { plz: "4105", cantonId: "BL" },
  { plz: "4106", cantonId: "BL" },
  { plz: "4107", cantonId: "BL" },
  { plz: "4108", cantonId: "SO" },
  { plz: "4112", cantonId: "SO" },
  { plz: "4112", cantonId: "SO" },
  { plz: "4114", cantonId: "SO" },
  { plz: "4115", cantonId: "SO" },
  { plz: "4116", cantonId: "SO" },
  { plz: "4117", cantonId: "BL" },
  { plz: "4118", cantonId: "SO" },
  { plz: "4123", cantonId: "BL" },
  { plz: "4124", cantonId: "BL" },
  { plz: "4125", cantonId: "BS" },
  { plz: "4126", cantonId: "BS" },
  { plz: "4127", cantonId: "BL" },
  { plz: "4132", cantonId: "BL" },
  { plz: "4133", cantonId: "BL" },
  { plz: "4142", cantonId: "BL" },
  { plz: "4143", cantonId: "SO" },
  { plz: "4144", cantonId: "BL" },
  { plz: "4145", cantonId: "SO" },
  { plz: "4146", cantonId: "SO" },
  { plz: "4147", cantonId: "BL" },
  { plz: "4148", cantonId: "BL" },
  { plz: "4153", cantonId: "BL" },
  { plz: "4202", cantonId: "BL" },
  { plz: "4203", cantonId: "BL" },
  { plz: "4204", cantonId: "SO" },
  { plz: "4206", cantonId: "SO" },
  { plz: "4207", cantonId: "BL" },
  { plz: "4208", cantonId: "SO" },
  { plz: "4222", cantonId: "BL" },
  { plz: "4223", cantonId: "BL" },
  { plz: "4224", cantonId: "BL" },
  { plz: "4225", cantonId: "BL" },
  { plz: "4226", cantonId: "SO" },
  { plz: "4227", cantonId: "SO" },
  { plz: "4228", cantonId: "SO" },
  { plz: "4229", cantonId: "SO" },
  { plz: "4232", cantonId: "SO" },
  { plz: "4233", cantonId: "SO" },
  { plz: "4234", cantonId: "SO" },
  { plz: "4242", cantonId: "BL" },
  { plz: "4243", cantonId: "BL" },
  { plz: "4244", cantonId: "BL" },
  { plz: "4245", cantonId: "SO" },
  { plz: "4246", cantonId: "BL" },
  { plz: "4247", cantonId: "SO" },
  { plz: "4252", cantonId: "SO" },
  { plz: "4253", cantonId: "BL" },
  { plz: "4254", cantonId: "BL" },
  { plz: "4302", cantonId: "BL" },
  { plz: "4303", cantonId: "AG" },
  { plz: "4304", cantonId: "BL" },
  { plz: "4305", cantonId: "AG" },
  { plz: "4310", cantonId: "AG" },
  { plz: "4312", cantonId: "AG" },
  { plz: "4313", cantonId: "AG" },
  { plz: "4314", cantonId: "AG" },
  { plz: "4315", cantonId: "AG" },
  { plz: "4316", cantonId: "AG" },
  { plz: "4317", cantonId: "AG" },
  { plz: "4322", cantonId: "AG" },
  { plz: "4323", cantonId: "AG" },
  { plz: "4324", cantonId: "AG" },
  { plz: "4325", cantonId: "AG" },
  { plz: "4332", cantonId: "AG" },
  { plz: "4333", cantonId: "AG" },
  { plz: "4334", cantonId: "AG" },
  { plz: "4402", cantonId: "BL" },
  { plz: "4410", cantonId: "BL" },
  { plz: "4411", cantonId: "BL" },
  { plz: "4412", cantonId: "SO" },
  { plz: "4413", cantonId: "SO" },
  { plz: "4414", cantonId: "BL" },
  { plz: "4415", cantonId: "BL" },
  { plz: "4416", cantonId: "BL" },
  { plz: "4417", cantonId: "BL" },
  { plz: "4418", cantonId: "BL" },
  { plz: "4419", cantonId: "BL" },
  { plz: "4421", cantonId: "SO" },
  { plz: "4422", cantonId: "BL" },
  { plz: "4423", cantonId: "BL" },
  { plz: "4424", cantonId: "BL" },
  { plz: "4425", cantonId: "BL" },
  { plz: "4426", cantonId: "BL" },
  { plz: "4431", cantonId: "BL" },
  { plz: "4432", cantonId: "BL" },
  { plz: "4433", cantonId: "BL" },
  { plz: "4434", cantonId: "BL" },
  { plz: "4435", cantonId: "BL" },
  { plz: "4436", cantonId: "BL" },
  { plz: "4436", cantonId: "BL" },
  { plz: "4437", cantonId: "BL" },
  { plz: "4438", cantonId: "BL" },
  { plz: "4441", cantonId: "BL" },
  { plz: "4442", cantonId: "BL" },
  { plz: "4443", cantonId: "BL" },
  { plz: "4444", cantonId: "BL" },
  { plz: "4445", cantonId: "BL" },
  { plz: "4446", cantonId: "BL" },
  { plz: "4447", cantonId: "BL" },
  { plz: "4448", cantonId: "BL" },
  { plz: "4450", cantonId: "BL" },
  { plz: "4451", cantonId: "BL" },
  { plz: "4452", cantonId: "BL" },
  { plz: "4453", cantonId: "BL" },
  { plz: "4455", cantonId: "BL" },
  { plz: "4456", cantonId: "BL" },
  { plz: "4457", cantonId: "BL" },
  { plz: "4458", cantonId: "BL" },
  { plz: "4460", cantonId: "BL" },
  { plz: "4461", cantonId: "BL" },
  { plz: "4462", cantonId: "BL" },
  { plz: "4463", cantonId: "BL" },
  { plz: "4464", cantonId: "BL" },
  { plz: "4465", cantonId: "BL" },
  { plz: "4466", cantonId: "BL" },
  { plz: "4467", cantonId: "BL" },
  { plz: "4468", cantonId: "SO" },
  { plz: "4469", cantonId: "BL" },
  { plz: "4492", cantonId: "BL" },
  { plz: "4493", cantonId: "BL" },
  { plz: "4494", cantonId: "BL" },
  { plz: "4495", cantonId: "BL" },
  { plz: "4496", cantonId: "BL" },
  { plz: "4497", cantonId: "BL" },
  { plz: "4500", cantonId: "SO" },
  { plz: "4512", cantonId: "SO" },
  { plz: "4513", cantonId: "SO" },
  { plz: "4514", cantonId: "SO" },
  { plz: "4515", cantonId: "SO" },
  { plz: "4515", cantonId: "SO" },
  { plz: "4522", cantonId: "SO" },
  { plz: "4523", cantonId: "SO" },
  { plz: "4524", cantonId: "SO" },
  { plz: "4524", cantonId: "SO" },
  { plz: "4524", cantonId: "SO" },
  { plz: "4525", cantonId: "SO" },
  { plz: "4528", cantonId: "SO" },
  { plz: "4532", cantonId: "SO" },
  { plz: "4533", cantonId: "SO" },
  { plz: "4534", cantonId: "SO" },
  { plz: "4535", cantonId: "SO" },
  { plz: "4535", cantonId: "SO" },
  { plz: "4536", cantonId: "BE" },
  { plz: "4537", cantonId: "BE" },
  { plz: "4538", cantonId: "BE" },
  { plz: "4539", cantonId: "BE" },
  { plz: "4539", cantonId: "BE" },
  { plz: "4542", cantonId: "SO" },
  { plz: "4543", cantonId: "SO" },
  { plz: "4552", cantonId: "SO" },
  { plz: "4553", cantonId: "SO" },
  { plz: "4554", cantonId: "SO" },
  { plz: "4554", cantonId: "SO" },
  { plz: "4556", cantonId: "SO" },
  { plz: "4556", cantonId: "SO" },
  { plz: "4556", cantonId: "SO" },
  { plz: "4556", cantonId: "SO" },
  { plz: "4557", cantonId: "SO" },
  { plz: "4558", cantonId: "SO" },
  { plz: "4558", cantonId: "SO" },
  { plz: "4558", cantonId: "SO" },
  { plz: "4562", cantonId: "SO" },
  { plz: "4563", cantonId: "SO" },
  { plz: "4564", cantonId: "BE" },
  { plz: "4564", cantonId: "BE" },
  { plz: "4565", cantonId: "SO" },
  { plz: "4566", cantonId: "SO" },
  { plz: "4566", cantonId: "SO" },
  { plz: "4566", cantonId: "SO" },
  { plz: "4571", cantonId: "SO" },
  { plz: "4571", cantonId: "SO" },
  { plz: "4573", cantonId: "SO" },
  { plz: "4574", cantonId: "SO" },
  { plz: "4574", cantonId: "SO" },
  { plz: "4576", cantonId: "SO" },
  { plz: "4577", cantonId: "SO" },
  { plz: "4578", cantonId: "SO" },
  { plz: "4579", cantonId: "SO" },
  { plz: "4581", cantonId: "SO" },
  { plz: "4582", cantonId: "SO" },
  { plz: "4583", cantonId: "SO" },
  { plz: "4583", cantonId: "SO" },
  { plz: "4584", cantonId: "SO" },
  { plz: "4584", cantonId: "SO" },
  { plz: "4585", cantonId: "SO" },
  { plz: "4586", cantonId: "SO" },
  { plz: "4587", cantonId: "SO" },
  { plz: "4588", cantonId: "SO" },
  { plz: "4588", cantonId: "SO" },
  { plz: "4588", cantonId: "SO" },
  { plz: "4600", cantonId: "SO" },
  { plz: "4612", cantonId: "SO" },
  { plz: "4613", cantonId: "SO" },
  { plz: "4614", cantonId: "SO" },
  { plz: "4615", cantonId: "SO" },
  { plz: "4616", cantonId: "SO" },
  { plz: "4617", cantonId: "SO" },
  { plz: "4618", cantonId: "SO" },
  { plz: "4622", cantonId: "SO" },
  { plz: "4623", cantonId: "SO" },
  { plz: "4624", cantonId: "SO" },
  { plz: "4625", cantonId: "SO" },
  { plz: "4626", cantonId: "SO" },
  { plz: "4628", cantonId: "SO" },
  { plz: "4629", cantonId: "SO" },
  { plz: "4632", cantonId: "SO" },
  { plz: "4633", cantonId: "SO" },
  { plz: "4634", cantonId: "SO" },
  { plz: "4652", cantonId: "SO" },
  { plz: "4653", cantonId: "SO" },
  { plz: "4654", cantonId: "SO" },
  { plz: "4655", cantonId: "SO" },
  { plz: "4655", cantonId: "SO" },
  { plz: "4656", cantonId: "SO" },
  { plz: "4657", cantonId: "SO" },
  { plz: "4658", cantonId: "SO" },
  { plz: "4663", cantonId: "AG" },
  { plz: "4665", cantonId: "AG" },
  { plz: "4702", cantonId: "SO" },
  { plz: "4703", cantonId: "SO" },
  { plz: "4704", cantonId: "BE" },
  { plz: "4704", cantonId: "BE" },
  { plz: "4710", cantonId: "SO" },
  { plz: "4712", cantonId: "SO" },
  { plz: "4713", cantonId: "SO" },
  { plz: "4714", cantonId: "SO" },
  { plz: "4715", cantonId: "SO" },
  { plz: "4716", cantonId: "SO" },
  { plz: "4716", cantonId: "SO" },
  { plz: "4717", cantonId: "SO" },
  { plz: "4718", cantonId: "SO" },
  { plz: "4719", cantonId: "SO" },
  { plz: "4800", cantonId: "AG" },
  { plz: "4802", cantonId: "AG" },
  { plz: "4803", cantonId: "AG" },
  { plz: "4805", cantonId: "AG" },
  { plz: "4806", cantonId: "LU" },
  { plz: "4812", cantonId: "AG" },
  { plz: "4813", cantonId: "AG" },
  { plz: "4814", cantonId: "AG" },
  { plz: "4852", cantonId: "AG" },
  { plz: "4853", cantonId: "AG" },
  { plz: "4853", cantonId: "AG" },
  { plz: "4856", cantonId: "AG" },
  { plz: "4900", cantonId: "BE" },
  { plz: "4911", cantonId: "BE" },
  { plz: "4912", cantonId: "BE" },
  { plz: "4913", cantonId: "BE" },
  { plz: "4914", cantonId: "BE" },
  { plz: "4915", cantonId: "LU" },
  { plz: "4916", cantonId: "BE" },
  { plz: "4917", cantonId: "BE" },
  { plz: "4917", cantonId: "BE" },
  { plz: "4919", cantonId: "BE" },
  { plz: "4922", cantonId: "BE" },
  { plz: "4922", cantonId: "BE" },
  { plz: "4923", cantonId: "BE" },
  { plz: "4924", cantonId: "BE" },
  { plz: "4932", cantonId: "BE" },
  { plz: "4932", cantonId: "BE" },
  { plz: "4933", cantonId: "BE" },
  { plz: "4934", cantonId: "BE" },
  { plz: "4935", cantonId: "BE" },
  { plz: "4936", cantonId: "BE" },
  { plz: "4937", cantonId: "BE" },
  { plz: "4938", cantonId: "BE" },
  { plz: "4938", cantonId: "BE" },
  { plz: "4942", cantonId: "BE" },
  { plz: "4943", cantonId: "BE" },
  { plz: "4944", cantonId: "BE" },
  { plz: "4950", cantonId: "BE" },
  { plz: "4952", cantonId: "BE" },
  { plz: "4953", cantonId: "BE" },
  { plz: "4954", cantonId: "BE" },
  { plz: "4955", cantonId: "BE" },
  { plz: "5000", cantonId: "AG" },
  { plz: "5004", cantonId: "AG" },
  { plz: "5012", cantonId: "SO" },
  { plz: "5012", cantonId: "SO" },
  { plz: "5012", cantonId: "SO" },
  { plz: "5013", cantonId: "SO" },
  { plz: "5014", cantonId: "SO" },
  { plz: "5015", cantonId: "SO" },
  { plz: "5017", cantonId: "AG" },
  { plz: "5018", cantonId: "AG" },
  { plz: "5022", cantonId: "AG" },
  { plz: "5023", cantonId: "AG" },
  { plz: "5024", cantonId: "AG" },
  { plz: "5025", cantonId: "AG" },
  { plz: "5026", cantonId: "AG" },
  { plz: "5027", cantonId: "AG" },
  { plz: "5028", cantonId: "AG" },
  { plz: "5032", cantonId: "AG" },
  { plz: "5033", cantonId: "AG" },
  { plz: "5034", cantonId: "AG" },
  { plz: "5035", cantonId: "AG" },
  { plz: "5036", cantonId: "AG" },
  { plz: "5037", cantonId: "AG" },
  { plz: "5040", cantonId: "AG" },
  { plz: "5042", cantonId: "AG" },
  { plz: "5043", cantonId: "AG" },
  { plz: "5044", cantonId: "AG" },
  { plz: "5046", cantonId: "AG" },
  { plz: "5046", cantonId: "AG" },
  { plz: "5046", cantonId: "AG" },
  { plz: "5053", cantonId: "AG" },
  { plz: "5053", cantonId: "AG" },
  { plz: "5054", cantonId: "AG" },
  { plz: "5054", cantonId: "AG" },
  { plz: "5056", cantonId: "AG" },
  { plz: "5057", cantonId: "AG" },
  { plz: "5058", cantonId: "AG" },
  { plz: "5062", cantonId: "AG" },
  { plz: "5063", cantonId: "AG" },
  { plz: "5064", cantonId: "AG" },
  { plz: "5070", cantonId: "AG" },
  { plz: "5072", cantonId: "AG" },
  { plz: "5073", cantonId: "AG" },
  { plz: "5074", cantonId: "AG" },
  { plz: "5075", cantonId: "AG" },
  { plz: "5076", cantonId: "AG" },
  { plz: "5077", cantonId: "AG" },
  { plz: "5078", cantonId: "AG" },
  { plz: "5079", cantonId: "AG" },
  { plz: "5080", cantonId: "AG" },
  { plz: "5082", cantonId: "AG" },
  { plz: "5083", cantonId: "AG" },
  { plz: "5084", cantonId: "AG" },
  { plz: "5085", cantonId: "AG" },
  { plz: "5102", cantonId: "AG" },
  { plz: "5103", cantonId: "AG" },
  { plz: "5103", cantonId: "AG" },
  { plz: "5105", cantonId: "AG" },
  { plz: "5106", cantonId: "AG" },
  { plz: "5107", cantonId: "AG" },
  { plz: "5108", cantonId: "AG" },
  { plz: "5112", cantonId: "AG" },
  { plz: "5113", cantonId: "AG" },
  { plz: "5116", cantonId: "AG" },
  { plz: "5200", cantonId: "AG" },
  { plz: "5210", cantonId: "AG" },
  { plz: "5212", cantonId: "AG" },
  { plz: "5213", cantonId: "AG" },
  { plz: "5222", cantonId: "AG" },
  { plz: "5223", cantonId: "AG" },
  { plz: "5224", cantonId: "AG" },
  { plz: "5224", cantonId: "AG" },
  { plz: "5224", cantonId: "AG" },
  { plz: "5225", cantonId: "AG" },
  { plz: "5233", cantonId: "AG" },
  { plz: "5234", cantonId: "AG" },
  { plz: "5235", cantonId: "AG" },
  { plz: "5236", cantonId: "AG" },
  { plz: "5237", cantonId: "AG" },
  { plz: "5242", cantonId: "AG" },
  { plz: "5242", cantonId: "AG" },
  { plz: "5243", cantonId: "AG" },
  { plz: "5244", cantonId: "AG" },
  { plz: "5245", cantonId: "AG" },
  { plz: "5246", cantonId: "AG" },
  { plz: "5272", cantonId: "AG" },
  { plz: "5273", cantonId: "AG" },
  { plz: "5274", cantonId: "AG" },
  { plz: "5275", cantonId: "AG" },
  { plz: "5276", cantonId: "AG" },
  { plz: "5277", cantonId: "AG" },
  { plz: "5300", cantonId: "AG" },
  { plz: "5301", cantonId: "AG" },
  { plz: "5303", cantonId: "AG" },
  { plz: "5304", cantonId: "AG" },
  { plz: "5305", cantonId: "AG" },
  { plz: "5306", cantonId: "AG" },
  { plz: "5312", cantonId: "AG" },
  { plz: "5313", cantonId: "AG" },
  { plz: "5314", cantonId: "AG" },
  { plz: "5315", cantonId: "AG" },
  { plz: "5316", cantonId: "AG" },
  { plz: "5316", cantonId: "AG" },
  { plz: "5317", cantonId: "AG" },
  { plz: "5318", cantonId: "AG" },
  { plz: "5322", cantonId: "AG" },
  { plz: "5323", cantonId: "AG" },
  { plz: "5324", cantonId: "AG" },
  { plz: "5325", cantonId: "AG" },
  { plz: "5326", cantonId: "AG" },
  { plz: "5330", cantonId: "AG" },
  { plz: "5332", cantonId: "AG" },
  { plz: "5333", cantonId: "AG" },
  { plz: "5334", cantonId: "AG" },
  { plz: "5400", cantonId: "AG" },
  { plz: "5404", cantonId: "AG" },
  { plz: "5405", cantonId: "AG" },
  { plz: "5406", cantonId: "AG" },
  { plz: "5408", cantonId: "AG" },
  { plz: "5412", cantonId: "AG" },
  { plz: "5413", cantonId: "AG" },
  { plz: "5415", cantonId: "AG" },
  { plz: "5415", cantonId: "AG" },
  { plz: "5415", cantonId: "AG" },
  { plz: "5416", cantonId: "AG" },
  { plz: "5417", cantonId: "AG" },
  { plz: "5420", cantonId: "AG" },
  { plz: "5423", cantonId: "AG" },
  { plz: "5425", cantonId: "AG" },
  { plz: "5426", cantonId: "AG" },
  { plz: "5430", cantonId: "AG" },
  { plz: "5432", cantonId: "AG" },
  { plz: "5436", cantonId: "AG" },
  { plz: "5442", cantonId: "AG" },
  { plz: "5443", cantonId: "AG" },
  { plz: "5444", cantonId: "AG" },
  { plz: "5445", cantonId: "AG" },
  { plz: "5452", cantonId: "AG" },
  { plz: "5453", cantonId: "AG" },
  { plz: "5454", cantonId: "AG" },
  { plz: "5462", cantonId: "AG" },
  { plz: "5463", cantonId: "AG" },
  { plz: "5464", cantonId: "AG" },
  { plz: "5465", cantonId: "AG" },
  { plz: "5466", cantonId: "AG" },
  { plz: "5467", cantonId: "AG" },
  { plz: "5502", cantonId: "AG" },
  { plz: "5503", cantonId: "AG" },
  { plz: "5504", cantonId: "AG" },
  { plz: "5505", cantonId: "AG" },
  { plz: "5506", cantonId: "AG" },
  { plz: "5507", cantonId: "AG" },
  { plz: "5512", cantonId: "AG" },
  { plz: "5522", cantonId: "AG" },
  { plz: "5524", cantonId: "AG" },
  { plz: "5524", cantonId: "AG" },
  { plz: "5525", cantonId: "AG" },
  { plz: "5600", cantonId: "AG" },
  { plz: "5600", cantonId: "AG" },
  { plz: "5603", cantonId: "AG" },
  { plz: "5604", cantonId: "AG" },
  { plz: "5605", cantonId: "AG" },
  { plz: "5606", cantonId: "AG" },
  { plz: "5607", cantonId: "AG" },
  { plz: "5608", cantonId: "AG" },
  { plz: "5610", cantonId: "AG" },
  { plz: "5611", cantonId: "AG" },
  { plz: "5612", cantonId: "AG" },
  { plz: "5613", cantonId: "AG" },
  { plz: "5614", cantonId: "AG" },
  { plz: "5615", cantonId: "AG" },
  { plz: "5616", cantonId: "AG" },
  { plz: "5617", cantonId: "AG" },
  { plz: "5618", cantonId: "AG" },
  { plz: "5619", cantonId: "AG" },
  { plz: "5619", cantonId: "AG" },
  { plz: "5620", cantonId: "AG" },
  { plz: "5621", cantonId: "AG" },
  { plz: "5622", cantonId: "AG" },
  { plz: "5623", cantonId: "AG" },
  { plz: "5624", cantonId: "AG" },
  { plz: "5624", cantonId: "AG" },
  { plz: "5625", cantonId: "AG" },
  { plz: "5626", cantonId: "AG" },
  { plz: "5627", cantonId: "AG" },
  { plz: "5628", cantonId: "AG" },
  { plz: "5630", cantonId: "AG" },
  { plz: "5632", cantonId: "AG" },
  { plz: "5634", cantonId: "AG" },
  { plz: "5636", cantonId: "AG" },
  { plz: "5637", cantonId: "AG" },
  { plz: "5637", cantonId: "AG" },
  { plz: "5642", cantonId: "AG" },
  { plz: "5643", cantonId: "AG" },
  { plz: "5643", cantonId: "AG" },
  { plz: "5643", cantonId: "AG" },
  { plz: "5644", cantonId: "AG" },
  { plz: "5645", cantonId: "AG" },
  { plz: "5645", cantonId: "AG" },
  { plz: "5646", cantonId: "AG" },
  { plz: "5647", cantonId: "AG" },
  { plz: "5702", cantonId: "AG" },
  { plz: "5703", cantonId: "AG" },
  { plz: "5704", cantonId: "AG" },
  { plz: "5705", cantonId: "AG" },
  { plz: "5706", cantonId: "AG" },
  { plz: "5707", cantonId: "AG" },
  { plz: "5708", cantonId: "AG" },
  { plz: "5712", cantonId: "AG" },
  { plz: "5722", cantonId: "AG" },
  { plz: "5723", cantonId: "AG" },
  { plz: "5724", cantonId: "AG" },
  { plz: "5725", cantonId: "AG" },
  { plz: "5726", cantonId: "AG" },
  { plz: "5727", cantonId: "AG" },
  { plz: "5728", cantonId: "AG" },
  { plz: "5732", cantonId: "AG" },
  { plz: "5733", cantonId: "AG" },
  { plz: "5734", cantonId: "AG" },
  { plz: "5735", cantonId: "LU" },
  { plz: "5736", cantonId: "AG" },
  { plz: "5737", cantonId: "AG" },
  { plz: "5742", cantonId: "AG" },
  { plz: "5745", cantonId: "AG" },
  { plz: "5746", cantonId: "SO" },
  { plz: "6000", cantonId: "LU" },
  { plz: "6003", cantonId: "LU" },
  { plz: "6004", cantonId: "LU" },
  { plz: "6005", cantonId: "LU" },
  { plz: "6005", cantonId: "LU" },
  { plz: "6006", cantonId: "LU" },
  { plz: "6010", cantonId: "LU" },
  { plz: "6010", cantonId: "LU" },
  { plz: "6012", cantonId: "LU" },
  { plz: "6013", cantonId: "LU" },
  { plz: "6014", cantonId: "LU" },
  { plz: "6015", cantonId: "LU" },
  { plz: "6016", cantonId: "LU" },
  { plz: "6017", cantonId: "LU" },
  { plz: "6018", cantonId: "LU" },
  { plz: "6019", cantonId: "LU" },
  { plz: "6020", cantonId: "LU" },
  { plz: "6022", cantonId: "LU" },
  { plz: "6023", cantonId: "LU" },
  { plz: "6024", cantonId: "LU" },
  { plz: "6025", cantonId: "LU" },
  { plz: "6026", cantonId: "LU" },
  { plz: "6027", cantonId: "LU" },
  { plz: "6028", cantonId: "LU" },
  { plz: "6030", cantonId: "LU" },
  { plz: "6032", cantonId: "LU" },
  { plz: "6033", cantonId: "LU" },
  { plz: "6034", cantonId: "LU" },
  { plz: "6035", cantonId: "LU" },
  { plz: "6036", cantonId: "LU" },
  { plz: "6037", cantonId: "LU" },
  { plz: "6038", cantonId: "LU" },
  { plz: "6038", cantonId: "LU" },
  { plz: "6039", cantonId: "LU" },
  { plz: "6042", cantonId: "AG" },
  { plz: "6043", cantonId: "LU" },
  { plz: "6044", cantonId: "LU" },
  { plz: "6045", cantonId: "LU" },
  { plz: "6047", cantonId: "LU" },
  { plz: "6048", cantonId: "LU" },
  { plz: "6052", cantonId: "NW" },
  { plz: "6053", cantonId: "OW" },
  { plz: "6055", cantonId: "OW" },
  { plz: "6056", cantonId: "OW" },
  { plz: "6060", cantonId: "OW" },
  { plz: "6060", cantonId: "OW" },
  { plz: "6062", cantonId: "OW" },
  { plz: "6063", cantonId: "OW" },
  { plz: "6064", cantonId: "OW" },
  { plz: "6066", cantonId: "OW" },
  { plz: "6067", cantonId: "OW" },
  { plz: "6068", cantonId: "OW" },
  { plz: "6072", cantonId: "OW" },
  { plz: "6073", cantonId: "OW" },
  { plz: "6074", cantonId: "OW" },
  { plz: "6078", cantonId: "OW" },
  { plz: "6078", cantonId: "OW" },
  { plz: "6083", cantonId: "BE" },
  { plz: "6084", cantonId: "BE" },
  { plz: "6085", cantonId: "BE" },
  { plz: "6086", cantonId: "BE" },
  { plz: "6102", cantonId: "LU" },
  { plz: "6103", cantonId: "LU" },
  { plz: "6105", cantonId: "LU" },
  { plz: "6106", cantonId: "LU" },
  { plz: "6110", cantonId: "LU" },
  { plz: "6110", cantonId: "LU" },
  { plz: "6112", cantonId: "LU" },
  { plz: "6113", cantonId: "LU" },
  { plz: "6114", cantonId: "LU" },
  { plz: "6122", cantonId: "LU" },
  { plz: "6123", cantonId: "LU" },
  { plz: "6125", cantonId: "LU" },
  { plz: "6126", cantonId: "LU" },
  { plz: "6130", cantonId: "LU" },
  { plz: "6132", cantonId: "LU" },
  { plz: "6133", cantonId: "LU" },
  { plz: "6142", cantonId: "LU" },
  { plz: "6143", cantonId: "LU" },
  { plz: "6144", cantonId: "LU" },
  { plz: "6145", cantonId: "LU" },
  { plz: "6146", cantonId: "LU" },
  { plz: "6147", cantonId: "LU" },
  { plz: "6152", cantonId: "LU" },
  { plz: "6153", cantonId: "LU" },
  { plz: "6154", cantonId: "LU" },
  { plz: "6156", cantonId: "LU" },
  { plz: "6156", cantonId: "LU" },
  { plz: "6162", cantonId: "LU" },
  { plz: "6162", cantonId: "LU" },
  { plz: "6162", cantonId: "LU" },
  { plz: "6163", cantonId: "LU" },
  { plz: "6166", cantonId: "LU" },
  { plz: "6167", cantonId: "LU" },
  { plz: "6170", cantonId: "LU" },
  { plz: "6173", cantonId: "LU" },
  { plz: "6174", cantonId: "LU" },
  { plz: "6182", cantonId: "LU" },
  { plz: "6192", cantonId: "LU" },
  { plz: "6196", cantonId: "LU" },
  { plz: "6197", cantonId: "BE" },
  { plz: "6203", cantonId: "LU" },
  { plz: "6204", cantonId: "LU" },
  { plz: "6205", cantonId: "LU" },
  { plz: "6206", cantonId: "LU" },
  { plz: "6207", cantonId: "LU" },
  { plz: "6208", cantonId: "LU" },
  { plz: "6210", cantonId: "LU" },
  { plz: "6211", cantonId: "LU" },
  { plz: "6212", cantonId: "LU" },
  { plz: "6212", cantonId: "LU" },
  { plz: "6213", cantonId: "LU" },
  { plz: "6214", cantonId: "LU" },
  { plz: "6215", cantonId: "LU" },
  { plz: "6215", cantonId: "LU" },
  { plz: "6216", cantonId: "LU" },
  { plz: "6217", cantonId: "LU" },
  { plz: "6218", cantonId: "LU" },
  { plz: "6221", cantonId: "LU" },
  { plz: "6222", cantonId: "LU" },
  { plz: "6231", cantonId: "LU" },
  { plz: "6232", cantonId: "LU" },
  { plz: "6233", cantonId: "LU" },
  { plz: "6234", cantonId: "LU" },
  { plz: "6234", cantonId: "LU" },
  { plz: "6235", cantonId: "LU" },
  { plz: "6236", cantonId: "LU" },
  { plz: "6242", cantonId: "LU" },
  { plz: "6243", cantonId: "LU" },
  { plz: "6244", cantonId: "LU" },
  { plz: "6245", cantonId: "LU" },
  { plz: "6246", cantonId: "LU" },
  { plz: "6247", cantonId: "LU" },
  { plz: "6248", cantonId: "LU" },
  { plz: "6252", cantonId: "LU" },
  { plz: "6253", cantonId: "LU" },
  { plz: "6260", cantonId: "LU" },
  { plz: "6260", cantonId: "LU" },
  { plz: "6260", cantonId: "LU" },
  { plz: "6260", cantonId: "LU" },
  { plz: "6262", cantonId: "LU" },
  { plz: "6263", cantonId: "LU" },
  { plz: "6264", cantonId: "LU" },
  { plz: "6265", cantonId: "LU" },
  { plz: "6274", cantonId: "LU" },
  { plz: "6275", cantonId: "LU" },
  { plz: "6276", cantonId: "LU" },
  { plz: "6277", cantonId: "LU" },
  { plz: "6277", cantonId: "LU" },
  { plz: "6280", cantonId: "LU" },
  { plz: "6280", cantonId: "LU" },
  { plz: "6283", cantonId: "LU" },
  { plz: "6284", cantonId: "LU" },
  { plz: "6284", cantonId: "LU" },
  { plz: "6285", cantonId: "LU" },
  { plz: "6285", cantonId: "LU" },
  { plz: "6286", cantonId: "LU" },
  { plz: "6287", cantonId: "LU" },
  { plz: "6288", cantonId: "LU" },
  { plz: "6289", cantonId: "LU" },
  { plz: "6289", cantonId: "LU" },
  { plz: "6294", cantonId: "LU" },
  { plz: "6295", cantonId: "LU" },
  { plz: "6300", cantonId: "ZG" },
  { plz: "6300", cantonId: "ZG" },
  { plz: "6312", cantonId: "ZG" },
  { plz: "6313", cantonId: "ZG" },
  { plz: "6313", cantonId: "ZG" },
  { plz: "6313", cantonId: "ZG" },
  { plz: "6314", cantonId: "ZG" },
  { plz: "6314", cantonId: "ZG" },
  { plz: "6315", cantonId: "ZG" },
  { plz: "6315", cantonId: "ZG" },
  { plz: "6315", cantonId: "ZG" },
  { plz: "6317", cantonId: "ZG" },
  { plz: "6318", cantonId: "ZG" },
  { plz: "6319", cantonId: "ZG" },
  { plz: "6330", cantonId: "ZG" },
  { plz: "6331", cantonId: "ZG" },
  { plz: "6332", cantonId: "ZG" },
  { plz: "6333", cantonId: "ZG" },
  { plz: "6340", cantonId: "ZG" },
  { plz: "6340", cantonId: "ZG" },
  { plz: "6343", cantonId: "ZG" },
  { plz: "6343", cantonId: "ZG" },
  { plz: "6343", cantonId: "ZG" },
  { plz: "6343", cantonId: "ZG" },
  { plz: "6344", cantonId: "LU" },
  { plz: "6345", cantonId: "ZG" },
  { plz: "6353", cantonId: "LU" },
  { plz: "6354", cantonId: "LU" },
  { plz: "6356", cantonId: "LU" },
  { plz: "6362", cantonId: "NW" },
  { plz: "6363", cantonId: "NW" },
  { plz: "6363", cantonId: "NW" },
  { plz: "6363", cantonId: "NW" },
  { plz: "6365", cantonId: "NW" },
  { plz: "6370", cantonId: "NW" },
  { plz: "6370", cantonId: "NW" },
  { plz: "6372", cantonId: "NW" },
  { plz: "6373", cantonId: "NW" },
  { plz: "6374", cantonId: "NW" },
  { plz: "6375", cantonId: "NW" },
  { plz: "6376", cantonId: "NW" },
  { plz: "6377", cantonId: "UR" },
  { plz: "6382", cantonId: "NW" },
  { plz: "6383", cantonId: "NW" },
  { plz: "6383", cantonId: "NW" },
  { plz: "6383", cantonId: "NW" },
  { plz: "6383", cantonId: "NW" },
  { plz: "6386", cantonId: "NW" },
  { plz: "6387", cantonId: "NW" },
  { plz: "6388", cantonId: "OW" },
  { plz: "6390", cantonId: "OW" },
  { plz: "6402", cantonId: "SZ" },
  { plz: "6403", cantonId: "SZ" },
  { plz: "6404", cantonId: "LU" },
  { plz: "6405", cantonId: "SZ" },
  { plz: "6410", cantonId: "SZ" },
  { plz: "6410", cantonId: "SZ" },
  { plz: "6410", cantonId: "SZ" },
  { plz: "6410", cantonId: "SZ" },
  { plz: "6410", cantonId: "SZ" },
  { plz: "6414", cantonId: "SZ" },
  { plz: "6415", cantonId: "SZ" },
  { plz: "6416", cantonId: "SZ" },
  { plz: "6417", cantonId: "SZ" },
  { plz: "6418", cantonId: "SZ" },
  { plz: "6422", cantonId: "SZ" },
  { plz: "6423", cantonId: "SZ" },
  { plz: "6424", cantonId: "SZ" },
  { plz: "6430", cantonId: "SZ" },
  { plz: "6432", cantonId: "SZ" },
  { plz: "6433", cantonId: "SZ" },
  { plz: "6434", cantonId: "SZ" },
  { plz: "6436", cantonId: "SZ" },
  { plz: "6436", cantonId: "SZ" },
  { plz: "6436", cantonId: "SZ" },
  { plz: "6438", cantonId: "SZ" },
  { plz: "6440", cantonId: "SZ" },
  { plz: "6441", cantonId: "UR" },
  { plz: "6442", cantonId: "SZ" },
  { plz: "6443", cantonId: "SZ" },
  { plz: "6452", cantonId: "SZ" },
  { plz: "6452", cantonId: "SZ" },
  { plz: "6454", cantonId: "UR" },
  { plz: "6460", cantonId: "UR" },
  { plz: "6461", cantonId: "UR" },
  { plz: "6462", cantonId: "UR" },
  { plz: "6463", cantonId: "UR" },
  { plz: "6464", cantonId: "UR" },
  { plz: "6465", cantonId: "UR" },
  { plz: "6466", cantonId: "UR" },
  { plz: "6467", cantonId: "UR" },
  { plz: "6468", cantonId: "UR" },
  { plz: "6469", cantonId: "UR" },
  { plz: "6472", cantonId: "UR" },
  { plz: "6473", cantonId: "UR" },
  { plz: "6474", cantonId: "UR" },
  { plz: "6475", cantonId: "UR" },
  { plz: "6476", cantonId: "UR" },
  { plz: "6482", cantonId: "UR" },
  { plz: "6484", cantonId: "UR" },
  { plz: "6485", cantonId: "UR" },
  { plz: "6487", cantonId: "UR" },
  { plz: "6490", cantonId: "UR" },
  { plz: "6491", cantonId: "UR" },
  { plz: "6493", cantonId: "UR" },
  { plz: "6500", cantonId: "TI" },
  { plz: "6503", cantonId: "TI" },
  { plz: "6512", cantonId: "TI" },
  { plz: "6513", cantonId: "TI" },
  { plz: "6514", cantonId: "TI" },
  { plz: "6515", cantonId: "TI" },
  { plz: "6516", cantonId: "TI" },
  { plz: "6517", cantonId: "TI" },
  { plz: "6518", cantonId: "TI" },
  { plz: "6523", cantonId: "TI" },
  { plz: "6524", cantonId: "TI" },
  { plz: "6525", cantonId: "TI" },
  { plz: "6526", cantonId: "TI" },
  { plz: "6527", cantonId: "TI" },
  { plz: "6528", cantonId: "TI" },
  { plz: "6532", cantonId: "TI" },
  { plz: "6533", cantonId: "TI" },
  { plz: "6534", cantonId: "GR" },
  { plz: "6535", cantonId: "GR" },
  { plz: "6537", cantonId: "GR" },
  { plz: "6538", cantonId: "GR" },
  { plz: "6540", cantonId: "GR" },
  { plz: "6541", cantonId: "GR" },
  { plz: "6542", cantonId: "GR" },
  { plz: "6543", cantonId: "GR" },
  { plz: "6544", cantonId: "GR" },
  { plz: "6545", cantonId: "GR" },
  { plz: "6546", cantonId: "GR" },
  { plz: "6547", cantonId: "GR" },
  { plz: "6548", cantonId: "GR" },
  { plz: "6549", cantonId: "GR" },
  { plz: "6556", cantonId: "GR" },
  { plz: "6557", cantonId: "GR" },
  { plz: "6558", cantonId: "GR" },
  { plz: "6562", cantonId: "GR" },
  { plz: "6563", cantonId: "GR" },
  { plz: "6565", cantonId: "GR" },
  { plz: "6571", cantonId: "TI" },
  { plz: "6572", cantonId: "TI" },
  { plz: "6573", cantonId: "TI" },
  { plz: "6574", cantonId: "TI" },
  { plz: "6575", cantonId: "TI" },
  { plz: "6575", cantonId: "TI" },
  { plz: "6576", cantonId: "TI" },
  { plz: "6577", cantonId: "TI" },
  { plz: "6578", cantonId: "TI" },
  { plz: "6579", cantonId: "TI" },
  { plz: "6582", cantonId: "TI" },
  { plz: "6583", cantonId: "TI" },
  { plz: "6584", cantonId: "TI" },
  { plz: "6592", cantonId: "TI" },
  { plz: "6593", cantonId: "TI" },
  { plz: "6594", cantonId: "TI" },
  { plz: "6595", cantonId: "TI" },
  { plz: "6596", cantonId: "TI" },
  { plz: "6597", cantonId: "TI" },
  { plz: "6598", cantonId: "TI" },
  { plz: "6599", cantonId: "TI" },
  { plz: "6600", cantonId: "TI" },
  { plz: "6600", cantonId: "TI" },
  { plz: "6600", cantonId: "TI" },
  { plz: "6605", cantonId: "TI" },
  { plz: "6605", cantonId: "TI" },
  { plz: "6611", cantonId: "TI" },
  { plz: "6611", cantonId: "TI" },
  { plz: "6611", cantonId: "TI" },
  { plz: "6612", cantonId: "TI" },
  { plz: "6613", cantonId: "TI" },
  { plz: "6614", cantonId: "TI" },
  { plz: "6614", cantonId: "TI" },
  { plz: "6616", cantonId: "TI" },
  { plz: "6618", cantonId: "TI" },
  { plz: "6622", cantonId: "TI" },
  { plz: "6631", cantonId: "TI" },
  { plz: "6632", cantonId: "TI" },
  { plz: "6633", cantonId: "TI" },
  { plz: "6634", cantonId: "TI" },
  { plz: "6635", cantonId: "TI" },
  { plz: "6636", cantonId: "TI" },
  { plz: "6637", cantonId: "TI" },
  { plz: "6644", cantonId: "TI" },
  { plz: "6645", cantonId: "TI" },
  { plz: "6646", cantonId: "TI" },
  { plz: "6647", cantonId: "TI" },
  { plz: "6648", cantonId: "TI" },
  { plz: "6652", cantonId: "TI" },
  { plz: "6653", cantonId: "TI" },
  { plz: "6654", cantonId: "TI" },
  { plz: "6655", cantonId: "TI" },
  { plz: "6655", cantonId: "TI" },
  { plz: "6655", cantonId: "TI" },
  { plz: "6656", cantonId: "TI" },
  { plz: "6657", cantonId: "TI" },
  { plz: "6658", cantonId: "TI" },
  { plz: "6659", cantonId: "TI" },
  { plz: "6659", cantonId: "TI" },
  { plz: "6661", cantonId: "TI" },
  { plz: "6661", cantonId: "TI" },
  { plz: "6661", cantonId: "TI" },
  { plz: "6662", cantonId: "TI" },
  { plz: "6663", cantonId: "TI" },
  { plz: "6663", cantonId: "TI" },
  { plz: "6664", cantonId: "TI" },
  { plz: "6670", cantonId: "TI" },
  { plz: "6672", cantonId: "TI" },
  { plz: "6673", cantonId: "TI" },
  { plz: "6674", cantonId: "TI" },
  { plz: "6674", cantonId: "TI" },
  { plz: "6675", cantonId: "TI" },
  { plz: "6676", cantonId: "TI" },
  { plz: "6677", cantonId: "TI" },
  { plz: "6677", cantonId: "TI" },
  { plz: "6678", cantonId: "TI" },
  { plz: "6678", cantonId: "TI" },
  { plz: "6678", cantonId: "TI" },
  { plz: "6682", cantonId: "TI" },
  { plz: "6683", cantonId: "TI" },
  { plz: "6683", cantonId: "TI" },
  { plz: "6684", cantonId: "TI" },
  { plz: "6684", cantonId: "TI" },
  { plz: "6685", cantonId: "TI" },
  { plz: "6690", cantonId: "TI" },
  { plz: "6690", cantonId: "TI" },
  { plz: "6692", cantonId: "TI" },
  { plz: "6692", cantonId: "TI" },
  { plz: "6693", cantonId: "TI" },
  { plz: "6694", cantonId: "TI" },
  { plz: "6695", cantonId: "TI" },
  { plz: "6695", cantonId: "TI" },
  { plz: "6696", cantonId: "TI" },
  { plz: "6702", cantonId: "TI" },
  { plz: "6703", cantonId: "TI" },
  { plz: "6705", cantonId: "TI" },
  { plz: "6707", cantonId: "TI" },
  { plz: "6710", cantonId: "TI" },
  { plz: "6710", cantonId: "TI" },
  { plz: "6713", cantonId: "TI" },
  { plz: "6714", cantonId: "TI" },
  { plz: "6715", cantonId: "TI" },
  { plz: "6716", cantonId: "TI" },
  { plz: "6716", cantonId: "TI" },
  { plz: "6716", cantonId: "TI" },
  { plz: "6717", cantonId: "TI" },
  { plz: "6717", cantonId: "TI" },
  { plz: "6718", cantonId: "TI" },
  { plz: "6718", cantonId: "TI" },
  { plz: "6719", cantonId: "TI" },
  { plz: "6720", cantonId: "TI" },
  { plz: "6720", cantonId: "TI" },
  { plz: "6721", cantonId: "TI" },
  { plz: "6721", cantonId: "TI" },
  { plz: "6722", cantonId: "TI" },
  { plz: "6723", cantonId: "TI" },
  { plz: "6723", cantonId: "TI" },
  { plz: "6723", cantonId: "TI" },
  { plz: "6724", cantonId: "TI" },
  { plz: "6724", cantonId: "TI" },
  { plz: "6742", cantonId: "TI" },
  { plz: "6743", cantonId: "TI" },
  { plz: "6744", cantonId: "TI" },
  { plz: "6745", cantonId: "TI" },
  { plz: "6746", cantonId: "TI" },
  { plz: "6746", cantonId: "TI" },
  { plz: "6746", cantonId: "TI" },
  { plz: "6747", cantonId: "TI" },
  { plz: "6748", cantonId: "TI" },
  { plz: "6749", cantonId: "TI" },
  { plz: "6749", cantonId: "TI" },
  { plz: "6760", cantonId: "TI" },
  { plz: "6760", cantonId: "TI" },
  { plz: "6760", cantonId: "TI" },
  { plz: "6760", cantonId: "TI" },
  { plz: "6760", cantonId: "TI" },
  { plz: "6760", cantonId: "TI" },
  { plz: "6763", cantonId: "TI" },
  { plz: "6763", cantonId: "TI" },
  { plz: "6764", cantonId: "TI" },
  { plz: "6772", cantonId: "TI" },
  { plz: "6773", cantonId: "TI" },
  { plz: "6774", cantonId: "TI" },
  { plz: "6775", cantonId: "TI" },
  { plz: "6776", cantonId: "TI" },
  { plz: "6777", cantonId: "TI" },
  { plz: "6777", cantonId: "TI" },
  { plz: "6780", cantonId: "TI" },
  { plz: "6780", cantonId: "TI" },
  { plz: "6781", cantonId: "TI" },
  { plz: "6781", cantonId: "TI" },
  { plz: "6802", cantonId: "TI" },
  { plz: "6803", cantonId: "TI" },
  { plz: "6804", cantonId: "TI" },
  { plz: "6805", cantonId: "TI" },
  { plz: "6806", cantonId: "TI" },
  { plz: "6807", cantonId: "TI" },
  { plz: "6808", cantonId: "TI" },
  { plz: "6809", cantonId: "TI" },
  { plz: "6810", cantonId: "TI" },
  { plz: "6814", cantonId: "TI" },
  { plz: "6814", cantonId: "TI" },
  { plz: "6815", cantonId: "TI" },
  { plz: "6816", cantonId: "TI" },
  { plz: "6817", cantonId: "TI" },
  { plz: "6818", cantonId: "TI" },
  { plz: "6821", cantonId: "TI" },
  { plz: "6822", cantonId: "TI" },
  { plz: "6823", cantonId: "TI" },
  { plz: "6825", cantonId: "TI" },
  { plz: "6826", cantonId: "TI" },
  { plz: "6827", cantonId: "TI" },
  { plz: "6828", cantonId: "TI" },
  { plz: "6830", cantonId: "TI" },
  { plz: "6832", cantonId: "TI" },
  { plz: "6832", cantonId: "TI" },
  { plz: "6833", cantonId: "TI" },
  { plz: "6834", cantonId: "TI" },
  { plz: "6835", cantonId: "TI" },
  { plz: "6837", cantonId: "TI" },
  { plz: "6837", cantonId: "TI" },
  { plz: "6838", cantonId: "TI" },
  { plz: "6838", cantonId: "TI" },
  { plz: "6838", cantonId: "TI" },
  { plz: "6839", cantonId: "TI" },
  { plz: "6850", cantonId: "TI" },
  { plz: "6852", cantonId: "TI" },
  { plz: "6853", cantonId: "TI" },
  { plz: "6854", cantonId: "TI" },
  { plz: "6855", cantonId: "TI" },
  { plz: "6862", cantonId: "TI" },
  { plz: "6863", cantonId: "TI" },
  { plz: "6864", cantonId: "TI" },
  { plz: "6865", cantonId: "TI" },
  { plz: "6866", cantonId: "TI" },
  { plz: "6867", cantonId: "TI" },
  { plz: "6872", cantonId: "TI" },
  { plz: "6872", cantonId: "TI" },
  { plz: "6873", cantonId: "TI" },
  { plz: "6874", cantonId: "TI" },
  { plz: "6875", cantonId: "TI" },
  { plz: "6875", cantonId: "TI" },
  { plz: "6877", cantonId: "TI" },
  { plz: "6883", cantonId: "TI" },
  { plz: "6900", cantonId: "TI" },
  { plz: "6900", cantonId: "TI" },
  { plz: "6900", cantonId: "TI" },
  { plz: "6911", cantonId: "IT" },
  { plz: "6912", cantonId: "TI" },
  { plz: "6913", cantonId: "TI" },
  { plz: "6914", cantonId: "TI" },
  { plz: "6915", cantonId: "TI" },
  { plz: "6916", cantonId: "TI" },
  { plz: "6917", cantonId: "TI" },
  { plz: "6918", cantonId: "TI" },
  { plz: "6919", cantonId: "TI" },
  { plz: "6921", cantonId: "TI" },
  { plz: "6922", cantonId: "TI" },
  { plz: "6924", cantonId: "TI" },
  { plz: "6925", cantonId: "TI" },
  { plz: "6926", cantonId: "TI" },
  { plz: "6927", cantonId: "TI" },
  { plz: "6928", cantonId: "TI" },
  { plz: "6929", cantonId: "TI" },
  { plz: "6930", cantonId: "TI" },
  { plz: "6932", cantonId: "TI" },
  { plz: "6933", cantonId: "TI" },
  { plz: "6934", cantonId: "TI" },
  { plz: "6935", cantonId: "TI" },
  { plz: "6936", cantonId: "TI" },
  { plz: "6937", cantonId: "TI" },
  { plz: "6938", cantonId: "TI" },
  { plz: "6938", cantonId: "TI" },
  { plz: "6939", cantonId: "TI" },
  { plz: "6939", cantonId: "TI" },
  { plz: "6942", cantonId: "TI" },
  { plz: "6943", cantonId: "TI" },
  { plz: "6944", cantonId: "TI" },
  { plz: "6945", cantonId: "TI" },
  { plz: "6946", cantonId: "TI" },
  { plz: "6947", cantonId: "TI" },
  { plz: "6948", cantonId: "TI" },
  { plz: "6949", cantonId: "TI" },
  { plz: "6950", cantonId: "TI" },
  { plz: "6951", cantonId: "TI" },
  { plz: "6951", cantonId: "TI" },
  { plz: "6951", cantonId: "TI" },
  { plz: "6951", cantonId: "TI" },
  { plz: "6951", cantonId: "TI" },
  { plz: "6951", cantonId: "TI" },
  { plz: "6951", cantonId: "TI" },
  { plz: "6952", cantonId: "TI" },
  { plz: "6953", cantonId: "TI" },
  { plz: "6954", cantonId: "TI" },
  { plz: "6954", cantonId: "TI" },
  { plz: "6955", cantonId: "TI" },
  { plz: "6955", cantonId: "TI" },
  { plz: "6956", cantonId: "TI" },
  { plz: "6957", cantonId: "TI" },
  { plz: "6958", cantonId: "TI" },
  { plz: "6958", cantonId: "TI" },
  { plz: "6959", cantonId: "TI" },
  { plz: "6959", cantonId: "TI" },
  { plz: "6959", cantonId: "TI" },
  { plz: "6959", cantonId: "TI" },
  { plz: "6959", cantonId: "TI" },
  { plz: "6962", cantonId: "TI" },
  { plz: "6963", cantonId: "TI" },
  { plz: "6963", cantonId: "TI" },
  { plz: "6964", cantonId: "TI" },
  { plz: "6965", cantonId: "TI" },
  { plz: "6966", cantonId: "TI" },
  { plz: "6967", cantonId: "TI" },
  { plz: "6968", cantonId: "TI" },
  { plz: "6974", cantonId: "TI" },
  { plz: "6976", cantonId: "TI" },
  { plz: "6977", cantonId: "TI" },
  { plz: "6978", cantonId: "TI" },
  { plz: "6979", cantonId: "TI" },
  { plz: "6980", cantonId: "TI" },
  { plz: "6981", cantonId: "TI" },
  { plz: "6981", cantonId: "TI" },
  { plz: "6981", cantonId: "TI" },
  { plz: "6981", cantonId: "TI" },
  { plz: "6982", cantonId: "TI" },
  { plz: "6983", cantonId: "TI" },
  { plz: "6984", cantonId: "TI" },
  { plz: "6986", cantonId: "TI" },
  { plz: "6986", cantonId: "TI" },
  { plz: "6986", cantonId: "TI" },
  { plz: "6987", cantonId: "TI" },
  { plz: "6988", cantonId: "TI" },
  { plz: "6989", cantonId: "TI" },
  { plz: "6990", cantonId: "TI" },
  { plz: "6991", cantonId: "TI" },
  { plz: "6992", cantonId: "TI" },
  { plz: "6992", cantonId: "TI" },
  { plz: "6993", cantonId: "TI" },
  { plz: "6994", cantonId: "TI" },
  { plz: "6995", cantonId: "TI" },
  { plz: "6995", cantonId: "TI" },
  { plz: "6996", cantonId: "TI" },
  { plz: "6997", cantonId: "TI" },
  { plz: "6998", cantonId: "TI" },
  { plz: "6999", cantonId: "TI" },
  { plz: "7000", cantonId: "GR" },
  { plz: "7012", cantonId: "GR" },
  { plz: "7013", cantonId: "GR" },
  { plz: "7014", cantonId: "GR" },
  { plz: "7015", cantonId: "GR" },
  { plz: "7016", cantonId: "GR" },
  { plz: "7017", cantonId: "GR" },
  { plz: "7018", cantonId: "GR" },
  { plz: "7019", cantonId: "GR" },
  { plz: "7023", cantonId: "GR" },
  { plz: "7026", cantonId: "GR" },
  { plz: "7027", cantonId: "GR" },
  { plz: "7027", cantonId: "GR" },
  { plz: "7027", cantonId: "GR" },
  { plz: "7028", cantonId: "GR" },
  { plz: "7028", cantonId: "GR" },
  { plz: "7029", cantonId: "GR" },
  { plz: "7031", cantonId: "GR" },
  { plz: "7032", cantonId: "GR" },
  { plz: "7050", cantonId: "GR" },
  { plz: "7056", cantonId: "GR" },
  { plz: "7057", cantonId: "GR" },
  { plz: "7058", cantonId: "GR" },
  { plz: "7062", cantonId: "GR" },
  { plz: "7063", cantonId: "GR" },
  { plz: "7064", cantonId: "GR" },
  { plz: "7074", cantonId: "GR" },
  { plz: "7075", cantonId: "GR" },
  { plz: "7076", cantonId: "GR" },
  { plz: "7077", cantonId: "GR" },
  { plz: "7078", cantonId: "GR" },
  { plz: "7082", cantonId: "GR" },
  { plz: "7083", cantonId: "GR" },
  { plz: "7084", cantonId: "GR" },
  { plz: "7104", cantonId: "GR" },
  { plz: "7104", cantonId: "GR" },
  { plz: "7106", cantonId: "GR" },
  { plz: "7107", cantonId: "GR" },
  { plz: "7109", cantonId: "GR" },
  { plz: "7110", cantonId: "GR" },
  { plz: "7111", cantonId: "GR" },
  { plz: "7112", cantonId: "GR" },
  { plz: "7113", cantonId: "GR" },
  { plz: "7114", cantonId: "GR" },
  { plz: "7115", cantonId: "GR" },
  { plz: "7116", cantonId: "GR" },
  { plz: "7116", cantonId: "GR" },
  { plz: "7122", cantonId: "GR" },
  { plz: "7122", cantonId: "GR" },
  { plz: "7126", cantonId: "GR" },
  { plz: "7127", cantonId: "GR" },
  { plz: "7128", cantonId: "GR" },
  { plz: "7130", cantonId: "GR" },
  { plz: "7130", cantonId: "GR" },
  { plz: "7132", cantonId: "GR" },
  { plz: "7133", cantonId: "GR" },
  { plz: "7134", cantonId: "GR" },
  { plz: "7135", cantonId: "GR" },
  { plz: "7136", cantonId: "GR" },
  { plz: "7137", cantonId: "GR" },
  { plz: "7138", cantonId: "GR" },
  { plz: "7141", cantonId: "GR" },
  { plz: "7142", cantonId: "GR" },
  { plz: "7143", cantonId: "GR" },
  { plz: "7144", cantonId: "GR" },
  { plz: "7145", cantonId: "GR" },
  { plz: "7146", cantonId: "GR" },
  { plz: "7147", cantonId: "GR" },
  { plz: "7148", cantonId: "GR" },
  { plz: "7148", cantonId: "GR" },
  { plz: "7149", cantonId: "GR" },
  { plz: "7151", cantonId: "GR" },
  { plz: "7152", cantonId: "GR" },
  { plz: "7153", cantonId: "GR" },
  { plz: "7154", cantonId: "GR" },
  { plz: "7155", cantonId: "GR" },
  { plz: "7156", cantonId: "GR" },
  { plz: "7156", cantonId: "GR" },
  { plz: "7157", cantonId: "GR" },
  { plz: "7158", cantonId: "GR" },
  { plz: "7159", cantonId: "GR" },
  { plz: "7162", cantonId: "GR" },
  { plz: "7163", cantonId: "GR" },
  { plz: "7164", cantonId: "GR" },
  { plz: "7165", cantonId: "GR" },
  { plz: "7166", cantonId: "GR" },
  { plz: "7167", cantonId: "GR" },
  { plz: "7168", cantonId: "GR" },
  { plz: "7172", cantonId: "GR" },
  { plz: "7173", cantonId: "GR" },
  { plz: "7174", cantonId: "GR" },
  { plz: "7175", cantonId: "GR" },
  { plz: "7176", cantonId: "GR" },
  { plz: "7180", cantonId: "GR" },
  { plz: "7182", cantonId: "GR" },
  { plz: "7183", cantonId: "GR" },
  { plz: "7184", cantonId: "GR" },
  { plz: "7185", cantonId: "GR" },
  { plz: "7186", cantonId: "GR" },
  { plz: "7187", cantonId: "GR" },
  { plz: "7188", cantonId: "GR" },
  { plz: "7189", cantonId: "GR" },
  { plz: "7201", cantonId: "GR" },
  { plz: "7202", cantonId: "GR" },
  { plz: "7203", cantonId: "GR" },
  { plz: "7204", cantonId: "GR" },
  { plz: "7205", cantonId: "GR" },
  { plz: "7206", cantonId: "GR" },
  { plz: "7208", cantonId: "GR" },
  { plz: "7212", cantonId: "GR" },
  { plz: "7213", cantonId: "GR" },
  { plz: "7214", cantonId: "GR" },
  { plz: "7214", cantonId: "GR" },
  { plz: "7214", cantonId: "GR" },
  { plz: "7215", cantonId: "GR" },
  { plz: "7220", cantonId: "GR" },
  { plz: "7222", cantonId: "GR" },
  { plz: "7223", cantonId: "GR" },
  { plz: "7224", cantonId: "GR" },
  { plz: "7226", cantonId: "GR" },
  { plz: "7226", cantonId: "GR" },
  { plz: "7228", cantonId: "GR" },
  { plz: "7228", cantonId: "GR" },
  { plz: "7231", cantonId: "GR" },
  { plz: "7232", cantonId: "GR" },
  { plz: "7233", cantonId: "GR" },
  { plz: "7235", cantonId: "GR" },
  { plz: "7240", cantonId: "GR" },
  { plz: "7241", cantonId: "GR" },
  { plz: "7242", cantonId: "GR" },
  { plz: "7243", cantonId: "GR" },
  { plz: "7244", cantonId: "GR" },
  { plz: "7245", cantonId: "GR" },
  { plz: "7246", cantonId: "GR" },
  { plz: "7247", cantonId: "GR" },
  { plz: "7249", cantonId: "GR" },
  { plz: "7250", cantonId: "GR" },
  { plz: "7252", cantonId: "GR" },
  { plz: "7260", cantonId: "GR" },
  { plz: "7265", cantonId: "GR" },
  { plz: "7270", cantonId: "GR" },
  { plz: "7272", cantonId: "GR" },
  { plz: "7276", cantonId: "GR" },
  { plz: "7277", cantonId: "GR" },
  { plz: "7278", cantonId: "GR" },
  { plz: "7302", cantonId: "GR" },
  { plz: "7303", cantonId: "GR" },
  { plz: "7304", cantonId: "GR" },
  { plz: "7306", cantonId: "GR" },
  { plz: "7307", cantonId: "GR" },
  { plz: "7310", cantonId: "SG" },
  { plz: "7312", cantonId: "SG" },
  { plz: "7313", cantonId: "SG" },
  { plz: "7314", cantonId: "SG" },
  { plz: "7315", cantonId: "SG" },
  { plz: "7317", cantonId: "SG" },
  { plz: "7317", cantonId: "SG" },
  { plz: "7320", cantonId: "SG" },
  { plz: "7323", cantonId: "SG" },
  { plz: "7324", cantonId: "SG" },
  { plz: "7325", cantonId: "SG" },
  { plz: "7326", cantonId: "SG" },
  { plz: "7402", cantonId: "GR" },
  { plz: "7403", cantonId: "GR" },
  { plz: "7404", cantonId: "GR" },
  { plz: "7405", cantonId: "GR" },
  { plz: "7407", cantonId: "GR" },
  { plz: "7408", cantonId: "GR" },
  { plz: "7408", cantonId: "GR" },
  { plz: "7411", cantonId: "GR" },
  { plz: "7412", cantonId: "GR" },
  { plz: "7413", cantonId: "GR" },
  { plz: "7414", cantonId: "GR" },
  { plz: "7415", cantonId: "GR" },
  { plz: "7415", cantonId: "GR" },
  { plz: "7416", cantonId: "GR" },
  { plz: "7417", cantonId: "GR" },
  { plz: "7418", cantonId: "GR" },
  { plz: "7419", cantonId: "GR" },
  { plz: "7421", cantonId: "GR" },
  { plz: "7422", cantonId: "GR" },
  { plz: "7423", cantonId: "GR" },
  { plz: "7423", cantonId: "GR" },
  { plz: "7424", cantonId: "GR" },
  { plz: "7424", cantonId: "GR" },
  { plz: "7425", cantonId: "GR" },
  { plz: "7426", cantonId: "GR" },
  { plz: "7427", cantonId: "GR" },
  { plz: "7428", cantonId: "GR" },
  { plz: "7428", cantonId: "GR" },
  { plz: "7430", cantonId: "GR" },
  { plz: "7430", cantonId: "GR" },
  { plz: "7431", cantonId: "GR" },
  { plz: "7431", cantonId: "GR" },
  { plz: "7432", cantonId: "GR" },
  { plz: "7433", cantonId: "GR" },
  { plz: "7433", cantonId: "GR" },
  { plz: "7433", cantonId: "GR" },
  { plz: "7433", cantonId: "GR" },
  { plz: "7433", cantonId: "GR" },
  { plz: "7434", cantonId: "GR" },
  { plz: "7435", cantonId: "GR" },
  { plz: "7436", cantonId: "GR" },
  { plz: "7437", cantonId: "GR" },
  { plz: "7438", cantonId: "GR" },
  { plz: "7440", cantonId: "GR" },
  { plz: "7442", cantonId: "GR" },
  { plz: "7443", cantonId: "GR" },
  { plz: "7444", cantonId: "GR" },
  { plz: "7445", cantonId: "GR" },
  { plz: "7446", cantonId: "GR" },
  { plz: "7447", cantonId: "GR" },
  { plz: "7447", cantonId: "GR" },
  { plz: "7448", cantonId: "GR" },
  { plz: "7450", cantonId: "GR" },
  { plz: "7451", cantonId: "GR" },
  { plz: "7452", cantonId: "GR" },
  { plz: "7453", cantonId: "GR" },
  { plz: "7454", cantonId: "GR" },
  { plz: "7455", cantonId: "GR" },
  { plz: "7456", cantonId: "GR" },
  { plz: "7456", cantonId: "GR" },
  { plz: "7457", cantonId: "GR" },
  { plz: "7458", cantonId: "GR" },
  { plz: "7459", cantonId: "GR" },
  { plz: "7460", cantonId: "GR" },
  { plz: "7462", cantonId: "GR" },
  { plz: "7463", cantonId: "GR" },
  { plz: "7464", cantonId: "GR" },
  { plz: "7472", cantonId: "GR" },
  { plz: "7473", cantonId: "GR" },
  { plz: "7477", cantonId: "GR" },
  { plz: "7482", cantonId: "GR" },
  { plz: "7482", cantonId: "GR" },
  { plz: "7482", cantonId: "GR" },
  { plz: "7484", cantonId: "GR" },
  { plz: "7492", cantonId: "GR" },
  { plz: "7493", cantonId: "GR" },
  { plz: "7494", cantonId: "GR" },
  { plz: "7500", cantonId: "GR" },
  { plz: "7502", cantonId: "GR" },
  { plz: "7503", cantonId: "GR" },
  { plz: "7504", cantonId: "GR" },
  { plz: "7505", cantonId: "GR" },
  { plz: "7512", cantonId: "GR" },
  { plz: "7513", cantonId: "GR" },
  { plz: "7513", cantonId: "GR" },
  { plz: "7514", cantonId: "GR" },
  { plz: "7514", cantonId: "GR" },
  { plz: "7515", cantonId: "GR" },
  { plz: "7516", cantonId: "GR" },
  { plz: "7517", cantonId: "GR" },
  { plz: "7522", cantonId: "GR" },
  { plz: "7523", cantonId: "GR" },
  { plz: "7524", cantonId: "GR" },
  { plz: "7525", cantonId: "GR" },
  { plz: "7526", cantonId: "GR" },
  { plz: "7527", cantonId: "GR" },
  { plz: "7530", cantonId: "GR" },
  { plz: "7532", cantonId: "GR" },
  { plz: "7533", cantonId: "GR" },
  { plz: "7534", cantonId: "GR" },
  { plz: "7535", cantonId: "GR" },
  { plz: "7536", cantonId: "GR" },
  { plz: "7537", cantonId: "GR" },
  { plz: "7542", cantonId: "GR" },
  { plz: "7543", cantonId: "GR" },
  { plz: "7545", cantonId: "GR" },
  { plz: "7546", cantonId: "GR" },
  { plz: "7550", cantonId: "GR" },
  { plz: "7551", cantonId: "GR" },
  { plz: "7552", cantonId: "GR" },
  { plz: "7553", cantonId: "GR" },
  { plz: "7554", cantonId: "GR" },
  { plz: "7554", cantonId: "GR" },
  { plz: "7556", cantonId: "GR" },
  { plz: "7557", cantonId: "GR" },
  { plz: "7558", cantonId: "GR" },
  { plz: "7559", cantonId: "GR" },
  { plz: "7560", cantonId: "GR" },
  { plz: "7562", cantonId: "GR" },
  { plz: "7563", cantonId: "GR" },
  { plz: "7602", cantonId: "GR" },
  { plz: "7603", cantonId: "GR" },
  { plz: "7604", cantonId: "GR" },
  { plz: "7605", cantonId: "GR" },
  { plz: "7606", cantonId: "GR" },
  { plz: "7608", cantonId: "GR" },
  { plz: "7610", cantonId: "GR" },
  { plz: "7710", cantonId: "GR" },
  { plz: "7710", cantonId: "GR" },
  { plz: "7741", cantonId: "GR" },
  { plz: "7742", cantonId: "GR" },
  { plz: "7742", cantonId: "GR" },
  { plz: "7742", cantonId: "GR" },
  { plz: "7743", cantonId: "GR" },
  { plz: "7743", cantonId: "GR" },
  { plz: "7744", cantonId: "GR" },
  { plz: "7745", cantonId: "GR" },
  { plz: "7746", cantonId: "GR" },
  { plz: "7747", cantonId: "GR" },
  { plz: "7748", cantonId: "GR" },
  { plz: "8000", cantonId: "ZH" },
  { plz: "8001", cantonId: "ZH" },
  { plz: "8002", cantonId: "ZH" },
  { plz: "8003", cantonId: "ZH" },
  { plz: "8004", cantonId: "ZH" },
  { plz: "8005", cantonId: "ZH" },
  { plz: "8006", cantonId: "ZH" },
  { plz: "8008", cantonId: "ZH" },
  { plz: "8032", cantonId: "ZH" },
  { plz: "8037", cantonId: "ZH" },
  { plz: "8038", cantonId: "ZH" },
  { plz: "8041", cantonId: "ZH" },
  { plz: "8044", cantonId: "ZH" },
  { plz: "8044", cantonId: "ZH" },
  { plz: "8045", cantonId: "ZH" },
  { plz: "8046", cantonId: "ZH" },
  { plz: "8047", cantonId: "ZH" },
  { plz: "8048", cantonId: "ZH" },
  { plz: "8049", cantonId: "ZH" },
  { plz: "8050", cantonId: "ZH" },
  { plz: "8051", cantonId: "ZH" },
  { plz: "8052", cantonId: "ZH" },
  { plz: "8053", cantonId: "ZH" },
  { plz: "8055", cantonId: "ZH" },
  { plz: "8057", cantonId: "ZH" },
  { plz: "8063", cantonId: "ZH" },
  { plz: "8064", cantonId: "ZH" },
  { plz: "8099", cantonId: "ZH" },
  { plz: "8102", cantonId: "ZH" },
  { plz: "8103", cantonId: "ZH" },
  { plz: "8104", cantonId: "ZH" },
  { plz: "8105", cantonId: "ZH" },
  { plz: "8105", cantonId: "ZH" },
  { plz: "8106", cantonId: "ZH" },
  { plz: "8107", cantonId: "ZH" },
  { plz: "8108", cantonId: "ZH" },
  { plz: "8109", cantonId: "AG" },
  { plz: "8112", cantonId: "ZH" },
  { plz: "8113", cantonId: "ZH" },
  { plz: "8114", cantonId: "ZH" },
  { plz: "8115", cantonId: "ZH" },
  { plz: "8117", cantonId: "ZH" },
  { plz: "8118", cantonId: "ZH" },
  { plz: "8121", cantonId: "ZH" },
  { plz: "8122", cantonId: "ZH" },
  { plz: "8123", cantonId: "ZH" },
  { plz: "8124", cantonId: "ZH" },
  { plz: "8125", cantonId: "ZH" },
  { plz: "8126", cantonId: "ZH" },
  { plz: "8127", cantonId: "ZH" },
  { plz: "8132", cantonId: "ZH" },
  { plz: "8132", cantonId: "ZH" },
  { plz: "8133", cantonId: "ZH" },
  { plz: "8134", cantonId: "ZH" },
  { plz: "8135", cantonId: "ZH" },
  { plz: "8135", cantonId: "ZH" },
  { plz: "8135", cantonId: "ZH" },
  { plz: "8136", cantonId: "ZH" },
  { plz: "8142", cantonId: "ZH" },
  { plz: "8143", cantonId: "ZH" },
  { plz: "8143", cantonId: "ZH" },
  { plz: "8152", cantonId: "ZH" },
  { plz: "8152", cantonId: "ZH" },
  { plz: "8152", cantonId: "ZH" },
  { plz: "8153", cantonId: "ZH" },
  { plz: "8154", cantonId: "ZH" },
  { plz: "8155", cantonId: "ZH" },
  { plz: "8156", cantonId: "ZH" },
  { plz: "8157", cantonId: "ZH" },
  { plz: "8158", cantonId: "ZH" },
  { plz: "8162", cantonId: "ZH" },
  { plz: "8164", cantonId: "ZH" },
  { plz: "8165", cantonId: "ZH" },
  { plz: "8165", cantonId: "ZH" },
  { plz: "8165", cantonId: "ZH" },
  { plz: "8166", cantonId: "ZH" },
  { plz: "8172", cantonId: "ZH" },
  { plz: "8173", cantonId: "ZH" },
  { plz: "8174", cantonId: "ZH" },
  { plz: "8175", cantonId: "ZH" },
  { plz: "8180", cantonId: "ZH" },
  { plz: "8181", cantonId: "ZH" },
  { plz: "8182", cantonId: "ZH" },
  { plz: "8184", cantonId: "ZH" },
  { plz: "8185", cantonId: "ZH" },
  { plz: "8187", cantonId: "ZH" },
  { plz: "8192", cantonId: "ZH" },
  { plz: "8192", cantonId: "ZH" },
  { plz: "8193", cantonId: "ZH" },
  { plz: "8194", cantonId: "ZH" },
  { plz: "8195", cantonId: "ZH" },
  { plz: "8196", cantonId: "ZH" },
  { plz: "8197", cantonId: "ZH" },
  { plz: "8200", cantonId: "SH" },
  { plz: "8203", cantonId: "SH" },
  { plz: "8207", cantonId: "SH" },
  { plz: "8208", cantonId: "SH" },
  { plz: "8212", cantonId: "SH" },
  { plz: "8212", cantonId: "SH" },
  { plz: "8213", cantonId: "SH" },
  { plz: "8214", cantonId: "SH" },
  { plz: "8215", cantonId: "SH" },
  { plz: "8216", cantonId: "SH" },
  { plz: "8217", cantonId: "SH" },
  { plz: "8218", cantonId: "SH" },
  { plz: "8219", cantonId: "SH" },
  { plz: "8222", cantonId: "SH" },
  { plz: "8223", cantonId: "SH" },
  { plz: "8224", cantonId: "SH" },
  { plz: "8225", cantonId: "SH" },
  { plz: "8226", cantonId: "SH" },
  { plz: "8228", cantonId: "SH" },
  { plz: "8231", cantonId: "SH" },
  { plz: "8232", cantonId: "SH" },
  { plz: "8233", cantonId: "SH" },
  { plz: "8234", cantonId: "SH" },
  { plz: "8235", cantonId: "SH" },
  { plz: "8236", cantonId: "SH" },
  { plz: "8236", cantonId: "SH" },
  { plz: "8238", cantonId: "DE" },
  { plz: "8239", cantonId: "SH" },
  { plz: "8240", cantonId: "SH" },
  { plz: "8241", cantonId: "SH" },
  { plz: "8242", cantonId: "SH" },
  { plz: "8242", cantonId: "SH" },
  { plz: "8243", cantonId: "SH" },
  { plz: "8245", cantonId: "ZH" },
  { plz: "8246", cantonId: "ZH" },
  { plz: "8247", cantonId: "ZH" },
  { plz: "8248", cantonId: "ZH" },
  { plz: "8252", cantonId: "TG" },
  { plz: "8253", cantonId: "TG" },
  { plz: "8253", cantonId: "TG" },
  { plz: "8254", cantonId: "TG" },
  { plz: "8255", cantonId: "TG" },
  { plz: "8259", cantonId: "TG" },
  { plz: "8259", cantonId: "TG" },
  { plz: "8259", cantonId: "TG" },
  { plz: "8259", cantonId: "TG" },
  { plz: "8260", cantonId: "SH" },
  { plz: "8261", cantonId: "SH" },
  { plz: "8262", cantonId: "SH" },
  { plz: "8263", cantonId: "SH" },
  { plz: "8264", cantonId: "TG" },
  { plz: "8265", cantonId: "TG" },
  { plz: "8266", cantonId: "TG" },
  { plz: "8267", cantonId: "TG" },
  { plz: "8268", cantonId: "TG" },
  { plz: "8268", cantonId: "TG" },
  { plz: "8269", cantonId: "TG" },
  { plz: "8272", cantonId: "TG" },
  { plz: "8273", cantonId: "TG" },
  { plz: "8274", cantonId: "TG" },
  { plz: "8274", cantonId: "TG" },
  { plz: "8280", cantonId: "TG" },
  { plz: "8302", cantonId: "ZH" },
  { plz: "8303", cantonId: "ZH" },
  { plz: "8304", cantonId: "ZH" },
  { plz: "8305", cantonId: "ZH" },
  { plz: "8306", cantonId: "ZH" },
  { plz: "8307", cantonId: "ZH" },
  { plz: "8307", cantonId: "ZH" },
  { plz: "8308", cantonId: "ZH" },
  { plz: "8308", cantonId: "ZH" },
  { plz: "8309", cantonId: "ZH" },
  { plz: "8310", cantonId: "ZH" },
  { plz: "8310", cantonId: "ZH" },
  { plz: "8311", cantonId: "ZH" },
  { plz: "8312", cantonId: "ZH" },
  { plz: "8314", cantonId: "ZH" },
  { plz: "8315", cantonId: "ZH" },
  { plz: "8317", cantonId: "ZH" },
  { plz: "8320", cantonId: "ZH" },
  { plz: "8322", cantonId: "ZH" },
  { plz: "8330", cantonId: "ZH" },
  { plz: "8330", cantonId: "ZH" },
  { plz: "8331", cantonId: "ZH" },
  { plz: "8332", cantonId: "ZH" },
  { plz: "8335", cantonId: "ZH" },
  { plz: "8340", cantonId: "ZH" },
  { plz: "8342", cantonId: "ZH" },
  { plz: "8344", cantonId: "ZH" },
  { plz: "8345", cantonId: "ZH" },
  { plz: "8352", cantonId: "ZH" },
  { plz: "8352", cantonId: "ZH" },
  { plz: "8353", cantonId: "ZH" },
  { plz: "8354", cantonId: "ZH" },
  { plz: "8355", cantonId: "TG" },
  { plz: "8356", cantonId: "TG" },
  { plz: "8357", cantonId: "TG" },
  { plz: "8360", cantonId: "TG" },
  { plz: "8360", cantonId: "TG" },
  { plz: "8362", cantonId: "TG" },
  { plz: "8363", cantonId: "TG" },
  { plz: "8370", cantonId: "TG" },
  { plz: "8370", cantonId: "TG" },
  { plz: "8372", cantonId: "TG" },
  { plz: "8374", cantonId: "TG" },
  { plz: "8374", cantonId: "TG" },
  { plz: "8376", cantonId: "TG" },
  { plz: "8376", cantonId: "TG" },
  { plz: "8400", cantonId: "ZH" },
  { plz: "8404", cantonId: "ZH" },
  { plz: "8404", cantonId: "ZH" },
  { plz: "8404", cantonId: "ZH" },
  { plz: "8405", cantonId: "ZH" },
  { plz: "8406", cantonId: "ZH" },
  { plz: "8408", cantonId: "ZH" },
  { plz: "8409", cantonId: "ZH" },
  { plz: "8412", cantonId: "ZH" },
  { plz: "8412", cantonId: "ZH" },
  { plz: "8412", cantonId: "ZH" },
  { plz: "8413", cantonId: "ZH" },
  { plz: "8414", cantonId: "ZH" },
  { plz: "8415", cantonId: "ZH" },
  { plz: "8415", cantonId: "ZH" },
  { plz: "8416", cantonId: "ZH" },
  { plz: "8418", cantonId: "ZH" },
  { plz: "8421", cantonId: "ZH" },
  { plz: "8422", cantonId: "ZH" },
  { plz: "8424", cantonId: "ZH" },
  { plz: "8425", cantonId: "ZH" },
  { plz: "8426", cantonId: "ZH" },
  { plz: "8427", cantonId: "ZH" },
  { plz: "8427", cantonId: "ZH" },
  { plz: "8428", cantonId: "ZH" },
  { plz: "8442", cantonId: "ZH" },
  { plz: "8444", cantonId: "ZH" },
  { plz: "8447", cantonId: "ZH" },
  { plz: "8450", cantonId: "ZH" },
  { plz: "8451", cantonId: "ZH" },
  { plz: "8452", cantonId: "ZH" },
  { plz: "8453", cantonId: "ZH" },
  { plz: "8454", cantonId: "SH" },
  { plz: "8455", cantonId: "SH" },
  { plz: "8457", cantonId: "ZH" },
  { plz: "8458", cantonId: "ZH" },
  { plz: "8459", cantonId: "ZH" },
  { plz: "8460", cantonId: "ZH" },
  { plz: "8461", cantonId: "ZH" },
  { plz: "8462", cantonId: "ZH" },
  { plz: "8463", cantonId: "ZH" },
  { plz: "8464", cantonId: "ZH" },
  { plz: "8465", cantonId: "ZH" },
  { plz: "8465", cantonId: "ZH" },
  { plz: "8466", cantonId: "ZH" },
  { plz: "8467", cantonId: "ZH" },
  { plz: "8468", cantonId: "ZH" },
  { plz: "8468", cantonId: "ZH" },
  { plz: "8471", cantonId: "ZH" },
  { plz: "8471", cantonId: "ZH" },
  { plz: "8471", cantonId: "ZH" },
  { plz: "8471", cantonId: "ZH" },
  { plz: "8471", cantonId: "ZH" },
  { plz: "8472", cantonId: "ZH" },
  { plz: "8474", cantonId: "ZH" },
  { plz: "8475", cantonId: "ZH" },
  { plz: "8476", cantonId: "ZH" },
  { plz: "8477", cantonId: "ZH" },
  { plz: "8478", cantonId: "ZH" },
  { plz: "8479", cantonId: "ZH" },
  { plz: "8482", cantonId: "ZH" },
  { plz: "8483", cantonId: "ZH" },
  { plz: "8484", cantonId: "ZH" },
  { plz: "8484", cantonId: "ZH" },
  { plz: "8484", cantonId: "ZH" },
  { plz: "8486", cantonId: "ZH" },
  { plz: "8487", cantonId: "ZH" },
  { plz: "8487", cantonId: "ZH" },
  { plz: "8488", cantonId: "ZH" },
  { plz: "8489", cantonId: "ZH" },
  { plz: "8492", cantonId: "ZH" },
  { plz: "8493", cantonId: "ZH" },
  { plz: "8494", cantonId: "ZH" },
  { plz: "8495", cantonId: "ZH" },
  { plz: "8496", cantonId: "ZH" },
  { plz: "8497", cantonId: "ZH" },
  { plz: "8498", cantonId: "ZH" },
  { plz: "8499", cantonId: "ZH" },
  { plz: "8500", cantonId: "TG" },
  { plz: "8500", cantonId: "TG" },
  { plz: "8505", cantonId: "TG" },
  { plz: "8505", cantonId: "TG" },
  { plz: "8506", cantonId: "TG" },
  { plz: "8507", cantonId: "TG" },
  { plz: "8508", cantonId: "TG" },
  { plz: "8512", cantonId: "TG" },
  { plz: "8512", cantonId: "TG" },
  { plz: "8512", cantonId: "TG" },
  { plz: "8514", cantonId: "TG" },
  { plz: "8522", cantonId: "TG" },
  { plz: "8522", cantonId: "TG" },
  { plz: "8523", cantonId: "ZH" },
  { plz: "8524", cantonId: "TG" },
  { plz: "8524", cantonId: "TG" },
  { plz: "8525", cantonId: "TG" },
  { plz: "8525", cantonId: "TG" },
  { plz: "8526", cantonId: "TG" },
  { plz: "8532", cantonId: "TG" },
  { plz: "8532", cantonId: "TG" },
  { plz: "8535", cantonId: "TG" },
  { plz: "8536", cantonId: "TG" },
  { plz: "8537", cantonId: "TG" },
  { plz: "8537", cantonId: "TG" },
  { plz: "8542", cantonId: "ZH" },
  { plz: "8543", cantonId: "ZH" },
  { plz: "8543", cantonId: "ZH" },
  { plz: "8543", cantonId: "ZH" },
  { plz: "8544", cantonId: "ZH" },
  { plz: "8545", cantonId: "ZH" },
  { plz: "8545", cantonId: "ZH" },
  { plz: "8546", cantonId: "TG" },
  { plz: "8546", cantonId: "TG" },
  { plz: "8546", cantonId: "TG" },
  { plz: "8547", cantonId: "TG" },
  { plz: "8548", cantonId: "ZH" },
  { plz: "8552", cantonId: "TG" },
  { plz: "8553", cantonId: "TG" },
  { plz: "8553", cantonId: "TG" },
  { plz: "8553", cantonId: "TG" },
  { plz: "8553", cantonId: "TG" },
  { plz: "8554", cantonId: "TG" },
  { plz: "8554", cantonId: "TG" },
  { plz: "8555", cantonId: "TG" },
  { plz: "8556", cantonId: "TG" },
  { plz: "8556", cantonId: "TG" },
  { plz: "8556", cantonId: "TG" },
  { plz: "8556", cantonId: "TG" },
  { plz: "8558", cantonId: "TG" },
  { plz: "8560", cantonId: "TG" },
  { plz: "8561", cantonId: "TG" },
  { plz: "8564", cantonId: "TG" },
  { plz: "8564", cantonId: "TG" },
  { plz: "8564", cantonId: "TG" },
  { plz: "8564", cantonId: "TG" },
  { plz: "8564", cantonId: "TG" },
  { plz: "8564", cantonId: "TG" },
  { plz: "8564", cantonId: "TG" },
  { plz: "8564", cantonId: "TG" },
  { plz: "8565", cantonId: "TG" },
  { plz: "8566", cantonId: "TG" },
  { plz: "8566", cantonId: "TG" },
  { plz: "8566", cantonId: "TG" },
  { plz: "8566", cantonId: "TG" },
  { plz: "8570", cantonId: "TG" },
  { plz: "8572", cantonId: "TG" },
  { plz: "8572", cantonId: "TG" },
  { plz: "8572", cantonId: "TG" },
  { plz: "8572", cantonId: "TG" },
  { plz: "8573", cantonId: "TG" },
  { plz: "8573", cantonId: "TG" },
  { plz: "8573", cantonId: "TG" },
  { plz: "8574", cantonId: "TG" },
  { plz: "8574", cantonId: "TG" },
  { plz: "8575", cantonId: "TG" },
  { plz: "8575", cantonId: "TG" },
  { plz: "8576", cantonId: "TG" },
  { plz: "8577", cantonId: "TG" },
  { plz: "8577", cantonId: "TG" },
  { plz: "8580", cantonId: "TG" },
  { plz: "8580", cantonId: "TG" },
  { plz: "8580", cantonId: "TG" },
  { plz: "8580", cantonId: "TG" },
  { plz: "8581", cantonId: "TG" },
  { plz: "8582", cantonId: "TG" },
  { plz: "8583", cantonId: "TG" },
  { plz: "8583", cantonId: "TG" },
  { plz: "8583", cantonId: "TG" },
  { plz: "8584", cantonId: "TG" },
  { plz: "8584", cantonId: "TG" },
  { plz: "8585", cantonId: "TG" },
  { plz: "8585", cantonId: "TG" },
  { plz: "8585", cantonId: "TG" },
  { plz: "8585", cantonId: "TG" },
  { plz: "8585", cantonId: "TG" },
  { plz: "8585", cantonId: "TG" },
  { plz: "8585", cantonId: "TG" },
  { plz: "8585", cantonId: "TG" },
  { plz: "8585", cantonId: "TG" },
  { plz: "8586", cantonId: "TG" },
  { plz: "8586", cantonId: "TG" },
  { plz: "8586", cantonId: "TG" },
  { plz: "8586", cantonId: "TG" },
  { plz: "8586", cantonId: "TG" },
  { plz: "8586", cantonId: "TG" },
  { plz: "8586", cantonId: "TG" },
  { plz: "8586", cantonId: "TG" },
  { plz: "8587", cantonId: "TG" },
  { plz: "8588", cantonId: "TG" },
  { plz: "8589", cantonId: "TG" },
  { plz: "8590", cantonId: "TG" },
  { plz: "8592", cantonId: "TG" },
  { plz: "8593", cantonId: "TG" },
  { plz: "8594", cantonId: "TG" },
  { plz: "8595", cantonId: "TG" },
  { plz: "8596", cantonId: "TG" },
  { plz: "8596", cantonId: "TG" },
  { plz: "8597", cantonId: "TG" },
  { plz: "8598", cantonId: "TG" },
  { plz: "8599", cantonId: "TG" },
  { plz: "8600", cantonId: "ZH" },
  { plz: "8602", cantonId: "ZH" },
  { plz: "8603", cantonId: "ZH" },
  { plz: "8604", cantonId: "ZH" },
  { plz: "8605", cantonId: "ZH" },
  { plz: "8606", cantonId: "ZH" },
  { plz: "8606", cantonId: "ZH" },
  { plz: "8607", cantonId: "ZH" },
  { plz: "8608", cantonId: "ZH" },
  { plz: "8610", cantonId: "ZH" },
  { plz: "8614", cantonId: "ZH" },
  { plz: "8614", cantonId: "ZH" },
  { plz: "8615", cantonId: "ZH" },
  { plz: "8615", cantonId: "ZH" },
  { plz: "8616", cantonId: "ZH" },
  { plz: "8617", cantonId: "ZH" },
  { plz: "8618", cantonId: "ZH" },
  { plz: "8620", cantonId: "ZH" },
  { plz: "8623", cantonId: "ZH" },
  { plz: "8624", cantonId: "ZH" },
  { plz: "8625", cantonId: "ZH" },
  { plz: "8626", cantonId: "ZH" },
  { plz: "8627", cantonId: "ZH" },
  { plz: "8630", cantonId: "ZH" },
  { plz: "8632", cantonId: "ZH" },
  { plz: "8633", cantonId: "ZH" },
  { plz: "8634", cantonId: "ZH" },
  { plz: "8635", cantonId: "ZH" },
  { plz: "8636", cantonId: "ZH" },
  { plz: "8637", cantonId: "ZH" },
  { plz: "8638", cantonId: "SG" },
  { plz: "8639", cantonId: "ZH" },
  { plz: "8640", cantonId: "SG" },
  { plz: "8640", cantonId: "SG" },
  { plz: "8645", cantonId: "SG" },
  { plz: "8646", cantonId: "SG" },
  { plz: "8700", cantonId: "ZH" },
  { plz: "8702", cantonId: "ZH" },
  { plz: "8703", cantonId: "ZH" },
  { plz: "8704", cantonId: "ZH" },
  { plz: "8706", cantonId: "ZH" },
  { plz: "8707", cantonId: "ZH" },
  { plz: "8708", cantonId: "ZH" },
  { plz: "8712", cantonId: "ZH" },
  { plz: "8713", cantonId: "ZH" },
  { plz: "8714", cantonId: "ZH" },
  { plz: "8715", cantonId: "SG" },
  { plz: "8716", cantonId: "SG" },
  { plz: "8717", cantonId: "SG" },
  { plz: "8718", cantonId: "SG" },
  { plz: "8722", cantonId: "SG" },
  { plz: "8723", cantonId: "SG" },
  { plz: "8725", cantonId: "SG" },
  { plz: "8725", cantonId: "SG" },
  { plz: "8726", cantonId: "SG" },
  { plz: "8727", cantonId: "SG" },
  { plz: "8730", cantonId: "SG" },
  { plz: "8732", cantonId: "SG" },
  { plz: "8733", cantonId: "SG" },
  { plz: "8734", cantonId: "SG" },
  { plz: "8735", cantonId: "SG" },
  { plz: "8735", cantonId: "SG" },
  { plz: "8737", cantonId: "SG" },
  { plz: "8738", cantonId: "SG" },
  { plz: "8739", cantonId: "SG" },
  { plz: "8750", cantonId: "GL" },
  { plz: "8750", cantonId: "GL" },
  { plz: "8750", cantonId: "GL" },
  { plz: "8751", cantonId: "UR" },
  { plz: "8752", cantonId: "GL" },
  { plz: "8753", cantonId: "GL" },
  { plz: "8754", cantonId: "GL" },
  { plz: "8755", cantonId: "GL" },
  { plz: "8756", cantonId: "GL" },
  { plz: "8757", cantonId: "GL" },
  { plz: "8758", cantonId: "GL" },
  { plz: "8762", cantonId: "GL" },
  { plz: "8762", cantonId: "GL" },
  { plz: "8762", cantonId: "GL" },
  { plz: "8765", cantonId: "GL" },
  { plz: "8766", cantonId: "GL" },
  { plz: "8767", cantonId: "GL" },
  { plz: "8772", cantonId: "GL" },
  { plz: "8773", cantonId: "GL" },
  { plz: "8774", cantonId: "GL" },
  { plz: "8775", cantonId: "GL" },
  { plz: "8775", cantonId: "GL" },
  { plz: "8777", cantonId: "GL" },
  { plz: "8777", cantonId: "GL" },
  { plz: "8782", cantonId: "GL" },
  { plz: "8783", cantonId: "GL" },
  { plz: "8784", cantonId: "GL" },
  { plz: "8800", cantonId: "ZH" },
  { plz: "8802", cantonId: "ZH" },
  { plz: "8803", cantonId: "ZH" },
  { plz: "8804", cantonId: "ZH" },
  { plz: "8805", cantonId: "ZH" },
  { plz: "8806", cantonId: "SZ" },
  { plz: "8807", cantonId: "SZ" },
  { plz: "8808", cantonId: "SZ" },
  { plz: "8810", cantonId: "ZH" },
  { plz: "8815", cantonId: "ZH" },
  { plz: "8816", cantonId: "ZH" },
  { plz: "8820", cantonId: "ZH" },
  { plz: "8824", cantonId: "ZH" },
  { plz: "8825", cantonId: "ZH" },
  { plz: "8832", cantonId: "SZ" },
  { plz: "8832", cantonId: "SZ" },
  { plz: "8833", cantonId: "ZH" },
  { plz: "8834", cantonId: "SZ" },
  { plz: "8835", cantonId: "SZ" },
  { plz: "8836", cantonId: "SZ" },
  { plz: "8840", cantonId: "SZ" },
  { plz: "8840", cantonId: "SZ" },
  { plz: "8841", cantonId: "SZ" },
  { plz: "8842", cantonId: "SZ" },
  { plz: "8843", cantonId: "SZ" },
  { plz: "8844", cantonId: "SZ" },
  { plz: "8845", cantonId: "SZ" },
  { plz: "8846", cantonId: "SZ" },
  { plz: "8847", cantonId: "SZ" },
  { plz: "8849", cantonId: "SZ" },
  { plz: "8852", cantonId: "SZ" },
  { plz: "8853", cantonId: "SZ" },
  { plz: "8854", cantonId: "SZ" },
  { plz: "8854", cantonId: "SZ" },
  { plz: "8855", cantonId: "SZ" },
  { plz: "8856", cantonId: "SZ" },
  { plz: "8857", cantonId: "SZ" },
  { plz: "8858", cantonId: "SZ" },
  { plz: "8862", cantonId: "SZ" },
  { plz: "8863", cantonId: "SZ" },
  { plz: "8864", cantonId: "SZ" },
  { plz: "8865", cantonId: "GL" },
  { plz: "8866", cantonId: "GL" },
  { plz: "8867", cantonId: "GL" },
  { plz: "8868", cantonId: "GL" },
  { plz: "8872", cantonId: "SG" },
  { plz: "8873", cantonId: "SG" },
  { plz: "8874", cantonId: "GL" },
  { plz: "8877", cantonId: "SG" },
  { plz: "8878", cantonId: "SG" },
  { plz: "8880", cantonId: "SG" },
  { plz: "8881", cantonId: "SG" },
  { plz: "8881", cantonId: "SG" },
  { plz: "8881", cantonId: "SG" },
  { plz: "8882", cantonId: "SG" },
  { plz: "8883", cantonId: "SG" },
  { plz: "8884", cantonId: "SG" },
  { plz: "8885", cantonId: "SG" },
  { plz: "8886", cantonId: "SG" },
  { plz: "8887", cantonId: "SG" },
  { plz: "8888", cantonId: "SG" },
  { plz: "8889", cantonId: "SG" },
  { plz: "8890", cantonId: "SG" },
  { plz: "8892", cantonId: "SG" },
  { plz: "8893", cantonId: "SG" },
  { plz: "8894", cantonId: "SG" },
  { plz: "8895", cantonId: "SG" },
  { plz: "8896", cantonId: "SG" },
  { plz: "8897", cantonId: "SG" },
  { plz: "8898", cantonId: "SG" },
  { plz: "8902", cantonId: "ZH" },
  { plz: "8903", cantonId: "ZH" },
  { plz: "8904", cantonId: "ZH" },
  { plz: "8905", cantonId: "AG" },
  { plz: "8905", cantonId: "AG" },
  { plz: "8906", cantonId: "ZH" },
  { plz: "8907", cantonId: "ZH" },
  { plz: "8908", cantonId: "ZH" },
  { plz: "8909", cantonId: "ZH" },
  { plz: "8910", cantonId: "ZH" },
  { plz: "8911", cantonId: "ZH" },
  { plz: "8912", cantonId: "ZH" },
  { plz: "8913", cantonId: "ZH" },
  { plz: "8914", cantonId: "ZH" },
  { plz: "8914", cantonId: "ZH" },
  { plz: "8915", cantonId: "ZH" },
  { plz: "8916", cantonId: "AG" },
  { plz: "8917", cantonId: "AG" },
  { plz: "8918", cantonId: "AG" },
  { plz: "8919", cantonId: "AG" },
  { plz: "8925", cantonId: "ZH" },
  { plz: "8926", cantonId: "ZH" },
  { plz: "8926", cantonId: "ZH" },
  { plz: "8926", cantonId: "ZH" },
  { plz: "8932", cantonId: "ZH" },
  { plz: "8933", cantonId: "ZH" },
  { plz: "8934", cantonId: "ZH" },
  { plz: "8942", cantonId: "ZH" },
  { plz: "8951", cantonId: "ZH" },
  { plz: "8952", cantonId: "ZH" },
  { plz: "8953", cantonId: "ZH" },
  { plz: "8954", cantonId: "ZH" },
  { plz: "8955", cantonId: "ZH" },
  { plz: "8956", cantonId: "AG" },
  { plz: "8957", cantonId: "AG" },
  { plz: "8962", cantonId: "AG" },
  { plz: "8964", cantonId: "AG" },
  { plz: "8965", cantonId: "AG" },
  { plz: "8966", cantonId: "AG" },
  { plz: "8967", cantonId: "AG" },
  { plz: "9000", cantonId: "SG" },
  { plz: "9007", cantonId: "SG" },
  { plz: "9008", cantonId: "SG" },
  { plz: "9010", cantonId: "SG" },
  { plz: "9011", cantonId: "SG" },
  { plz: "9012", cantonId: "SG" },
  { plz: "9014", cantonId: "SG" },
  { plz: "9015", cantonId: "SG" },
  { plz: "9016", cantonId: "SG" },
  { plz: "9030", cantonId: "SG" },
  { plz: "9032", cantonId: "SG" },
  { plz: "9033", cantonId: "SG" },
  { plz: "9034", cantonId: "SG" },
  { plz: "9035", cantonId: "AR" },
  { plz: "9036", cantonId: "SG" },
  { plz: "9037", cantonId: "AR" },
  { plz: "9038", cantonId: "AR" },
  { plz: "9042", cantonId: "AR" },
  { plz: "9043", cantonId: "AR" },
  { plz: "9044", cantonId: "AR" },
  { plz: "9050", cantonId: "AI" },
  { plz: "9050", cantonId: "AI" },
  { plz: "9050", cantonId: "AI" },
  { plz: "9050", cantonId: "AI" },
  { plz: "9050", cantonId: "AI" },
  { plz: "9050", cantonId: "AI" },
  { plz: "9052", cantonId: "AR" },
  { plz: "9053", cantonId: "AR" },
  { plz: "9054", cantonId: "AI" },
  { plz: "9055", cantonId: "AR" },
  { plz: "9056", cantonId: "AR" },
  { plz: "9057", cantonId: "AI" },
  { plz: "9057", cantonId: "AI" },
  { plz: "9057", cantonId: "AI" },
  { plz: "9058", cantonId: "AI" },
  { plz: "9062", cantonId: "AR" },
  { plz: "9063", cantonId: "AR" },
  { plz: "9064", cantonId: "AR" },
  { plz: "9100", cantonId: "AR" },
  { plz: "9103", cantonId: "AR" },
  { plz: "9104", cantonId: "AR" },
  { plz: "9105", cantonId: "AR" },
  { plz: "9107", cantonId: "AR" },
  { plz: "9108", cantonId: "AI" },
  { plz: "9108", cantonId: "AI" },
  { plz: "9108", cantonId: "AI" },
  { plz: "9112", cantonId: "AR" },
  { plz: "9113", cantonId: "SG" },
  { plz: "9114", cantonId: "SG" },
  { plz: "9115", cantonId: "SG" },
  { plz: "9116", cantonId: "SG" },
  { plz: "9122", cantonId: "SG" },
  { plz: "9122", cantonId: "SG" },
  { plz: "9123", cantonId: "SG" },
  { plz: "9125", cantonId: "SG" },
  { plz: "9126", cantonId: "SG" },
  { plz: "9127", cantonId: "SG" },
  { plz: "9200", cantonId: "SG" },
  { plz: "9203", cantonId: "SG" },
  { plz: "9204", cantonId: "SG" },
  { plz: "9205", cantonId: "SG" },
  { plz: "9212", cantonId: "SG" },
  { plz: "9213", cantonId: "TG" },
  { plz: "9214", cantonId: "TG" },
  { plz: "9215", cantonId: "TG" },
  { plz: "9215", cantonId: "TG" },
  { plz: "9216", cantonId: "TG" },
  { plz: "9216", cantonId: "TG" },
  { plz: "9217", cantonId: "TG" },
  { plz: "9220", cantonId: "TG" },
  { plz: "9223", cantonId: "TG" },
  { plz: "9223", cantonId: "TG" },
  { plz: "9225", cantonId: "TG" },
  { plz: "9225", cantonId: "TG" },
  { plz: "9230", cantonId: "SG" },
  { plz: "9231", cantonId: "SG" },
  { plz: "9240", cantonId: "SG" },
  { plz: "9240", cantonId: "SG" },
  { plz: "9242", cantonId: "SG" },
  { plz: "9243", cantonId: "SG" },
  { plz: "9244", cantonId: "SG" },
  { plz: "9245", cantonId: "SG" },
  { plz: "9245", cantonId: "SG" },
  { plz: "9246", cantonId: "SG" },
  { plz: "9247", cantonId: "SG" },
  { plz: "9248", cantonId: "SG" },
  { plz: "9249", cantonId: "SG" },
  { plz: "9249", cantonId: "SG" },
  { plz: "9249", cantonId: "SG" },
  { plz: "9300", cantonId: "SG" },
  { plz: "9304", cantonId: "SG" },
  { plz: "9305", cantonId: "SG" },
  { plz: "9306", cantonId: "TG" },
  { plz: "9308", cantonId: "SG" },
  { plz: "9312", cantonId: "SG" },
  { plz: "9313", cantonId: "SG" },
  { plz: "9314", cantonId: "TG" },
  { plz: "9315", cantonId: "TG" },
  { plz: "9315", cantonId: "TG" },
  { plz: "9320", cantonId: "TG" },
  { plz: "9320", cantonId: "TG" },
  { plz: "9320", cantonId: "TG" },
  { plz: "9322", cantonId: "TG" },
  { plz: "9323", cantonId: "SG" },
  { plz: "9325", cantonId: "TG" },
  { plz: "9326", cantonId: "TG" },
  { plz: "9327", cantonId: "SG" },
  { plz: "9400", cantonId: "SG" },
  { plz: "9402", cantonId: "SG" },
  { plz: "9403", cantonId: "SG" },
  { plz: "9404", cantonId: "SG" },
  { plz: "9405", cantonId: "AR" },
  { plz: "9410", cantonId: "AR" },
  { plz: "9411", cantonId: "AR" },
  { plz: "9413", cantonId: "AI" },
  { plz: "9414", cantonId: "AR" },
  { plz: "9422", cantonId: "SG" },
  { plz: "9423", cantonId: "SG" },
  { plz: "9424", cantonId: "SG" },
  { plz: "9425", cantonId: "SG" },
  { plz: "9426", cantonId: "AR" },
  { plz: "9427", cantonId: "AR" },
  { plz: "9427", cantonId: "AR" },
  { plz: "9428", cantonId: "AR" },
  { plz: "9430", cantonId: "SG" },
  { plz: "9434", cantonId: "SG" },
  { plz: "9435", cantonId: "SG" },
  { plz: "9436", cantonId: "SG" },
  { plz: "9437", cantonId: "SG" },
  { plz: "9442", cantonId: "AI" },
  { plz: "9442", cantonId: "AI" },
  { plz: "9443", cantonId: "SG" },
  { plz: "9444", cantonId: "SG" },
  { plz: "9445", cantonId: "SG" },
  { plz: "9450", cantonId: "SG" },
  { plz: "9450", cantonId: "SG" },
  { plz: "9451", cantonId: "SG" },
  { plz: "9452", cantonId: "SG" },
  { plz: "9453", cantonId: "SG" },
  { plz: "9462", cantonId: "SG" },
  { plz: "9463", cantonId: "SG" },
  { plz: "9464", cantonId: "SG" },
  { plz: "9464", cantonId: "SG" },
  { plz: "9465", cantonId: "SG" },
  { plz: "9466", cantonId: "SG" },
  { plz: "9467", cantonId: "SG" },
  { plz: "9468", cantonId: "SG" },
  { plz: "9469", cantonId: "SG" },
  { plz: "9470", cantonId: "SG" },
  { plz: "9470", cantonId: "SG" },
  { plz: "9472", cantonId: "SG" },
  { plz: "9472", cantonId: "SG" },
  { plz: "9473", cantonId: "SG" },
  { plz: "9475", cantonId: "SG" },
  { plz: "9476", cantonId: "SG" },
  { plz: "9476", cantonId: "SG" },
  { plz: "9477", cantonId: "SG" },
  { plz: "9478", cantonId: "SG" },
  { plz: "9479", cantonId: "SG" },
  { plz: "9479", cantonId: "SG" },
  { plz: "9479", cantonId: "SG" },
  { plz: "9485", cantonId: "FL" },
  { plz: "9486", cantonId: "FL" },
  { plz: "9487", cantonId: "FL" },
  { plz: "9488", cantonId: "FL" },
  { plz: "9490", cantonId: "FL" },
  { plz: "9491", cantonId: "FL" },
  { plz: "9492", cantonId: "FL" },
  { plz: "9493", cantonId: "FL" },
  { plz: "9494", cantonId: "FL" },
  { plz: "9495", cantonId: "FL" },
  { plz: "9496", cantonId: "FL" },
  { plz: "9497", cantonId: "FL" },
  { plz: "9498", cantonId: "FL" },
  { plz: "9500", cantonId: "SG" },
  { plz: "9502", cantonId: "TG" },
  { plz: "9503", cantonId: "TG" },
  { plz: "9503", cantonId: "TG" },
  { plz: "9504", cantonId: "TG" },
  { plz: "9506", cantonId: "TG" },
  { plz: "9507", cantonId: "TG" },
  { plz: "9508", cantonId: "TG" },
  { plz: "9512", cantonId: "SG" },
  { plz: "9514", cantonId: "TG" },
  { plz: "9515", cantonId: "TG" },
  { plz: "9517", cantonId: "TG" },
  { plz: "9523", cantonId: "SG" },
  { plz: "9524", cantonId: "SG" },
  { plz: "9525", cantonId: "SG" },
  { plz: "9526", cantonId: "SG" },
  { plz: "9527", cantonId: "SG" },
  { plz: "9532", cantonId: "TG" },
  { plz: "9533", cantonId: "SG" },
  { plz: "9534", cantonId: "SG" },
  { plz: "9535", cantonId: "TG" },
  { plz: "9536", cantonId: "SG" },
  { plz: "9542", cantonId: "TG" },
  { plz: "9543", cantonId: "TG" },
  { plz: "9545", cantonId: "TG" },
  { plz: "9546", cantonId: "TG" },
  { plz: "9547", cantonId: "TG" },
  { plz: "9548", cantonId: "TG" },
  { plz: "9552", cantonId: "SG" },
  { plz: "9553", cantonId: "TG" },
  { plz: "9554", cantonId: "TG" },
  { plz: "9555", cantonId: "TG" },
  { plz: "9556", cantonId: "TG" },
  { plz: "9556", cantonId: "TG" },
  { plz: "9562", cantonId: "TG" },
  { plz: "9562", cantonId: "TG" },
  { plz: "9565", cantonId: "TG" },
  { plz: "9565", cantonId: "TG" },
  { plz: "9565", cantonId: "TG" },
  { plz: "9565", cantonId: "TG" },
  { plz: "9565", cantonId: "TG" },
  { plz: "9573", cantonId: "TG" },
  { plz: "9601", cantonId: "SG" },
  { plz: "9602", cantonId: "SG" },
  { plz: "9602", cantonId: "SG" },
  { plz: "9604", cantonId: "SG" },
  { plz: "9604", cantonId: "SG" },
  { plz: "9604", cantonId: "SG" },
  { plz: "9606", cantonId: "SG" },
  { plz: "9607", cantonId: "SG" },
  { plz: "9608", cantonId: "SG" },
  { plz: "9612", cantonId: "SG" },
  { plz: "9613", cantonId: "SG" },
  { plz: "9614", cantonId: "SG" },
  { plz: "9615", cantonId: "SG" },
  { plz: "9620", cantonId: "SG" },
  { plz: "9621", cantonId: "SG" },
  { plz: "9622", cantonId: "SG" },
  { plz: "9630", cantonId: "SG" },
  { plz: "9631", cantonId: "SG" },
  { plz: "9633", cantonId: "SG" },
  { plz: "9633", cantonId: "SG" },
  { plz: "9642", cantonId: "SG" },
  { plz: "9643", cantonId: "SG" },
  { plz: "9650", cantonId: "SG" },
  { plz: "9651", cantonId: "SG" },
  { plz: "9652", cantonId: "SG" },
  { plz: "9655", cantonId: "SG" },
  { plz: "9656", cantonId: "SG" },
  { plz: "9657", cantonId: "SG" },
  { plz: "9658", cantonId: "SG" },
];

const cities = [
  { plz: "1000", city: "Lausanne" },
  { plz: "1003", city: "Lausanne" },
  { plz: "1004", city: "Lausanne" },
  { plz: "1005", city: "Lausanne" },
  { plz: "1006", city: "Lausanne" },
  { plz: "1007", city: "Lausanne" },
  { plz: "1008", city: "Jouxtens-Mézery" },
  { plz: "1008", city: "Prilly" },
  { plz: "1009", city: "Pully" },
  { plz: "1010", city: "Lausanne" },
  { plz: "1011", city: "Lausanne" },
  { plz: "1012", city: "Lausanne" },
  { plz: "1015", city: "Lausanne" },
  { plz: "1018", city: "Lausanne" },
  { plz: "1020", city: "Renens VD" },
  { plz: "1022", city: "Chavannes-près-Renens" },
  { plz: "1023", city: "Crissier" },
  { plz: "1024", city: "Ecublens VD" },
  { plz: "1025", city: "St-Sulpice VD" },
  { plz: "1026", city: "Denges" },
  { plz: "1026", city: "Echandens" },
  { plz: "1027", city: "Lonay" },
  { plz: "1028", city: "Préverenges" },
  { plz: "1029", city: "Villars-Ste-Croix" },
  { plz: "1030", city: "Bussigny-près-Lausanne" },
  { plz: "1031", city: "Mex VD" },
  { plz: "1032", city: "Romanel-sur-Lausanne" },
  { plz: "1033", city: "Cheseaux-sur-Lausanne" },
  { plz: "1034", city: "Boussens" },
  { plz: "1035", city: "Bournens" },
  { plz: "1036", city: "Sullens" },
  { plz: "1037", city: "Etagnières" },
  { plz: "1038", city: "Bercher" },
  { plz: "1040", city: "St-Barthélemy VD" },
  { plz: "1040", city: "Villars-le-Terroir" },
  { plz: "1040", city: "Echallens" },
  { plz: "1041", city: "Dommartin" },
  { plz: "1041", city: "Poliez-Pittet" },
  { plz: "1041", city: "Bottens" },
  { plz: "1041", city: "Naz" },
  { plz: "1041", city: "Montaubion-Chardonney" },
  { plz: "1041", city: "Poliez-le-Grand" },
  { plz: "1042", city: "Bettens" },
  { plz: "1042", city: "Bioley-Orjulaz" },
  { plz: "1042", city: "Assens" },
  { plz: "1043", city: "Sugnens" },
  { plz: "1044", city: "Fey" },
  { plz: "1045", city: "Ogens" },
  { plz: "1046", city: "Rueyres" },
  { plz: "1047", city: "Oppens" },
  { plz: "1052", city: "Le Mont-sur-Lausanne" },
  { plz: "1053", city: "Bretigny-sur-Morrens" },
  { plz: "1053", city: "Cugy VD" },
  { plz: "1054", city: "Morrens VD" },
  { plz: "1055", city: "Froideville" },
  { plz: "1058", city: "Villars-Tiercelin" },
  { plz: "1059", city: "Peney-le-Jorat" },
  { plz: "1061", city: "Villars-Mendraz" },
  { plz: "1062", city: "Sottens" },
  { plz: "1063", city: "Peyres-Possens" },
  { plz: "1063", city: "Boulens" },
  { plz: "1063", city: "Chapelle-sur-Moudon" },
  { plz: "1063", city: "Martherenges" },
  { plz: "1066", city: "Epalinges" },
  { plz: "1068", city: "Les Monts-de-Pully" },
  { plz: "1070", city: "Puidoux" },
  { plz: "1071", city: "Rivaz" },
  { plz: "1071", city: "St-Saphorin (Lavaux)" },
  { plz: "1071", city: "Chexbres" },
  { plz: "1072", city: "Forel (Lavaux)" },
  { plz: "1073", city: "Mollie-Margot" },
  { plz: "1073", city: "Savigny" },
  { plz: "1076", city: "Ferlens VD" },
  { plz: "1077", city: "Servion" },
  { plz: "1078", city: "Essertes" },
  { plz: "1080", city: "Les Cullayes" },
  { plz: "1081", city: "Montpreveyres" },
  { plz: "1082", city: "Corcelles-le-Jorat" },
  { plz: "1083", city: "Mézières VD" },
  { plz: "1084", city: "Carrouge VD" },
  { plz: "1085", city: "Vulliens" },
  { plz: "1088", city: "Ropraz" },
  { plz: "1090", city: "La Croix (Lutry)" },
  { plz: "1091", city: "Aran" },
  { plz: "1091", city: "Chenaux" },
  { plz: "1091", city: "Grandvaux" },
  { plz: "1092", city: "Belmont-sur-Lausanne" },
  { plz: "1093", city: "La Conversion" },
  { plz: "1094", city: "Paudex" },
  { plz: "1095", city: "Lutry" },
  { plz: "1096", city: "Villette (Lavaux)" },
  { plz: "1096", city: "Cully" },
  { plz: "1097", city: "Riex" },
  { plz: "1098", city: "Epesses" },
  { plz: "1110", city: "Morges" },
  { plz: "1112", city: "Echichens" },
  { plz: "1113", city: "St-Saphorin-sur-Morges" },
  { plz: "1114", city: "Colombier VD" },
  { plz: "1115", city: "Vullierens" },
  { plz: "1116", city: "Cottens VD" },
  { plz: "1117", city: "Grancy" },
  { plz: "1121", city: "Bremblens" },
  { plz: "1122", city: "Romanel-sur-Morges" },
  { plz: "1123", city: "Aclens" },
  { plz: "1124", city: "Gollion" },
  { plz: "1125", city: "Monnaz" },
  { plz: "1126", city: "Vaux-sur-Morges" },
  { plz: "1127", city: "Clarmont" },
  { plz: "1128", city: "Reverolle" },
  { plz: "1131", city: "Tolochenaz" },
  { plz: "1132", city: "Lully VD" },
  { plz: "1134", city: "Vufflens-le-Château" },
  { plz: "1134", city: "Chigny" },
  { plz: "1135", city: "Denens" },
  { plz: "1136", city: "Bussy-Chardonney" },
  { plz: "1141", city: "Sévery" },
  { plz: "1142", city: "Pampigny" },
  { plz: "1143", city: "Apples" },
  { plz: "1144", city: "Ballens" },
  { plz: "1145", city: "Bière" },
  { plz: "1146", city: "Mollens VD" },
  { plz: "1147", city: "Montricher" },
  { plz: "1148", city: "Cuarnens" },
  { plz: "1148", city: "Moiry VD" },
  { plz: "1148", city: "La Praz" },
  { plz: "1148", city: "Mont-la-Ville" },
  { plz: "1148", city: "Chavannes-le-Veyron" },
  { plz: "1148", city: "Mauraz" },
  { plz: "1148", city: "Villars-Bozon" },
  { plz: "1148", city: "La Coudre" },
  { plz: "1148", city: "L'Isle" },
  { plz: "1149", city: "Berolle" },
  { plz: "1162", city: "St-Prex" },
  { plz: "1163", city: "Etoy" },
  { plz: "1164", city: "Buchillon" },
  { plz: "1165", city: "Allaman" },
  { plz: "1166", city: "Perroy" },
  { plz: "1167", city: "Lussy-sur-Morges" },
  { plz: "1168", city: "Villars-sous-Yens" },
  { plz: "1169", city: "Yens" },
  { plz: "1170", city: "Aubonne" },
  { plz: "1172", city: "Bougy-Villars" },
  { plz: "1173", city: "Féchy" },
  { plz: "1174", city: "Montherod" },
  { plz: "1174", city: "Pizy" },
  { plz: "1175", city: "Lavigny" },
  { plz: "1176", city: "St-Livres" },
  { plz: "1180", city: "Tartegnin" },
  { plz: "1180", city: "Rolle" },
  { plz: "1182", city: "Gilly" },
  { plz: "1183", city: "Bursins" },
  { plz: "1184", city: "Vinzel" },
  { plz: "1184", city: "Luins" },
  { plz: "1185", city: "Mont-sur-Rolle" },
  { plz: "1186", city: "Essertines-sur-Rolle" },
  { plz: "1187", city: "St-Oyens" },
  { plz: "1188", city: "Gimel" },
  { plz: "1188", city: "St-George" },
  { plz: "1189", city: "Saubraz" },
  { plz: "1195", city: "Dully" },
  { plz: "1195", city: "Bursinel" },
  { plz: "1196", city: "Gland" },
  { plz: "1197", city: "Prangins" },
  { plz: "1200", city: "Genève" },
  { plz: "1201", city: "Genève" },
  { plz: "1202", city: "Genève" },
  { plz: "1203", city: "Genève" },
  { plz: "1204", city: "Genève" },
  { plz: "1205", city: "Genève" },
  { plz: "1206", city: "Genève" },
  { plz: "1207", city: "Genève" },
  { plz: "1208", city: "Genève" },
  { plz: "1209", city: "Genève" },
  { plz: "1212", city: "Grand-Lancy" },
  { plz: "1213", city: "Petit-Lancy" },
  { plz: "1213", city: "Onex" },
  { plz: "1214", city: "Vernier" },
  { plz: "1215", city: "Genève 15 Aéroport" },
  { plz: "1216", city: "Cointrin" },
  { plz: "1217", city: "Meyrin" },
  { plz: "1218", city: "Le Grand-Saconnex" },
  { plz: "1219", city: "Le Lignon" },
  { plz: "1219", city: "Aïre" },
  { plz: "1219", city: "Châtelaine" },
  { plz: "1220", city: "Les Avanchets" },
  { plz: "1222", city: "Vésenaz" },
  { plz: "1223", city: "Cologny" },
  { plz: "1224", city: "Chêne-Bougeries" },
  { plz: "1225", city: "Chêne-Bourg" },
  { plz: "1226", city: "Thônex" },
  { plz: "1227", city: "Les Acacias" },
  { plz: "1227", city: "Carouge GE" },
  { plz: "1228", city: "Plan-les-Ouates" },
  { plz: "1231", city: "Conches" },
  { plz: "1232", city: "Confignon" },
  { plz: "1233", city: "Bernex" },
  { plz: "1234", city: "Vessy" },
  { plz: "1236", city: "Cartigny" },
  { plz: "1237", city: "Avully" },
  { plz: "1239", city: "Collex" },
  { plz: "1241", city: "Puplinge" },
  { plz: "1242", city: "Satigny" },
  { plz: "1243", city: "Presinge" },
  { plz: "1244", city: "Choulex" },
  { plz: "1245", city: "Collonge-Bellerive" },
  { plz: "1246", city: "Corsier GE" },
  { plz: "1247", city: "Anières" },
  { plz: "1248", city: "Hermance" },
  { plz: "1251", city: "Gy" },
  { plz: "1252", city: "Meinier" },
  { plz: "1253", city: "Vandoeuvres" },
  { plz: "1254", city: "Jussy" },
  { plz: "1255", city: "Veyrier" },
  { plz: "1256", city: "Troinex" },
  { plz: "1257", city: "La Croix-de-Rozon" },
  { plz: "1258", city: "Perly" },
  { plz: "1260", city: "Nyon" },
  { plz: "1261", city: "Longirod" },
  { plz: "1261", city: "Marchissy" },
  { plz: "1261", city: "Le Vaud" },
  { plz: "1262", city: "Eysins" },
  { plz: "1263", city: "Crassier" },
  { plz: "1264", city: "St-Cergue" },
  { plz: "1265", city: "La Cure" },
  { plz: "1266", city: "Duillier" },
  { plz: "1267", city: "Vich" },
  { plz: "1267", city: "Coinsins" },
  { plz: "1268", city: "Burtigny" },
  { plz: "1268", city: "Begnins" },
  { plz: "1269", city: "Bassins" },
  { plz: "1270", city: "Trélex" },
  { plz: "1271", city: "Givrins" },
  { plz: "1272", city: "Genolier" },
  { plz: "1273", city: "Le Muids" },
  { plz: "1273", city: "Arzier" },
  { plz: "1274", city: "Grens" },
  { plz: "1274", city: "Signy" },
  { plz: "1275", city: "Chéserex" },
  { plz: "1276", city: "Gingins" },
  { plz: "1277", city: "Arnex-sur-Nyon" },
  { plz: "1277", city: "Borex" },
  { plz: "1278", city: "La Rippe" },
  { plz: "1279", city: "Bogis-Bossey" },
  { plz: "1279", city: "Chavannes-de-Bogis" },
  { plz: "1281", city: "Russin" },
  { plz: "1283", city: "Dardagny" },
  { plz: "1283", city: "La Plaine" },
  { plz: "1284", city: "Chancy" },
  { plz: "1285", city: "Athenaz (Avusy)" },
  { plz: "1286", city: "Soral" },
  { plz: "1287", city: "Laconnex" },
  { plz: "1288", city: "Aire-la-Ville" },
  { plz: "1290", city: "Versoix" },
  { plz: "1290", city: "Chavannes-des-Bois" },
  { plz: "1291", city: "Commugny" },
  { plz: "1292", city: "Chambésy" },
  { plz: "1293", city: "Bellevue" },
  { plz: "1294", city: "Genthod" },
  { plz: "1295", city: "Mies" },
  { plz: "1295", city: "Tannay" },
  { plz: "1296", city: "Coppet" },
  { plz: "1297", city: "Founex" },
  { plz: "1298", city: "Céligny" },
  { plz: "1299", city: "Crans-près-Céligny" },
  { plz: "1302", city: "Vufflens-la-Ville" },
  { plz: "1303", city: "Penthaz" },
  { plz: "1304", city: "Senarclens" },
  { plz: "1304", city: "Dizy" },
  { plz: "1304", city: "Allens" },
  { plz: "1304", city: "Cossonay-Ville" },
  { plz: "1305", city: "Penthalaz" },
  { plz: "1306", city: "Daillens" },
  { plz: "1307", city: "Lussery-Villars" },
  { plz: "1308", city: "La Chaux (Cossonay)" },
  { plz: "1312", city: "Eclépens" },
  { plz: "1313", city: "Ferreyres" },
  { plz: "1315", city: "La Sarraz" },
  { plz: "1316", city: "Chevilly" },
  { plz: "1317", city: "Orny" },
  { plz: "1318", city: "Pompaples" },
  { plz: "1321", city: "Arnex-sur-Orbe" },
  { plz: "1322", city: "Croy" },
  { plz: "1323", city: "Romainmôtier" },
  { plz: "1324", city: "Premier" },
  { plz: "1325", city: "Vaulion" },
  { plz: "1326", city: "Juriens" },
  { plz: "1329", city: "Bretonnières" },
  { plz: "1337", city: "Vallorbe" },
  { plz: "1338", city: "Ballaigues" },
  { plz: "1341", city: "Orient" },
  { plz: "1342", city: "Le Pont" },
  { plz: "1343", city: "Les Charbonnières" },
  { plz: "1344", city: "L'Abbaye" },
  { plz: "1345", city: "Le Lieu" },
  { plz: "1346", city: "Les Bioux" },
  { plz: "1347", city: "Le Solliat" },
  { plz: "1347", city: "Le Sentier" },
  { plz: "1348", city: "Le Brassus" },
  { plz: "1350", city: "Orbe" },
  { plz: "1352", city: "Agiez" },
  { plz: "1353", city: "Bofflens" },
  { plz: "1354", city: "Montcherand" },
  { plz: "1355", city: "Sergey" },
  { plz: "1355", city: "L'Abergement" },
  { plz: "1356", city: "Les Clées" },
  { plz: "1356", city: "La Russille" },
  { plz: "1357", city: "Lignerolle" },
  { plz: "1358", city: "Valeyres-sous-Rances" },
  { plz: "1372", city: "Bavois" },
  { plz: "1373", city: "Chavornay" },
  { plz: "1374", city: "Corcelles-sur-Chavornay" },
  { plz: "1375", city: "Penthéréaz" },
  { plz: "1376", city: "Eclagnens" },
  { plz: "1376", city: "Goumoens-le-Jux" },
  { plz: "1376", city: "Goumoens-la-Ville" },
  { plz: "1377", city: "Oulens-sous-Echallens" },
  { plz: "1400", city: "Yverdon-les-Bains" },
  { plz: "1400", city: "Cheseaux-Noréaz" },
  { plz: "1404", city: "Cuarny" },
  { plz: "1404", city: "Villars-Epeney" },
  { plz: "1405", city: "Pomy" },
  { plz: "1406", city: "Cronay" },
  { plz: "1407", city: "Bioley-Magnoux" },
  { plz: "1407", city: "Gossens" },
  { plz: "1407", city: "Mézery-près-Donneloye" },
  { plz: "1407", city: "Donneloye" },
  { plz: "1408", city: "Prahins" },
  { plz: "1409", city: "Chanéaz" },
  { plz: "1410", city: "Prévondavaux" },
  { plz: "1410", city: "St-Cierges" },
  { plz: "1410", city: "Denezy" },
  { plz: "1410", city: "Correvon" },
  { plz: "1410", city: "Thierrens" },
  { plz: "1412", city: "Valeyres-sous-Ursins" },
  { plz: "1412", city: "Ursins" },
  { plz: "1413", city: "Orzens" },
  { plz: "1415", city: "Molondin" },
  { plz: "1415", city: "Démoret" },
  { plz: "1416", city: "Pailly" },
  { plz: "1417", city: "Epautheyres" },
  { plz: "1417", city: "Essertines-sur-Yverdon" },
  { plz: "1418", city: "Vuarrens" },
  { plz: "1420", city: "Fiez" },
  { plz: "1421", city: "Fontaines-sur-Grandson" },
  { plz: "1421", city: "Grandevent" },
  { plz: "1422", city: "Grandson" },
  { plz: "1423", city: "Fontanezier" },
  { plz: "1423", city: "Romairon" },
  { plz: "1423", city: "Vaugondry" },
  { plz: "1423", city: "Villars-Burquin" },
  { plz: "1424", city: "Champagne" },
  { plz: "1425", city: "Onnens VD" },
  { plz: "1426", city: "Corcelles-près-Concise" },
  { plz: "1426", city: "Concise" },
  { plz: "1427", city: "Bonvillars" },
  { plz: "1428", city: "Mutrux" },
  { plz: "1428", city: "Provence" },
  { plz: "1429", city: "Giez" },
  { plz: "1430", city: "Orges" },
  { plz: "1431", city: "Vugelles-La Mothe" },
  { plz: "1431", city: "Novalles" },
  { plz: "1432", city: "Belmont-sur-Yverdon" },
  { plz: "1432", city: "Gressy" },
  { plz: "1433", city: "Suchy" },
  { plz: "1434", city: "Ependes VD" },
  { plz: "1435", city: "Essert-Pittet" },
  { plz: "1436", city: "Treycovagnes" },
  { plz: "1436", city: "Chamblon" },
  { plz: "1437", city: "Suscévaz" },
  { plz: "1438", city: "Mathod" },
  { plz: "1439", city: "Rances" },
  { plz: "1441", city: "Valeyres-sous-Montagny" },
  { plz: "1442", city: "Montagny-près-Yverdon" },
  { plz: "1443", city: "Champvent" },
  { plz: "1443", city: "Essert-sous-Champvent" },
  { plz: "1443", city: "Villars-sous-Champvent" },
  { plz: "1445", city: "Vuiteboeuf" },
  { plz: "1446", city: "Baulmes" },
  { plz: "1450", city: "La Sagne (Ste-Croix)" },
  { plz: "1450", city: "Le Château-de-Ste-Croix" },
  { plz: "1450", city: "Ste-Croix" },
  { plz: "1452", city: "Les Rasses" },
  { plz: "1453", city: "Mauborget" },
  { plz: "1453", city: "Bullet" },
  { plz: "1454", city: "La Vraconnaz" },
  { plz: "1454", city: "L'Auberson" },
  { plz: "1462", city: "Yvonand" },
  { plz: "1463", city: "Rovray" },
  { plz: "1464", city: "Chêne-Pâquier" },
  { plz: "1464", city: "Chavannes-le-Chêne" },
  { plz: "1468", city: "Cheyres" },
  { plz: "1470", city: "Bollion" },
  { plz: "1470", city: "Lully FR" },
  { plz: "1470", city: "Seiry" },
  { plz: "1470", city: "Estavayer-le-Lac" },
  { plz: "1473", city: "Font" },
  { plz: "1473", city: "Châtillon FR" },
  { plz: "1474", city: "Châbles FR" },
  { plz: "1475", city: "Autavaux" },
  { plz: "1475", city: "Forel FR" },
  { plz: "1475", city: "Montbrelloz" },
  { plz: "1482", city: "Cugy FR" },
  { plz: "1483", city: "Frasses" },
  { plz: "1483", city: "Vesin" },
  { plz: "1483", city: "Montet (Broye)" },
  { plz: "1484", city: "Aumont" },
  { plz: "1484", city: "Granges-de-Vesin" },
  { plz: "1485", city: "Nuvilly" },
  { plz: "1486", city: "Vuissens" },
  { plz: "1489", city: "Murist" },
  { plz: "1509", city: "Vucherens" },
  { plz: "1510", city: "Syens" },
  { plz: "1510", city: "Moudon" },
  { plz: "1512", city: "Chavannes-sur-Moudon" },
  { plz: "1513", city: "Hermenches" },
  { plz: "1513", city: "Rossenges" },
  { plz: "1514", city: "Bussy-sur-Moudon" },
  { plz: "1515", city: "Neyruz-sur-Moudon" },
  { plz: "1515", city: "Villars-le-Comte" },
  { plz: "1521", city: "Curtilles" },
  { plz: "1522", city: "Oulens-sur-Lucens" },
  { plz: "1522", city: "Lucens" },
  { plz: "1523", city: "Granges-près-Marnand" },
  { plz: "1524", city: "Marnand" },
  { plz: "1525", city: "Seigneux" },
  { plz: "1525", city: "Henniez" },
  { plz: "1526", city: "Forel-sur-Lucens" },
  { plz: "1526", city: "Cremin" },
  { plz: "1527", city: "Villeneuve FR" },
  { plz: "1528", city: "Praratoud" },
  { plz: "1528", city: "Surpierre" },
  { plz: "1529", city: "Cheiry" },
  { plz: "1530", city: "Payerne" },
  { plz: "1532", city: "Fétigny" },
  { plz: "1533", city: "Ménières" },
  { plz: "1534", city: "Chapelle (Broye)" },
  { plz: "1534", city: "Sassel" },
  { plz: "1535", city: "Combremont-le-Grand" },
  { plz: "1536", city: "Combremont-le-Petit" },
  { plz: "1537", city: "Champtauroz" },
  { plz: "1538", city: "Treytorrens (Payerne)" },
  { plz: "1541", city: "Morens FR" },
  { plz: "1541", city: "Sévaz" },
  { plz: "1541", city: "Bussy FR" },
  { plz: "1542", city: "Rueyres-les-Prés" },
  { plz: "1543", city: "Grandcour" },
  { plz: "1544", city: "Gletterens" },
  { plz: "1545", city: "Chevroux" },
  { plz: "1551", city: "Vers-chez-Perrin" },
  { plz: "1552", city: "Trey" },
  { plz: "1553", city: "Châtonnaye" },
  { plz: "1554", city: "Sédeilles" },
  { plz: "1554", city: "Rossens VD" },
  { plz: "1555", city: "Villarzel" },
  { plz: "1562", city: "Corcelles-près-Payerne" },
  { plz: "1563", city: "Dompierre FR" },
  { plz: "1564", city: "Domdidier" },
  { plz: "1565", city: "Vallon" },
  { plz: "1565", city: "Missy" },
  { plz: "1566", city: "Les Friques" },
  { plz: "1566", city: "St-Aubin FR" },
  { plz: "1567", city: "Delley" },
  { plz: "1568", city: "Portalban" },
  { plz: "1580", city: "Oleyres" },
  { plz: "1580", city: "Avenches" },
  { plz: "1582", city: "Donatyre" },
  { plz: "1583", city: "Villarepos" },
  { plz: "1584", city: "Villars-le-Grand" },
  { plz: "1585", city: "Bellerive VD" },
  { plz: "1585", city: "Cotterd" },
  { plz: "1585", city: "Salavaux" },
  { plz: "1586", city: "Vallamand" },
  { plz: "1587", city: "Montmagny" },
  { plz: "1587", city: "Constantine" },
  { plz: "1588", city: "Cudrefin" },
  { plz: "1589", city: "Chabrey" },
  { plz: "1595", city: "Clavaleyres" },
  { plz: "1595", city: "Faoug" },
  { plz: "1607", city: "Les Tavernes" },
  { plz: "1607", city: "Les Thioleyres" },
  { plz: "1607", city: "Palézieux-Village" },
  { plz: "1607", city: "Palézieux" },
  { plz: "1608", city: "Chapelle (Glâne)" },
  { plz: "1608", city: "Bussigny-sur-Oron" },
  { plz: "1608", city: "Chesalles-sur-Oron" },
  { plz: "1608", city: "Oron-le-Châtel" },
  { plz: "1609", city: "Besencens" },
  { plz: "1609", city: "Fiaugères" },
  { plz: "1609", city: "Le Jordil" },
  { plz: "1609", city: "St-Martin FR" },
  { plz: "1610", city: "Châtillens" },
  { plz: "1610", city: "Vuibroye" },
  { plz: "1610", city: "Oron-la-Ville" },
  { plz: "1611", city: "Le Crêt-près-Semsales" },
  { plz: "1612", city: "Ecoteaux" },
  { plz: "1613", city: "Maracon" },
  { plz: "1614", city: "Granges (Veveyse)" },
  { plz: "1615", city: "Bossonnens" },
  { plz: "1616", city: "Attalens" },
  { plz: "1617", city: "Tatroz" },
  { plz: "1617", city: "Remaufens" },
  { plz: "1618", city: "Châtel-St-Denis" },
  { plz: "1619", city: "Les Paccots" },
  { plz: "1623", city: "Semsales" },
  { plz: "1624", city: "La Verrerie" },
  { plz: "1624", city: "Grattavache" },
  { plz: "1624", city: "Progens" },
  { plz: "1625", city: "Maules" },
  { plz: "1625", city: "Sâles (Gruyère)" },
  { plz: "1626", city: "Romanens" },
  { plz: "1626", city: "Treyfayes" },
  { plz: "1626", city: "Rueyres-Treyfayes" },
  { plz: "1627", city: "Vaulruz" },
  { plz: "1628", city: "Vuadens" },
  { plz: "1630", city: "Bulle" },
  { plz: "1632", city: "Riaz" },
  { plz: "1633", city: "Vuippens" },
  { plz: "1633", city: "Marsens" },
  { plz: "1634", city: "La Roche FR" },
  { plz: "1635", city: "La Tour-de-Trême" },
  { plz: "1636", city: "Broc" },
  { plz: "1637", city: "Charmey (Gruyère)" },
  { plz: "1638", city: "Morlon" },
  { plz: "1642", city: "Sorens" },
  { plz: "1643", city: "Gumefens" },
  { plz: "1644", city: "Avry-devant-Pont" },
  { plz: "1645", city: "Le Bry" },
  { plz: "1646", city: "Echarlens" },
  { plz: "1647", city: "Corbières" },
  { plz: "1648", city: "Hauteville" },
  { plz: "1649", city: "Pont-la-Ville" },
  { plz: "1651", city: "Villarvolard" },
  { plz: "1652", city: "Botterens" },
  { plz: "1652", city: "Villarbeney" },
  { plz: "1653", city: "Châtel-sur-Montsalvens" },
  { plz: "1653", city: "Crésuz" },
  { plz: "1654", city: "Cerniat FR" },
  { plz: "1656", city: "Im Fang" },
  { plz: "1656", city: "Jaun" },
  { plz: "1657", city: "Abländschen" },
  { plz: "1658", city: "La Tine" },
  { plz: "1658", city: "Rossinière" },
  { plz: "1659", city: "Flendruz" },
  { plz: "1659", city: "Rougemont" },
  { plz: "1660", city: "Les Moulins" },
  { plz: "1660", city: "L'Etivaz" },
  { plz: "1660", city: "Château-d'Oex" },
  { plz: "1660", city: "La Lécherette" },
  { plz: "1661", city: "Le Pâquier-Montbarry" },
  { plz: "1663", city: "Moléson-sur-Gruyères" },
  { plz: "1663", city: "Pringy" },
  { plz: "1663", city: "Gruyères" },
  { plz: "1663", city: "Epagny" },
  { plz: "1665", city: "Estavannens" },
  { plz: "1666", city: "Villars-sous-Mont" },
  { plz: "1666", city: "Grandvillard" },
  { plz: "1667", city: "Enney" },
  { plz: "1669", city: "Neirivue" },
  { plz: "1669", city: "Les Sciernes-d'Albeuve" },
  { plz: "1669", city: "Montbovon" },
  { plz: "1669", city: "Lessoc" },
  { plz: "1669", city: "Albeuve" },
  { plz: "1670", city: "Bionnens" },
  { plz: "1670", city: "Esmonts" },
  { plz: "1670", city: "Ursy" },
  { plz: "1673", city: "Auboranges" },
  { plz: "1673", city: "Ecublens FR" },
  { plz: "1673", city: "Rue" },
  { plz: "1673", city: "Gillarens" },
  { plz: "1673", city: "Promasens" },
  { plz: "1674", city: "Vuarmarens" },
  { plz: "1674", city: "Montet (Glâne)" },
  { plz: "1674", city: "Morlens" },
  { plz: "1675", city: "Vauderens" },
  { plz: "1675", city: "Blessens" },
  { plz: "1675", city: "Mossel" },
  { plz: "1676", city: "Chavannes-les-Forts" },
  { plz: "1677", city: "Prez-vers-Siviriez" },
  { plz: "1678", city: "Siviriez" },
  { plz: "1679", city: "Villaraboud" },
  { plz: "1680", city: "Berlens" },
  { plz: "1680", city: "Romont FR" },
  { plz: "1681", city: "Billens" },
  { plz: "1681", city: "Hennens" },
  { plz: "1682", city: "Villars-Bramard" },
  { plz: "1682", city: "Dompierre VD" },
  { plz: "1682", city: "Lovatens" },
  { plz: "1682", city: "Cerniaz VD" },
  { plz: "1682", city: "Prévonloup" },
  { plz: "1683", city: "Brenles" },
  { plz: "1683", city: "Chesalles-sur-Moudon" },
  { plz: "1683", city: "Sarzens" },
  { plz: "1684", city: "Mézières FR" },
  { plz: "1685", city: "Villariaz" },
  { plz: "1686", city: "Grangettes-près-Romont" },
  { plz: "1686", city: "La Neirigue" },
  { plz: "1687", city: "Estévenens" },
  { plz: "1687", city: "La Magne" },
  { plz: "1687", city: "Vuisternens-devant-Romont" },
  { plz: "1688", city: "Sommentier" },
  { plz: "1688", city: "Lieffrens" },
  { plz: "1689", city: "Le Châtelard-près-Romont" },
  { plz: "1690", city: "Lussy FR" },
  { plz: "1690", city: "Villaz-St-Pierre" },
  { plz: "1691", city: "Villarimboud" },
  { plz: "1692", city: "Massonnens" },
  { plz: "1694", city: "Orsonnens" },
  { plz: "1694", city: "Chavannes-sous-Orsonnens" },
  { plz: "1694", city: "Villargiroud" },
  { plz: "1694", city: "Villarsiviriaux" },
  { plz: "1695", city: "Villarlod" },
  { plz: "1695", city: "Estavayer-le-Gibloux" },
  { plz: "1695", city: "Rueyres-St-Laurent" },
  { plz: "1695", city: "Villarsel-le-Gibloux" },
  { plz: "1696", city: "Vuisternens-en-Ogoz" },
  { plz: "1697", city: "La Joux FR" },
  { plz: "1697", city: "Les Ecasseys" },
  { plz: "1699", city: "Bouloz" },
  { plz: "1699", city: "Pont (Veveyse)" },
  { plz: "1699", city: "Porsel" },
  { plz: "1700", city: "Fribourg" },
  { plz: "1708", city: "Fribourg" },
  { plz: "1712", city: "Tafers" },
  { plz: "1713", city: "St. Antoni" },
  { plz: "1714", city: "Heitenried" },
  { plz: "1715", city: "Alterswil FR" },
  { plz: "1716", city: "Oberschrot" },
  { plz: "1716", city: "Schwarzsee" },
  { plz: "1716", city: "Plaffeien" },
  { plz: "1717", city: "St. Ursen" },
  { plz: "1718", city: "Rechthalten" },
  { plz: "1719", city: "Zumholz" },
  { plz: "1719", city: "Brünisried" },
  { plz: "1720", city: "Chésopelloz" },
  { plz: "1720", city: "Corminboeuf" },
  { plz: "1721", city: "Cormérod" },
  { plz: "1721", city: "Courtion" },
  { plz: "1721", city: "Misery" },
  { plz: "1722", city: "Bourguillon" },
  { plz: "1723", city: "Marly" },
  { plz: "1723", city: "Pierrafortscha" },
  { plz: "1723", city: "Villarsel-sur-Marly" },
  { plz: "1724", city: "Bonnefontaine" },
  { plz: "1724", city: "Essert FR" },
  { plz: "1724", city: "Ferpicloz" },
  { plz: "1724", city: "Montévraz" },
  { plz: "1724", city: "Oberried FR" },
  { plz: "1724", city: "Senèdes" },
  { plz: "1724", city: "Zénauva" },
  { plz: "1724", city: "Le Mouret" },
  { plz: "1725", city: "Posieux" },
  { plz: "1726", city: "Farvagny-le-Petit" },
  { plz: "1726", city: "Grenilles" },
  { plz: "1726", city: "Posat" },
  { plz: "1726", city: "Farvagny" },
  { plz: "1727", city: "Magnedens" },
  { plz: "1727", city: "Corpataux-Magnedens" },
  { plz: "1728", city: "Rossens FR" },
  { plz: "1730", city: "Ecuvillens" },
  { plz: "1731", city: "Ependes FR" },
  { plz: "1732", city: "Arconciel" },
  { plz: "1733", city: "Treyvaux" },
  { plz: "1734", city: "Tentlingen" },
  { plz: "1735", city: "Giffers" },
  { plz: "1736", city: "St. Silvester" },
  { plz: "1737", city: "Plasselb" },
  { plz: "1738", city: "Sangernboden" },
  { plz: "1740", city: "Neyruz FR" },
  { plz: "1741", city: "Cottens FR" },
  { plz: "1742", city: "Autigny" },
  { plz: "1744", city: "Chénens" },
  { plz: "1745", city: "Lentigny" },
  { plz: "1746", city: "Prez-vers-Noréaz" },
  { plz: "1747", city: "Corserey" },
  { plz: "1748", city: "Torny-le-Grand" },
  { plz: "1749", city: "Middes" },
  { plz: "1752", city: "Villars-sur-Glâne" },
  { plz: "1753", city: "Matran" },
  { plz: "1754", city: "Rosé" },
  { plz: "1754", city: "Corjolens" },
  { plz: "1754", city: "Avry-sur-Matran" },
  { plz: "1754", city: "Avry-Centre FR" },
  { plz: "1756", city: "Onnens FR" },
  { plz: "1756", city: "Lovens" },
  { plz: "1757", city: "Noréaz" },
  { plz: "1762", city: "Givisiez" },
  { plz: "1763", city: "Granges-Paccot" },
  { plz: "1772", city: "Ponthaux" },
  { plz: "1772", city: "Nierlet-les-Bois" },
  { plz: "1772", city: "Grolley" },
  { plz: "1773", city: "Léchelles" },
  { plz: "1773", city: "Chandon" },
  { plz: "1773", city: "Russy" },
  { plz: "1774", city: "Montagny-les-Monts" },
  { plz: "1774", city: "Cousset" },
  { plz: "1775", city: "Mannens" },
  { plz: "1775", city: "Grandsivaz" },
  { plz: "1776", city: "Montagny-la-Ville" },
  { plz: "1782", city: "Autafond" },
  { plz: "1782", city: "La Corbaz" },
  { plz: "1782", city: "Cormagens" },
  { plz: "1782", city: "Lossy" },
  { plz: "1782", city: "Formangueires" },
  { plz: "1782", city: "Belfaux" },
  { plz: "1783", city: "Pensier" },
  { plz: "1783", city: "Barberêche" },
  { plz: "1784", city: "Cournillens" },
  { plz: "1784", city: "Wallenried" },
  { plz: "1784", city: "Courtepin" },
  { plz: "1785", city: "Cressier FR" },
  { plz: "1786", city: "Sugiez" },
  { plz: "1787", city: "Môtier (Vully)" },
  { plz: "1787", city: "Mur (Vully) FR" },
  { plz: "1787", city: "Mur (Vully) VD" },
  { plz: "1788", city: "Praz (Vully)" },
  { plz: "1789", city: "Lugnorre" },
  { plz: "1791", city: "Courtaman" },
  { plz: "1792", city: "Guschelmuth" },
  { plz: "1792", city: "Cordast" },
  { plz: "1793", city: "Jeuss" },
  { plz: "1794", city: "Salvenach" },
  { plz: "1795", city: "Courlevon" },
  { plz: "1796", city: "Courgevaux" },
  { plz: "1797", city: "Münchenwiler" },
  { plz: "1800", city: "Vevey" },
  { plz: "1801", city: "Le Mont-Pèlerin" },
  { plz: "1802", city: "Corseaux" },
  { plz: "1803", city: "Chardonne" },
  { plz: "1804", city: "Corsier-sur-Vevey" },
  { plz: "1805", city: "Jongny" },
  { plz: "1806", city: "St-Légier-La Chiésaz" },
  { plz: "1807", city: "Blonay" },
  { plz: "1808", city: "Les Monts-de-Corsier" },
  { plz: "1809", city: "Fenil-sur-Corsier" },
  { plz: "1814", city: "La Tour-de-Peilz" },
  { plz: "1815", city: "Clarens" },
  { plz: "1816", city: "Chailly-Montreux" },
  { plz: "1817", city: "Brent" },
  { plz: "1820", city: "Montreux" },
  { plz: "1820", city: "Veytaux" },
  { plz: "1820", city: "Territet" },
  { plz: "1822", city: "Chernex" },
  { plz: "1823", city: "Glion" },
  { plz: "1824", city: "Caux" },
  { plz: "1832", city: "Villard-sur-Chamby" },
  { plz: "1832", city: "Chamby" },
  { plz: "1833", city: "Les Avants" },
  { plz: "1844", city: "Villeneuve VD" },
  { plz: "1845", city: "Noville" },
  { plz: "1846", city: "Chessel" },
  { plz: "1847", city: "Rennaz" },
  { plz: "1852", city: "Roche VD" },
  { plz: "1853", city: "Yvorne" },
  { plz: "1854", city: "Leysin" },
  { plz: "1856", city: "Corbeyrier" },
  { plz: "1860", city: "Aigle" },
  { plz: "1862", city: "La Comballaz" },
  { plz: "1862", city: "Les Mosses" },
  { plz: "1863", city: "Le Sépey" },
  { plz: "1864", city: "Vers-l'Eglise" },
  { plz: "1865", city: "Les Diablerets" },
  { plz: "1866", city: "La Forclaz VD" },
  { plz: "1867", city: "St-Triphon" },
  { plz: "1867", city: "Panex" },
  { plz: "1867", city: "Ollon VD" },
  { plz: "1868", city: "Collombey" },
  { plz: "1869", city: "Massongex" },
  { plz: "1870", city: "Monthey" },
  { plz: "1871", city: "Choëx" },
  { plz: "1871", city: "Les Giettes" },
  { plz: "1872", city: "Troistorrents" },
  { plz: "1873", city: "Champoussin" },
  { plz: "1873", city: "Les Crosets" },
  { plz: "1873", city: "Val-d'Illiez" },
  { plz: "1874", city: "Champéry" },
  { plz: "1875", city: "Morgins" },
  { plz: "1880", city: "Fenalet-sur-Bex" },
  { plz: "1880", city: "Frenières-sur-Bex" },
  { plz: "1880", city: "Les Plans-sur-Bex" },
  { plz: "1880", city: "Bex" },
  { plz: "1882", city: "Les Posses-sur-Bex" },
  { plz: "1882", city: "Gryon" },
  { plz: "1884", city: "Huémoz" },
  { plz: "1884", city: "Arveyes" },
  { plz: "1884", city: "Villars-sur-Ollon" },
  { plz: "1885", city: "Chesières" },
  { plz: "1890", city: "Mex VS" },
  { plz: "1890", city: "St-Maurice" },
  { plz: "1891", city: "Vérossaz" },
  { plz: "1892", city: "Lavey-les-Bains" },
  { plz: "1892", city: "Morcles" },
  { plz: "1892", city: "Lavey-Village" },
  { plz: "1893", city: "Muraz (Collombey)" },
  { plz: "1895", city: "Vionnaz" },
  { plz: "1896", city: "Miex" },
  { plz: "1896", city: "Vouvry" },
  { plz: "1897", city: "Les Evouettes" },
  { plz: "1897", city: "Bouveret" },
  { plz: "1898", city: "St-Gingolph" },
  { plz: "1899", city: "Torgon" },
  { plz: "1902", city: "Evionnaz" },
  { plz: "1903", city: "Collonges" },
  { plz: "1904", city: "Vernayaz" },
  { plz: "1905", city: "Dorénaz" },
  { plz: "1906", city: "Charrat" },
  { plz: "1907", city: "Saxon" },
  { plz: "1908", city: "Riddes" },
  { plz: "1911", city: "Mayens-de-Chamoson" },
  { plz: "1911", city: "Ovronnaz" },
  { plz: "1912", city: "Produit (Leytron)" },
  { plz: "1912", city: "Montagnon (Leytron)" },
  { plz: "1912", city: "Dugny (Leytron)" },
  { plz: "1912", city: "Leytron" },
  { plz: "1913", city: "Saillon" },
  { plz: "1914", city: "Auddes-sur-Riddes" },
  { plz: "1914", city: "Isérables" },
  { plz: "1918", city: "La Tzoumaz" },
  { plz: "1920", city: "Martigny" },
  { plz: "1921", city: "Martigny-Croix" },
  { plz: "1922", city: "Les Granges (Salvan)" },
  { plz: "1922", city: "Salvan" },
  { plz: "1923", city: "Le Trétien" },
  { plz: "1923", city: "Les Marécottes" },
  { plz: "1925", city: "Le Châtelard VS" },
  { plz: "1925", city: "Finhaut" },
  { plz: "1926", city: "Fully" },
  { plz: "1927", city: "Chemin" },
  { plz: "1928", city: "Ravoire" },
  { plz: "1929", city: "Trient" },
  { plz: "1932", city: "Les Valettes (Bovernier)" },
  { plz: "1932", city: "Bovernier" },
  { plz: "1933", city: "Vens (Sembrancher)" },
  { plz: "1933", city: "Chamoille (Sembrancher)" },
  { plz: "1933", city: "La Garde (Sembrancher)" },
  { plz: "1933", city: "Sembrancher" },
  { plz: "1934", city: "Bruson" },
  { plz: "1934", city: "Cotterg (Le Châble VS)" },
  { plz: "1934", city: "Villette (Le Châble VS)" },
  { plz: "1934", city: "Fontenelle (Le Châble VS)" },
  { plz: "1934", city: "Montagnier (Le Châble VS)" },
  { plz: "1934", city: "Le Châble VS" },
  { plz: "1936", city: "Verbier" },
  { plz: "1937", city: "Orsières" },
  { plz: "1938", city: "Champex-Lac" },
  { plz: "1941", city: "Cries (Vollèges)" },
  { plz: "1941", city: "Vollèges" },
  { plz: "1942", city: "Levron" },
  { plz: "1943", city: "Praz-de-Fort" },
  { plz: "1944", city: "La Fouly VS" },
  { plz: "1945", city: "Liddes" },
  { plz: "1945", city: "Fontaine Dessus (Liddes)" },
  { plz: "1945", city: "Fontaine Dessous (Liddes)" },
  { plz: "1945", city: "Dranse (Liddes)" },
  { plz: "1945", city: "Chandonne (Liddes)" },
  { plz: "1945", city: "Rive Haute (Liddes)" },
  { plz: "1945", city: "Fornex (Liddes)" },
  { plz: "1945", city: "Les Moulins VS (Liddes)" },
  { plz: "1945", city: "Vichères (Liddes)" },
  { plz: "1945", city: "Palasuit (Liddes)" },
  { plz: "1945", city: "Chez Petit (Liddes)" },
  { plz: "1945", city: "Petit Vichères (Liddes)" },
  { plz: "1946", city: "Bourg-St-Pierre" },
  { plz: "1947", city: "Prarreyer (Versegères)" },
  { plz: "1947", city: "Les Places (Versegères)" },
  { plz: "1947", city: "La Montoz (Versegères)" },
  { plz: "1947", city: "Champsec (Versegères)" },
  { plz: "1947", city: "Le Fregnoley (Versegères)" },
  { plz: "1947", city: "Versegères" },
  { plz: "1948", city: "Lourtier" },
  { plz: "1948", city: "Fionnay" },
  { plz: "1948", city: "Sarreyer" },
  { plz: "1948", city: "Le Planchamp (Lourtier)" },
  { plz: "1948", city: "Le Morgnes (Lourtier)" },
  { plz: "1950", city: "Sion" },
  { plz: "1955", city: "St-Pierre-de-Clages" },
  { plz: "1955", city: "Les Vérines (Chamoson)" },
  { plz: "1955", city: "Némiaz (Chamoson)" },
  { plz: "1955", city: "Grugnay (Chamoson)" },
  { plz: "1955", city: "Chamoson" },
  { plz: "1957", city: "Ardon" },
  { plz: "1958", city: "Uvrier" },
  { plz: "1958", city: "St-Léonard" },
  { plz: "1961", city: "Vernamiège" },
  { plz: "1962", city: "Pont-de-la-Morge (Sion)" },
  { plz: "1963", city: "Vétroz" },
  { plz: "1964", city: "Conthey" },
  { plz: "1965", city: "Chandolin-près-Savièse" },
  { plz: "1965", city: "Drône VS" },
  { plz: "1965", city: "Roumaz (Savièse)" },
  { plz: "1965", city: "Granois (Savièse)" },
  { plz: "1965", city: "St-Germain (Savièse)" },
  { plz: "1965", city: "Ormône (Savièse)" },
  { plz: "1965", city: "Mayens-de-la-Zour (Savièse)" },
  { plz: "1965", city: "Monteiller-Savièse" },
  { plz: "1965", city: "Savièse" },
  { plz: "1966", city: "Signèse (Ayent)" },
  { plz: "1966", city: "Fortunau (Ayent)" },
  { plz: "1966", city: "Luc (Ayent)" },
  { plz: "1966", city: "St-Romain (Ayent)" },
  { plz: "1966", city: "Saxonne (Ayent)" },
  { plz: "1966", city: "Villa (Ayent)" },
  { plz: "1966", city: "La Place (Ayent)" },
  { plz: "1966", city: "Botyre (Ayent)" },
  { plz: "1966", city: "Blignoud (Ayent)" },
  { plz: "1966", city: "Argnoud (Ayent)" },
  { plz: "1967", city: "Bramois" },
  { plz: "1968", city: "Mase" },
  { plz: "1969", city: "Liez (St-Martin)" },
  { plz: "1969", city: "Trogne (St-Martin)" },
  { plz: "1969", city: "Suen (St-Martin)" },
  { plz: "1969", city: "Eison (St.Martin)" },
  { plz: "1969", city: "St-Martin VS" },
  { plz: "1971", city: "Champlan (Grimisuat)" },
  { plz: "1971", city: "Coméraz (Grimisuat)" },
  { plz: "1971", city: "Grimisuat" },
  { plz: "1972", city: "Anzère" },
  { plz: "1973", city: "Nax" },
  { plz: "1974", city: "Arbaz" },
  { plz: "1975", city: "St-Séverin" },
  { plz: "1976", city: "Aven" },
  { plz: "1976", city: "Daillon" },
  { plz: "1976", city: "Erde" },
  { plz: "1977", city: "Icogne" },
  { plz: "1978", city: "Lens" },
  { plz: "1981", city: "Vex" },
  { plz: "1982", city: "Euseigne" },
  { plz: "1983", city: "Lana (Evolène)" },
  { plz: "1983", city: "Evolène" },
  { plz: "1984", city: "Les Haudères" },
  { plz: "1985", city: "La Sage" },
  { plz: "1986", city: "Arolla" },
  { plz: "1987", city: "Mâche" },
  { plz: "1987", city: "Hérémence" },
  { plz: "1988", city: "Les Collons" },
  { plz: "1988", city: "Thyon" },
  { plz: "1991", city: "Arvillard (Salins)" },
  { plz: "1991", city: "Pravidondaz (Salins)" },
  { plz: "1991", city: "Turin (Salins)" },
  { plz: "1991", city: "Misériez (Salins)" },
  { plz: "1991", city: "Salins" },
  { plz: "1992", city: "Les Agettes" },
  { plz: "1992", city: "Les Mayens-de-Sion" },
  { plz: "1992", city: "La Vernaz (Les Agettes)" },
  { plz: "1992", city: "Crête-à-l'Oeil(Les Agettes)" },
  { plz: "1993", city: "Clèbes (Nendaz)" },
  { plz: "1993", city: "Veysonnaz" },
  { plz: "1994", city: "Aproz (Nendaz)" },
  { plz: "1996", city: "Fey (Nendaz)" },
  { plz: "1996", city: "Beuson (Nendaz)" },
  { plz: "1996", city: "Baar (Nendaz)" },
  { plz: "1996", city: "Brignon (Nendaz)" },
  { plz: "1996", city: "Bioley-de-Brignon (Nendaz)" },
  { plz: "1996", city: "Bieudron (Nendaz)" },
  { plz: "1996", city: "Condémines (Nendaz)" },
  { plz: "1996", city: "Saclentz (Nendaz)" },
  { plz: "1996", city: "Basse-Nendaz" },
  { plz: "1997", city: "Siviez (Nendaz)" },
  { plz: "1997", city: "Haute-Nendaz" },
  { plz: "2000", city: "Neuchâtel" },
  { plz: "2012", city: "Auvernier" },
  { plz: "2013", city: "Colombier NE" },
  { plz: "2014", city: "Bôle" },
  { plz: "2015", city: "Areuse" },
  { plz: "2016", city: "Cortaillod" },
  { plz: "2017", city: "Boudry" },
  { plz: "2019", city: "Chambrelien" },
  { plz: "2019", city: "Rochefort" },
  { plz: "2022", city: "Bevaix" },
  { plz: "2023", city: "Gorgier" },
  { plz: "2024", city: "St-Aubin-Sauges" },
  { plz: "2025", city: "Chez-le-Bart" },
  { plz: "2027", city: "Montalchez" },
  { plz: "2027", city: "Fresens" },
  { plz: "2028", city: "Vaumarcus" },
  { plz: "2034", city: "Peseux" },
  { plz: "2035", city: "Corcelles NE" },
  { plz: "2036", city: "Cormondrèche" },
  { plz: "2037", city: "Montmollin" },
  { plz: "2037", city: "Montezillon" },
  { plz: "2042", city: "Valangin" },
  { plz: "2043", city: "Boudevilliers" },
  { plz: "2046", city: "Fontaines NE" },
  { plz: "2052", city: "La Vue-des-Alpes" },
  { plz: "2052", city: "Fontainemelon" },
  { plz: "2053", city: "Cernier" },
  { plz: "2054", city: "Les Vieux-Prés" },
  { plz: "2054", city: "Chézard-St-Martin" },
  { plz: "2056", city: "Dombresson" },
  { plz: "2057", city: "Villiers" },
  { plz: "2058", city: "Le Pâquier NE" },
  { plz: "2063", city: "Engollon" },
  { plz: "2063", city: "Fenin" },
  { plz: "2063", city: "Saules" },
  { plz: "2063", city: "Vilars NE" },
  { plz: "2065", city: "Savagnier" },
  { plz: "2067", city: "Chaumont" },
  { plz: "2068", city: "Hauterive NE" },
  { plz: "2072", city: "St-Blaise" },
  { plz: "2073", city: "Enges" },
  { plz: "2074", city: "Marin-Epagnier" },
  { plz: "2075", city: "Thielle" },
  { plz: "2075", city: "Wavre" },
  { plz: "2087", city: "Cornaux NE" },
  { plz: "2088", city: "Cressier NE" },
  { plz: "2103", city: "Noiraigue" },
  { plz: "2105", city: "Travers" },
  { plz: "2108", city: "Couvet" },
  { plz: "2112", city: "Môtiers NE" },
  { plz: "2113", city: "Boveresse" },
  { plz: "2114", city: "Fleurier" },
  { plz: "2115", city: "Buttes" },
  { plz: "2116", city: "Mont-de-Buttes" },
  { plz: "2117", city: "La Côte-aux-Fées" },
  { plz: "2123", city: "St-Sulpice NE" },
  { plz: "2124", city: "Les Sagnettes" },
  { plz: "2126", city: "Les Verrières" },
  { plz: "2127", city: "Les Bayards" },
  { plz: "2149", city: "Champ-du-Moulin" },
  { plz: "2149", city: "Fretereules" },
  { plz: "2149", city: "Brot-Dessous" },
  { plz: "2206", city: "Les Geneveys-sur-Coffrane" },
  { plz: "2207", city: "Coffrane" },
  { plz: "2208", city: "Les Hauts-Geneveys" },
  { plz: "2300", city: "La Chaux-de-Fonds" },
  { plz: "2300", city: "La Cibourg" },
  { plz: "2314", city: "La Sagne NE" },
  { plz: "2316", city: "Petit-Martel" },
  { plz: "2316", city: "Les Ponts-de-Martel" },
  { plz: "2318", city: "Brot-Plamboz" },
  { plz: "2322", city: "Le Crêt-du-Locle" },
  { plz: "2325", city: "Les Planchettes" },
  { plz: "2325", city: "Les Joux-Derrière" },
  { plz: "2333", city: "La Cibourg" },
  { plz: "2333", city: "La Ferrière" },
  { plz: "2336", city: "Les Bois" },
  { plz: "2338", city: "Les Emibois" },
  { plz: "2338", city: "Muriaux" },
  { plz: "2340", city: "Le Noirmont" },
  { plz: "2345", city: "Le Cerneux-Veusil" },
  { plz: "2345", city: "La Chaux-des-Breuleux" },
  { plz: "2345", city: "Les Breuleux" },
  { plz: "2350", city: "Saignelégier" },
  { plz: "2353", city: "Les Pommerats" },
  { plz: "2354", city: "Goumois" },
  { plz: "2360", city: "Le Bémont" },
  { plz: "2362", city: "Montfavergier" },
  { plz: "2362", city: "Montfaucon" },
  { plz: "2363", city: "Les Enfers" },
  { plz: "2364", city: "St-Brais" },
  { plz: "2400", city: "Le Prévoux" },
  { plz: "2400", city: "Le Locle" },
  { plz: "2405", city: "La Chaux-du-Milieu" },
  { plz: "2406", city: "Le Brouillet" },
  { plz: "2406", city: "Les Taillères" },
  { plz: "2406", city: "La Châtagne" },
  { plz: "2406", city: "La Brévine" },
  { plz: "2414", city: "Le Cerneux-Péquignot" },
  { plz: "2416", city: "Les Brenets" },
  { plz: "2500", city: "Biel/Bienne" },
  { plz: "2502", city: "Biel/Bienne" },
  { plz: "2503", city: "Biel/Bienne" },
  { plz: "2504", city: "Biel/Bienne" },
  { plz: "2505", city: "Biel/Bienne" },
  { plz: "2512", city: "Tüscherz-Alfermée" },
  { plz: "2513", city: "Twann" },
  { plz: "2514", city: "Ligerz" },
  { plz: "2515", city: "Prêles" },
  { plz: "2516", city: "Lamboing" },
  { plz: "2517", city: "Diesse" },
  { plz: "2518", city: "Nods" },
  { plz: "2520", city: "La Neuveville" },
  { plz: "2523", city: "Lignières" },
  { plz: "2525", city: "Le Landeron" },
  { plz: "2532", city: "Magglingen/Macolin" },
  { plz: "2533", city: "Evilard" },
  { plz: "2534", city: "Les Prés-d'Orvin" },
  { plz: "2534", city: "Orvin" },
  { plz: "2535", city: "Frinvillier" },
  { plz: "2536", city: "Plagne" },
  { plz: "2537", city: "Vauffelin" },
  { plz: "2538", city: "Romont BE" },
  { plz: "2540", city: "Grenchen" },
  { plz: "2542", city: "Pieterlen" },
  { plz: "2543", city: "Lengnau BE" },
  { plz: "2544", city: "Bettlach" },
  { plz: "2545", city: "Selzach" },
  { plz: "2552", city: "Orpund" },
  { plz: "2553", city: "Safnern" },
  { plz: "2554", city: "Meinisberg" },
  { plz: "2555", city: "Brügg BE" },
  { plz: "2556", city: "Scheuren" },
  { plz: "2556", city: "Schwadernau" },
  { plz: "2557", city: "Studen BE" },
  { plz: "2558", city: "Aegerten" },
  { plz: "2560", city: "Nidau" },
  { plz: "2562", city: "Port" },
  { plz: "2563", city: "Ipsach" },
  { plz: "2564", city: "Bellmund" },
  { plz: "2565", city: "Jens" },
  { plz: "2572", city: "Mörigen" },
  { plz: "2572", city: "Sutz" },
  { plz: "2575", city: "Hagneck" },
  { plz: "2575", city: "Gerolfingen" },
  { plz: "2575", city: "Täuffelen" },
  { plz: "2576", city: "Lüscherz" },
  { plz: "2577", city: "Finsterhennen" },
  { plz: "2577", city: "Siselen BE" },
  { plz: "2603", city: "Péry" },
  { plz: "2604", city: "La Heutte" },
  { plz: "2605", city: "Sonceboz-Sombeval" },
  { plz: "2606", city: "Corgémont" },
  { plz: "2607", city: "Cortébert" },
  { plz: "2608", city: "Montagne-de-Courtelary" },
  { plz: "2608", city: "Courtelary" },
  { plz: "2610", city: "Mont-Soleil" },
  { plz: "2610", city: "Mont-Crosin" },
  { plz: "2610", city: "Les Pontins" },
  { plz: "2610", city: "St-Imier" },
  { plz: "2612", city: "Cormoret" },
  { plz: "2613", city: "Villeret" },
  { plz: "2615", city: "Montagne-de-Sonvilier" },
  { plz: "2615", city: "Sonvilier" },
  { plz: "2616", city: "La Cibourg" },
  { plz: "2616", city: "Renan BE" },
  { plz: "2710", city: "Tavannes" },
  { plz: "2712", city: "Le Fuet" },
  { plz: "2713", city: "Bellelay" },
  { plz: "2714", city: "Le Prédame" },
  { plz: "2714", city: "Les Genevez JU" },
  { plz: "2715", city: "Monible" },
  { plz: "2715", city: "Châtelat" },
  { plz: "2716", city: "Sornetan" },
  { plz: "2717", city: "Fornet-Dessous" },
  { plz: "2717", city: "Rebévelier" },
  { plz: "2718", city: "Fornet-Dessus" },
  { plz: "2718", city: "Lajoux JU" },
  { plz: "2720", city: "La Tanne" },
  { plz: "2720", city: "Tramelan" },
  { plz: "2722", city: "Les Reussilles" },
  { plz: "2723", city: "Mont-Tramelan" },
  { plz: "2732", city: "Saicourt" },
  { plz: "2732", city: "Saules BE" },
  { plz: "2732", city: "Loveresse" },
  { plz: "2732", city: "Reconvilier" },
  { plz: "2733", city: "Pontenet" },
  { plz: "2735", city: "Bévilard" },
  { plz: "2735", city: "Champoz" },
  { plz: "2735", city: "Malleray" },
  { plz: "2736", city: "Sorvilier" },
  { plz: "2738", city: "Court" },
  { plz: "2740", city: "Moutier" },
  { plz: "2742", city: "Perrefitte" },
  { plz: "2743", city: "Eschert" },
  { plz: "2744", city: "Belprahon" },
  { plz: "2745", city: "Grandval" },
  { plz: "2746", city: "Crémines" },
  { plz: "2747", city: "Seehof" },
  { plz: "2747", city: "Corcelles BE" },
  { plz: "2748", city: "Souboz" },
  { plz: "2748", city: "Les Ecorcheresses" },
  { plz: "2762", city: "Roches BE" },
  { plz: "2800", city: "Delémont" },
  { plz: "2802", city: "Develier" },
  { plz: "2803", city: "Bourrignon" },
  { plz: "2805", city: "Soyhières" },
  { plz: "2806", city: "Mettembert" },
  { plz: "2807", city: "Lucelle" },
  { plz: "2807", city: "Pleigne" },
  { plz: "2812", city: "Movelier" },
  { plz: "2813", city: "Ederswiler" },
  { plz: "2814", city: "Roggenburg" },
  { plz: "2822", city: "Courroux" },
  { plz: "2823", city: "Courcelon" },
  { plz: "2824", city: "Vicques" },
  { plz: "2825", city: "Courchapoix" },
  { plz: "2826", city: "Corban" },
  { plz: "2827", city: "Schelten" },
  { plz: "2827", city: "Mervelier" },
  { plz: "2828", city: "Montsevelier" },
  { plz: "2829", city: "Vermes" },
  { plz: "2830", city: "Vellerat" },
  { plz: "2830", city: "Courrendlin" },
  { plz: "2832", city: "Rebeuvelier" },
  { plz: "2842", city: "Rossemaison" },
  { plz: "2843", city: "Châtillon JU" },
  { plz: "2852", city: "Courtételle" },
  { plz: "2853", city: "Courfaivre" },
  { plz: "2854", city: "Bassecourt" },
  { plz: "2855", city: "Glovelier" },
  { plz: "2856", city: "Boécourt" },
  { plz: "2857", city: "Montavon" },
  { plz: "2863", city: "Undervelier" },
  { plz: "2864", city: "Soulce" },
  { plz: "2873", city: "Saulcy" },
  { plz: "2874", city: "Montfavergier" },
  { plz: "2874", city: "St-Brais" },
  { plz: "2875", city: "Les Enfers" },
  { plz: "2875", city: "Montfaucon" },
  { plz: "2877", city: "Le Bémont JU" },
  { plz: "2882", city: "St-Ursanne" },
  { plz: "2883", city: "Montmelon" },
  { plz: "2884", city: "Montenol" },
  { plz: "2885", city: "Epauvillers" },
  { plz: "2886", city: "Epiquerez" },
  { plz: "2887", city: "Soubey" },
  { plz: "2888", city: "Seleute" },
  { plz: "2889", city: "Ocourt" },
  { plz: "2900", city: "Porrentruy" },
  { plz: "2902", city: "Fontenais" },
  { plz: "2903", city: "Villars-sur Fontenais" },
  { plz: "2904", city: "Bressaucourt" },
  { plz: "2905", city: "Courtedoux" },
  { plz: "2906", city: "Chevenez" },
  { plz: "2907", city: "Rocourt" },
  { plz: "2908", city: "Grandfontaine" },
  { plz: "2912", city: "Roche-d'Or" },
  { plz: "2912", city: "Réclère" },
  { plz: "2914", city: "Damvant" },
  { plz: "2915", city: "Bure" },
  { plz: "2916", city: "Fahy" },
  { plz: "2922", city: "Courchavon" },
  { plz: "2923", city: "Courtemaîche" },
  { plz: "2924", city: "Montignez" },
  { plz: "2925", city: "Buix" },
  { plz: "2926", city: "Boncourt" },
  { plz: "2932", city: "Coeuve" },
  { plz: "2933", city: "Damphreux" },
  { plz: "2933", city: "Lugnez" },
  { plz: "2935", city: "Beurnevésin" },
  { plz: "2942", city: "Alle" },
  { plz: "2943", city: "Vendlincourt" },
  { plz: "2944", city: "Bonfol" },
  { plz: "2946", city: "Miécourt" },
  { plz: "2947", city: "Charmoille" },
  { plz: "2950", city: "Courtemautruy" },
  { plz: "2950", city: "Courgenay" },
  { plz: "2952", city: "Cornol" },
  { plz: "2953", city: "Fregiécourt" },
  { plz: "2953", city: "Pleujouse" },
  { plz: "2954", city: "Asuel" },
  { plz: "3000", city: "Bern" },
  { plz: "3004", city: "Bern" },
  { plz: "3005", city: "Bern" },
  { plz: "3006", city: "Bern" },
  { plz: "3007", city: "Bern" },
  { plz: "3008", city: "Bern" },
  { plz: "3010", city: "Bern" },
  { plz: "3011", city: "Bern" },
  { plz: "3012", city: "Bern" },
  { plz: "3013", city: "Bern" },
  { plz: "3014", city: "Bern" },
  { plz: "3015", city: "Bern" },
  { plz: "3018", city: "Bern" },
  { plz: "3019", city: "Bern" },
  { plz: "3020", city: "Bern" },
  { plz: "3027", city: "Bern" },
  { plz: "3032", city: "Hinterkappelen" },
  { plz: "3033", city: "Wohlen b. Bern" },
  { plz: "3034", city: "Murzelen" },
  { plz: "3035", city: "Frieswil" },
  { plz: "3036", city: "Detligen" },
  { plz: "3037", city: "Herrenschwanden" },
  { plz: "3038", city: "Kirchlindach" },
  { plz: "3042", city: "Ortschwaben" },
  { plz: "3043", city: "Uettligen" },
  { plz: "3044", city: "Innerberg" },
  { plz: "3045", city: "Meikirch" },
  { plz: "3046", city: "Wahlendorf" },
  { plz: "3047", city: "Bremgarten b. Bern" },
  { plz: "3048", city: "Worblaufen" },
  { plz: "3049", city: "Säriswil" },
  { plz: "3052", city: "Zollikofen" },
  { plz: "3053", city: "Deisswil b. Münchenbuchsee" },
  { plz: "3053", city: "Diemerswil" },
  { plz: "3053", city: "Wiggiswil" },
  { plz: "3053", city: "Lätti" },
  { plz: "3053", city: "Münchenbuchsee" },
  { plz: "3054", city: "Schüpfen" },
  { plz: "3063", city: "Ittigen" },
  { plz: "3065", city: "Bolligen" },
  { plz: "3066", city: "Stettlen" },
  { plz: "3067", city: "Boll" },
  { plz: "3068", city: "Utzigen" },
  { plz: "3072", city: "Ostermundigen" },
  { plz: "3073", city: "Gümligen" },
  { plz: "3074", city: "Muri b. Bern" },
  { plz: "3075", city: "Rüfenacht BE" },
  { plz: "3076", city: "Worb" },
  { plz: "3077", city: "Enggistein" },
  { plz: "3078", city: "Richigen" },
  { plz: "3082", city: "Schlosswil" },
  { plz: "3083", city: "Trimstein" },
  { plz: "3084", city: "Wabern" },
  { plz: "3086", city: "Englisberg" },
  { plz: "3086", city: "Zimmerwald" },
  { plz: "3087", city: "Niedermuhlern" },
  { plz: "3088", city: "Oberbütschel" },
  { plz: "3088", city: "Rüeggisberg" },
  { plz: "3089", city: "Hinterfultigen" },
  { plz: "3095", city: "Spiegel b. Bern" },
  { plz: "3096", city: "Oberbalm" },
  { plz: "3097", city: "Liebefeld" },
  { plz: "3098", city: "Köniz" },
  { plz: "3098", city: "Schliern b. Köniz" },
  { plz: "3099", city: "Rüti b. Riggisberg" },
  { plz: "3110", city: "Münsingen" },
  { plz: "3111", city: "Tägertschi" },
  { plz: "3112", city: "Allmendingen b. Bern" },
  { plz: "3113", city: "Rubigen" },
  { plz: "3114", city: "Wichtrach" },
  { plz: "3115", city: "Gerzensee" },
  { plz: "3116", city: "Mühledorf BE" },
  { plz: "3116", city: "Noflen BE" },
  { plz: "3116", city: "Kirchdorf BE" },
  { plz: "3122", city: "Kehrsatz" },
  { plz: "3123", city: "Belp" },
  { plz: "3124", city: "Belpberg" },
  { plz: "3125", city: "Toffen" },
  { plz: "3126", city: "Gelterfingen" },
  { plz: "3126", city: "Kaufdorf" },
  { plz: "3127", city: "Lohnstorf" },
  { plz: "3127", city: "Mühlethurnen" },
  { plz: "3128", city: "Kirchenthurnen" },
  { plz: "3128", city: "Rümligen" },
  { plz: "3132", city: "Riggisberg" },
  { plz: "3144", city: "Gasel" },
  { plz: "3145", city: "Niederscherli" },
  { plz: "3147", city: "Mittelhäusern" },
  { plz: "3148", city: "Lanzenhäusern" },
  { plz: "3150", city: "Schwarzenburg" },
  { plz: "3152", city: "Mamishaus" },
  { plz: "3153", city: "Rüschegg Gambach" },
  { plz: "3154", city: "Rüschegg Heubach" },
  { plz: "3155", city: "Helgisried-Rohrbach" },
  { plz: "3156", city: "Riffenmatt" },
  { plz: "3157", city: "Milken" },
  { plz: "3158", city: "Guggisberg" },
  { plz: "3159", city: "Riedstätt" },
  { plz: "3172", city: "Niederwangen b. Bern" },
  { plz: "3173", city: "Oberwangen b. Bern" },
  { plz: "3174", city: "Thörishaus" },
  { plz: "3175", city: "Flamatt" },
  { plz: "3176", city: "Neuenegg" },
  { plz: "3177", city: "Laupen BE" },
  { plz: "3178", city: "Bösingen" },
  { plz: "3179", city: "Kriechenwil" },
  { plz: "3182", city: "Ueberstorf" },
  { plz: "3183", city: "Albligen" },
  { plz: "3184", city: "Wünnewil" },
  { plz: "3185", city: "Schmitten FR" },
  { plz: "3186", city: "Düdingen" },
  { plz: "3202", city: "Frauenkappelen" },
  { plz: "3203", city: "Mühleberg" },
  { plz: "3204", city: "Rosshäusern" },
  { plz: "3205", city: "Gümmenen" },
  { plz: "3206", city: "Rizenbach" },
  { plz: "3206", city: "Ferenbalm" },
  { plz: "3206", city: "Biberen" },
  { plz: "3206", city: "Gammen" },
  { plz: "3206", city: "Wallenbuch" },
  { plz: "3207", city: "Wileroltigen" },
  { plz: "3207", city: "Golaten" },
  { plz: "3208", city: "Gurbrü" },
  { plz: "3210", city: "Kerzers" },
  { plz: "3212", city: "Kleingurmels" },
  { plz: "3212", city: "Gurmels" },
  { plz: "3213", city: "Liebistorf" },
  { plz: "3213", city: "Kleinbösingen" },
  { plz: "3214", city: "Ulmiz" },
  { plz: "3215", city: "Gempenach" },
  { plz: "3215", city: "Büchslen" },
  { plz: "3215", city: "Lurtigen" },
  { plz: "3216", city: "Agriswil" },
  { plz: "3216", city: "Ried b. Kerzers" },
  { plz: "3225", city: "Müntschemier" },
  { plz: "3226", city: "Treiten" },
  { plz: "3232", city: "Ins" },
  { plz: "3233", city: "Tschugg" },
  { plz: "3234", city: "Vinelz" },
  { plz: "3235", city: "Erlach" },
  { plz: "3236", city: "Gampelen" },
  { plz: "3237", city: "Brüttelen" },
  { plz: "3238", city: "Gals" },
  { plz: "3250", city: "Lyss" },
  { plz: "3251", city: "Ruppoldsried" },
  { plz: "3251", city: "Wengi b. Büren" },
  { plz: "3252", city: "Worben" },
  { plz: "3253", city: "Schnottwil" },
  { plz: "3254", city: "Balm b. Messen" },
  { plz: "3254", city: "Messen" },
  { plz: "3255", city: "Rapperswil BE" },
  { plz: "3256", city: "Dieterswil" },
  { plz: "3256", city: "Bangerten b. Dieterswil" },
  { plz: "3256", city: "Seewil" },
  { plz: "3257", city: "Ammerzwil BE" },
  { plz: "3257", city: "Grossaffoltern" },
  { plz: "3262", city: "Suberg" },
  { plz: "3263", city: "Büetigen" },
  { plz: "3264", city: "Diessbach b. Büren" },
  { plz: "3266", city: "Wiler b. Seedorf" },
  { plz: "3267", city: "Seedorf BE" },
  { plz: "3268", city: "Lobsigen" },
  { plz: "3270", city: "Aarberg" },
  { plz: "3271", city: "Radelfingen b. Aarberg" },
  { plz: "3272", city: "Epsach" },
  { plz: "3272", city: "Walperswil" },
  { plz: "3273", city: "Kappelen" },
  { plz: "3274", city: "Hermrigen" },
  { plz: "3274", city: "Bühl b. Aarberg" },
  { plz: "3274", city: "Merzligen" },
  { plz: "3280", city: "Meyriez" },
  { plz: "3280", city: "Greng" },
  { plz: "3280", city: "Murten" },
  { plz: "3282", city: "Bargen BE" },
  { plz: "3283", city: "Niederried b. Kallnach" },
  { plz: "3283", city: "Kallnach" },
  { plz: "3284", city: "Fräschels" },
  { plz: "3285", city: "Galmiz" },
  { plz: "3286", city: "Muntelier" },
  { plz: "3292", city: "Busswil b. Büren" },
  { plz: "3293", city: "Dotzigen" },
  { plz: "3294", city: "Meienried" },
  { plz: "3294", city: "Büren an der Aare" },
  { plz: "3295", city: "Rüti b. Büren" },
  { plz: "3296", city: "Arch" },
  { plz: "3297", city: "Leuzigen" },
  { plz: "3298", city: "Oberwil b. Büren" },
  { plz: "3302", city: "Moosseedorf" },
  { plz: "3303", city: "Zuzwil BE" },
  { plz: "3303", city: "Ballmoos" },
  { plz: "3303", city: "Münchringen" },
  { plz: "3303", city: "Jegenstorf" },
  { plz: "3305", city: "Iffwil" },
  { plz: "3305", city: "Scheunen" },
  { plz: "3306", city: "Etzelkofen" },
  { plz: "3307", city: "Brunnenthal" },
  { plz: "3308", city: "Grafenried" },
  { plz: "3309", city: "Kernenried" },
  { plz: "3309", city: "Zauggenried" },
  { plz: "3312", city: "Fraubrunnen" },
  { plz: "3313", city: "Büren zum Hof" },
  { plz: "3314", city: "Schalunen" },
  { plz: "3315", city: "Kräiligen" },
  { plz: "3315", city: "Bätterkinden" },
  { plz: "3317", city: "Limpach" },
  { plz: "3317", city: "Mülchi" },
  { plz: "3322", city: "Mattstetten" },
  { plz: "3322", city: "Urtenen-Schönbühl" },
  { plz: "3323", city: "Bäriswil BE" },
  { plz: "3324", city: "Mötschwil" },
  { plz: "3324", city: "Hindelbank" },
  { plz: "3325", city: "Hettiswil b. Hindelbank" },
  { plz: "3326", city: "Krauchthal" },
  { plz: "3360", city: "Herzogenbuchsee" },
  { plz: "3362", city: "Niederönz" },
  { plz: "3363", city: "Oberönz" },
  { plz: "3365", city: "Seeberg" },
  { plz: "3365", city: "Grasswil" },
  { plz: "3366", city: "Bettenhausen" },
  { plz: "3366", city: "Bollodingen" },
  { plz: "3367", city: "Ochlenberg" },
  { plz: "3367", city: "Thörigen" },
  { plz: "3368", city: "Bleienbach" },
  { plz: "3372", city: "Wanzwil" },
  { plz: "3373", city: "Heimenhausen" },
  { plz: "3373", city: "Röthenbach Herzogenbuchsee" },
  { plz: "3374", city: "Wangenried" },
  { plz: "3375", city: "Inkwil" },
  { plz: "3376", city: "Graben" },
  { plz: "3376", city: "Berken" },
  { plz: "3377", city: "Walliswil b. Wangen" },
  { plz: "3380", city: "Walliswil b. Niederbipp" },
  { plz: "3380", city: "Wangen an der Aare" },
  { plz: "3400", city: "Burgdorf" },
  { plz: "3412", city: "Heimiswil" },
  { plz: "3413", city: "Kaltacker" },
  { plz: "3414", city: "Oberburg" },
  { plz: "3415", city: "Schafhausen im Emmental" },
  { plz: "3415", city: "Rüegsauschachen" },
  { plz: "3415", city: "Hasle b. Burgdorf" },
  { plz: "3416", city: "Affoltern im Emmental" },
  { plz: "3417", city: "Rüegsau" },
  { plz: "3418", city: "Rüegsbach" },
  { plz: "3419", city: "Biembach im Emmental" },
  { plz: "3421", city: "Rüti b. Lyssach" },
  { plz: "3421", city: "Lyssach" },
  { plz: "3422", city: "Rüdtligen" },
  { plz: "3422", city: "Alchenflüh" },
  { plz: "3422", city: "Kirchberg BE" },
  { plz: "3423", city: "Ersigen" },
  { plz: "3424", city: "Niederösch" },
  { plz: "3424", city: "Oberösch" },
  { plz: "3425", city: "Willadingen" },
  { plz: "3425", city: "Koppigen" },
  { plz: "3426", city: "Aefligen" },
  { plz: "3427", city: "Utzenstorf" },
  { plz: "3428", city: "Wiler b. Utzenstorf" },
  { plz: "3429", city: "Hellsau" },
  { plz: "3429", city: "Höchstetten" },
  { plz: "3432", city: "Lützelflüh-Goldbach" },
  { plz: "3433", city: "Schwanden im Emmental" },
  { plz: "3434", city: "Obergoldbach" },
  { plz: "3435", city: "Ramsei" },
  { plz: "3436", city: "Zollbrück" },
  { plz: "3437", city: "Rüderswil" },
  { plz: "3438", city: "Lauperswil" },
  { plz: "3439", city: "Ranflüh" },
  { plz: "3452", city: "Grünenmatt" },
  { plz: "3453", city: "Heimisbach" },
  { plz: "3454", city: "Sumiswald" },
  { plz: "3455", city: "Grünen" },
  { plz: "3456", city: "Trachselwald" },
  { plz: "3457", city: "Wasen im Emmental" },
  { plz: "3462", city: "Weier im Emmental" },
  { plz: "3463", city: "Häusernmoos im Emmental" },
  { plz: "3464", city: "Schmidigen-Mühleweg" },
  { plz: "3465", city: "Dürrenroth" },
  { plz: "3472", city: "Rumendingen" },
  { plz: "3472", city: "Wynigen" },
  { plz: "3473", city: "Alchenstorf" },
  { plz: "3474", city: "Rüedisbach" },
  { plz: "3475", city: "Riedtwil" },
  { plz: "3475", city: "Hermiswil" },
  { plz: "3476", city: "Oschwand" },
  { plz: "3503", city: "Gysenstein" },
  { plz: "3504", city: "Niederhünigen" },
  { plz: "3504", city: "Oberhünigen" },
  { plz: "3506", city: "Grosshöchstetten" },
  { plz: "3507", city: "Biglen" },
  { plz: "3508", city: "Arni BE" },
  { plz: "3510", city: "Freimettigen" },
  { plz: "3510", city: "Häutligen" },
  { plz: "3510", city: "Konolfingen" },
  { plz: "3512", city: "Walkringen" },
  { plz: "3513", city: "Bigenthal" },
  { plz: "3531", city: "Oberthal" },
  { plz: "3532", city: "Mirchel" },
  { plz: "3532", city: "Zäziwil" },
  { plz: "3533", city: "Bowil" },
  { plz: "3534", city: "Signau" },
  { plz: "3535", city: "Schüpbach" },
  { plz: "3536", city: "Aeschau" },
  { plz: "3537", city: "Eggiwil" },
  { plz: "3538", city: "Röthenbach im Emmental" },
  { plz: "3543", city: "Emmenmatt" },
  { plz: "3550", city: "Langnau im Emmental" },
  { plz: "3551", city: "Oberfrittenbach" },
  { plz: "3552", city: "Bärau" },
  { plz: "3553", city: "Gohl" },
  { plz: "3555", city: "Trubschachen" },
  { plz: "3556", city: "Trub" },
  { plz: "3557", city: "Fankhaus (Trub)" },
  { plz: "3600", city: "Thun" },
  { plz: "3603", city: "Thun" },
  { plz: "3604", city: "Thun" },
  { plz: "3608", city: "Thun" },
  { plz: "3612", city: "Steffisburg" },
  { plz: "3613", city: "Steffisburg" },
  { plz: "3614", city: "Unterlangenegg" },
  { plz: "3615", city: "Heimenschwand" },
  { plz: "3616", city: "Schwarzenegg" },
  { plz: "3617", city: "Fahrni b. Thun" },
  { plz: "3618", city: "Süderen" },
  { plz: "3619", city: "Eriz" },
  { plz: "3619", city: "Innereriz" },
  { plz: "3622", city: "Homberg b. Thun" },
  { plz: "3623", city: "Teuffenthal b. Thun" },
  { plz: "3623", city: "Horrenbach" },
  { plz: "3623", city: "Buchen BE" },
  { plz: "3624", city: "Schwendibach" },
  { plz: "3624", city: "Goldiwil (Thun)" },
  { plz: "3625", city: "Heiligenschwendi" },
  { plz: "3626", city: "Hünibach" },
  { plz: "3627", city: "Heimberg" },
  { plz: "3628", city: "Kienersrüti" },
  { plz: "3628", city: "Uttigen" },
  { plz: "3629", city: "Oppligen" },
  { plz: "3629", city: "Jaberg" },
  { plz: "3629", city: "Kiesen" },
  { plz: "3631", city: "Höfen b. Thun" },
  { plz: "3632", city: "Niederstocken" },
  { plz: "3632", city: "Oberstocken" },
  { plz: "3633", city: "Amsoldingen" },
  { plz: "3634", city: "Thierachern" },
  { plz: "3635", city: "Uebeschi" },
  { plz: "3636", city: "Längenbühl" },
  { plz: "3636", city: "Forst b. Längenbühl" },
  { plz: "3638", city: "Pohlern" },
  { plz: "3638", city: "Blumenstein" },
  { plz: "3645", city: "Gwatt (Thun)" },
  { plz: "3645", city: "Zwieselberg" },
  { plz: "3646", city: "Einigen" },
  { plz: "3647", city: "Reutigen" },
  { plz: "3652", city: "Hilterfingen" },
  { plz: "3653", city: "Oberhofen am Thunersee" },
  { plz: "3654", city: "Gunten" },
  { plz: "3655", city: "Sigriswil" },
  { plz: "3656", city: "Tschingel ob Gunten" },
  { plz: "3656", city: "Aeschlen ob Gunten" },
  { plz: "3656", city: "Ringoldswil" },
  { plz: "3657", city: "Schwanden (Sigriswil)" },
  { plz: "3658", city: "Merligen" },
  { plz: "3661", city: "Uetendorf" },
  { plz: "3662", city: "Seftigen" },
  { plz: "3663", city: "Gurzelen" },
  { plz: "3664", city: "Burgistein" },
  { plz: "3665", city: "Wattenwil" },
  { plz: "3671", city: "Herbligen" },
  { plz: "3671", city: "Brenzikofen" },
  { plz: "3672", city: "Aeschlen b. Oberdiessbach" },
  { plz: "3672", city: "Oberdiessbach" },
  { plz: "3673", city: "Linden" },
  { plz: "3674", city: "Bleiken b. Oberdiessbach" },
  { plz: "3700", city: "Spiez" },
  { plz: "3702", city: "Hondrich" },
  { plz: "3703", city: "Aeschiried" },
  { plz: "3703", city: "Aeschi b. Spiez" },
  { plz: "3704", city: "Krattigen" },
  { plz: "3705", city: "Faulensee" },
  { plz: "3706", city: "Leissigen" },
  { plz: "3707", city: "Därligen" },
  { plz: "3711", city: "Emdthal" },
  { plz: "3711", city: "Mülenen" },
  { plz: "3713", city: "Reichenbach im Kandertal" },
  { plz: "3714", city: "Wengi b. Frutigen" },
  { plz: "3714", city: "Frutigen" },
  { plz: "3715", city: "Adelboden" },
  { plz: "3716", city: "Kandergrund" },
  { plz: "3717", city: "Blausee-Mitholz" },
  { plz: "3718", city: "Kandersteg" },
  { plz: "3722", city: "Scharnachtal" },
  { plz: "3723", city: "Kiental" },
  { plz: "3724", city: "Ried (Frutigen)" },
  { plz: "3725", city: "Achseten" },
  { plz: "3752", city: "Wimmis" },
  { plz: "3753", city: "Oey" },
  { plz: "3754", city: "Diemtigen" },
  { plz: "3755", city: "Horboden" },
  { plz: "3756", city: "Zwischenflüh" },
  { plz: "3757", city: "Schwenden im Diemtigtal" },
  { plz: "3758", city: "Latterbach" },
  { plz: "3762", city: "Erlenbach im Simmental" },
  { plz: "3763", city: "Därstetten" },
  { plz: "3764", city: "Weissenburg" },
  { plz: "3765", city: "Oberwil im Simmental" },
  { plz: "3766", city: "Boltigen" },
  { plz: "3770", city: "Zweisimmen" },
  { plz: "3771", city: "Blankenburg" },
  { plz: "3772", city: "St. Stephan" },
  { plz: "3773", city: "Matten (St. Stephan)" },
  { plz: "3775", city: "Lenk im Simmental" },
  { plz: "3776", city: "Oeschseite" },
  { plz: "3777", city: "Saanenmöser" },
  { plz: "3778", city: "Schönried" },
  { plz: "3780", city: "Gstaad" },
  { plz: "3781", city: "Turbach" },
  { plz: "3782", city: "Lauenen b. Gstaad" },
  { plz: "3783", city: "Grund b. Gstaad" },
  { plz: "3784", city: "Feutersoey" },
  { plz: "3785", city: "Gsteig b. Gstaad" },
  { plz: "3792", city: "Saanen" },
  { plz: "3800", city: "Sundlauenen" },
  { plz: "3800", city: "Interlaken" },
  { plz: "3800", city: "Unterseen" },
  { plz: "3800", city: "Matten b. Interlaken" },
  { plz: "3801", city: "Jungfraujoch" },
  { plz: "3803", city: "Beatenberg" },
  { plz: "3804", city: "Habkern" },
  { plz: "3805", city: "Goldswil b. Interlaken" },
  { plz: "3806", city: "Bönigen b. Interlaken" },
  { plz: "3807", city: "Iseltwald" },
  { plz: "3812", city: "Wilderswil" },
  { plz: "3813", city: "Saxeten" },
  { plz: "3814", city: "Gsteigwiler" },
  { plz: "3815", city: "Zweilütschinen" },
  { plz: "3815", city: "Gündlischwand" },
  { plz: "3816", city: "Lütschental" },
  { plz: "3816", city: "Burglauenen" },
  { plz: "3818", city: "Grindelwald" },
  { plz: "3822", city: "Isenfluh" },
  { plz: "3822", city: "Lauterbrunnen" },
  { plz: "3823", city: "Kleine Scheidegg" },
  { plz: "3823", city: "Eigergletscher" },
  { plz: "3823", city: "Wengen" },
  { plz: "3824", city: "Stechelberg" },
  { plz: "3825", city: "Mürren" },
  { plz: "3826", city: "Gimmelwald" },
  { plz: "3852", city: "Ringgenberg BE" },
  { plz: "3853", city: "Niederried b. Interlaken" },
  { plz: "3854", city: "Oberried am Brienzersee" },
  { plz: "3855", city: "Schwanden b. Brienz" },
  { plz: "3855", city: "Brienz BE" },
  { plz: "3855", city: "Axalp" },
  { plz: "3856", city: "Brienzwiler" },
  { plz: "3857", city: "Unterbach BE" },
  { plz: "3858", city: "Hofstetten b. Brienz" },
  { plz: "3860", city: "Rosenlaui" },
  { plz: "3860", city: "Schattenhalb" },
  { plz: "3860", city: "Meiringen" },
  { plz: "3860", city: "Brünig" },
  { plz: "3862", city: "Innertkirchen" },
  { plz: "3863", city: "Gadmen" },
  { plz: "3864", city: "Guttannen" },
  { plz: "3900", city: "Gamsen" },
  { plz: "3900", city: "Brigerbad" },
  { plz: "3900", city: "Brig" },
  { plz: "3901", city: "Rothwald" },
  { plz: "3902", city: "Glis" },
  { plz: "3903", city: "Birgisch" },
  { plz: "3903", city: "Mund" },
  { plz: "3904", city: "Naters" },
  { plz: "3905", city: "Saas-Almagell" },
  { plz: "3906", city: "Saas-Fee" },
  { plz: "3907", city: "Simplon Hospiz" },
  { plz: "3907", city: "Gabi (Simplon)" },
  { plz: "3907", city: "Gondo" },
  { plz: "3907", city: "Simplon Dorf" },
  { plz: "3908", city: "Saas-Balen" },
  { plz: "3910", city: "Saas-Grund" },
  { plz: "3911", city: "Ried-Brig" },
  { plz: "3912", city: "Termen" },
  { plz: "3913", city: "Rosswald" },
  { plz: "3914", city: "Belalp" },
  { plz: "3914", city: "Blatten b. Naters" },
  { plz: "3916", city: "Ferden" },
  { plz: "3917", city: "Goppenstein" },
  { plz: "3917", city: "Kippel" },
  { plz: "3918", city: "Wiler (Lötschen)" },
  { plz: "3919", city: "Blatten (Lötschen)" },
  { plz: "3920", city: "Zermatt" },
  { plz: "3922", city: "Eisten" },
  { plz: "3922", city: "Kalpetran" },
  { plz: "3922", city: "Stalden VS" },
  { plz: "3923", city: "Törbel" },
  { plz: "3924", city: "Gasenried" },
  { plz: "3924", city: "St. Niklaus VS" },
  { plz: "3925", city: "Grächen" },
  { plz: "3926", city: "Embd" },
  { plz: "3927", city: "Herbriggen" },
  { plz: "3928", city: "Randa" },
  { plz: "3929", city: "Täsch" },
  { plz: "3930", city: "Eyholz" },
  { plz: "3930", city: "Visp" },
  { plz: "3931", city: "Lalden" },
  { plz: "3932", city: "Visperterminen" },
  { plz: "3933", city: "Staldenried" },
  { plz: "3934", city: "Zeneggen" },
  { plz: "3935", city: "Bürchen" },
  { plz: "3937", city: "Baltschieder" },
  { plz: "3938", city: "Ausserberg" },
  { plz: "3939", city: "Eggerberg" },
  { plz: "3940", city: "Steg VS" },
  { plz: "3942", city: "Niedergesteln" },
  { plz: "3942", city: "St. German" },
  { plz: "3942", city: "Raron" },
  { plz: "3943", city: "Eischoll" },
  { plz: "3944", city: "Unterbäch VS" },
  { plz: "3945", city: "Gampel" },
  { plz: "3945", city: "Niedergampel" },
  { plz: "3946", city: "Gruben" },
  { plz: "3946", city: "Turtmann" },
  { plz: "3947", city: "Ergisch" },
  { plz: "3948", city: "Unterems" },
  { plz: "3948", city: "Oberems" },
  { plz: "3949", city: "Hohtenn" },
  { plz: "3951", city: "Agarn" },
  { plz: "3952", city: "Susten" },
  { plz: "3953", city: "Inden" },
  { plz: "3953", city: "Varen" },
  { plz: "3953", city: "Leuk Stadt" },
  { plz: "3954", city: "Leukerbad" },
  { plz: "3955", city: "Albinen" },
  { plz: "3956", city: "Guttet-Feschel" },
  { plz: "3957", city: "Bratsch" },
  { plz: "3957", city: "Erschmatt" },
  { plz: "3960", city: "Corin-de-la-Crête" },
  { plz: "3960", city: "Loc" },
  { plz: "3960", city: "Muraz (Sierre)" },
  { plz: "3960", city: "Niouc" },
  { plz: "3960", city: "Sierre" },
  { plz: "3961", city: "Chandolin" },
  { plz: "3961", city: "Ayer" },
  { plz: "3961", city: "St-Jean VS" },
  { plz: "3961", city: "Vissoie" },
  { plz: "3961", city: "St-Luc" },
  { plz: "3961", city: "Zinal" },
  { plz: "3961", city: "Grimentz" },
  { plz: "3963", city: "Montana" },
  { plz: "3963", city: "Crans-Montana" },
  { plz: "3963", city: "Aminona" },
  { plz: "3965", city: "Chippis" },
  { plz: "3966", city: "Réchy" },
  { plz: "3966", city: "Chalais" },
  { plz: "3967", city: "Vercorin" },
  { plz: "3968", city: "Veyras" },
  { plz: "3970", city: "Salgesch" },
  { plz: "3971", city: "Chermignon-d'en-Bas" },
  { plz: "3971", city: "Ollon VS" },
  { plz: "3971", city: "Chermignon" },
  { plz: "3972", city: "Miège" },
  { plz: "3973", city: "Venthône" },
  { plz: "3974", city: "Mollens VS" },
  { plz: "3975", city: "Randogne" },
  { plz: "3976", city: "Champzabé" },
  { plz: "3976", city: "Noës" },
  { plz: "3977", city: "Granges VS" },
  { plz: "3978", city: "Flanthey" },
  { plz: "3979", city: "Grône" },
  { plz: "3982", city: "Bitsch" },
  { plz: "3983", city: "Bister" },
  { plz: "3983", city: "Filet" },
  { plz: "3983", city: "Goppisberg" },
  { plz: "3983", city: "Greich" },
  { plz: "3983", city: "Mörel" },
  { plz: "3984", city: "Fieschertal" },
  { plz: "3984", city: "Fiesch" },
  { plz: "3985", city: "Geschinen" },
  { plz: "3985", city: "Münster VS" },
  { plz: "3986", city: "Ried-Mörel" },
  { plz: "3987", city: "Riederalp" },
  { plz: "3988", city: "Obergesteln" },
  { plz: "3988", city: "Ulrichen" },
  { plz: "3989", city: "Niederwald" },
  { plz: "3989", city: "Blitzingen" },
  { plz: "3989", city: "Biel VS" },
  { plz: "3989", city: "Ritzingen" },
  { plz: "3989", city: "Selkingen" },
  { plz: "3991", city: "Betten" },
  { plz: "3992", city: "Bettmeralp" },
  { plz: "3993", city: "Grengiols" },
  { plz: "3994", city: "Lax" },
  { plz: "3994", city: "Martisberg" },
  { plz: "3995", city: "Ausserbinn" },
  { plz: "3995", city: "Mühlebach (Goms)" },
  { plz: "3995", city: "Steinhaus" },
  { plz: "3995", city: "Ernen" },
  { plz: "3996", city: "Binn" },
  { plz: "3997", city: "Bellwald" },
  { plz: "3998", city: "Gluringen" },
  { plz: "3998", city: "Reckingen VS" },
  { plz: "3999", city: "Oberwald" },
  { plz: "4000", city: "Basel" },
  { plz: "4001", city: "Basel" },
  { plz: "4031", city: "Basel Universitätsspital" },
  { plz: "4051", city: "Basel" },
  { plz: "4052", city: "Basel" },
  { plz: "4053", city: "Basel" },
  { plz: "4054", city: "Basel" },
  { plz: "4055", city: "Basel" },
  { plz: "4056", city: "Basel" },
  { plz: "4057", city: "Basel" },
  { plz: "4058", city: "Basel" },
  { plz: "4059", city: "Basel" },
  { plz: "4101", city: "Bruderholz" },
  { plz: "4102", city: "Binningen" },
  { plz: "4103", city: "Bottmingen" },
  { plz: "4104", city: "Oberwil BL" },
  { plz: "4105", city: "Biel-Benken BL" },
  { plz: "4106", city: "Therwil" },
  { plz: "4107", city: "Ettingen" },
  { plz: "4108", city: "Witterswil" },
  { plz: "4112", city: "Bättwil" },
  { plz: "4112", city: "Flüh" },
  { plz: "4114", city: "Hofstetten SO" },
  { plz: "4115", city: "Mariastein" },
  { plz: "4116", city: "Metzerlen" },
  { plz: "4117", city: "Burg im Leimental" },
  { plz: "4118", city: "Rodersdorf" },
  { plz: "4123", city: "Allschwil" },
  { plz: "4124", city: "Schönenbuch" },
  { plz: "4125", city: "Riehen" },
  { plz: "4126", city: "Bettingen" },
  { plz: "4127", city: "Birsfelden" },
  { plz: "4132", city: "Muttenz" },
  { plz: "4133", city: "Pratteln" },
  { plz: "4142", city: "Münchenstein" },
  { plz: "4143", city: "Dornach" },
  { plz: "4144", city: "Arlesheim" },
  { plz: "4145", city: "Gempen" },
  { plz: "4146", city: "Hochwald" },
  { plz: "4147", city: "Aesch BL" },
  { plz: "4148", city: "Pfeffingen" },
  { plz: "4153", city: "Reinach BL" },
  { plz: "4202", city: "Duggingen" },
  { plz: "4203", city: "Grellingen" },
  { plz: "4204", city: "Himmelried" },
  { plz: "4206", city: "Seewen SO" },
  { plz: "4207", city: "Bretzwil" },
  { plz: "4208", city: "Nunningen" },
  { plz: "4222", city: "Zwingen" },
  { plz: "4223", city: "Blauen" },
  { plz: "4224", city: "Nenzlingen" },
  { plz: "4225", city: "Brislach" },
  { plz: "4226", city: "Breitenbach" },
  { plz: "4227", city: "Büsserach" },
  { plz: "4228", city: "Erschwil" },
  { plz: "4229", city: "Beinwil SO" },
  { plz: "4232", city: "Fehren" },
  { plz: "4233", city: "Meltingen" },
  { plz: "4234", city: "Zullwil" },
  { plz: "4242", city: "Laufen" },
  { plz: "4243", city: "Dittingen" },
  { plz: "4244", city: "Röschenz" },
  { plz: "4245", city: "Kleinlützel" },
  { plz: "4246", city: "Wahlen b. Laufen" },
  { plz: "4247", city: "Grindel" },
  { plz: "4252", city: "Bärschwil" },
  { plz: "4253", city: "Liesberg" },
  { plz: "4254", city: "Liesberg Dorf" },
  { plz: "4302", city: "Augst BL" },
  { plz: "4303", city: "Kaiseraugst" },
  { plz: "4304", city: "Giebenach" },
  { plz: "4305", city: "Olsberg" },
  { plz: "4310", city: "Rheinfelden" },
  { plz: "4312", city: "Magden" },
  { plz: "4313", city: "Möhlin" },
  { plz: "4314", city: "Zeiningen" },
  { plz: "4315", city: "Zuzgen" },
  { plz: "4316", city: "Hellikon" },
  { plz: "4317", city: "Wegenstetten" },
  { plz: "4322", city: "Mumpf" },
  { plz: "4323", city: "Wallbach" },
  { plz: "4324", city: "Obermumpf" },
  { plz: "4325", city: "Schupfart" },
  { plz: "4332", city: "Stein AG" },
  { plz: "4333", city: "Münchwilen AG" },
  { plz: "4334", city: "Sisseln AG" },
  { plz: "4402", city: "Frenkendorf" },
  { plz: "4410", city: "Liestal" },
  { plz: "4411", city: "Seltisberg" },
  { plz: "4412", city: "Nuglar" },
  { plz: "4413", city: "Büren SO" },
  { plz: "4414", city: "Füllinsdorf" },
  { plz: "4415", city: "Lausen" },
  { plz: "4416", city: "Bubendorf" },
  { plz: "4417", city: "Ziefen" },
  { plz: "4418", city: "Reigoldswil" },
  { plz: "4419", city: "Lupsingen" },
  { plz: "4421", city: "St. Pantaleon" },
  { plz: "4422", city: "Arisdorf" },
  { plz: "4423", city: "Hersberg" },
  { plz: "4424", city: "Arboldswil" },
  { plz: "4425", city: "Titterten" },
  { plz: "4426", city: "Lauwil" },
  { plz: "4431", city: "Bennwil" },
  { plz: "4432", city: "Lampenberg" },
  { plz: "4433", city: "Ramlinsburg" },
  { plz: "4434", city: "Hölstein" },
  { plz: "4435", city: "Niederdorf" },
  { plz: "4436", city: "Liedertswil" },
  { plz: "4436", city: "Oberdorf BL" },
  { plz: "4437", city: "Waldenburg" },
  { plz: "4438", city: "Langenbruck" },
  { plz: "4441", city: "Thürnen" },
  { plz: "4442", city: "Diepflingen" },
  { plz: "4443", city: "Wittinsburg" },
  { plz: "4444", city: "Rümlingen" },
  { plz: "4445", city: "Häfelfingen" },
  { plz: "4446", city: "Buckten" },
  { plz: "4447", city: "Känerkinden" },
  { plz: "4448", city: "Läufelfingen" },
  { plz: "4450", city: "Sissach" },
  { plz: "4451", city: "Wintersingen" },
  { plz: "4452", city: "Itingen" },
  { plz: "4453", city: "Nusshof" },
  { plz: "4455", city: "Zunzgen" },
  { plz: "4456", city: "Tenniken" },
  { plz: "4457", city: "Diegten" },
  { plz: "4458", city: "Eptingen" },
  { plz: "4460", city: "Gelterkinden" },
  { plz: "4461", city: "Böckten" },
  { plz: "4462", city: "Rickenbach BL" },
  { plz: "4463", city: "Buus" },
  { plz: "4464", city: "Maisprach" },
  { plz: "4465", city: "Hemmiken" },
  { plz: "4466", city: "Ormalingen" },
  { plz: "4467", city: "Rothenfluh" },
  { plz: "4468", city: "Kienberg" },
  { plz: "4469", city: "Anwil" },
  { plz: "4492", city: "Tecknau" },
  { plz: "4493", city: "Wenslingen" },
  { plz: "4494", city: "Oltingen" },
  { plz: "4495", city: "Zeglingen" },
  { plz: "4496", city: "Kilchberg BL" },
  { plz: "4497", city: "Rünenberg" },
  { plz: "4500", city: "Solothurn" },
  { plz: "4512", city: "Bellach" },
  { plz: "4513", city: "Langendorf" },
  { plz: "4514", city: "Lommiswil" },
  { plz: "4515", city: "Weissenstein b. Solothurn" },
  { plz: "4515", city: "Oberdorf SO" },
  { plz: "4522", city: "Rüttenen" },
  { plz: "4523", city: "Niederwil SO" },
  { plz: "4524", city: "Balmberg" },
  { plz: "4524", city: "Oberbalmberg" },
  { plz: "4524", city: "Günsberg" },
  { plz: "4525", city: "Balm b. Günsberg" },
  { plz: "4528", city: "Zuchwil" },
  { plz: "4532", city: "Feldbrunnen" },
  { plz: "4533", city: "Riedholz" },
  { plz: "4534", city: "Flumenthal" },
  { plz: "4535", city: "Hubersdorf" },
  { plz: "4535", city: "Kammersrohr" },
  { plz: "4536", city: "Attiswil" },
  { plz: "4537", city: "Wiedlisbach" },
  { plz: "4538", city: "Oberbipp" },
  { plz: "4539", city: "Rumisberg" },
  { plz: "4539", city: "Farnern" },
  { plz: "4542", city: "Luterbach" },
  { plz: "4543", city: "Deitingen" },
  { plz: "4552", city: "Derendingen" },
  { plz: "4553", city: "Subingen" },
  { plz: "4554", city: "Hüniken" },
  { plz: "4554", city: "Etziken" },
  { plz: "4556", city: "Bolken" },
  { plz: "4556", city: "Burgäschi" },
  { plz: "4556", city: "Steinhof SO" },
  { plz: "4556", city: "Aeschi SO" },
  { plz: "4557", city: "Horriwil" },
  { plz: "4558", city: "Hersiwil" },
  { plz: "4558", city: "Heinrichswil" },
  { plz: "4558", city: "Winistorf" },
  { plz: "4562", city: "Biberist" },
  { plz: "4563", city: "Gerlafingen" },
  { plz: "4564", city: "Zielebach" },
  { plz: "4564", city: "Obergerlafingen" },
  { plz: "4565", city: "Recherswil" },
  { plz: "4566", city: "Halten" },
  { plz: "4566", city: "Oekingen" },
  { plz: "4566", city: "Kriegstetten" },
  { plz: "4571", city: "Ichertswil" },
  { plz: "4571", city: "Lüterkofen" },
  { plz: "4573", city: "Lohn-Ammannsegg" },
  { plz: "4574", city: "Lüsslingen" },
  { plz: "4574", city: "Nennigkofen" },
  { plz: "4576", city: "Tscheppach" },
  { plz: "4577", city: "Hessigkofen" },
  { plz: "4578", city: "Bibern SO" },
  { plz: "4579", city: "Gossliwil" },
  { plz: "4581", city: "Küttigkofen" },
  { plz: "4582", city: "Brügglen" },
  { plz: "4583", city: "Aetigkofen" },
  { plz: "4583", city: "Mühledorf SO" },
  { plz: "4584", city: "Gächliwil" },
  { plz: "4584", city: "Lüterswil" },
  { plz: "4585", city: "Biezwil" },
  { plz: "4586", city: "Kyburg-Buchegg" },
  { plz: "4587", city: "Aetingen" },
  { plz: "4588", city: "Unterramsern" },
  { plz: "4588", city: "Oberramsern" },
  { plz: "4588", city: "Brittern" },
  { plz: "4600", city: "Olten" },
  { plz: "4612", city: "Wangen b. Olten" },
  { plz: "4613", city: "Rickenbach SO" },
  { plz: "4614", city: "Hägendorf" },
  { plz: "4615", city: "Allerheiligenberg" },
  { plz: "4616", city: "Kappel SO" },
  { plz: "4617", city: "Gunzgen" },
  { plz: "4618", city: "Boningen" },
  { plz: "4622", city: "Egerkingen" },
  { plz: "4623", city: "Neuendorf" },
  { plz: "4624", city: "Härkingen" },
  { plz: "4625", city: "Oberbuchsiten" },
  { plz: "4626", city: "Niederbuchsiten" },
  { plz: "4628", city: "Wolfwil" },
  { plz: "4629", city: "Fulenbach" },
  { plz: "4632", city: "Trimbach" },
  { plz: "4633", city: "Hauenstein" },
  { plz: "4634", city: "Wisen SO" },
  { plz: "4652", city: "Winznau" },
  { plz: "4653", city: "Obergösgen" },
  { plz: "4654", city: "Lostorf" },
  { plz: "4655", city: "Rohr b. Olten" },
  { plz: "4655", city: "Stüsslingen" },
  { plz: "4656", city: "Starrkirch-Wil" },
  { plz: "4657", city: "Dulliken" },
  { plz: "4658", city: "Däniken SO" },
  { plz: "4663", city: "Aarburg" },
  { plz: "4665", city: "Oftringen" },
  { plz: "4702", city: "Oensingen" },
  { plz: "4703", city: "Kestenholz" },
  { plz: "4704", city: "Wolfisberg" },
  { plz: "4704", city: "Niederbipp" },
  { plz: "4710", city: "Balsthal" },
  { plz: "4712", city: "Laupersdorf" },
  { plz: "4713", city: "Matzendorf" },
  { plz: "4714", city: "Aedermannsdorf" },
  { plz: "4715", city: "Herbetswil" },
  { plz: "4716", city: "Gänsbrunnen" },
  { plz: "4716", city: "Welschenrohr" },
  { plz: "4717", city: "Mümliswil" },
  { plz: "4718", city: "Holderbank SO" },
  { plz: "4719", city: "Ramiswil" },
  { plz: "4800", city: "Zofingen" },
  { plz: "4802", city: "Strengelbach" },
  { plz: "4803", city: "Vordemwald" },
  { plz: "4805", city: "Brittnau" },
  { plz: "4806", city: "Wikon" },
  { plz: "4812", city: "Mühlethal" },
  { plz: "4813", city: "Uerkheim" },
  { plz: "4814", city: "Bottenwil" },
  { plz: "4852", city: "Rothrist" },
  { plz: "4853", city: "Riken AG" },
  { plz: "4853", city: "Murgenthal" },
  { plz: "4856", city: "Glashütten" },
  { plz: "4900", city: "Langenthal" },
  { plz: "4911", city: "Schwarzhäusern" },
  { plz: "4912", city: "Aarwangen" },
  { plz: "4913", city: "Bannwil" },
  { plz: "4914", city: "Roggwil BE" },
  { plz: "4915", city: "St. Urban" },
  { plz: "4916", city: "Untersteckholz" },
  { plz: "4917", city: "Busswil b. Melchnau" },
  { plz: "4917", city: "Melchnau" },
  { plz: "4919", city: "Reisiswil" },
  { plz: "4922", city: "Thunstetten" },
  { plz: "4922", city: "Bützberg" },
  { plz: "4923", city: "Wynau" },
  { plz: "4924", city: "Obersteckholz" },
  { plz: "4932", city: "Gutenburg" },
  { plz: "4932", city: "Lotzwil" },
  { plz: "4933", city: "Rütschelen" },
  { plz: "4934", city: "Madiswil" },
  { plz: "4935", city: "Leimiswil" },
  { plz: "4936", city: "Kleindietwil" },
  { plz: "4937", city: "Ursenbach" },
  { plz: "4938", city: "Rohrbachgraben" },
  { plz: "4938", city: "Rohrbach" },
  { plz: "4942", city: "Walterswil BE" },
  { plz: "4943", city: "Oeschenbach" },
  { plz: "4944", city: "Auswil" },
  { plz: "4950", city: "Huttwil" },
  { plz: "4952", city: "Eriswil" },
  { plz: "4953", city: "Schwarzenbach (Huttwil)" },
  { plz: "4954", city: "Wyssachen" },
  { plz: "4955", city: "Gondiswil" },
  { plz: "5000", city: "Aarau" },
  { plz: "5004", city: "Aarau" },
  { plz: "5012", city: "Eppenberg" },
  { plz: "5012", city: "Wöschnau" },
  { plz: "5012", city: "Schönenwerd" },
  { plz: "5013", city: "Niedergösgen" },
  { plz: "5014", city: "Gretzenbach" },
  { plz: "5015", city: "Erlinsbach SO" },
  { plz: "5017", city: "Barmelweid" },
  { plz: "5018", city: "Erlinsbach" },
  { plz: "5022", city: "Rombach" },
  { plz: "5023", city: "Biberstein" },
  { plz: "5024", city: "Küttigen" },
  { plz: "5025", city: "Asp" },
  { plz: "5026", city: "Densbüren" },
  { plz: "5027", city: "Herznach" },
  { plz: "5028", city: "Ueken" },
  { plz: "5032", city: "Aarau Rohr" },
  { plz: "5033", city: "Buchs AG" },
  { plz: "5034", city: "Suhr" },
  { plz: "5035", city: "Unterentfelden" },
  { plz: "5036", city: "Oberentfelden" },
  { plz: "5037", city: "Muhen" },
  { plz: "5040", city: "Schöftland" },
  { plz: "5042", city: "Hirschthal" },
  { plz: "5043", city: "Holziken" },
  { plz: "5044", city: "Schlossrued" },
  { plz: "5046", city: "Schmiedrued" },
  { plz: "5046", city: "Walde AG" },
  { plz: "5046", city: "Schmiedrued-Walde" },
  { plz: "5053", city: "Wittwil" },
  { plz: "5053", city: "Staffelbach" },
  { plz: "5054", city: "Kirchleerau" },
  { plz: "5054", city: "Moosleerau" },
  { plz: "5056", city: "Attelwil" },
  { plz: "5057", city: "Reitnau" },
  { plz: "5058", city: "Wiliberg" },
  { plz: "5062", city: "Oberhof" },
  { plz: "5063", city: "Wölflinswil" },
  { plz: "5064", city: "Wittnau" },
  { plz: "5070", city: "Frick" },
  { plz: "5072", city: "Oeschgen" },
  { plz: "5073", city: "Gipf-Oberfrick" },
  { plz: "5074", city: "Eiken" },
  { plz: "5075", city: "Hornussen" },
  { plz: "5076", city: "Bözen" },
  { plz: "5077", city: "Elfingen" },
  { plz: "5078", city: "Effingen" },
  { plz: "5079", city: "Zeihen" },
  { plz: "5080", city: "Laufenburg" },
  { plz: "5082", city: "Kaisten" },
  { plz: "5083", city: "Ittenthal" },
  { plz: "5084", city: "Rheinsulz" },
  { plz: "5085", city: "Sulz AG" },
  { plz: "5102", city: "Rupperswil" },
  { plz: "5103", city: "Wildegg" },
  { plz: "5103", city: "Möriken AG" },
  { plz: "5105", city: "Auenstein" },
  { plz: "5106", city: "Veltheim AG" },
  { plz: "5107", city: "Schinznach Dorf" },
  { plz: "5108", city: "Oberflachs" },
  { plz: "5112", city: "Thalheim AG" },
  { plz: "5113", city: "Holderbank AG" },
  { plz: "5116", city: "Schinznach Bad" },
  { plz: "5200", city: "Brugg AG" },
  { plz: "5210", city: "Windisch" },
  { plz: "5212", city: "Hausen AG" },
  { plz: "5213", city: "Villnachern" },
  { plz: "5222", city: "Umiken" },
  { plz: "5223", city: "Riniken" },
  { plz: "5224", city: "Gallenkirch" },
  { plz: "5224", city: "Linn" },
  { plz: "5224", city: "Unterbözberg" },
  { plz: "5225", city: "Oberbözberg" },
  { plz: "5233", city: "Stilli" },
  { plz: "5234", city: "Villigen" },
  { plz: "5235", city: "Rüfenach AG" },
  { plz: "5236", city: "Remigen" },
  { plz: "5237", city: "Mönthal" },
  { plz: "5242", city: "Birr" },
  { plz: "5242", city: "Lupfig" },
  { plz: "5243", city: "Mülligen" },
  { plz: "5244", city: "Birrhard" },
  { plz: "5245", city: "Habsburg" },
  { plz: "5246", city: "Scherz" },
  { plz: "5272", city: "Gansingen" },
  { plz: "5273", city: "Oberhofen AG" },
  { plz: "5274", city: "Mettau" },
  { plz: "5275", city: "Etzgen" },
  { plz: "5276", city: "Wil AG" },
  { plz: "5277", city: "Hottwil" },
  { plz: "5300", city: "Turgi" },
  { plz: "5301", city: "Siggenthal Station" },
  { plz: "5303", city: "Würenlingen" },
  { plz: "5304", city: "Endingen" },
  { plz: "5305", city: "Unterendingen" },
  { plz: "5306", city: "Tegerfelden" },
  { plz: "5312", city: "Döttingen" },
  { plz: "5313", city: "Klingnau" },
  { plz: "5314", city: "Kleindöttingen" },
  { plz: "5315", city: "Böttstein" },
  { plz: "5316", city: "Felsenau AG" },
  { plz: "5316", city: "Leuggern" },
  { plz: "5317", city: "Hettenschwil" },
  { plz: "5318", city: "Mandach" },
  { plz: "5322", city: "Koblenz" },
  { plz: "5323", city: "Rietheim" },
  { plz: "5324", city: "Full-Reuenthal" },
  { plz: "5325", city: "Leibstadt" },
  { plz: "5326", city: "Schwaderloch" },
  { plz: "5330", city: "Bad Zurzach" },
  { plz: "5332", city: "Rekingen AG" },
  { plz: "5333", city: "Baldingen" },
  { plz: "5334", city: "Böbikon" },
  { plz: "5400", city: "Baden" },
  { plz: "5404", city: "Baden" },
  { plz: "5405", city: "Dättwil AG" },
  { plz: "5406", city: "Rütihof" },
  { plz: "5408", city: "Ennetbaden" },
  { plz: "5412", city: "Gebenstorf" },
  { plz: "5413", city: "Birmenstorf AG" },
  { plz: "5415", city: "Hertenstein AG" },
  { plz: "5415", city: "Rieden AG" },
  { plz: "5415", city: "Nussbaumen AG" },
  { plz: "5416", city: "Kirchdorf AG" },
  { plz: "5417", city: "Untersiggenthal" },
  { plz: "5420", city: "Ehrendingen" },
  { plz: "5423", city: "Freienwil" },
  { plz: "5425", city: "Schneisingen" },
  { plz: "5426", city: "Lengnau AG" },
  { plz: "5430", city: "Wettingen" },
  { plz: "5432", city: "Neuenhof" },
  { plz: "5436", city: "Würenlos" },
  { plz: "5442", city: "Fislisbach" },
  { plz: "5443", city: "Niederrohrdorf" },
  { plz: "5444", city: "Künten" },
  { plz: "5445", city: "Eggenwil" },
  { plz: "5452", city: "Oberrohrdorf" },
  { plz: "5453", city: "Remetschwil" },
  { plz: "5454", city: "Bellikon" },
  { plz: "5462", city: "Siglistorf" },
  { plz: "5463", city: "Wislikofen" },
  { plz: "5464", city: "Rümikon AG" },
  { plz: "5465", city: "Mellikon" },
  { plz: "5466", city: "Kaiserstuhl AG" },
  { plz: "5467", city: "Fisibach" },
  { plz: "5502", city: "Hunzenschwil" },
  { plz: "5503", city: "Schafisheim" },
  { plz: "5504", city: "Othmarsingen" },
  { plz: "5505", city: "Brunegg" },
  { plz: "5506", city: "Mägenwil" },
  { plz: "5507", city: "Mellingen" },
  { plz: "5512", city: "Wohlenschwil" },
  { plz: "5522", city: "Tägerig" },
  { plz: "5524", city: "Nesselnbach" },
  { plz: "5524", city: "Niederwil AG" },
  { plz: "5525", city: "Fischbach-Göslikon" },
  { plz: "5600", city: "Lenzburg" },
  { plz: "5600", city: "Ammerswil AG" },
  { plz: "5603", city: "Staufen" },
  { plz: "5604", city: "Hendschiken" },
  { plz: "5605", city: "Dottikon" },
  { plz: "5606", city: "Dintikon" },
  { plz: "5607", city: "Hägglingen" },
  { plz: "5608", city: "Stetten AG" },
  { plz: "5610", city: "Wohlen AG" },
  { plz: "5611", city: "Anglikon" },
  { plz: "5612", city: "Villmergen" },
  { plz: "5613", city: "Hilfikon" },
  { plz: "5614", city: "Sarmenstorf" },
  { plz: "5615", city: "Fahrwangen" },
  { plz: "5616", city: "Meisterschwanden" },
  { plz: "5617", city: "Tennwil" },
  { plz: "5618", city: "Bettwil" },
  { plz: "5619", city: "Büttikon AG" },
  { plz: "5619", city: "Uezwil" },
  { plz: "5620", city: "Bremgarten AG" },
  { plz: "5621", city: "Zufikon" },
  { plz: "5622", city: "Waltenschwil" },
  { plz: "5623", city: "Boswil" },
  { plz: "5624", city: "Waldhäusern AG" },
  { plz: "5624", city: "Bünzen" },
  { plz: "5625", city: "Kallern" },
  { plz: "5626", city: "Hermetschwil-Staffeln" },
  { plz: "5627", city: "Besenbüren" },
  { plz: "5628", city: "Aristau" },
  { plz: "5630", city: "Muri AG" },
  { plz: "5632", city: "Buttwil" },
  { plz: "5634", city: "Merenschwand" },
  { plz: "5636", city: "Benzenschwil" },
  { plz: "5637", city: "Geltwil" },
  { plz: "5637", city: "Beinwil (Freiamt)" },
  { plz: "5642", city: "Mühlau" },
  { plz: "5643", city: "Alikon" },
  { plz: "5643", city: "Meienberg" },
  { plz: "5643", city: "Sins" },
  { plz: "5644", city: "Auw" },
  { plz: "5645", city: "Aettenschwil" },
  { plz: "5645", city: "Fenkrieden" },
  { plz: "5646", city: "Abtwil AG" },
  { plz: "5647", city: "Oberrüti" },
  { plz: "5702", city: "Niederlenz" },
  { plz: "5703", city: "Seon" },
  { plz: "5704", city: "Egliswil" },
  { plz: "5705", city: "Hallwil" },
  { plz: "5706", city: "Boniswil" },
  { plz: "5707", city: "Seengen" },
  { plz: "5708", city: "Birrwil" },
  { plz: "5712", city: "Beinwil am See" },
  { plz: "5722", city: "Gränichen" },
  { plz: "5723", city: "Teufenthal AG" },
  { plz: "5724", city: "Dürrenäsch" },
  { plz: "5725", city: "Leutwil" },
  { plz: "5726", city: "Unterkulm" },
  { plz: "5727", city: "Oberkulm" },
  { plz: "5728", city: "Gontenschwil" },
  { plz: "5732", city: "Zetzwil" },
  { plz: "5733", city: "Leimbach AG" },
  { plz: "5734", city: "Reinach AG" },
  { plz: "5735", city: "Pfeffikon LU" },
  { plz: "5736", city: "Burg AG" },
  { plz: "5737", city: "Menziken" },
  { plz: "5742", city: "Kölliken" },
  { plz: "5745", city: "Safenwil" },
  { plz: "5746", city: "Walterswil SO" },
  { plz: "6000", city: "Luzern" },
  { plz: "6003", city: "Luzern" },
  { plz: "6004", city: "Luzern" },
  { plz: "6005", city: "Luzern" },
  { plz: "6005", city: "St. Niklausen LU" },
  { plz: "6006", city: "Luzern" },
  { plz: "6010", city: "Kriens" },
  { plz: "6010", city: "Pilatus Kulm" },
  { plz: "6012", city: "Obernau" },
  { plz: "6013", city: "Eigenthal" },
  { plz: "6014", city: "Luzern" },
  { plz: "6015", city: "Luzern" },
  { plz: "6016", city: "Hellbühl" },
  { plz: "6017", city: "Ruswil" },
  { plz: "6018", city: "Buttisholz" },
  { plz: "6019", city: "Sigigen" },
  { plz: "6020", city: "Emmenbrücke" },
  { plz: "6022", city: "Grosswangen" },
  { plz: "6023", city: "Rothenburg" },
  { plz: "6024", city: "Hildisrieden" },
  { plz: "6025", city: "Neudorf" },
  { plz: "6026", city: "Rain" },
  { plz: "6027", city: "Römerswil LU" },
  { plz: "6028", city: "Herlisberg" },
  { plz: "6030", city: "Ebikon" },
  { plz: "6032", city: "Emmen" },
  { plz: "6033", city: "Buchrain" },
  { plz: "6034", city: "Inwil" },
  { plz: "6035", city: "Perlen" },
  { plz: "6036", city: "Dierikon" },
  { plz: "6037", city: "Root" },
  { plz: "6038", city: "Gisikon" },
  { plz: "6038", city: "Honau" },
  { plz: "6039", city: "Root Längenbold" },
  { plz: "6042", city: "Dietwil" },
  { plz: "6043", city: "Adligenswil" },
  { plz: "6044", city: "Udligenswil" },
  { plz: "6045", city: "Meggen" },
  { plz: "6047", city: "Kastanienbaum" },
  { plz: "6048", city: "Horw" },
  { plz: "6052", city: "Hergiswil NW" },
  { plz: "6053", city: "Alpnachstad" },
  { plz: "6055", city: "Alpnach Dorf" },
  { plz: "6056", city: "Kägiswil" },
  { plz: "6060", city: "Sarnen" },
  { plz: "6060", city: "Ramersberg" },
  { plz: "6062", city: "Wilen (Sarnen)" },
  { plz: "6063", city: "Stalden (Sarnen)" },
  { plz: "6064", city: "Kerns" },
  { plz: "6066", city: "St. Niklausen OW" },
  { plz: "6067", city: "Melchtal" },
  { plz: "6068", city: "Melchsee-Frutt" },
  { plz: "6072", city: "Sachseln" },
  { plz: "6073", city: "Flüeli-Ranft" },
  { plz: "6074", city: "Giswil" },
  { plz: "6078", city: "Bürglen OW" },
  { plz: "6078", city: "Lungern" },
  { plz: "6083", city: "Hasliberg Hohfluh" },
  { plz: "6084", city: "Hasliberg Wasserwendi" },
  { plz: "6085", city: "Hasliberg Goldern" },
  { plz: "6086", city: "Hasliberg Reuti" },
  { plz: "6102", city: "Malters" },
  { plz: "6103", city: "Schwarzenberg LU" },
  { plz: "6105", city: "Schachen LU" },
  { plz: "6106", city: "Werthenstein" },
  { plz: "6110", city: "Fontannen b. Wolhusen" },
  { plz: "6110", city: "Wolhusen" },
  { plz: "6112", city: "Doppleschwand" },
  { plz: "6113", city: "Romoos" },
  { plz: "6114", city: "Steinhuserberg" },
  { plz: "6122", city: "Menznau" },
  { plz: "6123", city: "Geiss" },
  { plz: "6125", city: "Menzberg" },
  { plz: "6126", city: "Daiwil" },
  { plz: "6130", city: "Willisau" },
  { plz: "6132", city: "Rohrmatt" },
  { plz: "6133", city: "Hergiswil b. Willisau" },
  { plz: "6142", city: "Gettnau" },
  { plz: "6143", city: "Ohmstal" },
  { plz: "6144", city: "Zell LU" },
  { plz: "6145", city: "Fischbach LU" },
  { plz: "6146", city: "Grossdietwil" },
  { plz: "6147", city: "Altbüron" },
  { plz: "6152", city: "Hüswil" },
  { plz: "6153", city: "Ufhusen" },
  { plz: "6154", city: "Hofstatt" },
  { plz: "6156", city: "Luthern Bad" },
  { plz: "6156", city: "Luthern" },
  { plz: "6162", city: "Rengg" },
  { plz: "6162", city: "Finsterwald b. Entlebuch" },
  { plz: "6162", city: "Entlebuch" },
  { plz: "6163", city: "Ebnet" },
  { plz: "6166", city: "Hasle LU" },
  { plz: "6167", city: "Bramboden" },
  { plz: "6170", city: "Schüpfheim" },
  { plz: "6173", city: "Flühli LU" },
  { plz: "6174", city: "Sörenberg" },
  { plz: "6182", city: "Escholzmatt" },
  { plz: "6192", city: "Wiggen" },
  { plz: "6196", city: "Marbach LU" },
  { plz: "6197", city: "Schangnau" },
  { plz: "6203", city: "Sempach Station" },
  { plz: "6204", city: "Sempach" },
  { plz: "6205", city: "Eich" },
  { plz: "6206", city: "Neuenkirch" },
  { plz: "6207", city: "Nottwil" },
  { plz: "6208", city: "Oberkirch LU" },
  { plz: "6210", city: "Sursee" },
  { plz: "6211", city: "Buchs LU" },
  { plz: "6212", city: "Kaltbach" },
  { plz: "6212", city: "St. Erhard" },
  { plz: "6213", city: "Knutwil" },
  { plz: "6214", city: "Schenkon" },
  { plz: "6215", city: "Schwarzenbach Beromünster" },
  { plz: "6215", city: "Beromünster" },
  { plz: "6216", city: "Mauensee" },
  { plz: "6217", city: "Kottwil" },
  { plz: "6218", city: "Ettiswil" },
  { plz: "6221", city: "Rickenbach LU" },
  { plz: "6222", city: "Gunzwil" },
  { plz: "6231", city: "Schlierbach" },
  { plz: "6232", city: "Geuensee" },
  { plz: "6233", city: "Büron" },
  { plz: "6234", city: "Kulmerau" },
  { plz: "6234", city: "Triengen" },
  { plz: "6235", city: "Winikon" },
  { plz: "6236", city: "Wilihof" },
  { plz: "6242", city: "Wauwil" },
  { plz: "6243", city: "Egolzwil" },
  { plz: "6244", city: "Nebikon" },
  { plz: "6245", city: "Ebersecken" },
  { plz: "6246", city: "Altishofen" },
  { plz: "6247", city: "Schötz" },
  { plz: "6248", city: "Alberswil" },
  { plz: "6252", city: "Dagmersellen" },
  { plz: "6253", city: "Uffikon" },
  { plz: "6260", city: "Reidermoos" },
  { plz: "6260", city: "Hintermoos" },
  { plz: "6260", city: "Mehlsecken" },
  { plz: "6260", city: "Reiden" },
  { plz: "6262", city: "Langnau b. Reiden" },
  { plz: "6263", city: "Richenthal" },
  { plz: "6264", city: "Pfaffnau" },
  { plz: "6265", city: "Roggliswil" },
  { plz: "6274", city: "Eschenbach LU" },
  { plz: "6275", city: "Ballwil" },
  { plz: "6276", city: "Hohenrain" },
  { plz: "6277", city: "Kleinwangen" },
  { plz: "6277", city: "Lieli LU" },
  { plz: "6280", city: "Hochdorf" },
  { plz: "6280", city: "Urswil" },
  { plz: "6283", city: "Baldegg" },
  { plz: "6284", city: "Sulz LU" },
  { plz: "6284", city: "Gelfingen" },
  { plz: "6285", city: "Retschwil" },
  { plz: "6285", city: "Hitzkirch" },
  { plz: "6286", city: "Altwis" },
  { plz: "6287", city: "Aesch LU" },
  { plz: "6288", city: "Schongau" },
  { plz: "6289", city: "Müswangen" },
  { plz: "6289", city: "Hämikon" },
  { plz: "6294", city: "Ermensee" },
  { plz: "6295", city: "Mosen" },
  { plz: "6300", city: "Zug" },
  { plz: "6300", city: "Zugerberg" },
  { plz: "6312", city: "Steinhausen" },
  { plz: "6313", city: "Edlibach" },
  { plz: "6313", city: "Finstersee" },
  { plz: "6313", city: "Menzingen" },
  { plz: "6314", city: "Neuägeri" },
  { plz: "6314", city: "Unterägeri" },
  { plz: "6315", city: "Morgarten" },
  { plz: "6315", city: "Alosen" },
  { plz: "6315", city: "Oberägeri" },
  { plz: "6317", city: "Oberwil b. Zug" },
  { plz: "6318", city: "Walchwil" },
  { plz: "6319", city: "Allenwinden" },
  { plz: "6330", city: "Cham" },
  { plz: "6331", city: "Hünenberg" },
  { plz: "6332", city: "Hagendorn" },
  { plz: "6333", city: "Hünenberg See" },
  { plz: "6340", city: "Baar" },
  { plz: "6340", city: "Sihlbrugg" },
  { plz: "6343", city: "Buonas" },
  { plz: "6343", city: "Risch" },
  { plz: "6343", city: "Holzhäusern ZG" },
  { plz: "6343", city: "Rotkreuz" },
  { plz: "6344", city: "Meierskappel" },
  { plz: "6345", city: "Neuheim" },
  { plz: "6353", city: "Weggis" },
  { plz: "6354", city: "Vitznau" },
  { plz: "6356", city: "Rigi Kaltbad" },
  { plz: "6362", city: "Stansstad" },
  { plz: "6363", city: "Obbürgen" },
  { plz: "6363", city: "Bürgenstock" },
  { plz: "6363", city: "Fürigen" },
  { plz: "6365", city: "Kehrsiten" },
  { plz: "6370", city: "Stans" },
  { plz: "6370", city: "Oberdorf NW" },
  { plz: "6372", city: "Ennetmoos" },
  { plz: "6373", city: "Ennetbürgen" },
  { plz: "6374", city: "Buochs" },
  { plz: "6375", city: "Beckenried" },
  { plz: "6376", city: "Emmetten" },
  { plz: "6377", city: "Seelisberg" },
  { plz: "6382", city: "Büren NW" },
  { plz: "6383", city: "Niederrickenbach" },
  { plz: "6383", city: "Wiesenberg" },
  { plz: "6383", city: "Wirzweli" },
  { plz: "6383", city: "Dallenwil" },
  { plz: "6386", city: "Wolfenschiessen" },
  { plz: "6387", city: "Oberrickenbach" },
  { plz: "6388", city: "Grafenort" },
  { plz: "6390", city: "Engelberg" },
  { plz: "6402", city: "Merlischachen" },
  { plz: "6403", city: "Küssnacht am Rigi" },
  { plz: "6404", city: "Greppen" },
  { plz: "6405", city: "Immensee" },
  { plz: "6410", city: "Rigi Klösterli" },
  { plz: "6410", city: "Rigi Staffel" },
  { plz: "6410", city: "Rigi Kulm" },
  { plz: "6410", city: "Rigi Scheidegg" },
  { plz: "6410", city: "Goldau" },
  { plz: "6414", city: "Oberarth" },
  { plz: "6415", city: "Arth" },
  { plz: "6416", city: "Steinerberg" },
  { plz: "6417", city: "Sattel" },
  { plz: "6418", city: "Rothenthurm" },
  { plz: "6422", city: "Steinen" },
  { plz: "6423", city: "Seewen SZ" },
  { plz: "6424", city: "Lauerz" },
  { plz: "6430", city: "Schwyz" },
  { plz: "6432", city: "Rickenbach b. Schwyz" },
  { plz: "6433", city: "Stoos SZ" },
  { plz: "6434", city: "Illgau" },
  { plz: "6436", city: "Ried (Muotathal)" },
  { plz: "6436", city: "Bisisthal" },
  { plz: "6436", city: "Muotathal" },
  { plz: "6438", city: "Ibach" },
  { plz: "6440", city: "Brunnen" },
  { plz: "6441", city: "Rütli" },
  { plz: "6442", city: "Gersau" },
  { plz: "6443", city: "Morschach" },
  { plz: "6452", city: "Riemenstalden" },
  { plz: "6452", city: "Sisikon" },
  { plz: "6454", city: "Flüelen" },
  { plz: "6460", city: "Altdorf UR" },
  { plz: "6461", city: "Isenthal" },
  { plz: "6462", city: "Seedorf UR" },
  { plz: "6463", city: "Bürglen UR" },
  { plz: "6464", city: "Spiringen" },
  { plz: "6465", city: "Unterschächen" },
  { plz: "6466", city: "Bauen" },
  { plz: "6467", city: "Schattdorf" },
  { plz: "6468", city: "Attinghausen" },
  { plz: "6469", city: "Haldi b. Schattdorf" },
  { plz: "6472", city: "Erstfeld" },
  { plz: "6473", city: "Silenen" },
  { plz: "6474", city: "Amsteg" },
  { plz: "6475", city: "Bristen" },
  { plz: "6476", city: "Intschi" },
  { plz: "6482", city: "Gurtnellen" },
  { plz: "6484", city: "Wassen UR" },
  { plz: "6485", city: "Meien" },
  { plz: "6487", city: "Göschenen" },
  { plz: "6490", city: "Andermatt" },
  { plz: "6491", city: "Realp" },
  { plz: "6493", city: "Hospental" },
  { plz: "6500", city: "Bellinzona" },
  { plz: "6503", city: "Bellinzona" },
  { plz: "6512", city: "Giubiasco" },
  { plz: "6513", city: "Monte Carasso" },
  { plz: "6514", city: "Sementina" },
  { plz: "6515", city: "Gudo" },
  { plz: "6516", city: "Cugnasco" },
  { plz: "6517", city: "Arbedo" },
  { plz: "6518", city: "Gorduno" },
  { plz: "6523", city: "Preonzo" },
  { plz: "6524", city: "Moleno" },
  { plz: "6525", city: "Gnosca" },
  { plz: "6526", city: "Prosito" },
  { plz: "6527", city: "Lodrino" },
  { plz: "6528", city: "Camorino" },
  { plz: "6532", city: "Castione" },
  { plz: "6533", city: "Lumino" },
  { plz: "6534", city: "S. Vittore" },
  { plz: "6535", city: "Roveredo GR" },
  { plz: "6537", city: "Grono" },
  { plz: "6538", city: "Verdabbio" },
  { plz: "6540", city: "Castaneda" },
  { plz: "6541", city: "Sta. Maria in Calanca" },
  { plz: "6542", city: "Buseno" },
  { plz: "6543", city: "Arvigo" },
  { plz: "6544", city: "Braggio" },
  { plz: "6545", city: "Selma" },
  { plz: "6546", city: "Cauco" },
  { plz: "6547", city: "Augio" },
  { plz: "6548", city: "Rossa" },
  { plz: "6549", city: "Laura" },
  { plz: "6556", city: "Leggia" },
  { plz: "6557", city: "Cama" },
  { plz: "6558", city: "Lostallo" },
  { plz: "6562", city: "Soazza" },
  { plz: "6563", city: "Mesocco" },
  { plz: "6565", city: "S. Bernardino" },
  { plz: "6571", city: "Indemini" },
  { plz: "6572", city: "Quartino" },
  { plz: "6573", city: "Magadino" },
  { plz: "6574", city: "Vira (Gambarogno)" },
  { plz: "6575", city: "Vairano" },
  { plz: "6575", city: "S. Nazzaro" },
  { plz: "6576", city: "Gerra (Gambarogno)" },
  { plz: "6577", city: "Ranzo" },
  { plz: "6578", city: "Caviano" },
  { plz: "6579", city: "Piazzogna" },
  { plz: "6582", city: "Pianezzo" },
  { plz: "6583", city: "S. Antonio (Val Morobbia)" },
  { plz: "6584", city: "Carena" },
  { plz: "6592", city: "S. Antonino" },
  { plz: "6593", city: "Cadenazzo" },
  { plz: "6594", city: "Contone" },
  { plz: "6595", city: "Riazzino" },
  { plz: "6596", city: "Gordola" },
  { plz: "6597", city: "Agarone" },
  { plz: "6598", city: "Tenero" },
  { plz: "6599", city: "Robasacco" },
  { plz: "6600", city: "Locarno" },
  { plz: "6600", city: "Muralto" },
  { plz: "6600", city: "Solduno" },
  { plz: "6605", city: "Monte Brè sopra Locarno" },
  { plz: "6605", city: "Locarno" },
  { plz: "6611", city: "Mosogno" },
  { plz: "6611", city: "Gresso" },
  { plz: "6611", city: "Crana" },
  { plz: "6612", city: "Ascona" },
  { plz: "6613", city: "Porto Ronco" },
  { plz: "6614", city: "Isole di Brissago" },
  { plz: "6614", city: "Brissago" },
  { plz: "6616", city: "Losone" },
  { plz: "6618", city: "Arcegno" },
  { plz: "6622", city: "Ronco sopra Ascona" },
  { plz: "6631", city: "Corippo" },
  { plz: "6632", city: "Vogorno" },
  { plz: "6633", city: "Lavertezzo" },
  { plz: "6634", city: "Brione (Verzasca)" },
  { plz: "6635", city: "Gerra (Verzasca)" },
  { plz: "6636", city: "Frasco" },
  { plz: "6637", city: "Sonogno" },
  { plz: "6644", city: "Orselina" },
  { plz: "6645", city: "Brione sopra Minusio" },
  { plz: "6646", city: "Contra" },
  { plz: "6647", city: "Mergoscia" },
  { plz: "6648", city: "Minusio" },
  { plz: "6652", city: "Tegna" },
  { plz: "6653", city: "Verscio" },
  { plz: "6654", city: "Cavigliano" },
  { plz: "6655", city: "Verdasio" },
  { plz: "6655", city: "Rasa" },
  { plz: "6655", city: "Intragna" },
  { plz: "6656", city: "Golino" },
  { plz: "6657", city: "Palagnedra" },
  { plz: "6658", city: "Borgnone" },
  { plz: "6659", city: "Moneto" },
  { plz: "6659", city: "Camedo" },
  { plz: "6661", city: "Auressio" },
  { plz: "6661", city: "Berzona" },
  { plz: "6661", city: "Loco" },
  { plz: "6662", city: "Russo" },
  { plz: "6663", city: "Comologno" },
  { plz: "6663", city: "Spruga" },
  { plz: "6664", city: "Vergeletto" },
  { plz: "6670", city: "Avegno" },
  { plz: "6672", city: "Gordevio" },
  { plz: "6673", city: "Maggia" },
  { plz: "6674", city: "Riveo" },
  { plz: "6674", city: "Someo" },
  { plz: "6675", city: "Cevio" },
  { plz: "6676", city: "Bignasco" },
  { plz: "6677", city: "Moghegno" },
  { plz: "6677", city: "Aurigeno" },
  { plz: "6678", city: "Coglio" },
  { plz: "6678", city: "Lodano" },
  { plz: "6678", city: "Giumaglio" },
  { plz: "6682", city: "Linescio" },
  { plz: "6683", city: "Cerentino" },
  { plz: "6683", city: "Niva (Vallemaggia)" },
  { plz: "6684", city: "Campo (Vallemaggia)" },
  { plz: "6684", city: "Cimalmotto" },
  { plz: "6685", city: "Bosco/Gurin" },
  { plz: "6690", city: "Cavergno" },
  { plz: "6690", city: "S. Carlo (Val Bavona)" },
  { plz: "6692", city: "Brontallo" },
  { plz: "6692", city: "Menzonio" },
  { plz: "6693", city: "Broglio" },
  { plz: "6694", city: "Prato-Sornico" },
  { plz: "6695", city: "Peccia" },
  { plz: "6695", city: "Piano di Peccia" },
  { plz: "6696", city: "Fusio" },
  { plz: "6702", city: "Claro" },
  { plz: "6703", city: "Osogna" },
  { plz: "6705", city: "Cresciano" },
  { plz: "6707", city: "Iragna" },
  { plz: "6710", city: "Biasca" },
  { plz: "6710", city: "Biasca Stazione" },
  { plz: "6713", city: "Malvaglia" },
  { plz: "6714", city: "Semione" },
  { plz: "6715", city: "Dongio" },
  { plz: "6716", city: "Leontica" },
  { plz: "6716", city: "Lottigna" },
  { plz: "6716", city: "Acquarossa" },
  { plz: "6717", city: "Dangio-Torre" },
  { plz: "6717", city: "Torre" },
  { plz: "6718", city: "Camperio" },
  { plz: "6718", city: "Olivone" },
  { plz: "6719", city: "Aquila" },
  { plz: "6720", city: "Campo (Blenio)" },
  { plz: "6720", city: "Ghirone" },
  { plz: "6721", city: "Ludiano" },
  { plz: "6721", city: "Motto (Blenio)" },
  { plz: "6722", city: "Corzoneso" },
  { plz: "6723", city: "Prugiasco" },
  { plz: "6723", city: "Castro" },
  { plz: "6723", city: "Marolta" },
  { plz: "6724", city: "Ponto Valentino" },
  { plz: "6724", city: "Largario" },
  { plz: "6742", city: "Pollegio" },
  { plz: "6743", city: "Bodio TI" },
  { plz: "6744", city: "Personico" },
  { plz: "6745", city: "Giornico" },
  { plz: "6746", city: "Calonico" },
  { plz: "6746", city: "Nivo" },
  { plz: "6746", city: "Lavorgo" },
  { plz: "6747", city: "Chironico" },
  { plz: "6748", city: "Anzonico" },
  { plz: "6749", city: "Sobrio" },
  { plz: "6749", city: "Cavagnago" },
  { plz: "6760", city: "Molare" },
  { plz: "6760", city: "Calpiogna" },
  { plz: "6760", city: "Carì" },
  { plz: "6760", city: "Rossura" },
  { plz: "6760", city: "Faido" },
  { plz: "6760", city: "Campello" },
  { plz: "6763", city: "Mairengo" },
  { plz: "6763", city: "Osco" },
  { plz: "6764", city: "Chiggiogna" },
  { plz: "6772", city: "Rodi-Fiesso" },
  { plz: "6773", city: "Prato (Leventina)" },
  { plz: "6774", city: "Dalpe" },
  { plz: "6775", city: "Ambrì" },
  { plz: "6776", city: "Piotta" },
  { plz: "6777", city: "Quinto" },
  { plz: "6777", city: "Varenzo" },
  { plz: "6780", city: "Madrano" },
  { plz: "6780", city: "Airolo" },
  { plz: "6781", city: "Villa Bedretto" },
  { plz: "6781", city: "Bedretto" },
  { plz: "6802", city: "Rivera" },
  { plz: "6803", city: "Camignolo" },
  { plz: "6804", city: "Bironico" },
  { plz: "6805", city: "Mezzovico" },
  { plz: "6806", city: "Sigirino" },
  { plz: "6807", city: "Taverne" },
  { plz: "6808", city: "Torricella" },
  { plz: "6809", city: "Medeglia" },
  { plz: "6810", city: "Isone" },
  { plz: "6814", city: "Lamone" },
  { plz: "6814", city: "Cadempino" },
  { plz: "6815", city: "Melide" },
  { plz: "6816", city: "Bissone" },
  { plz: "6817", city: "Maroggia" },
  { plz: "6818", city: "Melano" },
  { plz: "6821", city: "Rovio" },
  { plz: "6822", city: "Arogno" },
  { plz: "6823", city: "Pugerna" },
  { plz: "6825", city: "Capolago" },
  { plz: "6826", city: "Riva San Vitale" },
  { plz: "6827", city: "Brusino Arsizio" },
  { plz: "6828", city: "Balerna" },
  { plz: "6830", city: "Chiasso" },
  { plz: "6832", city: "Seseglio" },
  { plz: "6832", city: "Pedrinate" },
  { plz: "6833", city: "Vacallo" },
  { plz: "6834", city: "Morbio Inferiore" },
  { plz: "6835", city: "Morbio Superiore" },
  { plz: "6837", city: "Bruzella" },
  { plz: "6837", city: "Caneggio" },
  { plz: "6838", city: "Cabbio" },
  { plz: "6838", city: "Scudellate" },
  { plz: "6838", city: "Muggio" },
  { plz: "6839", city: "Sagno" },
  { plz: "6850", city: "Mendrisio" },
  { plz: "6852", city: "Genestrerio" },
  { plz: "6853", city: "Ligornetto" },
  { plz: "6854", city: "S. Pietro" },
  { plz: "6855", city: "Stabio" },
  { plz: "6862", city: "Rancate" },
  { plz: "6863", city: "Besazio" },
  { plz: "6864", city: "Arzo" },
  { plz: "6865", city: "Tremona" },
  { plz: "6866", city: "Meride" },
  { plz: "6867", city: "Serpiano" },
  { plz: "6872", city: "Salorino" },
  { plz: "6872", city: "Somazzo" },
  { plz: "6873", city: "Corteglia" },
  { plz: "6874", city: "Castel San Pietro" },
  { plz: "6875", city: "Casima" },
  { plz: "6875", city: "Monte" },
  { plz: "6877", city: "Coldrerio" },
  { plz: "6883", city: "Novazzano" },
  { plz: "6900", city: "Lugano" },
  { plz: "6900", city: "Massagno" },
  { plz: "6900", city: "Paradiso" },
  { plz: "6911", city: "Campione d'Italia" },
  { plz: "6912", city: "Pazzallo" },
  { plz: "6913", city: "Carabbia" },
  { plz: "6914", city: "Carona" },
  { plz: "6915", city: "Pambio-Noranco" },
  { plz: "6916", city: "Grancia" },
  { plz: "6917", city: "Barbengo" },
  { plz: "6918", city: "Figino" },
  { plz: "6919", city: "Carabietta" },
  { plz: "6921", city: "Vico Morcote" },
  { plz: "6922", city: "Morcote" },
  { plz: "6924", city: "Sorengo" },
  { plz: "6925", city: "Gentilino" },
  { plz: "6926", city: "Montagnola" },
  { plz: "6927", city: "Agra" },
  { plz: "6928", city: "Manno" },
  { plz: "6929", city: "Gravesano" },
  { plz: "6930", city: "Bedano" },
  { plz: "6932", city: "Breganzona" },
  { plz: "6933", city: "Muzzano" },
  { plz: "6934", city: "Bioggio" },
  { plz: "6935", city: "Bosco Luganese" },
  { plz: "6936", city: "Cademario" },
  { plz: "6937", city: "Breno" },
  { plz: "6938", city: "Vezio" },
  { plz: "6938", city: "Fescoggia" },
  { plz: "6939", city: "Arosio" },
  { plz: "6939", city: "Mugena" },
  { plz: "6942", city: "Savosa" },
  { plz: "6943", city: "Vezia" },
  { plz: "6944", city: "Cureglia" },
  { plz: "6945", city: "Origlio" },
  { plz: "6946", city: "Ponte Capriasca" },
  { plz: "6947", city: "Vaglio" },
  { plz: "6948", city: "Porza" },
  { plz: "6949", city: "Comano" },
  { plz: "6950", city: "Tesserete" },
  { plz: "6951", city: "Insone" },
  { plz: "6951", city: "Odogno" },
  { plz: "6951", city: "Colla" },
  { plz: "6951", city: "Bogno" },
  { plz: "6951", city: "Cozzo" },
  { plz: "6951", city: "Signôra" },
  { plz: "6951", city: "Scareglia" },
  { plz: "6952", city: "Canobbio" },
  { plz: "6953", city: "Lugaggia" },
  { plz: "6954", city: "Sala Capriasca" },
  { plz: "6954", city: "Bigorio" },
  { plz: "6955", city: "Cagiallo" },
  { plz: "6955", city: "Oggio" },
  { plz: "6956", city: "Lopagno" },
  { plz: "6957", city: "Roveredo TI" },
  { plz: "6958", city: "Bidogno" },
  { plz: "6958", city: "Corticiasca" },
  { plz: "6959", city: "Cimadera" },
  { plz: "6959", city: "Certara" },
  { plz: "6959", city: "Curtina" },
  { plz: "6959", city: "Piandera Paese" },
  { plz: "6959", city: "Maglio di Colla" },
  { plz: "6962", city: "Viganello" },
  { plz: "6963", city: "Cureggia" },
  { plz: "6963", city: "Pregassona" },
  { plz: "6964", city: "Davesco-Soragno" },
  { plz: "6965", city: "Cadro" },
  { plz: "6966", city: "Villa Luganese" },
  { plz: "6967", city: "Dino" },
  { plz: "6968", city: "Sonvico" },
  { plz: "6974", city: "Aldesago" },
  { plz: "6976", city: "Castagnola" },
  { plz: "6977", city: "Ruvigliana" },
  { plz: "6978", city: "Gandria" },
  { plz: "6979", city: "Brè sopra Lugano" },
  { plz: "6980", city: "Castelrotto" },
  { plz: "6981", city: "Bedigliora" },
  { plz: "6981", city: "Biogno-Beride" },
  { plz: "6981", city: "Bombinasco" },
  { plz: "6981", city: "Banco" },
  { plz: "6982", city: "Agno" },
  { plz: "6983", city: "Magliaso" },
  { plz: "6984", city: "Pura" },
  { plz: "6986", city: "Miglieglia" },
  { plz: "6986", city: "Curio" },
  { plz: "6986", city: "Novaggio" },
  { plz: "6987", city: "Caslano" },
  { plz: "6988", city: "Ponte Tresa" },
  { plz: "6989", city: "Purasca" },
  { plz: "6990", city: "Cassina d'Agno" },
  { plz: "6991", city: "Neggio" },
  { plz: "6992", city: "Cimo" },
  { plz: "6992", city: "Vernate" },
  { plz: "6993", city: "Iseo" },
  { plz: "6994", city: "Aranno" },
  { plz: "6995", city: "Madonna del Piano" },
  { plz: "6995", city: "Molinazzo di Monteggio" },
  { plz: "6996", city: "Ponte Cremenaga" },
  { plz: "6997", city: "Sessa" },
  { plz: "6998", city: "Termine" },
  { plz: "6999", city: "Astano" },
  { plz: "7000", city: "Chur" },
  { plz: "7012", city: "Felsberg" },
  { plz: "7013", city: "Domat/Ems" },
  { plz: "7014", city: "Trin" },
  { plz: "7015", city: "Tamins" },
  { plz: "7016", city: "Trin Mulin" },
  { plz: "7017", city: "Flims Dorf" },
  { plz: "7018", city: "Flims Waldhaus" },
  { plz: "7019", city: "Fidaz" },
  { plz: "7023", city: "Haldenstein" },
  { plz: "7026", city: "Maladers" },
  { plz: "7027", city: "Lüen" },
  { plz: "7027", city: "Castiel" },
  { plz: "7027", city: "Calfreisen" },
  { plz: "7028", city: "Pagig" },
  { plz: "7028", city: "St. Peter" },
  { plz: "7029", city: "Peist" },
  { plz: "7031", city: "Laax GR" },
  { plz: "7032", city: "Laax GR 2" },
  { plz: "7050", city: "Arosa" },
  { plz: "7056", city: "Molinis" },
  { plz: "7057", city: "Langwies" },
  { plz: "7058", city: "Litzirüti" },
  { plz: "7062", city: "Passugg-Araschgen" },
  { plz: "7063", city: "Praden" },
  { plz: "7064", city: "Tschiertschen" },
  { plz: "7074", city: "Malix" },
  { plz: "7075", city: "Churwalden" },
  { plz: "7076", city: "Parpan" },
  { plz: "7077", city: "Valbella" },
  { plz: "7078", city: "Lenzerheide/Lai" },
  { plz: "7082", city: "Vaz/Obervaz" },
  { plz: "7083", city: "Lantsch/Lenz" },
  { plz: "7084", city: "Brienz/Brinzauls GR" },
  { plz: "7104", city: "Arezen" },
  { plz: "7104", city: "Versam" },
  { plz: "7106", city: "Tenna" },
  { plz: "7107", city: "Safien Platz" },
  { plz: "7109", city: "Thalkirch" },
  { plz: "7110", city: "Peiden" },
  { plz: "7111", city: "Pitasch" },
  { plz: "7112", city: "Duvin" },
  { plz: "7113", city: "Camuns" },
  { plz: "7114", city: "Uors (Lumnezia)" },
  { plz: "7115", city: "Surcasti" },
  { plz: "7116", city: "Tersnaus" },
  { plz: "7116", city: "St. Martin (Lugnez)" },
  { plz: "7122", city: "Valendas" },
  { plz: "7122", city: "Carrera" },
  { plz: "7126", city: "Castrisch" },
  { plz: "7127", city: "Sevgein" },
  { plz: "7128", city: "Riein" },
  { plz: "7130", city: "Schnaus" },
  { plz: "7130", city: "Ilanz" },
  { plz: "7132", city: "Vals" },
  { plz: "7133", city: "Obersaxen Affeier" },
  { plz: "7134", city: "Obersaxen Meierhof" },
  { plz: "7135", city: "Obersaxen Giraniga" },
  { plz: "7136", city: "Obersaxen Friggahüs" },
  { plz: "7137", city: "Flond" },
  { plz: "7138", city: "Surcuolm" },
  { plz: "7141", city: "Luven" },
  { plz: "7142", city: "Cumbel" },
  { plz: "7143", city: "Morissen" },
  { plz: "7144", city: "Vella" },
  { plz: "7145", city: "Degen" },
  { plz: "7146", city: "Vattiz" },
  { plz: "7147", city: "Vignogn" },
  { plz: "7148", city: "Surin" },
  { plz: "7148", city: "Lumbrein" },
  { plz: "7149", city: "Vrin" },
  { plz: "7151", city: "Schluein" },
  { plz: "7152", city: "Sagogn" },
  { plz: "7153", city: "Falera" },
  { plz: "7154", city: "Ruschein" },
  { plz: "7155", city: "Ladir" },
  { plz: "7156", city: "Pigniu" },
  { plz: "7156", city: "Rueun" },
  { plz: "7157", city: "Siat" },
  { plz: "7158", city: "Waltensburg/Vuorz" },
  { plz: "7159", city: "Andiast" },
  { plz: "7162", city: "Tavanasa" },
  { plz: "7163", city: "Danis" },
  { plz: "7164", city: "Dardin" },
  { plz: "7165", city: "Breil/Brigels" },
  { plz: "7166", city: "Trun" },
  { plz: "7167", city: "Zignau" },
  { plz: "7168", city: "Schlans" },
  { plz: "7172", city: "Rabius" },
  { plz: "7173", city: "Surrein" },
  { plz: "7174", city: "S. Benedetg" },
  { plz: "7175", city: "Sumvitg" },
  { plz: "7176", city: "Cumpadials" },
  { plz: "7180", city: "Disentis/Mustér" },
  { plz: "7182", city: "Cavardiras" },
  { plz: "7183", city: "Mompé Medel" },
  { plz: "7184", city: "Curaglia" },
  { plz: "7185", city: "Platta" },
  { plz: "7186", city: "Segnas" },
  { plz: "7187", city: "Camischolas" },
  { plz: "7188", city: "Sedrun" },
  { plz: "7189", city: "Rueras" },
  { plz: "7201", city: "Untervaz Bahnhof" },
  { plz: "7202", city: "Says" },
  { plz: "7203", city: "Trimmis" },
  { plz: "7204", city: "Untervaz" },
  { plz: "7205", city: "Zizers" },
  { plz: "7206", city: "Igis" },
  { plz: "7208", city: "Malans GR" },
  { plz: "7212", city: "Seewis Dorf" },
  { plz: "7213", city: "Valzeina" },
  { plz: "7214", city: "Seewis-Pardisla" },
  { plz: "7214", city: "Seewis-Schmitten" },
  { plz: "7214", city: "Grüsch" },
  { plz: "7215", city: "Fanas" },
  { plz: "7220", city: "Schiers" },
  { plz: "7222", city: "Mittellunden" },
  { plz: "7223", city: "Buchen im Prättigau" },
  { plz: "7224", city: "Putz" },
  { plz: "7226", city: "Stels" },
  { plz: "7226", city: "Fajauna" },
  { plz: "7228", city: "Schuders" },
  { plz: "7228", city: "Pusserein" },
  { plz: "7231", city: "Pragg-Jenaz" },
  { plz: "7232", city: "Furna" },
  { plz: "7233", city: "Jenaz" },
  { plz: "7235", city: "Fideris" },
  { plz: "7240", city: "Küblis" },
  { plz: "7241", city: "Conters im Prättigau" },
  { plz: "7242", city: "Luzein" },
  { plz: "7243", city: "Pany" },
  { plz: "7244", city: "Gadenstätt" },
  { plz: "7245", city: "Ascharina" },
  { plz: "7246", city: "St. Antönien" },
  { plz: "7247", city: "Saas im Prättigau" },
  { plz: "7249", city: "Serneus" },
  { plz: "7250", city: "Klosters" },
  { plz: "7252", city: "Klosters Dorf" },
  { plz: "7260", city: "Davos Dorf" },
  { plz: "7265", city: "Davos Wolfgang" },
  { plz: "7270", city: "Davos Platz" },
  { plz: "7272", city: "Davos Clavadel" },
  { plz: "7276", city: "Davos Frauenkirch" },
  { plz: "7277", city: "Davos Glaris" },
  { plz: "7278", city: "Davos Monstein" },
  { plz: "7302", city: "Landquart" },
  { plz: "7303", city: "Mastrils" },
  { plz: "7304", city: "Maienfeld" },
  { plz: "7306", city: "Fläsch" },
  { plz: "7307", city: "Jenins" },
  { plz: "7310", city: "Bad Ragaz" },
  { plz: "7312", city: "Pfäfers" },
  { plz: "7313", city: "St. Margrethenberg" },
  { plz: "7314", city: "Vadura" },
  { plz: "7315", city: "Vättis" },
  { plz: "7317", city: "Vasön" },
  { plz: "7317", city: "Valens" },
  { plz: "7320", city: "Sargans" },
  { plz: "7323", city: "Wangs" },
  { plz: "7324", city: "Vilters" },
  { plz: "7325", city: "Schwendi im Weisstannental" },
  { plz: "7326", city: "Weisstannen" },
  { plz: "7402", city: "Bonaduz" },
  { plz: "7403", city: "Rhäzüns" },
  { plz: "7404", city: "Feldis/Veulden" },
  { plz: "7405", city: "Rothenbrunnen" },
  { plz: "7407", city: "Trans" },
  { plz: "7408", city: "Realta" },
  { plz: "7408", city: "Cazis" },
  { plz: "7411", city: "Sils im Domleschg" },
  { plz: "7412", city: "Scharans" },
  { plz: "7413", city: "Fürstenaubruck" },
  { plz: "7414", city: "Fürstenau" },
  { plz: "7415", city: "Pratval" },
  { plz: "7415", city: "Rodels" },
  { plz: "7416", city: "Almens" },
  { plz: "7417", city: "Paspels" },
  { plz: "7418", city: "Tumegl/Tomils" },
  { plz: "7419", city: "Scheid" },
  { plz: "7421", city: "Summaprada" },
  { plz: "7422", city: "Tartar" },
  { plz: "7423", city: "Sarn" },
  { plz: "7423", city: "Portein" },
  { plz: "7424", city: "Präz" },
  { plz: "7424", city: "Dalin" },
  { plz: "7425", city: "Masein" },
  { plz: "7426", city: "Flerden" },
  { plz: "7427", city: "Urmein" },
  { plz: "7428", city: "Tschappina" },
  { plz: "7428", city: "Glaspass" },
  { plz: "7430", city: "Rongellen" },
  { plz: "7430", city: "Thusis" },
  { plz: "7431", city: "Mutten" },
  { plz: "7431", city: "Obermutten" },
  { plz: "7432", city: "Zillis" },
  { plz: "7433", city: "Farden" },
  { plz: "7433", city: "Wergenstein" },
  { plz: "7433", city: "Lohn GR" },
  { plz: "7433", city: "Donat" },
  { plz: "7433", city: "Mathon" },
  { plz: "7434", city: "Sufers" },
  { plz: "7435", city: "Splügen" },
  { plz: "7436", city: "Medels im Rheinwald" },
  { plz: "7437", city: "Nufenen" },
  { plz: "7438", city: "Hinterrhein" },
  { plz: "7440", city: "Andeer" },
  { plz: "7442", city: "Clugin" },
  { plz: "7443", city: "Pignia" },
  { plz: "7444", city: "Ausserferrera" },
  { plz: "7445", city: "Innerferrera" },
  { plz: "7446", city: "Campsut-Cröt" },
  { plz: "7447", city: "Cresta (Avers)" },
  { plz: "7447", city: "Am Bach (Avers)" },
  { plz: "7448", city: "Juf" },
  { plz: "7450", city: "Tiefencastel" },
  { plz: "7451", city: "Alvaschein" },
  { plz: "7452", city: "Cunter" },
  { plz: "7453", city: "Tinizong" },
  { plz: "7454", city: "Rona" },
  { plz: "7455", city: "Mulegns" },
  { plz: "7456", city: "Sur" },
  { plz: "7456", city: "Marmorera" },
  { plz: "7457", city: "Bivio" },
  { plz: "7458", city: "Mon" },
  { plz: "7459", city: "Stierva" },
  { plz: "7460", city: "Savognin" },
  { plz: "7462", city: "Salouf" },
  { plz: "7463", city: "Riom" },
  { plz: "7464", city: "Parsonz" },
  { plz: "7472", city: "Surava" },
  { plz: "7473", city: "Alvaneu Bad" },
  { plz: "7477", city: "Filisur" },
  { plz: "7482", city: "Stugl/Stuls" },
  { plz: "7482", city: "Preda" },
  { plz: "7482", city: "Bergün/Bravuogn" },
  { plz: "7484", city: "Latsch" },
  { plz: "7492", city: "Alvaneu Dorf" },
  { plz: "7493", city: "Schmitten (Albula)" },
  { plz: "7494", city: "Wiesen GR" },
  { plz: "7500", city: "St. Moritz" },
  { plz: "7502", city: "Bever" },
  { plz: "7503", city: "Samedan" },
  { plz: "7504", city: "Pontresina" },
  { plz: "7505", city: "Celerina/Schlarigna" },
  { plz: "7512", city: "Champfèr" },
  { plz: "7513", city: "Silvaplana-Surlej" },
  { plz: "7513", city: "Silvaplana" },
  { plz: "7514", city: "Fex" },
  { plz: "7514", city: "Sils/Segl Maria" },
  { plz: "7515", city: "Sils/Segl Baselgia" },
  { plz: "7516", city: "Maloja" },
  { plz: "7517", city: "Plaun da Lej" },
  { plz: "7522", city: "La Punt-Chamues-ch" },
  { plz: "7523", city: "Madulain" },
  { plz: "7524", city: "Zuoz" },
  { plz: "7525", city: "S-chanf" },
  { plz: "7526", city: "Cinuos-chel" },
  { plz: "7527", city: "Brail" },
  { plz: "7530", city: "Zernez" },
  { plz: "7532", city: "Tschierv" },
  { plz: "7533", city: "Fuldera" },
  { plz: "7534", city: "Lü" },
  { plz: "7535", city: "Valchava" },
  { plz: "7536", city: "Sta. Maria Val Müstair" },
  { plz: "7537", city: "Müstair" },
  { plz: "7542", city: "Susch" },
  { plz: "7543", city: "Lavin" },
  { plz: "7545", city: "Guarda" },
  { plz: "7546", city: "Ardez" },
  { plz: "7550", city: "Scuol" },
  { plz: "7551", city: "Ftan" },
  { plz: "7552", city: "Vulpera" },
  { plz: "7553", city: "Tarasp" },
  { plz: "7554", city: "Crusch" },
  { plz: "7554", city: "Sent" },
  { plz: "7556", city: "Ramosch" },
  { plz: "7557", city: "Vnà" },
  { plz: "7558", city: "Strada" },
  { plz: "7559", city: "Tschlin" },
  { plz: "7560", city: "Martina" },
  { plz: "7562", city: "Samnaun-Compatsch" },
  { plz: "7563", city: "Samnaun Dorf" },
  { plz: "7602", city: "Casaccia" },
  { plz: "7603", city: "Vicosoprano" },
  { plz: "7604", city: "Borgonovo" },
  { plz: "7605", city: "Stampa" },
  { plz: "7606", city: "Promontogno" },
  { plz: "7608", city: "Castasegna" },
  { plz: "7610", city: "Soglio" },
  { plz: "7710", city: "Ospizio Bernina" },
  { plz: "7710", city: "Alp Grüm" },
  { plz: "7741", city: "S. Carlo (Poschiavo)" },
  { plz: "7742", city: "Sfazù" },
  { plz: "7742", city: "La Rösa" },
  { plz: "7742", city: "Poschiavo" },
  { plz: "7743", city: "Miralago" },
  { plz: "7743", city: "Brusio" },
  { plz: "7744", city: "Campocologno" },
  { plz: "7745", city: "Li Curt" },
  { plz: "7746", city: "Le Prese" },
  { plz: "7747", city: "Viano" },
  { plz: "7748", city: "Campascio" },
  { plz: "8000", city: "Zürich" },
  { plz: "8001", city: "Zürich" },
  { plz: "8002", city: "Zürich" },
  { plz: "8003", city: "Zürich" },
  { plz: "8004", city: "Zürich" },
  { plz: "8005", city: "Zürich" },
  { plz: "8006", city: "Zürich" },
  { plz: "8008", city: "Zürich" },
  { plz: "8032", city: "Zürich" },
  { plz: "8037", city: "Zürich" },
  { plz: "8038", city: "Zürich" },
  { plz: "8041", city: "Zürich" },
  { plz: "8044", city: "Gockhausen" },
  { plz: "8044", city: "Zürich" },
  { plz: "8045", city: "Zürich" },
  { plz: "8046", city: "Zürich" },
  { plz: "8047", city: "Zürich" },
  { plz: "8048", city: "Zürich" },
  { plz: "8049", city: "Zürich" },
  { plz: "8050", city: "Zürich" },
  { plz: "8051", city: "Zürich" },
  { plz: "8052", city: "Zürich" },
  { plz: "8053", city: "Zürich" },
  { plz: "8055", city: "Zürich" },
  { plz: "8057", city: "Zürich" },
  { plz: "8063", city: "Zürich" },
  { plz: "8064", city: "Zürich" },
  { plz: "8099", city: "Zürich" },
  { plz: "8102", city: "Oberengstringen" },
  { plz: "8103", city: "Unterengstringen" },
  { plz: "8104", city: "Weiningen ZH" },
  { plz: "8105", city: "Regensdorf" },
  { plz: "8105", city: "Watt" },
  { plz: "8106", city: "Adlikon b. Regensdorf" },
  { plz: "8107", city: "Buchs ZH" },
  { plz: "8108", city: "Dällikon" },
  { plz: "8109", city: "Kloster Fahr" },
  { plz: "8112", city: "Otelfingen" },
  { plz: "8113", city: "Boppelsen" },
  { plz: "8114", city: "Dänikon ZH" },
  { plz: "8115", city: "Hüttikon" },
  { plz: "8117", city: "Fällanden" },
  { plz: "8118", city: "Pfaffhausen" },
  { plz: "8121", city: "Benglen" },
  { plz: "8122", city: "Binz" },
  { plz: "8123", city: "Ebmatingen" },
  { plz: "8124", city: "Maur" },
  { plz: "8125", city: "Zollikerberg" },
  { plz: "8126", city: "Zumikon" },
  { plz: "8127", city: "Forch" },
  { plz: "8132", city: "Hinteregg" },
  { plz: "8132", city: "Egg b. Zürich" },
  { plz: "8133", city: "Esslingen" },
  { plz: "8134", city: "Adliswil" },
  { plz: "8135", city: "Sihlbrugg Station" },
  { plz: "8135", city: "Sihlwald" },
  { plz: "8135", city: "Langnau am Albis" },
  { plz: "8136", city: "Gattikon" },
  { plz: "8142", city: "Uitikon Waldegg" },
  { plz: "8143", city: "Uetliberg" },
  { plz: "8143", city: "Stallikon" },
  { plz: "8152", city: "Glattpark (Opfikon)" },
  { plz: "8152", city: "Glattbrugg" },
  { plz: "8152", city: "Opfikon" },
  { plz: "8153", city: "Rümlang" },
  { plz: "8154", city: "Oberglatt ZH" },
  { plz: "8155", city: "Niederhasli" },
  { plz: "8156", city: "Oberhasli" },
  { plz: "8157", city: "Dielsdorf" },
  { plz: "8158", city: "Regensberg" },
  { plz: "8162", city: "Steinmaur" },
  { plz: "8164", city: "Bachs" },
  { plz: "8165", city: "Oberweningen" },
  { plz: "8165", city: "Schleinikon" },
  { plz: "8165", city: "Schöfflisdorf" },
  { plz: "8166", city: "Niederweningen" },
  { plz: "8172", city: "Niederglatt ZH" },
  { plz: "8173", city: "Neerach" },
  { plz: "8174", city: "Stadel b. Niederglatt" },
  { plz: "8175", city: "Windlach" },
  { plz: "8180", city: "Bülach" },
  { plz: "8181", city: "Höri" },
  { plz: "8182", city: "Hochfelden" },
  { plz: "8184", city: "Bachenbülach" },
  { plz: "8185", city: "Winkel" },
  { plz: "8187", city: "Weiach" },
  { plz: "8192", city: "Zweidlen" },
  { plz: "8192", city: "Glattfelden" },
  { plz: "8193", city: "Eglisau" },
  { plz: "8194", city: "Hüntwangen" },
  { plz: "8195", city: "Wasterkingen" },
  { plz: "8196", city: "Wil ZH" },
  { plz: "8197", city: "Rafz" },
  { plz: "8200", city: "Schaffhausen" },
  { plz: "8203", city: "Schaffhausen" },
  { plz: "8207", city: "Schaffhausen" },
  { plz: "8208", city: "Schaffhausen" },
  { plz: "8212", city: "Neuhausen am Rheinfall" },
  { plz: "8212", city: "Nohl" },
  { plz: "8213", city: "Neunkirch" },
  { plz: "8214", city: "Gächlingen" },
  { plz: "8215", city: "Hallau" },
  { plz: "8216", city: "Oberhallau" },
  { plz: "8217", city: "Wilchingen" },
  { plz: "8218", city: "Osterfingen" },
  { plz: "8219", city: "Trasadingen" },
  { plz: "8222", city: "Beringen" },
  { plz: "8223", city: "Guntmadingen" },
  { plz: "8224", city: "Löhningen" },
  { plz: "8225", city: "Siblingen" },
  { plz: "8226", city: "Schleitheim" },
  { plz: "8228", city: "Beggingen" },
  { plz: "8231", city: "Hemmental" },
  { plz: "8232", city: "Merishausen" },
  { plz: "8233", city: "Bargen SH" },
  { plz: "8234", city: "Stetten SH" },
  { plz: "8235", city: "Lohn SH" },
  { plz: "8236", city: "Opfertshofen SH" },
  { plz: "8236", city: "Büttenhardt" },
  { plz: "8238", city: "Büsingen" },
  { plz: "8239", city: "Dörflingen" },
  { plz: "8240", city: "Thayngen" },
  { plz: "8241", city: "Barzheim" },
  { plz: "8242", city: "Hofen SH" },
  { plz: "8242", city: "Bibern SH" },
  { plz: "8243", city: "Altdorf SH" },
  { plz: "8245", city: "Feuerthalen" },
  { plz: "8246", city: "Langwiesen" },
  { plz: "8247", city: "Flurlingen" },
  { plz: "8248", city: "Uhwiesen" },
  { plz: "8252", city: "Schlatt TG" },
  { plz: "8253", city: "Willisdorf" },
  { plz: "8253", city: "Diessenhofen" },
  { plz: "8254", city: "Basadingen" },
  { plz: "8255", city: "Schlattingen" },
  { plz: "8259", city: "Kaltenbach" },
  { plz: "8259", city: "Etzwilen" },
  { plz: "8259", city: "Wagenhausen" },
  { plz: "8259", city: "Rheinklingen" },
  { plz: "8260", city: "Stein am Rhein" },
  { plz: "8261", city: "Hemishofen" },
  { plz: "8262", city: "Ramsen" },
  { plz: "8263", city: "Buch SH" },
  { plz: "8264", city: "Eschenz" },
  { plz: "8265", city: "Mammern" },
  { plz: "8266", city: "Steckborn" },
  { plz: "8267", city: "Berlingen" },
  { plz: "8268", city: "Salenstein" },
  { plz: "8268", city: "Mannenbach-Salenstein" },
  { plz: "8269", city: "Fruthwilen" },
  { plz: "8272", city: "Ermatingen" },
  { plz: "8273", city: "Triboltingen" },
  { plz: "8274", city: "Gottlieben" },
  { plz: "8274", city: "Tägerwilen" },
  { plz: "8280", city: "Kreuzlingen" },
  { plz: "8302", city: "Kloten" },
  { plz: "8303", city: "Bassersdorf" },
  { plz: "8304", city: "Wallisellen" },
  { plz: "8305", city: "Dietlikon" },
  { plz: "8306", city: "Brüttisellen" },
  { plz: "8307", city: "Ottikon b. Kemptthal" },
  { plz: "8307", city: "Effretikon" },
  { plz: "8308", city: "Agasul" },
  { plz: "8308", city: "Illnau" },
  { plz: "8309", city: "Nürensdorf" },
  { plz: "8310", city: "Kemptthal" },
  { plz: "8310", city: "Grafstal" },
  { plz: "8311", city: "Brütten" },
  { plz: "8312", city: "Winterberg ZH" },
  { plz: "8314", city: "Kyburg" },
  { plz: "8315", city: "Lindau" },
  { plz: "8317", city: "Tagelswangen" },
  { plz: "8320", city: "Fehraltorf" },
  { plz: "8322", city: "Madetswil" },
  { plz: "8330", city: "Hermatswil" },
  { plz: "8330", city: "Pfäffikon ZH" },
  { plz: "8331", city: "Auslikon" },
  { plz: "8332", city: "Russikon" },
  { plz: "8335", city: "Hittnau" },
  { plz: "8340", city: "Hinwil" },
  { plz: "8342", city: "Wernetshausen" },
  { plz: "8344", city: "Bäretswil" },
  { plz: "8345", city: "Adetswil" },
  { plz: "8352", city: "Ricketwil (Winterthur)" },
  { plz: "8352", city: "Elsau" },
  { plz: "8353", city: "Elgg" },
  { plz: "8354", city: "Hofstetten ZH" },
  { plz: "8355", city: "Aadorf" },
  { plz: "8356", city: "Ettenhausen TG" },
  { plz: "8357", city: "Guntershausen b. Aadorf" },
  { plz: "8360", city: "Wallenwil" },
  { plz: "8360", city: "Eschlikon TG" },
  { plz: "8362", city: "Balterswil" },
  { plz: "8363", city: "Bichelsee" },
  { plz: "8370", city: "Busswil TG" },
  { plz: "8370", city: "Sirnach" },
  { plz: "8372", city: "Wiezikon b. Sirnach" },
  { plz: "8374", city: "Oberwangen TG" },
  { plz: "8374", city: "Dussnang" },
  { plz: "8376", city: "Fischingen" },
  { plz: "8376", city: "Au TG" },
  { plz: "8400", city: "Winterthur" },
  { plz: "8404", city: "Reutlingen (Winterthur)" },
  { plz: "8404", city: "Stadel (Winterthur)" },
  { plz: "8404", city: "Winterthur" },
  { plz: "8405", city: "Winterthur" },
  { plz: "8406", city: "Winterthur" },
  { plz: "8408", city: "Winterthur" },
  { plz: "8409", city: "Winterthur" },
  { plz: "8412", city: "Aesch (Neftenbach)" },
  { plz: "8412", city: "Riet (Neftenbach)" },
  { plz: "8412", city: "Hünikon (Neftenbach)" },
  { plz: "8413", city: "Neftenbach" },
  { plz: "8414", city: "Buch am Irchel" },
  { plz: "8415", city: "Berg am Irchel" },
  { plz: "8415", city: "Gräslikon" },
  { plz: "8416", city: "Flaach" },
  { plz: "8418", city: "Schlatt b. Winterthur" },
  { plz: "8421", city: "Dättlikon" },
  { plz: "8422", city: "Pfungen" },
  { plz: "8424", city: "Embrach" },
  { plz: "8425", city: "Oberembrach" },
  { plz: "8426", city: "Lufingen" },
  { plz: "8427", city: "Freienstein" },
  { plz: "8427", city: "Rorbas" },
  { plz: "8428", city: "Teufen ZH" },
  { plz: "8442", city: "Hettlingen" },
  { plz: "8444", city: "Henggart" },
  { plz: "8447", city: "Dachsen" },
  { plz: "8450", city: "Andelfingen" },
  { plz: "8451", city: "Kleinandelfingen" },
  { plz: "8452", city: "Adlikon b. Andelfingen" },
  { plz: "8453", city: "Alten" },
  { plz: "8454", city: "Buchberg" },
  { plz: "8455", city: "Rüdlingen" },
  { plz: "8457", city: "Humlikon" },
  { plz: "8458", city: "Dorf" },
  { plz: "8459", city: "Volken" },
  { plz: "8460", city: "Marthalen" },
  { plz: "8461", city: "Oerlingen" },
  { plz: "8462", city: "Rheinau" },
  { plz: "8463", city: "Benken ZH" },
  { plz: "8464", city: "Ellikon am Rhein" },
  { plz: "8465", city: "Rudolfingen" },
  { plz: "8465", city: "Wildensbuch" },
  { plz: "8466", city: "Trüllikon" },
  { plz: "8467", city: "Truttikon" },
  { plz: "8468", city: "Waltalingen" },
  { plz: "8468", city: "Guntalingen" },
  { plz: "8471", city: "Rutschwil (Dägerlen)" },
  { plz: "8471", city: "Dägerlen" },
  { plz: "8471", city: "Oberwil (Dägerlen)" },
  { plz: "8471", city: "Berg (Dägerlen)" },
  { plz: "8471", city: "Bänk (Dägerlen)" },
  { plz: "8472", city: "Seuzach" },
  { plz: "8474", city: "Dinhard" },
  { plz: "8475", city: "Ossingen" },
  { plz: "8476", city: "Unterstammheim" },
  { plz: "8477", city: "Oberstammheim" },
  { plz: "8478", city: "Thalheim an der Thur" },
  { plz: "8479", city: "Altikon" },
  { plz: "8482", city: "Sennhof (Winterthur)" },
  { plz: "8483", city: "Kollbrunn" },
  { plz: "8484", city: "Neschwil" },
  { plz: "8484", city: "Theilingen" },
  { plz: "8484", city: "Weisslingen" },
  { plz: "8486", city: "Rikon im Tösstal" },
  { plz: "8487", city: "Rämismühle" },
  { plz: "8487", city: "Zell ZH" },
  { plz: "8488", city: "Turbenthal" },
  { plz: "8489", city: "Wildberg" },
  { plz: "8492", city: "Wila" },
  { plz: "8493", city: "Saland" },
  { plz: "8494", city: "Bauma" },
  { plz: "8495", city: "Schmidrüti" },
  { plz: "8496", city: "Steg im Tösstal" },
  { plz: "8497", city: "Fischenthal" },
  { plz: "8498", city: "Gibswil-Ried" },
  { plz: "8499", city: "Sternenberg" },
  { plz: "8500", city: "Frauenfeld" },
  { plz: "8500", city: "Gerlikon" },
  { plz: "8505", city: "Dettighofen" },
  { plz: "8505", city: "Pfyn" },
  { plz: "8506", city: "Lanzenneunforn" },
  { plz: "8507", city: "Hörhausen" },
  { plz: "8508", city: "Homburg" },
  { plz: "8512", city: "Lustdorf" },
  { plz: "8512", city: "Wetzikon TG" },
  { plz: "8512", city: "Thundorf" },
  { plz: "8514", city: "Amlikon-Bissegg" },
  { plz: "8522", city: "Häuslenen" },
  { plz: "8522", city: "Aawangen" },
  { plz: "8523", city: "Hagenbuch ZH" },
  { plz: "8524", city: "Buch b. Frauenfeld" },
  { plz: "8524", city: "Uesslingen" },
  { plz: "8525", city: "Niederneunforn" },
  { plz: "8525", city: "Wilen b. Neunforn" },
  { plz: "8526", city: "Oberneunforn" },
  { plz: "8532", city: "Weiningen TG" },
  { plz: "8532", city: "Warth" },
  { plz: "8535", city: "Herdern" },
  { plz: "8536", city: "Hüttwilen" },
  { plz: "8537", city: "Nussbaumen TG" },
  { plz: "8537", city: "Uerschhausen" },
  { plz: "8542", city: "Wiesendangen" },
  { plz: "8543", city: "Bertschikon" },
  { plz: "8543", city: "Gundetswil" },
  { plz: "8543", city: "Kefikon ZH" },
  { plz: "8544", city: "Attikon" },
  { plz: "8545", city: "Rickenbach ZH" },
  { plz: "8545", city: "Rickenbach Sulz" },
  { plz: "8546", city: "Kefikon TG" },
  { plz: "8546", city: "Islikon" },
  { plz: "8546", city: "Menzengrüt" },
  { plz: "8547", city: "Gachnang" },
  { plz: "8548", city: "Ellikon an der Thur" },
  { plz: "8552", city: "Felben-Wellhausen" },
  { plz: "8553", city: "Eschikofen" },
  { plz: "8553", city: "Harenwilen" },
  { plz: "8553", city: "Mettendorf TG" },
  { plz: "8553", city: "Hüttlingen" },
  { plz: "8554", city: "Müllheim-Wigoltingen" },
  { plz: "8554", city: "Bonau" },
  { plz: "8555", city: "Müllheim Dorf" },
  { plz: "8556", city: "Engwang" },
  { plz: "8556", city: "Illhart" },
  { plz: "8556", city: "Lamperswil TG" },
  { plz: "8556", city: "Wigoltingen" },
  { plz: "8558", city: "Raperswilen" },
  { plz: "8560", city: "Märstetten" },
  { plz: "8561", city: "Ottoberg" },
  { plz: "8564", city: "Lipperswil" },
  { plz: "8564", city: "Engwilen" },
  { plz: "8564", city: "Wäldi" },
  { plz: "8564", city: "Sonterswil" },
  { plz: "8564", city: "Wagerswil" },
  { plz: "8564", city: "Hattenhausen" },
  { plz: "8564", city: "Gunterswilen" },
  { plz: "8564", city: "Hefenhausen" },
  { plz: "8565", city: "Hugelshofen" },
  { plz: "8566", city: "Dotnacht" },
  { plz: "8566", city: "Neuwilen" },
  { plz: "8566", city: "Ellighausen" },
  { plz: "8566", city: "Lippoldswilen" },
  { plz: "8570", city: "Weinfelden" },
  { plz: "8572", city: "Andhausen" },
  { plz: "8572", city: "Graltshausen" },
  { plz: "8572", city: "Guntershausen b. Berg" },
  { plz: "8572", city: "Berg TG" },
  { plz: "8573", city: "Alterswilen" },
  { plz: "8573", city: "Altishausen" },
  { plz: "8573", city: "Siegershausen" },
  { plz: "8574", city: "Illighausen" },
  { plz: "8574", city: "Lengwil-Oberhofen" },
  { plz: "8575", city: "Istighofen" },
  { plz: "8575", city: "Bürglen TG" },
  { plz: "8576", city: "Mauren TG" },
  { plz: "8577", city: "Toos" },
  { plz: "8577", city: "Schönholzerswilen" },
  { plz: "8580", city: "Sommeri" },
  { plz: "8580", city: "Hagenwil b. Amriswil" },
  { plz: "8580", city: "Hefenhofen" },
  { plz: "8580", city: "Amriswil" },
  { plz: "8581", city: "Schocherswil" },
  { plz: "8582", city: "Dozwil" },
  { plz: "8583", city: "Götighofen" },
  { plz: "8583", city: "Donzhausen" },
  { plz: "8583", city: "Sulgen" },
  { plz: "8584", city: "Leimbach TG" },
  { plz: "8584", city: "Opfershofen TG" },
  { plz: "8585", city: "Happerswil" },
  { plz: "8585", city: "Eggethof" },
  { plz: "8585", city: "Zuben" },
  { plz: "8585", city: "Schönenbaumgarten" },
  { plz: "8585", city: "Herrenhof" },
  { plz: "8585", city: "Birwinken" },
  { plz: "8585", city: "Klarsreuti" },
  { plz: "8585", city: "Mattwil" },
  { plz: "8585", city: "Langrickenbach" },
  { plz: "8586", city: "Andwil TG" },
  { plz: "8586", city: "Kümmertshausen" },
  { plz: "8586", city: "Riedt b. Erlen" },
  { plz: "8586", city: "Buchackern" },
  { plz: "8586", city: "Engishofen" },
  { plz: "8586", city: "Ennetaach" },
  { plz: "8586", city: "Buch b. Kümmertshausen" },
  { plz: "8586", city: "Erlen" },
  { plz: "8587", city: "Oberaach" },
  { plz: "8588", city: "Zihlschlacht" },
  { plz: "8589", city: "Sitterdorf" },
  { plz: "8590", city: "Romanshorn" },
  { plz: "8592", city: "Uttwil" },
  { plz: "8593", city: "Kesswil" },
  { plz: "8594", city: "Güttingen" },
  { plz: "8595", city: "Altnau" },
  { plz: "8596", city: "Münsterlingen" },
  { plz: "8596", city: "Scherzingen" },
  { plz: "8597", city: "Landschlacht" },
  { plz: "8598", city: "Bottighofen" },
  { plz: "8599", city: "Salmsach" },
  { plz: "8600", city: "Dübendorf" },
  { plz: "8602", city: "Wangen b. Dübendorf" },
  { plz: "8603", city: "Schwerzenbach" },
  { plz: "8604", city: "Volketswil" },
  { plz: "8605", city: "Gutenswil" },
  { plz: "8606", city: "Nänikon" },
  { plz: "8606", city: "Greifensee" },
  { plz: "8607", city: "Aathal-Seegräben" },
  { plz: "8608", city: "Bubikon" },
  { plz: "8610", city: "Uster" },
  { plz: "8614", city: "Sulzbach" },
  { plz: "8614", city: "Bertschikon (Gossau ZH)" },
  { plz: "8615", city: "Wermatswil" },
  { plz: "8615", city: "Freudwil" },
  { plz: "8616", city: "Riedikon" },
  { plz: "8617", city: "Mönchaltorf" },
  { plz: "8618", city: "Oetwil am See" },
  { plz: "8620", city: "Wetzikon ZH" },
  { plz: "8623", city: "Wetzikon ZH" },
  { plz: "8624", city: "Grüt (Gossau ZH)" },
  { plz: "8625", city: "Gossau ZH" },
  { plz: "8626", city: "Ottikon (Gossau ZH)" },
  { plz: "8627", city: "Grüningen" },
  { plz: "8630", city: "Rüti ZH" },
  { plz: "8632", city: "Tann" },
  { plz: "8633", city: "Wolfhausen" },
  { plz: "8634", city: "Hombrechtikon" },
  { plz: "8635", city: "Dürnten" },
  { plz: "8636", city: "Wald ZH" },
  { plz: "8637", city: "Laupen ZH" },
  { plz: "8638", city: "Goldingen" },
  { plz: "8639", city: "Faltigberg" },
  { plz: "8640", city: "Rapperswil SG" },
  { plz: "8640", city: "Hurden" },
  { plz: "8645", city: "Jona" },
  { plz: "8646", city: "Wagen" },
  { plz: "8700", city: "Küsnacht ZH" },
  { plz: "8702", city: "Zollikon" },
  { plz: "8703", city: "Erlenbach ZH" },
  { plz: "8704", city: "Herrliberg" },
  { plz: "8706", city: "Meilen" },
  { plz: "8707", city: "Uetikon am See" },
  { plz: "8708", city: "Männedorf" },
  { plz: "8712", city: "Stäfa" },
  { plz: "8713", city: "Uerikon" },
  { plz: "8714", city: "Feldbach" },
  { plz: "8715", city: "Bollingen" },
  { plz: "8716", city: "Schmerikon" },
  { plz: "8717", city: "Benken SG" },
  { plz: "8718", city: "Schänis" },
  { plz: "8722", city: "Kaltbrunn" },
  { plz: "8723", city: "Rufi" },
  { plz: "8725", city: "Gebertingen" },
  { plz: "8725", city: "Ernetschwil" },
  { plz: "8726", city: "Ricken SG" },
  { plz: "8727", city: "Walde SG" },
  { plz: "8730", city: "Uznach" },
  { plz: "8732", city: "Neuhaus SG" },
  { plz: "8733", city: "Eschenbach SG" },
  { plz: "8734", city: "Ermenswil" },
  { plz: "8735", city: "Rüeterswil" },
  { plz: "8735", city: "St. Gallenkappel" },
  { plz: "8737", city: "Gommiswald" },
  { plz: "8738", city: "Uetliburg SG" },
  { plz: "8739", city: "Rieden SG" },
  { plz: "8750", city: "Klöntal" },
  { plz: "8750", city: "Glarus" },
  { plz: "8750", city: "Riedern" },
  { plz: "8751", city: "Urnerboden" },
  { plz: "8752", city: "Näfels" },
  { plz: "8753", city: "Mollis" },
  { plz: "8754", city: "Netstal" },
  { plz: "8755", city: "Ennenda" },
  { plz: "8756", city: "Mitlödi" },
  { plz: "8757", city: "Filzbach" },
  { plz: "8758", city: "Obstalden" },
  { plz: "8762", city: "Schwändi b. Schwanden" },
  { plz: "8762", city: "Sool" },
  { plz: "8762", city: "Schwanden GL" },
  { plz: "8765", city: "Engi" },
  { plz: "8766", city: "Matt" },
  { plz: "8767", city: "Elm" },
  { plz: "8772", city: "Nidfurn" },
  { plz: "8773", city: "Haslen GL" },
  { plz: "8774", city: "Leuggelbach" },
  { plz: "8775", city: "Luchsingen" },
  { plz: "8775", city: "Hätzingen" },
  { plz: "8777", city: "Diesbach GL" },
  { plz: "8777", city: "Betschwanden" },
  { plz: "8782", city: "Rüti GL" },
  { plz: "8783", city: "Linthal" },
  { plz: "8784", city: "Braunwald" },
  { plz: "8800", city: "Thalwil" },
  { plz: "8802", city: "Kilchberg ZH" },
  { plz: "8803", city: "Rüschlikon" },
  { plz: "8804", city: "Au ZH" },
  { plz: "8805", city: "Richterswil" },
  { plz: "8806", city: "Bäch SZ" },
  { plz: "8807", city: "Freienbach" },
  { plz: "8808", city: "Pfäffikon SZ" },
  { plz: "8810", city: "Horgen" },
  { plz: "8815", city: "Horgenberg" },
  { plz: "8816", city: "Hirzel" },
  { plz: "8820", city: "Wädenswil" },
  { plz: "8824", city: "Schönenberg ZH" },
  { plz: "8825", city: "Hütten" },
  { plz: "8832", city: "Wilen b. Wollerau" },
  { plz: "8832", city: "Wollerau" },
  { plz: "8833", city: "Samstagern" },
  { plz: "8834", city: "Schindellegi" },
  { plz: "8835", city: "Feusisberg" },
  { plz: "8836", city: "Bennau" },
  { plz: "8840", city: "Trachslau" },
  { plz: "8840", city: "Einsiedeln" },
  { plz: "8841", city: "Gross" },
  { plz: "8842", city: "Unteriberg" },
  { plz: "8843", city: "Oberiberg" },
  { plz: "8844", city: "Euthal" },
  { plz: "8845", city: "Studen SZ" },
  { plz: "8846", city: "Willerzell" },
  { plz: "8847", city: "Egg SZ" },
  { plz: "8849", city: "Alpthal" },
  { plz: "8852", city: "Altendorf" },
  { plz: "8853", city: "Lachen SZ" },
  { plz: "8854", city: "Siebnen" },
  { plz: "8854", city: "Galgenen" },
  { plz: "8855", city: "Wangen SZ" },
  { plz: "8856", city: "Tuggen" },
  { plz: "8857", city: "Vorderthal" },
  { plz: "8858", city: "Innerthal" },
  { plz: "8862", city: "Schübelbach" },
  { plz: "8863", city: "Buttikon SZ" },
  { plz: "8864", city: "Reichenburg" },
  { plz: "8865", city: "Bilten" },
  { plz: "8866", city: "Ziegelbrücke" },
  { plz: "8867", city: "Niederurnen" },
  { plz: "8868", city: "Oberurnen" },
  { plz: "8872", city: "Weesen" },
  { plz: "8873", city: "Amden" },
  { plz: "8874", city: "Mühlehorn" },
  { plz: "8877", city: "Murg" },
  { plz: "8878", city: "Quinten" },
  { plz: "8880", city: "Walenstadt" },
  { plz: "8881", city: "Knoblisbühl" },
  { plz: "8881", city: "Tscherlach" },
  { plz: "8881", city: "Walenstadtberg" },
  { plz: "8882", city: "Unterterzen" },
  { plz: "8883", city: "Quarten" },
  { plz: "8884", city: "Oberterzen" },
  { plz: "8885", city: "Mols" },
  { plz: "8886", city: "Mädris-Vermol" },
  { plz: "8887", city: "Mels" },
  { plz: "8888", city: "Heiligkreuz (Mels)" },
  { plz: "8889", city: "Plons" },
  { plz: "8890", city: "Flums" },
  { plz: "8892", city: "Berschis" },
  { plz: "8893", city: "Flums Hochwiese" },
  { plz: "8894", city: "Flumserberg Saxli" },
  { plz: "8895", city: "Flumserberg Portels" },
  { plz: "8896", city: "Flumserberg Bergheim" },
  { plz: "8897", city: "Flumserberg Tannenheim" },
  { plz: "8898", city: "Flumserberg Tannenbodenalp" },
  { plz: "8902", city: "Urdorf" },
  { plz: "8903", city: "Birmensdorf ZH" },
  { plz: "8904", city: "Aesch ZH" },
  { plz: "8905", city: "Islisberg" },
  { plz: "8905", city: "Arni AG" },
  { plz: "8906", city: "Bonstetten" },
  { plz: "8907", city: "Wettswil" },
  { plz: "8908", city: "Hedingen" },
  { plz: "8909", city: "Zwillikon" },
  { plz: "8910", city: "Affoltern am Albis" },
  { plz: "8911", city: "Rifferswil" },
  { plz: "8912", city: "Obfelden" },
  { plz: "8913", city: "Ottenbach" },
  { plz: "8914", city: "Aeugstertal" },
  { plz: "8914", city: "Aeugst am Albis" },
  { plz: "8915", city: "Hausen am Albis" },
  { plz: "8916", city: "Jonen" },
  { plz: "8917", city: "Oberlunkhofen" },
  { plz: "8918", city: "Unterlunkhofen" },
  { plz: "8919", city: "Rottenschwil" },
  { plz: "8925", city: "Ebertswil" },
  { plz: "8926", city: "Hauptikon" },
  { plz: "8926", city: "Uerzlikon" },
  { plz: "8926", city: "Kappel am Albis" },
  { plz: "8932", city: "Mettmenstetten" },
  { plz: "8933", city: "Maschwanden" },
  { plz: "8934", city: "Knonau" },
  { plz: "8942", city: "Oberrieden" },
  { plz: "8951", city: "Fahrweid" },
  { plz: "8952", city: "Schlieren" },
  { plz: "8953", city: "Dietikon" },
  { plz: "8954", city: "Geroldswil" },
  { plz: "8955", city: "Oetwil an der Limmat" },
  { plz: "8956", city: "Killwangen" },
  { plz: "8957", city: "Spreitenbach" },
  { plz: "8962", city: "Bergdietikon" },
  { plz: "8964", city: "Rudolfstetten" },
  { plz: "8965", city: "Berikon" },
  { plz: "8966", city: "Oberwil-Lieli" },
  { plz: "8967", city: "Widen" },
  { plz: "9000", city: "St. Gallen" },
  { plz: "9007", city: "St. Gallen" },
  { plz: "9008", city: "St. Gallen" },
  { plz: "9010", city: "St. Gallen" },
  { plz: "9011", city: "St. Gallen" },
  { plz: "9012", city: "St. Gallen" },
  { plz: "9014", city: "St. Gallen" },
  { plz: "9015", city: "St. Gallen" },
  { plz: "9016", city: "St. Gallen" },
  { plz: "9030", city: "Abtwil SG" },
  { plz: "9032", city: "Engelburg" },
  { plz: "9033", city: "Untereggen" },
  { plz: "9034", city: "Eggersriet" },
  { plz: "9035", city: "Grub AR" },
  { plz: "9036", city: "Grub SG" },
  { plz: "9037", city: "Speicherschwendi" },
  { plz: "9038", city: "Rehetobel" },
  { plz: "9042", city: "Speicher" },
  { plz: "9043", city: "Trogen" },
  { plz: "9044", city: "Wald AR" },
  { plz: "9050", city: "Appenzell Eggerstanden" },
  { plz: "9050", city: "Appenzell Enggenhütten" },
  { plz: "9050", city: "Appenzell Meistersrüte" },
  { plz: "9050", city: "Appenzell Steinegg" },
  { plz: "9050", city: "Appenzell Schlatt" },
  { plz: "9050", city: "Appenzell" },
  { plz: "9052", city: "Niederteufen" },
  { plz: "9053", city: "Teufen AR" },
  { plz: "9054", city: "Haslen AI" },
  { plz: "9055", city: "Bühler" },
  { plz: "9056", city: "Gais" },
  { plz: "9057", city: "Schwende" },
  { plz: "9057", city: "Wasserauen" },
  { plz: "9057", city: "Weissbad" },
  { plz: "9058", city: "Brülisau" },
  { plz: "9062", city: "Lustmühle" },
  { plz: "9063", city: "Stein AR" },
  { plz: "9064", city: "Hundwil" },
  { plz: "9100", city: "Herisau" },
  { plz: "9103", city: "Schwellbrunn" },
  { plz: "9104", city: "Waldstatt" },
  { plz: "9105", city: "Schönengrund" },
  { plz: "9107", city: "Urnäsch" },
  { plz: "9108", city: "Gontenbad" },
  { plz: "9108", city: "Jakobsbad" },
  { plz: "9108", city: "Gonten" },
  { plz: "9112", city: "Schachen b. Herisau" },
  { plz: "9113", city: "Degersheim" },
  { plz: "9114", city: "Hoffeld" },
  { plz: "9115", city: "Dicken" },
  { plz: "9116", city: "Wolfertswil" },
  { plz: "9122", city: "Ebersol" },
  { plz: "9122", city: "Mogelsberg" },
  { plz: "9123", city: "Nassen" },
  { plz: "9125", city: "Brunnadern" },
  { plz: "9126", city: "Necker" },
  { plz: "9127", city: "St. Peterzell" },
  { plz: "9200", city: "Gossau SG" },
  { plz: "9203", city: "Niederwil SG" },
  { plz: "9204", city: "Andwil SG" },
  { plz: "9205", city: "Waldkirch" },
  { plz: "9212", city: "Arnegg" },
  { plz: "9213", city: "Hauptwil" },
  { plz: "9214", city: "Kradolf" },
  { plz: "9215", city: "Schönenberg an der Thur" },
  { plz: "9215", city: "Buhwil" },
  { plz: "9216", city: "Heldswil" },
  { plz: "9216", city: "Hohentannen" },
  { plz: "9217", city: "Neukirch an der Thur" },
  { plz: "9220", city: "Bischofszell" },
  { plz: "9223", city: "Schweizersholz" },
  { plz: "9223", city: "Halden" },
  { plz: "9225", city: "Wilen (Gottshaus)" },
  { plz: "9225", city: "St. Pelagiberg" },
  { plz: "9230", city: "Flawil" },
  { plz: "9231", city: "Egg (Flawil)" },
  { plz: "9240", city: "Niederglatt SG" },
  { plz: "9240", city: "Uzwil" },
  { plz: "9242", city: "Oberuzwil" },
  { plz: "9243", city: "Jonschwil" },
  { plz: "9244", city: "Niederuzwil" },
  { plz: "9245", city: "Sonnental" },
  { plz: "9245", city: "Oberbüren" },
  { plz: "9246", city: "Niederbüren" },
  { plz: "9247", city: "Henau" },
  { plz: "9248", city: "Bichwil" },
  { plz: "9249", city: "Oberstetten" },
  { plz: "9249", city: "Niederstetten" },
  { plz: "9249", city: "Algetshausen" },
  { plz: "9300", city: "Wittenbach" },
  { plz: "9304", city: "Bernhardzell" },
  { plz: "9305", city: "Berg SG" },
  { plz: "9306", city: "Freidorf TG" },
  { plz: "9308", city: "Lömmenschwil" },
  { plz: "9312", city: "Häggenschwil" },
  { plz: "9313", city: "Muolen" },
  { plz: "9314", city: "Steinebrunn" },
  { plz: "9315", city: "Winden" },
  { plz: "9315", city: "Neukirch (Egnach)" },
  { plz: "9320", city: "Frasnacht" },
  { plz: "9320", city: "Stachen" },
  { plz: "9320", city: "Arbon" },
  { plz: "9322", city: "Egnach" },
  { plz: "9323", city: "Steinach" },
  { plz: "9325", city: "Roggwil TG" },
  { plz: "9326", city: "Horn" },
  { plz: "9327", city: "Tübach" },
  { plz: "9400", city: "Rorschach" },
  { plz: "9402", city: "Mörschwil" },
  { plz: "9403", city: "Goldach" },
  { plz: "9404", city: "Rorschacherberg" },
  { plz: "9405", city: "Wienacht-Tobel" },
  { plz: "9410", city: "Heiden" },
  { plz: "9411", city: "Reute AR" },
  { plz: "9413", city: "Oberegg" },
  { plz: "9414", city: "Schachen b. Reute" },
  { plz: "9422", city: "Staad SG" },
  { plz: "9423", city: "Altenrhein" },
  { plz: "9424", city: "Rheineck" },
  { plz: "9425", city: "Thal" },
  { plz: "9426", city: "Lutzenberg" },
  { plz: "9427", city: "Zelg (Wolfhalden)" },
  { plz: "9427", city: "Wolfhalden" },
  { plz: "9428", city: "Walzenhausen" },
  { plz: "9430", city: "St. Margrethen SG" },
  { plz: "9434", city: "Au SG" },
  { plz: "9435", city: "Heerbrugg" },
  { plz: "9436", city: "Balgach" },
  { plz: "9437", city: "Marbach SG" },
  { plz: "9442", city: "Büriswilen" },
  { plz: "9442", city: "Berneck" },
  { plz: "9443", city: "Widnau" },
  { plz: "9444", city: "Diepoldsau" },
  { plz: "9445", city: "Rebstein" },
  { plz: "9450", city: "Lüchingen" },
  { plz: "9450", city: "Altstätten SG" },
  { plz: "9451", city: "Kriessern" },
  { plz: "9452", city: "Hinterforst" },
  { plz: "9453", city: "Eichberg" },
  { plz: "9462", city: "Montlingen" },
  { plz: "9463", city: "Oberriet SG" },
  { plz: "9464", city: "Lienz" },
  { plz: "9464", city: "Rüthi (Rheintal)" },
  { plz: "9465", city: "Salez" },
  { plz: "9466", city: "Sennwald" },
  { plz: "9467", city: "Frümsen" },
  { plz: "9468", city: "Sax" },
  { plz: "9469", city: "Haag (Rheintal)" },
  { plz: "9470", city: "Buchs SG" },
  { plz: "9470", city: "Werdenberg" },
  { plz: "9472", city: "Grabserberg" },
  { plz: "9472", city: "Grabs" },
  { plz: "9473", city: "Gams" },
  { plz: "9475", city: "Sevelen" },
  { plz: "9476", city: "Fontnas" },
  { plz: "9476", city: "Weite" },
  { plz: "9477", city: "Trübbach" },
  { plz: "9478", city: "Azmoos" },
  { plz: "9479", city: "Gretschins" },
  { plz: "9479", city: "Malans SG" },
  { plz: "9479", city: "Oberschan" },
  { plz: "9485", city: "Nendeln" },
  { plz: "9486", city: "Schaanwald" },
  { plz: "9487", city: "Gamprin-Bendern" },
  { plz: "9488", city: "Schellenberg" },
  { plz: "9490", city: "Vaduz" },
  { plz: "9491", city: "Ruggell" },
  { plz: "9492", city: "Eschen" },
  { plz: "9493", city: "Mauren FL" },
  { plz: "9494", city: "Schaan" },
  { plz: "9495", city: "Triesen" },
  { plz: "9496", city: "Balzers" },
  { plz: "9497", city: "Triesenberg" },
  { plz: "9498", city: "Planken" },
  { plz: "9500", city: "Wil SG" },
  { plz: "9502", city: "Braunau" },
  { plz: "9503", city: "Stehrenberg" },
  { plz: "9503", city: "Lanterswil" },
  { plz: "9504", city: "Friltschen" },
  { plz: "9506", city: "Lommis" },
  { plz: "9507", city: "Stettfurt" },
  { plz: "9508", city: "Weingarten-Kalthäusern" },
  { plz: "9512", city: "Rossrüti" },
  { plz: "9514", city: "Wuppenau" },
  { plz: "9515", city: "Hosenruck" },
  { plz: "9517", city: "Mettlen" },
  { plz: "9523", city: "Züberwangen" },
  { plz: "9524", city: "Zuzwil SG" },
  { plz: "9525", city: "Lenggenwil" },
  { plz: "9526", city: "Zuckenriet" },
  { plz: "9527", city: "Niederhelfenschwil" },
  { plz: "9532", city: "Rickenbach b. Wil" },
  { plz: "9533", city: "Kirchberg SG" },
  { plz: "9534", city: "Gähwil" },
  { plz: "9535", city: "Wilen b. Wil" },
  { plz: "9536", city: "Schwarzenbach SG" },
  { plz: "9542", city: "Münchwilen TG" },
  { plz: "9543", city: "St. Margarethen TG" },
  { plz: "9545", city: "Wängi" },
  { plz: "9546", city: "Tuttwil" },
  { plz: "9547", city: "Wittenwil" },
  { plz: "9548", city: "Matzingen" },
  { plz: "9552", city: "Bronschhofen" },
  { plz: "9553", city: "Bettwiesen" },
  { plz: "9554", city: "Tägerschen" },
  { plz: "9555", city: "Tobel" },
  { plz: "9556", city: "Zezikon" },
  { plz: "9556", city: "Affeltrangen" },
  { plz: "9562", city: "Buch b. Märwil" },
  { plz: "9562", city: "Märwil" },
  { plz: "9565", city: "Rothenhausen" },
  { plz: "9565", city: "Schmidshof" },
  { plz: "9565", city: "Oppikon" },
  { plz: "9565", city: "Oberbussnang" },
  { plz: "9565", city: "Bussnang" },
  { plz: "9573", city: "Littenheid" },
  { plz: "9601", city: "Lütisburg Station" },
  { plz: "9602", city: "Müselbach" },
  { plz: "9602", city: "Bazenheid" },
  { plz: "9604", city: "Oberrindal" },
  { plz: "9604", city: "Unterrindal" },
  { plz: "9604", city: "Lütisburg" },
  { plz: "9606", city: "Bütschwil" },
  { plz: "9607", city: "Mosnang" },
  { plz: "9608", city: "Ganterschwil" },
  { plz: "9612", city: "Dreien" },
  { plz: "9613", city: "Mühlrüti" },
  { plz: "9614", city: "Libingen" },
  { plz: "9615", city: "Dietfurt" },
  { plz: "9620", city: "Lichtensteig" },
  { plz: "9621", city: "Oberhelfenschwil" },
  { plz: "9622", city: "Krinau" },
  { plz: "9630", city: "Wattwil" },
  { plz: "9631", city: "Ulisbach" },
  { plz: "9633", city: "Bächli (Hemberg)" },
  { plz: "9633", city: "Hemberg" },
  { plz: "9642", city: "Ebnat-Kappel" },
  { plz: "9643", city: "Krummenau" },
  { plz: "9650", city: "Nesslau" },
  { plz: "9651", city: "Ennetbühl" },
  { plz: "9652", city: "Neu St. Johann" },
  { plz: "9655", city: "Stein SG" },
  { plz: "9656", city: "Alt St. Johann" },
  { plz: "9657", city: "Unterwasser" },
  { plz: "9658", city: "Wildhaus" },
];

const regions = [
  { id: 1, name: "Zürich" },
  { id: 2, name: "Genf" },
  { id: 3, name: "Bern" },
  { id: 4, name: "Nordwestschweiz" },
  { id: 5, name: "Ostschweiz" },
  { id: 6, name: "Zentralschweiz" },
  { id: 7, name: "Romandie" },
  { id: 8, name: "Südschweiz" },
];
const cantons = [
  { id: "ZH", name: "Zürich", regionId: 1 },
  { id: "GE", name: "Genf", regionId: 2 },
  { id: "BE", name: "Bern", regionId: 3 },

  { id: "AG", name: "Aargau", regionId: 4 },
  { id: "BS", name: "Basel-Stadt", regionId: 4 },
  { id: "BL", name: "Basel-Landschaft", regionId: 4 },
  { id: "SO", name: "Solothurn", regionId: 4 },

  { id: "SG", name: "St. Gallen", regionId: 5 },
  { id: "TG", name: "Thurgau", regionId: 5 },
  { id: "GL", name: "Glarus", regionId: 5 },
  { id: "SH", name: "Schaffhausen", regionId: 5 },
  { id: "AI", name: "Appenzell Innerrhoden", regionId: 5 },
  { id: "AR", name: "Appenzell Ausserrhoden", regionId: 5 },

  { id: "LU", name: "Luzern", regionId: 6 },
  { id: "ZG", name: "Zug", regionId: 6 },
  { id: "OW", name: "Obwald", regionId: 6 },
  { id: "NW", name: "Nidwald", regionId: 6 },
  { id: "SZ", name: "Schwyz", regionId: 6 },
  { id: "UR", name: "Uri", regionId: 6 },

  { id: "FR", name: "Fribourg", regionId: 7 },
  { id: "JU", name: "Jura", regionId: 7 },
  { id: "NE", name: "Neuchâtel", regionId: 7 },
  { id: "VD", name: "Vaud", regionId: 7 },

  { id: "GR", name: "Graubünden", regionId: 8 },
  { id: "TI", name: "Tessin", regionId: 8 },
  { id: "VS", name: "Valais", regionId: 8 },
];

const getCantonName = (id) => {
  var canton = cantons.find((c) => c.id == id);
  return canton ? canton.name : "";
};

const CURRENT_YEAR = new Date().getFullYear();
const getAgeText = (year, t) => {
  /*  <Option key={1} value={1}>
                  &lt; 20 yrs. old
                </Option>
                <Option key={2} value={2}>
                  20 - 50 yrs. old
                </Option>
                <Option key={3} value={3}>
                  &gt; 50 yrs. old */
  var ages = [
    t("global.less_than_twenty_yrs_old"),
    t("global.twenty_dash_fifty"),
    t("global.more_than_fifty"),
  ];
  var age = CURRENT_YEAR - year;

  if (age > 50) return ages[2];
  else if (age > 20) return ages[1];
  else return ages[0];
};

export { postal_codes, cities, cantons, regions, getCantonName, getAgeText };
