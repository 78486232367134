import { types } from "mobx-state-tree";


const PropertyRatingModel = types
  .model('PropertyRatingModel',{
    id: types.optional(types.number, 0),
    key: types.optional(types.string, ""),
    value: types.optional(types.number, 0),
    comment: types.optional(types.string, ""),
    potential_comment: types.maybeNull(types.string, ""),
    indexChanged: types.optional(types.number, 0),
  })
  .actions((self) => ({
    setValues(values) {
      for (var p in values) {
        self[p] = values[p];
      }
    },
  }));

export default PropertyRatingModel;
