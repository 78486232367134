import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import CompanyModel from "../../models/CompanyModel";
import {
  Layout,
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  AutoComplete,
  Space,
  Table,
  Modal,
  Select,
} from "antd";

//translation
import { useTranslation } from "react-i18next";

const { Option } = Select;

const { Title } = Typography;

const ClientDetailModal = (props) => {
  const { t } = useTranslation("common"); // for translation

  const {
    store,
    isClientModalVisible,
    setIsClientModalVisible,
    postal_codes,
    cities,
    cantons,
    regions,
    search,
    setSearchKeyword,
  } = props;

  const xloadClientList = (s = "") => {
    /*  store.getClients().then(s=>{
      search(s);
      setTimeout(() => {
        search('');
      }, 500);
     
    }); */
  };

  const clientId = store.activeClientId;
  const [selectedClient, setSelectedClient] = useState(CompanyModel.create({}));

  useEffect(() => {
    if (clientId === 0) {
      setSelectedClient(CompanyModel.create({}));
    } else {
      setSelectedClient(store.clients.find((c) => c.id === clientId));
    }
    return () => {
      setSelectedClient(CompanyModel.create({}));
    };
  }, [clientId]);

  const [ClientFormMessage, setClientFormMessage] = useState("");

  const plzs = postal_codes
    .map((r) => {
      return { value: r.plz };
    })
    .filter(function(obj, index, self) {
      return (
        index ===
        self.findIndex(function(t) {
          return t["value"] === obj["value"];
        })
      );
    });

  const cts = cities.map((r) => {
    return { value: r.city };
  });
  const cants = cantons.map((r) => {
    return { value: r.name };
  });

  useEffect(() => {
    setClientFormMessage("");

    setIsClientModalVisible(false);

    setTimeout(() => {
      //setPlzOptions(plzs);
      setPlzOptions([]);
      //setCityOptions(cts);
      setCityOptions([]);
      //setCantonOptions(cants);
      setCantonOptions(
        cantons.map((r) => {
          return { value: r.id, name: r.name };
        })
      );
      //console.log(plzs);
    }, 500);

    return () => {};
  }, []);

  const [form] = Form.useForm();
  const onClientFormFinish = (values) => {
    setIsSaving(true);
    console.log("Success:", values); //{email: "valerie", password: "12345678$", remember: true}

    if (selectedClient.id > 0) {
      selectedClient.setName(values.name);
      selectedClient.setAddress(values.address);
      selectedClient.setPlz(values.plz);
      selectedClient.setCity(values.city);
      selectedClient.setCanton(values.canton);
      selectedClient.update().then((result) => {
        if (result) {
          store.setClientListUpdateOn(new Date().getTime().toString());
          // setSearchKeyword("");
          setClientFormMessage(t("client_detail.client_info_updated_msg"));
        } else {
          setClientFormMessage(t("client_detail.cant_up_client_info_msg"));
        }
      });
    } else {
      // this is an new client
      store
        .addClient(
          values.name,
          values.address,
          values.plz,
          values.city,
          values.canton,
          values.region
        )
        .then((result) => {
          if (result && result.id) {
            /* store.properties.push(result);

            setSearchKeyword(values.name);

            setTimeout(() => {
              setSearchKeyword("");
            }, 1000); */
            store.setClientListUpdateOn(new Date().getTime().toString());
            setClientFormMessage(t("client_detail.new_client_added_msg"));
            /*  setClientId(0); */
            setClientName("");
            setPlz("");
            setCity("");
            setCanton("");
            setTimeout(() => {
              setIsClientModalVisible(false);
            }, 500);
            setClientFormMessage("");
            setClientNameValidationState("");
            form.resetFields();
          } else {
            setClientFormMessage(t("client_detail.cant_add_client_info_msg"));
          }
        });
    }
    setIsSaving(false);
  };

  const onClientFormFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /* const [clientId, setClientId] = useState(0); */
  const [clientName, setClientName] = useState("");
  const [clientNameValidationState, setClientNameValidationState] = useState(
    ""
  );
  const [address, setAddress] = useState("");
  const [plz, setPlz] = useState("");
  const [city, setCity] = useState("");
  const [canton, setCanton] = useState("");

  const [plzOptions, setPlzOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [cantonOptions, setCantonOptions] = useState([]);

  useEffect(() => {
    if (selectedClient) {
      /*  setClientId(selectedClient.id); */
      setClientName(selectedClient.name);
      setAddress(selectedClient.address);
      setPlz(selectedClient.plz);
      setCity(selectedClient.city);
      setCanton(selectedClient.canton);

      form.setFieldsValue({ ...selectedClient });
    } else {
      /* setClientId(0); */
      setClientName("");
      setAddress("");
      setPlz("");
      setCity("");
      setCanton("");
      form.resetFields();
    }
    return () => {
      // nothing yet
    };
  }, [selectedClient]);

  const onChangeClientName = (e) => {
    console.log("ClientName changed", e);
    setClientNameValidationState("validating");
    let target_value = e.target.value.trim();
    if (target_value == "") {
      setClientNameValidationState("error");
      return;
    }
    store.clientExists(target_value).then((res) => {
      if (res.exists === false) setClientNameValidationState("success");
      else {
        if (selectedClient) {
          if (res.client.id === selectedClient.id)
            setClientNameValidationState("success");
          //this is the same client
          else setClientNameValidationState("error");
        }
      }
    });
  };
  const onSelectPLZ = (data) => {
    console.log("onSelect", data);
  };
  const onChangePLZ = (data) => {
    console.log("onChangePLZ", data);
    setPlz(data);
    // if exact match then filter city autocomplete list
    if (plzs.find((r) => r.value === data)) {
      //set cantons
      var plzdata = postal_codes.find((p) => p.plz === data);
      if (plzdata) {
        /*  var cantonIds = postal_codes
          .filter((p) => p.plz === thiscity.plz)
          .map((x) => x.cantonId); 
        
          var filteredCantons = cantons.filter(function (canton) {
          return cantonIds.indexOf(canton.id) != -1;
        }); */

        var filteredCantons = cantons.filter(
          (canton) => canton.id === plzdata.cantonId
        );

        setCantonOptions(
          filteredCantons.length > 0
            ? filteredCantons.map((r) => {
                return { value: r.id, name: r.name };
              })
            : []
        );

        if (filteredCantons.length > 0) {
          setCanton(filteredCantons[0].id);

          form.setFieldsValue({ canton: filteredCantons[0].id });

          if (filteredCantons.length === 1) {
            var region = regions.find(
              (r) => r.id === filteredCantons[0].regionId
            );
            if (region) form.setFieldsValue({ region: region.name });
          }
        }
      } else {
        setCantonOptions(
          cantons.map((r) => {
            return { value: r.id, name: r.name };
          })
        );
      }

      var filteredCities = cities.filter((c) => c.plz === data);

      setCityOptions(
        filteredCities.map((r) => {
          return { value: r.city };
        })
      );

      if (filteredCities.length > 0) {
        setCity(filteredCities[0].city);
        form.setFieldsValue({ city: filteredCities[0].city });
      }
    } else {
      form.setFieldsValue({ city: "", canton: "", region: "" });
      setCityOptions([]);

      setCantonOptions(
        cantons.map((r) => {
          return { value: r.id, name: r.name };
        })
      );
    }
  };
  const onSearchPLZ = (searchText) => {
    setPlzOptions(plzs.filter((r) => r.value.indexOf(searchText) > -1));
  };
  const onChangeCity = (data) => {
    console.log("onChangeCity", data);
    setCity(data);
    // if exact match then filter city autocomplete list
    var thiscity = cities.find((r) => r.city === data);
    if (thiscity) {
      console.log(thiscity);
    }
  };
  const onChangeCanton = (data) => {
    console.log("onChangeCanton", data);
    var canton = cantons.find((c) => c.id === data);
    if (canton) {
      var region = regions.find((r) => r.id === canton.regionId);
      if (region) form.setFieldsValue({ region: region.name });
    }
  };
  let [IsSaving, setIsSaving] = useState(false);
  return (
    <>
      <Modal
        destroyOnClose
        title={false}
        centered
        visible={isClientModalVisible}
        okText={t("client_detail.submit")}
        closable={false}
        footer={false}
        className={"ant-custom"}
      >
        <Form
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{ remember: false }}
          onFinish={onClientFormFinish}
          onFinishFailed={onClientFormFinishFailed}
        >
          <Row type="flex" align="left">
            <Title
              style={{
                display: "block !important",
                textAlign: "left",
                width: "100%",
              }}
              level={3}
            >
              {clientId === 0
                ? t("client_detail.add_new_client")
                : t("client_detail.existing_client")}
            </Title>
          </Row>
          <Form.Item
            label={t("client_detail.client_name")}
            name="name"
            validateStatus={clientNameValidationState}
            hasFeedback
            rules={[
              {
                required: true,
                message: t("client_detail.please_enter_client_name"),
              },
            ]}
            onChange={onChangeClientName}
          >
            <Input value={clientName} />
          </Form.Item>
          <Form.Item
            label={t("client_detail.address")}
            name="address"
            rules={[
              {
                required: true,
                message: t("client_detail.please_enter_client_add"),
              },
            ]}
          >
            <Input value={address} />
          </Form.Item>
          <Form.Item
            label={t("client_detail.plz")}
            name="plz"
            rules={[
              {
                required: true,
                message: t("client_detail.please_enter_postal_code"),
              },
            ]}
          >
            <AutoComplete
              options={plzOptions}
              onSelect={onSelectPLZ}
              onSearch={onSearchPLZ}
              onChange={onChangePLZ}
              placeholder={t("client_detail.plz")}
            >
              <Input value={plz} />
            </AutoComplete>
          </Form.Item>
          <Form.Item
            label={t("client_detail.city")}
            name="city"
            rules={[
              {
                required: true,
                message: t("client_detail.please_enter_postal_code"),
              },
            ]}
          >
            <AutoComplete
              options={cityOptions}
              onChange={onChangeCity}
              placeholder="City"
            >
              <Input value={city} />
            </AutoComplete>

            {/* {cityOptions.length > 0 && (
              <Select
                onChange={onChangeCity}
                showSearch={cityOptions.length === 0}
              >
                {cityOptions.map((city) => (
                  <Option key={city.value} value={city.value}>
                    {city.value}
                  </Option>
                ))}
              </Select>
            )}
            {cityOptions.length == 0 && <Input />} */}
          </Form.Item>
          <Form.Item
            label={t("client_detail.canton")}
            name="canton"
            rules={[
              {
                required: true,
                message: t("client_detail.please_enter_canton"),
              },
            ]}
          >
            {/*  <AutoComplete options={cantonOptions} placeholder="Canton" onChange={onChangeCanton}>
              <Input value={canton} maxLength={2} />
            </AutoComplete> */}

            <Select onChange={onChangeCanton}>
              {cantonOptions.map((canton) => (
                <Option key={canton.value} value={canton.value}>
                  {canton.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t("client_detail.region")} name="region">
            <Input readOnly={true} />
          </Form.Item>
          <Form.Item>
            <Button
              type="default"
              htmlType="button"
              style={{
                display: "inline-block",
                width: "calc(50% - 16px)",
                margin: "0 8px 0",
              }}
              onClick={() => {
                setIsClientModalVisible(false);
              }}
            >
              {t("client_detail.cancel")}
            </Button>
            <Button
              disabled={IsSaving ? true : false}
              type="primary"
              htmlType="submit"
              style={{
                display: "inline-block",
                width: "calc(50% - 16px)",
                margin: "0 8px",
              }}
            >
              {t("client_detail.submit")}
            </Button>
          </Form.Item>
          <div
            style={{
              display: "block !important",
              textAlign: "center",
              width: "100%",
            }}
          >
            {ClientFormMessage}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default inject("store")(observer(ClientDetailModal));
