import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  COLORS_SERIES_BENCHMARK,
  COLORS_SERIES_BENCHMARK2,
  COLORS_SERIES_SETA,
  COLORS_SERIES_SETB,
  COLORS_SERIES_SETA1,
} from ".";
import axios from "axios";

import { Chart, Line, Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Card,
  Typography,
  Space,
  Button,
  Select,
  Statistic,
  Empty,
  TreeSelect,
  Tooltip,
  Affix,
  Spin,
  Modal,
  Result,
  Popconfirm,
  Popover,
  Avatar,
} from "antd";
const { Option } = Select;
const { Title } = Typography;
const PieRenderUsageType = ({
  resultSet,
  selectionModeA,
  selectionModeB,
  setNameA,
  setNameB,
  setToShow,
  t,
}) => {
  if (!resultSet.length || resultSet.length == 0) {
    var emptyDesc =
      t("admin_dashboard.select_portfolios_in_set_a") +
      (selectionModeB === "benchmark" ? "" : t("admin_dashboard.and_set_b")) +
      ".";
    return <Empty description={emptyDesc}></Empty>;
  }
  /*   const categories = resultSet
    .filter((value, index, self) => {
      return self.indexOf(x=>x.category_id==value.category_id) === index;
    })
    .sort((a, b) => a.category_id > b.category_id).map(c=>c.category); */
  const pfLabels = {
    "pf-1": t("admin_dashboard.portfoliotype_1"),
    "pf-2": t("admin_dashboard.portfoliotype_2"),
    "pf-3": t("admin_dashboard.portfoliotype_3"),
    "pf-4": t("admin_dashboard.portfoliotype_4"),
  };

  var datasetfilter = "";
  if (setToShow == 1) {
    if (selectionModeA === "benchmark") datasetfilter = "SSREI A";
    else if (selectionModeA === "set") datasetfilter = setNameA;
    else if (selectionModeA.indexOf("pf-" == 0))
      datasetfilter = pfLabels[selectionModeA] + " A";
  } else {
    if (selectionModeB === "benchmark") datasetfilter = "SSREI B";
    else if (selectionModeB === "set") datasetfilter = setNameB;
    else if (selectionModeB.indexOf("pf-" == 0))
      datasetfilter = pfLabels[selectionModeB] + " B";
  }

  const categories = resultSet
    .map((r) => {
      return {
        category_id: r.category_id,
        category: t("chart_dataset_names." + r.category),
      };
    })
    .filter(
      (v, i, a) =>
        a.findIndex(
          (t) => t.category_id === v.category_id && t.category === v.category
        ) === i
    )
    .sort((a, b) =>
      a.category_id > b.category_id ? 1 : b.category_id > a.category_id ? -1 : 0
    )
    .map((c) => c.category);

  const datasetnames = resultSet
    .map((c) => c.dataset)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    })
    .filter((name) => name == datasetfilter);

  // var CompareToBenchMarkB = true;

  const datasets = datasetnames.map((d, index) => {
    return {
      label: d,
      data: resultSet
        .sort((a, b) =>
          a.category_id > b.category_id
            ? 1
            : b.category_id > a.category_id
            ? -1
            : 0
        )
        .filter((r) => r.dataset === d)
        .map((s) => {
          return s.value ? parseFloat(parseFloat(s.value).toFixed(2)) : 0;
        }),
      backgroundColor: resultSet
        .sort((a, b) =>
          a.category_id > b.category_id
            ? 1
            : b.category_id > a.category_id
            ? -1
            : 0
        )
        .filter((r) => r.dataset === d)
        .map((s, si) => {
          if (setToShow == 1) {
            /* if (selectionModeA === "set") return COLORS_SERIES_BENCHMARK[0];
            else return  COLORS_SERIES_SETA[si]; */
            return COLORS_SERIES_SETA[si];
          } else {
            /*   if (selectionModeB === "set") return COLORS_SERIES_BENCHMARK[si];
            else return  COLORS_SERIES_BENCHMARK[si]; */
            return COLORS_SERIES_BENCHMARK[si];
          }
        }),

      fill: true,
    };
  });

  const data = {
    labels: categories /* resultSet.map((c) => c.category) */,
    datasets: datasets /* resultSet.map((s, index) => {
      var CompareToBenchMarkB = true;
      return {
        label: s.dataset,
        data: s.value ? parseFloat(parseFloat(s.value).toFixed(2)) : 0,
        backgroundColor: CompareToBenchMarkB
          ? COLORS_SERIES1[index % 2]
          : COLORS_SERIES2[index % 2],
        fill: false,
      };
    }) */,
  };
  const options = {
    animation: { animateScale: true },
    maintainAspectRatio: false,

    legend: { display: true, position: "left", boxWidth: 3, align: "start" },
    /* responsive:false,
    height: 250, */
    layout: {
      padding: {
        left: 0,
        right: 50,
        top: 20,
        bottom: 20,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index] || "";

          if (label) {
            label += ": ";
          }
          label += (
            Math.round(data.datasets[0].data[tooltipItem.index] * 100) / 100
          ).toLocaleString("de-ch", { minimumFractionDigits: 2 });

          let sum = 0;
          let dataArr = data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });

          let value = data.datasets[0].data[tooltipItem.index];
          let percentage = ((value * 100) / sum).toFixed(2) + "%";
          var vv =
            isNaN((value * 100) / sum) || value === 0
              ? ""
              : "  (" + percentage + ")";
          label += vv;
          return label;
        },
      },
    },
    plugins: {
      datalabels: {
        display: "auto",
        color: "#000000",
        anchor: "end",
        align: "end",
        offset: 0,
        clamp: true,
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + "%";

          var vv = isNaN((value * 100) / sum) || value === 0 ? "" : percentage;
          // : value + ", " + percentage;

          return /* (ctx.dataIndex%2==0?" \n":"")+ */ vv;
        },
        color: "#000000",
      },
    },
  };
  return <Pie data={data} options={options} />;
};

const TwoSetComparisonPieChart = (props) => {
  const {
    t,
    title,
    subtitle,
    apiEndPoints /* { "benchmark":"RatingByUsageBenchmark", "set":"RatingByUsageSetWIthName" } */,
    selectionModeA,
    selectionModeB,
    filtersA,
    filtersB,
    setNameA,
    setNameB,

    ...restProps
  } = props;

  const [chartData, setChartData] = useState(false);
  const [hasData, setHasData] = useState(false);
  const pfLabels = {
    "pf-1": t("admin_dashboard.portfoliotype_1"),
    "pf-2": t("admin_dashboard.portfoliotype_2"),
    "pf-3": t("admin_dashboard.portfoliotype_3"),
    "pf-4": t("admin_dashboard.portfoliotype_4"),
  };

  const requestChartData = async () => {
    var dataset = [];
    if (selectionModeA === "benchmark") {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/${apiEndPoints[selectionModeA]}`, {})

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [
        ...dataset,
        ...result.map((r) => {
          return { ...r, dataset: "SSREI A" };
        }),
      ];
    } else if (selectionModeA === "set") {
      if (filtersA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/${apiEndPoints[selectionModeA]}`, {
            portfolioIds: filtersA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
            setName: "Set A",
          })

          .then((response) => response)
          .catch((error) => error.response);

        dataset = [...dataset, ...result];
      } else {
        //setHasData(false);
      }
    } else if (["pf-1", "pf-2", "pf-3", "pf-4"].indexOf(selectionModeA) > -1) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/${apiEndPoints[selectionModeA]}`, {
          id: selectionModeA.split("-")[1],
          setName: pfLabels[selectionModeA] + " A",
        })

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [...dataset, ...result];
    }
    //todo by porfolio tyoes

    if (selectionModeB === "benchmark") {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/${apiEndPoints[selectionModeB]}`, {})

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [
        ...dataset,
        ...result.map((r) => {
          return { ...r, dataset: "SSREI B" };
        }),
      ];

      //setHasData(true);
    } else if (selectionModeB === "set") {
      if (filtersB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/${apiEndPoints[selectionModeB]}`, {
            portfolioIds: filtersB.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
            setName: "Set B",
          })

          .then((response) => response)
          .catch((error) => error.response);

        dataset = [...dataset, ...result];
      } else {
        //setHasData(false);
      }
    } else if (["pf-1", "pf-2", "pf-3", "pf-4"].indexOf(selectionModeB) > -1) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/${apiEndPoints[selectionModeB]}`, {
          id: selectionModeB.split("-")[1],
          setName: pfLabels[selectionModeB] + " B",
        })

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [...dataset, ...result];
    }
    setChartData(dataset);
    setHasData(dataset.length > 0);
    //  setUsageBarChartData(dataset);
    // setHasUsageBarChartData(true);
  };

  useEffect(() => {
    //requestThreeYearRatings();

    requestChartData();

    /*  if (setToShow == "SSREI A" && selectionModeA == "set")
      setSetToShow(setNameA);
    else if (setToShow == setNameA && selectionModeA == "benchmark")
      setSetToShow("SSREI A");
    else if (setToShow == "SSREI B" && selectionModeB == "set")
      setSetToShow(setNameB);
    else if (setToShow == setNameB && selectionModeB == "benchmark")
      setSetToShow("SSREI B");
    else if (["pf-1", "pf-2", "pf-3"].indexOf(setToShow) && ["pf-1", "pf-2", "pf-3"].indexOf(selectionModeA))
    setSetToShow(pfLabels[selectionModeA] + " A");
    else if (["pf-1", "pf-2", "pf-3"].indexOf(setToShow) && ["pf-1", "pf-2", "pf-3"].indexOf(selectionModeB))
    setSetToShow(pfLabels[selectionModeB] + " B"); */

    /* if (setToShow == "SSREI A") {
      if (selectionModeA == "set") setSetToShow(setNameA);
      else if (selectionModeA == "benchmark") setSetToShow("SSREI A");
      else if (
        ["pf-1", "pf-2", "pf-3"].indexOf(setToShow) &&
        ["pf-1", "pf-2", "pf-3"].indexOf(selectionModeA)
      )
        setToShow(setToShow);
    } else if (setToShow == "Set A") {
      if (selectionModeA == "set") setSetToShow(setNameA);
      else if (selectionModeA == "benchmark") setSetToShow("SSREI A");
      else if (
        ["pf-1", "pf-2", "pf-3"].indexOf(setToShow) &&
        ["pf-1", "pf-2", "pf-3"].indexOf(selectionModeA)
      )
        setToShow(setToShow);
    }
 */
    return () => {
      //setUsageBarChartData(false);
    };
  }, [filtersA, filtersB, selectionModeA, selectionModeB]);
  const [setToShow, setSetToShow] = useState(1);
  const handlePieChartUsageTypeChange = (value) => {
    setSetToShow(value);
  };
  return (
    <Card style={{ height: "430px" }}>
      <Title
        level={3}
        style={{ textAlign: "left", textTransform: "uppercase" }}
      >
        <Select
          defaultValue="1"
          bordered={false}
          style={{ float: "right", width: "150px" }}
          onChange={handlePieChartUsageTypeChange}
          value={setToShow}
          // style={{ float: "right" }}
        >
          <Option value={1}>
            {selectionModeA == "benchmark"
              ? "SSREI A"
              : ["pf-1", "pf-2", "pf-3", "pf-4"].indexOf(selectionModeA) > -1
              ? pfLabels[selectionModeA] + " A"
              : setNameA}
          </Option>
          <Option value={2}>
            {selectionModeB == "benchmark"
              ? "SSREI B"
              : ["pf-1", "pf-2", "pf-3", "pf-4"].indexOf(selectionModeB) > -1
              ? pfLabels[selectionModeB] + " B"
              : setNameB}
          </Option>
        </Select>
        <div>
          {title}
          {subtitle ? subtitle : undefined}
        </div>
      </Title>
      <div className={"dashboard-chart-area"}>
        <PieRenderUsageType
          resultSet={chartData}
          selectionModeA={selectionModeA}
          selectionModeB={selectionModeB}
          setNameA={setNameA}
          setNameB={setNameB}
          setToShow={setToShow}
          t={t}
        ></PieRenderUsageType>
      </div>
    </Card>
  );
};

export default TwoSetComparisonPieChart;
