import React from "react";
import reqwest from "reqwest";
import axios from "axios";
import { Form, Input, Button, Upload, Alert, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import TextArea from "antd/lib/input/TextArea";
//translation
import { useTranslation } from "react-i18next";

const UpdateForm = ({
  confirmLoading,
  setConfirmLoading,
  setVisibleUpdate,
  setUpdating,
  visibleUpdate,
  fileForEdit,
  setExistingFiles,
}) => {
  const { t } = useTranslation("common"); //translation

  const [form] = Form.useForm();

  const [titleExist, setTitleExistState] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [documents, setFiles] = React.useState([]);

  const [multiFile, setMulti] = React.useState(false);
  const props = {
    onRemove: (file) => {
      setFiles((prevState) => {
        const index = prevState.indexOf(file);
        const newFileList = prevState.slice();

        newFileList.splice(index, 1);

        return newFileList;
      });
    },

    beforeUpload: (file) => {
      setMulti(false);

      setFiles((prevState) => {
        if (prevState.length === 0) {
          return [...prevState, file];
        } else {
          setMulti(true);
          return prevState;
        }
      });

      return false;
    },

    document,
  };

  React.useEffect(() => {
    (() => {
      setTitle(fileForEdit.title);
      setDescription(fileForEdit.description);
    })();
  }, [fileForEdit]);

  const handleOk = async () => {
    setTitleExistState(false);

    let _titleFound = false;

    try {
      if (fileForEdit.title !== title) {
        const checkFile = await axios.get(`/api/uploads/${title}`);

        _titleFound = checkFile.data.found;
      }

      if (!_titleFound) {
        setConfirmLoading(true);
        setUpdating(true);

        const fileList = documents;
        const formData = new FormData();

        fileList.forEach((file) => {
          formData.append("files", file);
        });

        formData.append("title", title);
        formData.append("edit", true);
        formData.append("description", description);

        reqwest({
          url: `/api/uploads/update/${fileForEdit.key}`,
          method: "PUT",
          processData: false,
          data: formData,
          success: (res) => {
            setExistingFiles((prevState) => {
              const _i = prevState.findIndex(
                (_pS) => _pS.key === fileForEdit.key
              );

              prevState[_i].title = title;
              prevState[_i].description = description;

              if (fileList.length > 0) {
                prevState[_i].documentName = fileList[0].name;
              }

              return prevState;
            });

            setFiles([]);
            setVisibleUpdate(false);
            setConfirmLoading(false);
            setUpdating(false);

            message.success(t("update_form.update_successful"));
          },
          error: () => {
            setConfirmLoading(false);

            setUpdating(false);
            message.error(t("update_form.update_failed"));
          },
        });

        setVisibleUpdate(false);
        setConfirmLoading(false);
      } else {
        setTitleExistState(true);
      }
    } catch (error) {
      console.log(error);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisibleUpdate(false);
  };

  return (
    <>
      <Modal
        title={t("update_form.title")}
        visible={visibleUpdate}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              handleOk();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        confirmLoading={confirmLoading}
        okText={t("update_form.save")}
        onCancel={handleCancel}
        // footer={[
        //    <Button key="back" onClick={handleCancel}>
        //    Close
        //    </Button>,
        //    <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
        //    Save
        //    </Button>,
        // ]}
      >
        {titleExist && (
          <Alert
            message={t("update_form.warning")}
            description={t("update_form.title_already_exists")}
            type="error"
            showIcon
            closable
          />
        )}
        <Form
          style={{ marginTop: "5px" }}
          layout="vertical"
          initialValues={{
            title: title,
            description: description,
          }}
        >
          <Form.Item
            label={t("update_form.title")}
            name="title"
            rules={[
              {
                required: true,
                message: t("update_form.please_input_document_title"),
              },
            ]}
          >
            <Input
              onChange={(e) => setTitle(e.currentTarget.value)}
              value={title}
              name="title"
            />
          </Form.Item>

          <Form.Item
            label={t("update_form.description")}
            name="description"
            rules={[
              {
                required: true,
                message: t("update_form.please_input_document_description"),
              },
            ]}
          >
            <TextArea
              rows={4}
              onChange={(e) => setDescription(e.currentTarget.value)}
              value={description}
              name="description"
            />
          </Form.Item>

          {multiFile && (
            <Alert
              message={t("update_form.warning")}
              description={t("update_form.only_one_file_can_be_uploaded")}
              type="warning"
              showIcon
              closable
            />
          )}
          <br />
          <h6>
            {t("update_form.current_file")} {fileForEdit.documentName}{" "}
          </h6>

          <h4> {t("update_form.replace_file")}</h4>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>
              {t("update_form.select_file")}
            </Button>
          </Upload>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateForm;
