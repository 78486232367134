import { useState } from "react";

const RatingsModalController = ({ criteria, t }) => {
  const [selectedKey, setSelectedKey] = useState();
  const [selectedFoto, setSelectedFoto] = useState();

  const [helpTextTitle, setHelpTextTitle] = useState("");
  const [helpGroup, setHelpGroup] = useState("");
  const [helpChild, setHelpChild] = useState("");
  const [helpIntro, setHelpIntro] = useState("");
  const [helpManual, setHelpManual] = useState("");
  const [helpAutomatic, setHelpAutomatic] = useState("");
  const [helpCardStyle, setHelpCardStyle] = useState("");
  // const [helpZertifikat, setHelpZertifikat] = useState("");
  const [helpFoto, setHelpFoto] = useState("");
  const [helpFotoGoogle, setHelpFotoGoogle] = useState("");
  const [helpFotoPlan, setHelpFotoPlan] = useState("");
  const [helpFotoGooglePlan, setHelpFotoGooglePlan] = useState("");
  // const [helpKonzepte, setHelpKonzepte] = useState("");
  const [helpMessungen, setHelpMessungen] = useState("");
  const [helpPlan, setHelpPlan] = useState("");
  const [helpWebLink, setHelpWebLink] = useState("");
  const [helpSonstige, setHelpSonstige] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isReverted, setIsReverted] = useState(true);

  const setHelpDescription = (key) => {
    setHelpChild("");

    setIsEditing(false);
    setIsChanged(false);
    setIsReverted(false);
    setSelectedKey(key.split(".").join("_"));

    var key1 = parseInt(key.split(".")[0]);
    if (key1 < 200) setHelpCardStyle("t1");
    else if (key1 < 300) setHelpCardStyle("t2");
    else setHelpCardStyle("t3");

    criteria.forEach((g) => {
      g.children.forEach((c, cindex) => {
        if (c.key === key) {
          setHelpGroup(g.groupName[0] + (cindex + 1));
          setHelpTextTitle(
            t("criteria." + c.key.split(".").join("_") + ".title")
          );
          //   setHelpText({
          //     intro: c.help
          //       ? t("criteria." + c.key.split(".").join("_") + ".help")
          //       : "",
          //     manual: c.manual
          //       ? t("criteria." + c.key.split(".").join("_") + ".manual")
          //       : "",
          //     automatic: c.automatic
          //       ? t("criteria." + c.key.split(".").join("_") + ".automatic")
          //       : "",
          //   });
          setHelpIntro(
            c.help ? t("criteria." + c.key.split(".").join("_") + ".help") : ""
          );
          setHelpManual(
            c.manual
              ? t("criteria." + c.key.split(".").join("_") + ".manual")
              : ""
          );
          setHelpAutomatic(
            c.automatic
              ? t("criteria." + c.key.split(".").join("_") + ".automatic")
              : ""
          );
          setHelpFoto(
            c.foto ? t("criteria." + c.key.split(".").join("_") + ".foto") : ""
          );
          setHelpFotoGoogle(
            c.fotoorgoogle
              ? t("criteria." + c.key.split(".").join("_") + ".fotoorgoogle")
              : ""
          );
          setHelpFotoPlan(
            c.fotoorplan
              ? t("criteria." + c.key.split(".").join("_") + ".fotoorplan")
              : ""
          );
          setHelpFotoGooglePlan(
            c.fotoorgoogleorplan
              ? t(
                  "criteria." +
                    c.key.split(".").join("_") +
                    ".fotoorgoogleorplan"
                )
              : ""
          );
          // setHelpZertifikat(
          //   c.zertifikat
          //     ? typeof c.zertifikat === "boolean"
          //       ? t("criteria." + c.key.split(".").join("_") + ".plan")
          //       : c.zertifikat
          //           .map((z) => " - " + t("criteria.zertifikats." + z))
          //           .join("<br/>")
          //     : ""
          // );
          setHelpPlan(
            c.plan
              ? typeof c.plan === "boolean"
                ? t("criteria." + c.key.split(".").join("_") + ".plan")
                : c.plan
                    .map((z) => " - " + t("criteria.plans." + z))
                    .join("<br/>")
              : ""
          );
          setHelpWebLink(
            c.weblink
              ? t("criteria." + c.key.split(".").join("_") + ".weblink")
              : "" /*  t("admin_rating.description_not_available") */
          );
          setHelpMessungen(
            c.messungen
              ? t("criteria." + c.key.split(".").join("_") + ".messungen")
              : ""
          );
          setHelpSonstige(
            c.sonstige
              ? t("criteria." + c.key.split(".").join("_") + ".sonstige")
              : ""
          );
        } else {
          if (c.children) {
            c.children.forEach((s) => {
              if (s.key === key) {
                setHelpGroup(g.groupName[0] + (cindex + 1));
                setHelpTextTitle(
                  t("criteria." + c.key.split(".").join("_") + ".title")
                );
                setHelpChild(
                  t("criteria." + s.key.split(".").join("_") + ".title")
                );
                // setHelpText({
                //   ...helpText,
                //   intro: s.help
                //     ? t("criteria." + s.key.split(".").join("_") + ".help")
                //     : "",
                //   manual: s.manual
                //     ? t("criteria." + s.key.split(".").join("_") + ".manual")
                //     : "",
                //   automatic: s.automatic
                //     ? t("criteria." + s.key.split(".").join("_") + ".automatic")
                //     : "",
                // });
                setHelpIntro(
                  s.help
                    ? t("criteria." + s.key.split(".").join("_") + ".help")
                    : ""
                );
                setHelpManual(
                  s.manual
                    ? t("criteria." + s.key.split(".").join("_") + ".manual")
                    : ""
                );
                setHelpAutomatic(
                  s.automatic
                    ? t("criteria." + s.key.split(".").join("_") + ".automatic")
                    : ""
                );
                setHelpFoto(
                  s.foto
                    ? t("criteria." + s.key.split(".").join("_") + ".foto")
                    : ""
                );
                setHelpFotoGoogle(
                  s.fotoorgoogle
                    ? t(
                        "criteria." +
                          s.key.split(".").join("_") +
                          ".fotoorgoogle"
                      )
                    : ""
                );
                setHelpFotoPlan(
                  s.fotoorplan
                    ? t(
                        "criteria." + s.key.split(".").join("_") + ".fotoorplan"
                      )
                    : ""
                );
                setHelpFotoGooglePlan(
                  s.fotoorgoogleorplan
                    ? t(
                        "criteria." +
                          s.key.split(".").join("_") +
                          ".fotoorgoogleorplan"
                      )
                    : ""
                );
                // setHelpZertifikat(
                //   s.zertifikat
                //     ? typeof s.zertifikat === "boolean"
                //       ? t(
                //           "criteria." +
                //             s.key.split(".").join("_") +
                //             ".zertifikat"
                //         )
                //       : s.zertifikat
                //           .map((z) => " - " + t("criteria.zertifikats." + z))
                //           .join("<br/>")
                //     : ""
                // );
                setHelpPlan(
                  s.plan
                    ? typeof s.plan === "boolean"
                      ? t("criteria." + s.key.split(".").join("_") + ".plan")
                      : s.plan
                          .map((z) => " - " + t("criteria.plans." + z))
                          .join("<br/>")
                    : ""
                );
                setHelpWebLink(
                  s.weblink
                    ? t("criteria." + s.key.split(".").join("_") + ".weblink")
                    : "" /*  t("admin_rating.description_not_available") */
                );
                setHelpMessungen(
                  s.messungen
                    ? t("criteria." + s.key.split(".").join("_") + ".messungen")
                    : ""
                );
                setHelpSonstige(
                  s.sonstige
                    ? t("criteria." + s.key.split(".").join("_") + ".sonstige")
                    : ""
                );
              }
            });
          }
        }
      });
    });
  };

  return {
    setHelpDescription,
    selectedKey,
    helpTextTitle,
    setHelpTextTitle,
    helpGroup,
    setHelpGroup,
    helpChild,
    setHelpChild,
    helpIntro,
    setHelpIntro,
    helpManual,
    setHelpManual,
    helpAutomatic,
    setHelpAutomatic,
    helpCardStyle,
    setHelpCardStyle,
    helpFoto,
    setHelpFoto,
    helpFotoGoogle,
    setHelpFotoGoogle,
    helpFotoPlan,
    setHelpFotoPlan,
    helpFotoGooglePlan,
    setHelpFotoGooglePlan,
    helpMessungen,
    setHelpMessungen,
    helpPlan,
    setHelpPlan,
    helpWebLink,
    setHelpWebLink,
    helpSonstige,
    setHelpSonstige,
    isEditing,
    setIsEditing,
    isChanged,
    setIsChanged,
    isReverted,
    setIsReverted,
    selectedFoto,
    setSelectedFoto,
  };
};

export default RatingsModalController;
