import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
// import { toJS } from "mobx";
import YearInput from "./../Common/YearInput";
import PropertyModel from "../../models/PropertyModel";

import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  AutoComplete,
  Modal,
  Select,
  InputNumber,
  // Tooltip,
  message,
  // Popconfirm,
} from "antd";
import { postal_codes, cities, cantons, regions } from "../../data/AddressBook";
import PortfolioModel from "../../models/PortfolioModel";

import { criteria } from "../../data/RatingCriteria";
import ClientPropertyDetailController from "../../controllers/ClientPropertyDetailController";

//translation
import { useTranslation } from "react-i18next";

const { Option } = Select;

const { Title, Text } = Typography;

const ClientPropertyDetailModal = ({
  store,
  visible,
  setVisible,
  propertyId,
  refreshList,
  // popConfirmRatingvisible,
  // setPopConfirmRatingvisible,
}) => {
  const { t } = useTranslation("common");
  const [form] = Form.useForm();

  /* store */
  const propertyAttributes = store.propertyAttributeMasters;
  const clientId = store.activeClientId;
  const portfolioId = store.activePortfolioId;
  const id = propertyId;
  const plzs = postal_codes
    .map((r) => {
      return { value: r.plz };
    })
    .filter(function (obj, index, self) {
      return (
        index ===
        self.findIndex(function (t) {
          return t["value"] === obj["value"];
        })
      );
    });
  /* useStates */
  const [plzOptions, setPlzOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [cantonOptions, setCantonOptions] = useState([]);
  const [address /*setAddress*/] = useState("");
  const [plz, setPlz] = useState("");
  const [city, setCity] = useState("");
  const [, /*canton*/ setCanton] = useState("");
  const [propertyNameFound, setPropertyNameFound] = useState(false);
  const [originalPropertyAttributes, setOriginalPropertyAttributes] = useState(
    []
  );
  const [InvalidRatings, setInvalidRatings] = useState([]);
  const [areaTotal, setAreaTotal] = useState(0.0);
  const [, /*modalMessage*/ setModalMessage] = useState("");
  const [, /*confirmLoading*/ setConfirmLoading] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState(
    PortfolioModel.create({})
  );
  const [currentProperty, setCurrentProperty] = useState(
    PropertyModel.create({})
  );

  /* controller */
  const { getMissingRatingKeys } = ClientPropertyDetailController({
    t,
    form,
  });

  /* setting the selected active portfolio */
  // useEffect(() => {
  //   if (clientId > 0 && portfolioId > 0) {
  //     setSelectedPortfolio(
  //       store.clients
  //         .find((c) => c.id === store.activeClientId)
  //         .portfolios.find((pf) => pf.id === store.activePortfolioId)
  //     );
  //   }
  //   return () => {
  //     setSelectedPortfolio(PortfolioModel.create({}));
  //   };
  // }, [portfolioId]);

  /* useEffects */
  /*set selected active portfolio */
  useEffect(() => {
    if (clientId > 0 && portfolioId > 0) {
      setSelectedPortfolio(
        store.clients
          .find((c) => c.id === store.activeClientId)
          .portfolios.find((pf) => pf.id === store.activePortfolioId)
      );
    }
    return () => {
      // set it back to blank
      setSelectedPortfolio(PortfolioModel.create({}));
      setCurrentProperty(PropertyModel.create({}));
    };
  }, [clientId, portfolioId]);

  /* clearing form data to blank */
  useEffect(() => {
    if (propertyAttributes.length == 0) store.getPropertyAttributeList();
    resetFields();
    // setPlzOptions([]);
    // setCityOptions([]);
    // setCantonOptions(
    //   cantons.map((r) => {
    //     return { value: r.id, name: r.name };
    //   })
    // );

    // form.resetFields();
    // form.setFieldsValue({
    //   name: "",
    //   address: "",
    //   plz: "",
    //   city: "",
    //   canton: "",
    //   region: "",
    //   age: "",
    //   lastTotalRenovation: "",
    //   areaOffice: "",
    //   areaResidential: "",
    //   areaCommercial: "",
    //   areaBuilding: "",
    //   areaHealth: "",
    // });
    setModalMessage("");
    return () => {};
  }, []);

  /* setting current property base on id */
  useEffect(() => {
    if (id > 0) {
      setCurrentProperty(
        store.clients
          .find((c) => c.id === store.activeClientId)
          .portfolios.find((pf) => pf.id === store.activePortfolioId)
          .properties.find((p) => p.id === id)
      );
    } else {
      setCurrentProperty(PropertyModel.create({}));
    }
    return () => {};
  }, [id]);

  /* getting missing rating keys checker */
  useEffect(() => {
    resetFields();
    if (currentProperty.id > 0) {
      currentProperty.getRatings().then((x) => {
        var m = getMissingRatingKeys(currentProperty.ratings);
        setInvalidRatings(m);
      });
      setOriginalPropertyAttributes(
        currentProperty.propertyAttributes.map((x) => x)
      );
    }

    return () => {
      setInvalidRatings([]);
    };
  }, [currentProperty]);

  useEffect(() => {
    var m = getMissingRatingKeys(currentProperty.ratings);
    setInvalidRatings(m);
  }, [currentProperty.ratings, store.clientListUpdateOn, areaTotal]);

  /* reset fields function */
  const resetFields = () => {
    if (currentProperty) {
      if (currentProperty.id === 0) {
        setPlzOptions([]);
        setCityOptions([]);
        setCantonOptions(
          cantons.map((r) => {
            return { value: r.id, name: r.name };
          })
        );

        form.resetFields();
        form.setFieldsValue({
          name: "",
          address: "",
          plz: "",
          city: "",
          canton: "",
          region: "",
          age: "",
          lastTotalRenovation: "",
          areaOffice: "",
          areaResidential: "",
          areaCommercial: "",
          areaBuilding: "",
          areaHealth: "",
          attributes: [],
        });
      } else {
        const {
          name,
          address,
          plz,
          city,
          canton,
          region,
          age,
          lastTotalRenovation,
          areaOffice,
          areaResidential,
          areaCommercial,
          areaBuilding,
          areaHealth,
          propertyAttributes,
        } = currentProperty;
        form.setFieldsValue({
          name,
          address,
          plz,
          city,
          canton,
          region,
          age: age == 0 ? "" : age,
          lastTotalRenovation:
            lastTotalRenovation == 1 ? "" : lastTotalRenovation,
          areaOffice: parseInt(areaOffice),
          areaResidential: parseInt(areaResidential),
          areaCommercial: parseInt(areaCommercial),
          areaBuilding: parseInt(areaBuilding),
          areaHealth: parseInt(areaHealth),
          attributes: propertyAttributes,
        });
        onChangePLZ(plz);
      }
    }
    setAreaTotal(
      form.getFieldValue("areaOffice") +
        form.getFieldValue("areaResidential") +
        form.getFieldValue("areaCommercial") +
        form.getFieldValue("areaBuilding") +
        form.getFieldValue("areaHealth")
    );
    setModalMessage("");
  };

  /* saving initial ratings */
  const saveInitialRatings = (currentProperty, toRecalc = false) => {
    //transform form fields to array of {key,value,comment}
    var defaults = [];
    if (currentProperty.propertyAttributes.length > 0) {
      defaults = store.propertyDefaultRatings.filter((def) => {
        return currentProperty.propertyAttributes.includes(def.attribute_id);
      });
    }

    const getDefaultInitialValue = (key) => {
      var ret = false;

      if (currentProperty.propertyAttributes.length > 0) {
        // this assumes that there could be multiple matches
        var defaultKeyRating = defaults
          .filter((def) => def.key === key && def.value > -1)
          .sort((a, b) => parseInt(a.value) - parseInt(b.value));

        if (defaultKeyRating && defaultKeyRating.length) {
          if (defaultKeyRating.length > 0) {
            // take the last element's .value
            var thisKeyRating = defaultKeyRating[defaultKeyRating.length - 1];

            if (ret === false || ret < thisKeyRating.value) {
              ret = thisKeyRating.value;

              console.log(
                "Matched:" +
                  key +
                  " = " +
                  thisKeyRating.attribute_id +
                  ":" +
                  thisKeyRating.key +
                  "=>" +
                  thisKeyRating.value
              );
            }
          }
        }
      }

      let _pE = {};

      if (currentProperty.areaResidential > 0) {
        _pE = {
          ..._pE,
          "108.1.1": null,
        };
      }

      if (currentProperty.areaOffice > 0) {
        _pE = {
          ..._pE,
          "108.1.2": null,
        };
      }

      if (currentProperty.areaCommercial > 0) {
        _pE = {
          ..._pE,
          "108.1.3": null,
        };
      }

      if (currentProperty.areaBuilding > 0) {
        _pE = {
          ..._pE,
          "108.1.4": null,
        };
      }

      if (currentProperty.areaHealth > 0) {
        _pE = {
          ..._pE,
          "108.1.5": null,
        };
      }

      const ageRatingsByCriteria = {
        "> 1": {
          106.2: null,
          107.1: null,
          ..._pE,
          108.2: null,
          202.1: null,
          301.1: null,
          301.2: null,
          303.2: null,
          303.3: null,
        },
      };

      // which age bracket does this property belong?
      var age_bracket = "";
      var this_age = currentProperty.age;
      if (this_age > 1) age_bracket = "> 1";

      // Rating Checker
      if (age_bracket !== "" && !toRecalc) {
        var bracket_criteria = ageRatingsByCriteria[age_bracket];

        if (key in bracket_criteria) {
          if (ret === false || ret < bracket_criteria[key]) {
            ret = bracket_criteria[key];

            console.log("Matched:" + key + " = " + age_bracket + "=>" + ret);
          }
        }
      }

      return ret;
    };

    var calculated_ratings = [];
    var p_id = currentProperty.id;

    criteria.forEach((group) => {
      group.children.forEach((rating) => {
        if (rating.children) {
          rating.children.forEach((subrating) => {
            let defaultValue = false;
            if (currentProperty.areaResidential > 0) {
              if (subrating.key === "108.1.1" || subrating.key === "304.2.1")
                defaultValue = getDefaultInitialValue(subrating.key);
            }

            if (currentProperty.areaOffice > 0) {
              if (subrating.key === "108.1.2" || subrating.key === "304.2.2")
                defaultValue = getDefaultInitialValue(subrating.key);
            }

            if (currentProperty.areaCommercial > 0) {
              if (
                subrating.key === "103.1.3" ||
                subrating.key === "108.1.3" ||
                subrating.key === "304.2.3"
              )
                defaultValue = getDefaultInitialValue(subrating.key);
            }

            if (currentProperty.areaBuilding > 0) {
              if (subrating.key === "108.1.4" || subrating.key === "304.2.4")
                defaultValue = getDefaultInitialValue(subrating.key);
            }

            if (currentProperty.areaHealth > 0) {
              if (subrating.key === "108.1.5" || subrating.key === "304.2.5")
                defaultValue = getDefaultInitialValue(subrating.key);
            }

            if (defaultValue !== false) {
              calculated_ratings.push({
                propertyId: p_id,
                key: subrating.key,
                value: defaultValue,
                comment: "",
              });
            }
          });
        } else {
          var defaultValue = getDefaultInitialValue(rating.key);
          if (defaultValue !== false) {
            calculated_ratings.push({
              propertyId: p_id,
              key: rating.key,
              value: defaultValue,
              comment: "",
            });
          }
        }
      });
    });

    var alldefaults = store.propertyDefaultRatings;
    originalPropertyAttributes.forEach((orig) => {
      // if the previously selected attrubte is no longer selected now,,,
      if (
        currentProperty.propertyAttributes.length == 0 ||
        currentProperty.propertyAttributes.includes(orig) == false
      ) {
        //then any rating connected to this attribute must be removed,
        var dr = alldefaults.filter(
          (d) => d.attribute_id == orig && d.value > -1
        );
        if (dr && dr.length) {
          dr.forEach((dre) => {
            calculated_ratings.push({
              propertyId: p_id,
              key: dre.key,
              value: -1,
              comment:
                "Related attribute was unchecked so remove related ratings",
            });
          });
        }
      }
    });

    // if (currentProperty.id == 0) {
    if (calculated_ratings.find((r) => isNaN(r.value))) {
      console.log("Cannot save has missing rating value.");
    } else {
      if (calculated_ratings.find((r) => isNaN(r.value))) {
        console.log("Cannot save has missing rating value.");
      } else {
        currentProperty
          .updateRatings(calculated_ratings, toRecalc)
          .then((success) => {
            console.log("Default ratings applied to property.");
            setOriginalPropertyAttributes(
              currentProperty.propertyAttributes.map((x) => x)
            );

            currentProperty.getRatings().then((success) => {
              var m = getMissingRatingKeys(currentProperty.ratings);
              setInvalidRatings(m);
            });
          });
      }
    }
    /*  } else {
      var allRatings = currentProperty.ratings.map((r) => {
        return {
          propertyId: currentProperty.id,
          key: r.key,
          value: r.value,
          comment: r.comment,
        };
      });

      ratings.forEach((r) => {
        allRatings.forEach((a) => {
          if (a.key == r.key) {
            a.value = r.value;
            a.comment = r.comment;
          }
        });
      });

      currentProperty.updateRatings(allRatings).then((success) => {
        console.log("Default ratings applied to new property.");
      });
    } */
  };

  const onPropertyFormFinish = async (values) => {
    await clearRatingsAndUpdate();
    if (values.lastTotalRenovation === "") values.lastTotalRenovation = 1;
    var totalArea =
      values.areaOffice +
      values.areaResidential +
      values.areaCommercial +
      values.areaBuilding +
      values.areaHealth;
    if (totalArea <= 0) {
      setModalMessage(t("client_property.insufficient_area"));
      return;
    }

    if (id === undefined || id === 0) {
      selectedPortfolio
        .addProperty({ portfolioId, ...values })
        .then((newProperty) => {
          if (newProperty) {
            saveInitialRatings(newProperty);
            store.setClientListUpdateOn(new Date().getTime().toString());
            store.getPropertiesByCompany(clientId);

            message.success(t("client_property.new_property_added"));
            form.resetFields();
            form.setFieldsValue({
              name: "",
              address: "",
              plz: "",
              city: "",
              canton: "",
              region: "",
              age: "",
              lastTotalRenovation: "",
              areaOffice: 0,
              areaResidential: 0,
              areaCommercial: 0,
              areaBuilding: 0,
              areaHealth: 0,
              attributes: [],
            });
            refreshList(true);
            setTimeout(() => {
              setVisible(false);
            }, 1500);
          } else {
            message.error(t("client_property.property_info_could_not_updated"));
          }
        });
    } else {
      //currentProperty.setValues(values);
      currentProperty
        .update({ portfolioId: currentProperty.portfolioId, ...values })
        .then((success) => {
          if (success) {
            // saveInitialRatings(currentProperty)

            //  WHen attributes get unchecked, Remove Ratings that were previously calculated by this attribute..

            setModalMessage(t("client_property.property_info_updated"));
            message.success(t("client_property.property_info_updated"));
            refreshList(true);
          } else {
            setModalMessage(
              t("client_property.property_info_could_not_updated")
            );
            message.error(t("client_property.property_info_could_not_updated"));
          }
        });
    }
  };

  useEffect(() => {
    (async () => {
      if (
        currentProperty.ratingChanged > 1 &&
        currentProperty.ratings.length === 0
      ) {
        // await onPropertyFormFinish(form.getFieldsValue());
        setTimeout(() => {
          saveInitialRatings(currentProperty, 1);
          setConfirmLoading(false);
          // setPopConfirmRatingvisible(false);
        }, 1000);
      }
    })();
  }, [currentProperty.ratingChanged, currentProperty.ratings.length]);

  const clearRatingsAndUpdate = async () => {
    // setConfirmLoading(true);
    currentProperty.clearRatings();
  };

  const onPropertyFormFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [propertyIdValidationState, setPropertyIdValidationState] =
    useState("");
  const onSelectPLZ = (data) => {
    console.log("onSelect", data);
  };
  const onChangePLZ = (data) => {
    setPlz(data);
    // if exact match then filter city autocomplete list
    if (plzs.find((r) => r.value === data)) {
      //set cantons
      var plzdata = postal_codes.find((p) => p.plz === data);
      if (plzdata) {
        var filteredCantons = cantons.filter(
          (canton) => canton.id === plzdata.cantonId
        );

        setCantonOptions(
          filteredCantons.length > 0
            ? filteredCantons.map((r) => {
                return { value: r.id, name: r.name };
              })
            : []
        );

        if (filteredCantons.length > 0) {
          setCanton(filteredCantons[0].id);

          form.setFieldsValue({ canton: filteredCantons[0].id });

          if (filteredCantons.length === 1) {
            var region = regions.find(
              (r) => r.id === filteredCantons[0].regionId
            );
            if (region) form.setFieldsValue({ region: region.name });
          }
        }
      } else {
        setCantonOptions(
          cantons.map((r) => {
            return { value: r.id, name: r.name };
          })
        );
      }

      var filteredCities = cities.filter((c) => c.plz === data);

      setCityOptions(
        filteredCities.map((r) => {
          return { value: r.city };
        })
      );

      if (filteredCities.length > 0) {
        setCity(filteredCities[0].city);
        form.setFieldsValue({ city: filteredCities[0].city });
      }
    } else {
      setCantonOptions(
        cantons.map((r) => {
          return { value: r.id, name: r.name };
        })
      );
      setCityOptions([]);

      if (propertyId < 1)
        form.setFieldsValue({ city: "", canton: "", region: "" });
    }
  };
  const onSearchPLZ = (searchText) => {
    setPlzOptions(plzs.filter((r) => r.value.indexOf(searchText) > -1));
  };
  const onChangeCity = (data) => {
    console.log("onChangeCity", data);
    setCity(data);
    // if exact match then filter city autocomplete list
    var thiscity = cities.find((r) => r.city === data);
    if (thiscity) {
      console.log(thiscity);
    }
  };
  const onChangeCanton = (data) => {
    console.log("onChangeCanton", data);
    var canton = cantons.find((c) => c.id === data);
    if (canton) {
      var region = regions.find((r) => r.id === canton.regionId);
      if (region) form.setFieldsValue({ region: region.name });
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setAreaTotal(
      form.getFieldValue("areaOffice") +
        form.getFieldValue("areaResidential") +
        form.getFieldValue("areaCommercial") +
        form.getFieldValue("areaBuilding") +
        form.getFieldValue("areaHealth")
    );

    if (
      changedValues.attributes ||
      allValues.areaResidential === 0 ||
      allValues.areaOffice === 0 ||
      allValues.areaCommercial === 0 ||
      allValues.areaBuilding === 0 ||
      allValues.areaHealth === 0
    ) {
      // check for Property Attribute exclusivity if there are changes in selected attributes

      var CheckedAttributes = allValues.attributes;

      // allow check if area  is non-zero
      //Wohenen: 2-areaResidential,BUrro: 3-areaOffice,Gewerbe 4-areaCommercial -

      // if (CheckedAttributes.indexOf(2) > -1) {
      //   if (allValues.areaResidential === 0) {
      //     CheckedAttributes.splice(CheckedAttributes.indexOf(2), 1);
      //   }
      // }
      // if (CheckedAttributes.indexOf(3) > -1) {
      //   if (allValues.areaOffice === 0) {
      //     CheckedAttributes.splice(CheckedAttributes.indexOf(3), 1);
      //   }
      // }
      // if (CheckedAttributes.indexOf(4) > -1) {
      //   if (allValues.areaCommercial === 0) {
      //     CheckedAttributes.splice(CheckedAttributes.indexOf(4), 1);
      //   }
      // }

      // exclusive ORs

      if (changedValues.attributes) {
        //[6:Radonwert <2% gemäss Radonkarte (weiss markiert),7:Radonmessung / Nachweis Einhaltung Grenzwert]
        // if (
        //   changedValues.attributes.indexOf(6) > -1 &&
        //   CheckedAttributes.indexOf(7) > -1
        // ) {
        //   CheckedAttributes.splice(CheckedAttributes.indexOf(6), 1);
        // }
        // if (
        //   changedValues.attributes.indexOf(7) > -1 &&
        //   CheckedAttributes.indexOf(6) > -1
        // ) {
        //   CheckedAttributes.splice(CheckedAttributes.indexOf(7), 1);
        // }
        //[8:Nach Minergie zertifiziert , 9:Nach Minergie-Eco zertifiziert]
        // if (
        //   CheckedAttributes.indexOf(8) > -1 &&
        //   CheckedAttributes.indexOf(9) > -1
        // ) {
        //   CheckedAttributes.splice(CheckedAttributes.indexOf(8), 1);
        // }
        //[10:GEAK A, 11:GEAK B/C]
        // if (
        //   CheckedAttributes.indexOf(10) > -1 &&
        //   CheckedAttributes.indexOf(11) > -1
        // ) {
        //   CheckedAttributes.splice(CheckedAttributes.indexOf(10), 1);
        // }
        //[12:Nach Minergie (A) zertifiziert, 13:Nach Minergie (P) zertifiziert]
        // if (
        //   CheckedAttributes.indexOf(12) > -1 &&
        //   CheckedAttributes.indexOf(13) > -1
        // ) {
        //   CheckedAttributes.splice(CheckedAttributes.indexOf(12), 1);
        // }
      }
      form.setFieldsValue({ attributes: CheckedAttributes });
    }
  };

  const checkExistingProperty = (e) => {
    const _name = e.currentTarget.value;

    const _portfolio = store.portfolios.find(
      (_p) => _p.id === store.activePortfolioId
    );

    const found = _portfolio.properties.findIndex(
      (_pp) => _pp.name.toLowerCase() === _name.toLowerCase()
    );

    if (found > -1) {
      setPropertyNameFound(true);
    } else {
      setPropertyNameFound(false);
    }
  };

  const CURRENT_YEAR = new Date().getFullYear();
  return (
    <Modal
      destroyOnClose
      title={false}
      centered
      visible={visible}
      okText={t("client_property.submit")}
      closable={true}
      onCancel={() => {
        setVisible(false);
      }}
      footer={false}
      width={1300}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onPropertyFormFinish}
        onFinishFailed={onPropertyFormFinishFailed}
        onValuesChange={onValuesChange}
      >
        <Row type="flex" align="left">
          <Title
            style={{
              display: "block !important",
              textAlign: "left",
              width: "100%",
            }}
            level={3}
          >
            {!id || id === 0
              ? t("client_property.new")
              : t("client_property.existing")}{" "}
            {t("client_property.property")}
          </Title>
        </Row>
        <Row gutter={16}>
          <Col span={14}>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item
                  labelCol={16}
                  label={
                    <>
                      {t("client_property.property_id")}
                      {propertyNameFound ? (
                        <small style={{ color: "red", marginLeft: "10px" }}>
                          {t("propertyExist")}
                        </small>
                      ) : (
                        ""
                      )}
                    </>
                  }
                  name="name"
                  // validateStatus={propertyIdValidationState}
                  // hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t("client_property.please_enter_property_id"),
                    },
                  ]}
                >
                  <Input onChange={checkExistingProperty} />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label={t("client_property.year")}
                  name="age"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "client_property.please_enter_construction_year"
                      ),
                    },
                  ]}
                >
                  <YearInput />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label={t("client_property.last_total_renovation")}
                  name="lastTotalRenovation"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: t(
                  //       "client_property.please_enter_total_renovation_year"
                  //     ),
                  //   },
                  // ]}
                >
                  <YearInput />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  labelCol={16}
                  label={t("client_property.address")}
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: t("client_property.please_enter_client_add"),
                    },
                  ]}
                >
                  <Input value={address} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  label={t("client_property.plz")}
                  name="plz"
                  rules={[
                    {
                      required: true,
                      message: t("client_property.please_enter_postal_code"),
                    },
                  ]}
                >
                  <AutoComplete
                    options={plzOptions}
                    onSelect={onSelectPLZ}
                    onSearch={onSearchPLZ}
                    onChange={onChangePLZ}
                    placeholder={t("client_property.plz")}
                  >
                    <Input value={plz} />
                  </AutoComplete>
                </Form.Item>
                {/* <Form.Item
                  label={t("client_property.canton")}
                  name="canton"
                  rules={[
                    {
                      required: true,
                      message: t("client_property.please_enter_canton"),
                    },
                  ]}
                >
                  <Select
                    onChange={onChangeCanton}
                    placeholder={t("client_property.canton")}
                  >
                    {cantonOptions.map((canton) => (
                      <Option key={canton.value} value={canton.value}>
                        {canton.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item> */}
              </Col>
              <Col span={16}>
                <Form.Item
                  label={t("client_property.city")}
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: t("client_property.please_enter_city"),
                    },
                  ]}
                >
                  <AutoComplete
                    options={cityOptions}
                    onChange={onChangeCity}
                    placeholder={t("client_property.city")}
                  >
                    <Input value={city} />
                  </AutoComplete>
                </Form.Item>

                <Form.Item
                  style={{ display: "none" }}
                  label={t("client_property.region")}
                  name="region"
                >
                  <Input readOnly={true} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={t("client_property.canton")}
                  name="canton"
                  rules={[
                    {
                      required: true,
                      message: t("client_property.please_enter_canton"),
                    },
                  ]}
                >
                  <Select
                    onChange={onChangeCanton}
                    placeholder={t("client_property.canton")}
                  >
                    {cantonOptions.map((canton) => (
                      <Option key={canton.value} value={canton.value}>
                        {canton.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <label> {t("client_property.area_per_usage_form")}</label>
                <div className="ant-table-wrapper">
                  <div
                    className="ant-table ant-table-small ant-table-bordered"
                    style={{ borderRight: "solid 1px rgb(240,240,240)" }}
                  >
                    <div className="ant-table-container">
                      <table style={{ tableLayout: "auto" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_property.wohnen")} (m<sup>2</sup>)
                            </th>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_property.büro")} (m<sup>2</sup>)
                            </th>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_property.gewerbe")} (m<sup>2</sup>)
                            </th>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_property.bildung")} (m<sup>2</sup>)
                            </th>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_property.gesundheit")} (m<sup>2</sup>)
                            </th>
                            <th className="ant-table-cell" align={"center"}>
                              {t("client_property.total")} (m<sup>2</sup>)
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ant-table-tbody">
                          <tr className="ant-table-row ant-table-row-level-0">
                            <td className="ant-table-cell">
                              <Form.Item
                                style={{ marginBottom: "0px" }}
                                name="areaResidential"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "client_property.please_enter_residential_space"
                                    ),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={999999}
                                  step={1}
                                  formatter={(value) =>
                                    !isNaN(value) &&
                                    value &&
                                    parseInt(value).toLocaleString("de-ch")
                                  }
                                />
                              </Form.Item>
                            </td>
                            <td className="ant-table-cell">
                              <Form.Item
                                style={{ marginBottom: "0px" }}
                                name="areaOffice"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "client_property.please_enter_office_space"
                                    ),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={999999}
                                  step={1}
                                  formatter={(value) =>
                                    !isNaN(value) &&
                                    value &&
                                    parseInt(value).toLocaleString("de-ch")
                                  }
                                />
                              </Form.Item>
                            </td>
                            <td className="ant-table-cell">
                              <Form.Item
                                style={{ marginBottom: "0px" }}
                                name="areaCommercial"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "client_property.please_enter_commercial_space"
                                    ),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={999999}
                                  step={1}
                                  formatter={(value) =>
                                    !isNaN(value) &&
                                    value &&
                                    parseInt(value).toLocaleString("de-ch")
                                  }
                                />
                              </Form.Item>
                            </td>
                            <td className="ant-table-cell">
                              <Form.Item
                                style={{ marginBottom: "0px" }}
                                name="areaBuilding"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "client_property.please_enter_bildung_space"
                                    ),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={999999}
                                  step={1}
                                  formatter={(value) =>
                                    !isNaN(value) &&
                                    value &&
                                    parseInt(value).toLocaleString("de-ch")
                                  }
                                />
                              </Form.Item>
                            </td>
                            <td className="ant-table-cell">
                              <Form.Item
                                style={{ marginBottom: "0px" }}
                                name="areaHealth"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "client_property.please_enter_gesundheit_space"
                                    ),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={999999}
                                  step={1}
                                  formatter={(value) =>
                                    !isNaN(value) &&
                                    value &&
                                    parseInt(value).toLocaleString("de-ch")
                                  }
                                />
                              </Form.Item>
                            </td>
                            <td
                              className="ant-table-cell"
                              style={{ textAlign: "center" }}
                            >
                              {!isNaN(areaTotal) &&
                                areaTotal &&
                                parseInt(areaTotal).toLocaleString("de-ch", {
                                  minimumFractionDigits: 0,
                                })}
                              m<sup>2</sup>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Form.Item
              labelCol={24}
              label={t("property.attributes")}
              name="attributes"
            >
              <Checkbox.Group style={{ width: "100%", marginLeft: "15px" }}>
                <Row gutter={[0, 10]}>
                  <Text>{t("property.attribute_category.allgemein")}</Text>
                  {propertyAttributes.map((att) => {
                    if (att.active && att.attributeCategoryId === 1) {
                      return (
                        <Col key={att.id} span={24}>
                          <Checkbox value={att.id}>
                            {t("property.attributenames." + att.id)}
                          </Checkbox>
                        </Col>
                      );
                    }
                  })}
                  <Text style={{ marginTop: "10px" }}>
                    {t("property.attribute_category.zertifizierungen")}
                  </Text>
                  {propertyAttributes.map((att) => {
                    if (att.active && att.attributeCategoryId === 2) {
                      return (
                        <Col key={att.id} span={24}>
                          <Checkbox value={att.id}>
                            {t("property.attributenames." + att.id)}
                          </Checkbox>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            {/* {currentProperty.ratings.length < 13 ? (
              <h3 style={{ color: "red" }}>
                {t("property.rating_values_missing")}
              </h3>
            ) : (
              ""
            )} */}
            {id > 0 && InvalidRatings.length > 0 && (
              // <Card
              //   style={{
              //     color: "red",
              //     marginLeft: "20px",
              //     marginRight: "20px",
              //   }}
              // >
              <>
                <h3 style={{ color: "red" }}>
                  {t("property.rating_values_missing")}
                </h3>
              </>
              //   {InvalidRatings.length > 6 ? (
              //     <ol>
              //       {InvalidRatings.map((i, _i) => (
              //         <li key={_i} style={{ textTransform: "uppercase" }}>
              //           {i}
              //         </li>
              //       ))}
              //     </ol>
              //   ) : (
              //     ""
              //   )}
              // </Card>
            )}
          </Col>
        </Row>
        <Row gutter={[16, 0]} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <div align={"right"}>
              <Form.Item>
                <Button
                  type="default"
                  htmlType="button"
                  style={{
                    display: "inline-block",
                    width: "calc(14% - 16px)",
                    margin: "0 8px 0",
                  }}
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  {t("client_property.dismiss")}
                </Button>
                <Button
                  type="default"
                  htmlType="button"
                  style={{
                    display: "inline-block",
                    width: "calc(14% - 16px)",
                    margin: "0 8px 0",
                  }}
                  onClick={() => {
                    resetFields();
                  }}
                >
                  {t("client_property.reset")}
                </Button>
                {/* <Popconfirm
                className="mv-popup"
                title={() => (
                  <>
                    <p style={{ paddingTop: "1px" }}>
                      {t("recalculateConfirm")}
                    </p>
                    {t("client_property.property_info_updated")}
                  </>
                )}
                visible={popConfirmRatingvisible}
                onConfirm={() => clearRatingsAndUpdate(currentProperty)}
                okButtonProps={{ loading: confirmLoading }}
                onCancel={() => setPopConfirmRatingvisible(false)}
              >
                <Button
                  type="danger"
                  onClick={() => setPopConfirmRatingvisible(true)}
                  style={{
                    display: "inline-block",
                    width: "calc(24% - 16px)",
                    margin: "0 8px",
                  }}
                >
                  {t("recalculate")}
                </Button>
              </Popconfirm> */}
                <Button
                  type="primary"
                  // onClick={}
                  htmlType="submit"
                  style={{
                    display: "inline-block",
                    width: "calc(14% - 16px)",
                    margin: "0 8px",
                  }}
                >
                  {t("client_property.save")}
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
      <div>{/* <p align={'middle'}>{modalMessage}</p> */}</div>
    </Modal>
  );
};

export default inject("store")(observer(ClientPropertyDetailModal));
