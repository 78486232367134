import React from "react";
import CriteriaSettingsController from "../../../controllers/CriteriaSettingsController";
import "tinymce/tinymce";
import "tinymce/models/dom/model";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import { Editor } from "@tinymce/tinymce-react";

const TinyMCE = ({ set, data }) => {
  const { onContentChange } = CriteriaSettingsController({ set });

  return (
    <Editor
      init={{
        skin: false,
        content_css: false,
        content_style:
          // "div.rotate-vertical {writing-mode: vertical-rl; transform: rotate(180deg); margin-right: 2px;}",
          `.rotate-vertical {text-align: center;white-space: nowrap;vertical-align: middle;width: 1.5em;} .rotate-vertical div {writing-mode: vertical-rl; transform: rotate(180deg);}`,
        statusbar: false,
        menubar: false,
        inline: false,
        width: "650px",
        plugins: ["autoresize", "link", "lists", "table"],
        toolbar:
          "undo redo | blocks fontsize | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | table tablecellvalign | link | customRotateText270 | forecolor backcolor pastetext removeformat",
        toolbar_mode: "wrap",
        smart_paste: true,
        font_size_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
        table_toolbar: "",
        table_resize_bars: true,
        // table_column_resizing: "preservetable",
        setup: function (editor) {
          let selectedContent = "";
          editor.ui.registry.addButton("customRotateText270", {
            icon: "rotate-left",
            tooltip: "Rotate Text Vertically",
            enabled: true,
            onAction: (_) => {
              console.log(selectedContent, "selectedContent");
              editor.selection.setContent(
                `<div class="rotate-vertical"><div>${selectedContent}</div></div>`
              );
              editor.undoManager.add(function (ed) {
                alert("added.");
              });
            },
            onSetup: (_) => {
              const editorEventCallback = (_) => {
                selectedContent = editor.selection.getContent({
                  format: "text",
                });
              };
              editor.on("NodeChange", editorEventCallback);

              return () => editor.off("NodeChange", editorEventCallback);
            },
          });
        },
      }}
      value={data}
      onEditorChange={onContentChange}
    />
  );
};

export default TinyMCE;
