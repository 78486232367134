import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import LoginModel from "../../models/LoginModel";
import CompanyModel from "../../models/CompanyModel";
import parsePhoneNumber from "libphonenumber-js";
import ResizableTitle from "../Common/ResizableTitle";

import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  Switch,
  Space,
  Table,
  Modal,
  message,
  Select,
  Popconfirm,
} from "antd";

import {
  ThunderboltOutlined,
  PlusOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
//translation
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const ClientUsersListing = (props) => {
  const { store, mustRefresh,  selectedClient /* clientId */ } = props;
  const { t } = useTranslation("common"); //translation


/*   const [selectedClient, setSelectedClient] = useState(CompanyModel.create({})); */
  const [Users, setUsers] = useState([]);


  

  const refreshList = (reloadStore = true) => {
    console.log("refreshing user listing...");
    if (reloadStore) {
      selectedClient.getUsers().then((success) => {
        if (success) {
          setUsers(selectedClient.users);
          store.setClientListUpdateOn(new Date().getTime().toString());
        }
      });
    } else {
      setUsers(selectedClient.users);
    }
  };

  const [phonePrefix, setPhonePrefix] = useState(false);
  const [mobilePrefix, setMobilePrefix] = useState(false);

  useEffect(() => {
    if (selectedClient.id>0) {
      //refreshList(true);
      selectedClient.getUsers().then((s) => {
        setUsers(selectedClient.users);
      });
    }
    return () => {
      setUsers([]);
    };
  }, [selectedClient,store.clientListUpdateOn]);

  const [ModalMessage, setModalMessage] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onClientUsersFormFinish = (values) => {
    var existing = selectedClient.users.find(
      (u) => u.email === values.email && u.id != userId
    );
    setConfirmLoading(true);
    if (existing) {
      setModalMessage(
        `${t("client_users.the_email_add")} '${values.email}' ${t(
          "client_users.already_registered_to"
        )} ${existing.firstName} ${existing.lastName}`
      );
      setConfirmLoading(false);
    } else {
      if (userId === 0) {
        selectedClient
          .addUser(
            values.lastName,
            values.firstName,
            values.phoneNumber,
            values.mobileNumber,
            values.email,
            selectedClient.id,
            values.position,
            values.password,
            values.portfolioAccess,
            values.sendInviteEmail
          )
          .then((user) => {
            if (user.status !== 409) {

              if(values.sendInviteEmail)
              setModalMessage(
                `${t("client_users.new_user_added")} '${values.email}' ${t(
                  "client_users.containing_the_login_info"
                )}`
              );
              else {

                setModalMessage(
                  `${t("client_users.new_user_added_2")}`
                );
              }
              form.setFieldsValue({
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                mobileNumber: "",
                password: "",
                position: "",
                portfolioAccess: [],
              });
              refreshList(true);
              setTimeout(() => {
                setModalMessage(t("client_users.enter_new_user_info"));
              }, 5000);
            } else if (user.status === 409) {
              setModalMessage(
                `${t("client_users.the_email_add")} '${values.email}' ${t(
                  "client_users.already_registered"
                )}`
              );
            } else {
              setModalMessage(t("client_users.user_could_not_added"));
            }
            setConfirmLoading(false);
          });
      } else {
        /* password,
        email,
        firstName,
        lastName,
        position,
        companyId,
        portfoliosWithAccess=[] */

        selectedUser
          .update(
            selectedUser.password === values.password ? "" : values.password,
            values.email,
            values.firstName,
            values.lastName,
            values.phoneNumber,
            values.mobileNumber,
            values.position,
            selectedClient.id,
            values.portfolioAccess
          )
          .then((user) => {
            if (user) {
              setModalMessage(t("client_users.user_detail_upd_succesfully"));
              successMessage(t("client_users.user_detail_upd_succesfully"));
              refreshList(true);
            } else {
              setModalMessage(t("client_users.user_detail_could_not_upd"));
              errorMessage(t("client_users.user_detail_could_not_upd"));
            }
          });
        setConfirmLoading(false);
      }
    }
  };
  const onClientUsersFormFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setConfirmLoading(false);
  };
  const [form] = Form.useForm();

  const [IsFormMode, setIsFormMode] = useState(false);

  /* const [uroDefaultValues, setUroDefaultValues] = useState([]); */
  const [userRightsOptions, setUserRightsOptions] = useState([]);
  function userRightsOptionsOnChange(checkedValues) {
    console.log("checked = ", checkedValues);
  }
  /*  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
    marginLeft: "10px",
  }; */

  const [userId, setUserId] = useState(false);
  const [selectedUser, setSelectedUser] = useState(LoginModel.create({}));
  useEffect(() => {
    
    if (IsFormMode === true) {
      if (userId === 0) {
        setSelectedUser(LoginModel.create({}));
      } else {
        //an existing user is selected from the list
        console.log(toJS(selectedClient.users))
        setSelectedUser(selectedClient.users.find((u) => u.id === userId));
      }
    } else {
      setUserId(0)
      
      setSelectedUser(LoginModel.create({}));
    }
    return () => {
      setModalMessage("");

      form.setFieldsValue({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        position: "",
        portfolioAccess: [],
      });
    };
  }, [IsFormMode, userId]);

  useEffect(() => {
    setPhonePrefix(false);
    setMobilePrefix(false);
    if (selectedUser && IsFormMode === true) {
      var user = selectedUser;
      if (user.id === 0) {
        form.setFieldsValue({
          firstName: "",
          lastName: "",
          phoneNumber: "",
          mobileNumber: "",
          email: "",
          password: "",
          position: "",
          portfolioAccess: [],
        });
        selectedClient.getPortfolios().then((success) => {
          if (success) {
            var pua_options = [];
            selectedClient.portfolios.forEach((portfolio) => {
              pua_options.push({
                label: `${portfolio.name}`,
                value: portfolio.id,
                hasAccess: false,
              });
            });
            setUserRightsOptions(pua_options);
            /* setUroDefaultValues([]); */
            form.setFieldsValue({ portfolioAccess: [] });
            console.log(form.getFieldsValue());
          }
        });
      } else {
        form.setFieldsValue({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          mobileNumber: user.mobileNumber,
          password: "",
          position: user.position,
          portfolioAccess: [],
        });

        const phoneNumber = parsePhoneNumber(user.phoneNumber, "CH");
        if (phoneNumber && phoneNumber.isValid() === true) {
          setPhonePrefix(phoneNumber.country);
        }
        const mobilePhoneNumber = parsePhoneNumber(user.mobileNumber, "CH");
        if (mobilePhoneNumber && mobilePhoneNumber.isValid() === true) {
          setMobilePrefix(mobilePhoneNumber.country);
        }

        selectedClient.getPortfolios().then((success) => {
          if (success) {
            var pua_options = [];
            store.getPortfolioUserAccessByUserId(userId).then((success2) => {
              if (success2) {
                selectedClient.portfolios.forEach((portfolio) => {
                  pua_options.push({
                    label: `${portfolio.name}`,
                    value: portfolio.id,
                    hasAccess: store.userPortfoliosAccessRights.find(
                      (pua) => pua.portfolioId === portfolio.id
                    )
                      ? true
                      : false,
                  });
                });
                setUserRightsOptions(pua_options);
                /*  setUroDefaultValues(
            pua_options
              .filter((ua) => ua.hasAccess === true)
              .map((ub) => ub.value)
          ); */
                form.setFieldsValue({
                  portfolioAccess: store.userPortfoliosAccessRights.map(
                    (u) => u.portfolioId
                  ),
                });
              }
            });
          }
        });
      }
    }
  }, [selectedUser]);

  const deactivateUser = (record, permanent = false) => {
    //record.setActive(false);
    console.log(`Remove ${record.id}...`);
    selectedClient.getUsers().then((success) => {

      selectedClient.removeUser(record.id, permanent ? 1 : 0).then((success) => {
        if (success) {
          //refreshList(true);
          successMessage(
            `${record.firstName} ${record.lastName} (${record.email}) ${t(
              "client_users.no_longer_have_access"
            )}`
          );
        } else {
          errorMessage(
            `${t("client_users.failed_to_remove_access")} ${record.firstName} ${
              record.lastName
            } (${record.email}).`
          );
        }
      });

    });
   
    /*  confirm({
      title: "Confirm User Deactivation",
      icon: <ExclamationCircleOutlined />,
      content: `${record.firstName} ${record.lastName} (${record.email}) will no longer have access. Continue?`,
      onOk() {
        store.removeUser(record.id, 0).then((success) => {
          if (success) {
            refreshList(true);
            successMessage(
              `${record.firstName} ${record.lastName} (${record.email}) no longer have access.`
            );
          } else {
            errorMessage(
              `Failed to remove access to ${record.firstName} ${record.lastName} (${record.email}).`
            );
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    }); */
  };

  const activateUser = (record) => {
    //record.setActive(true);
    console.log(`Activate ${record.id}...`);
    selectedClient.activateUser(record.id).then((success) => {
      if (success) {
        refreshList(true);
        successMessage(
          `${t("client_users.failed_to_granted_to")} ${record.firstName} ${
            record.lastName
          } (${record.email}).`
        );
      } else {
        errorMessage(
          `${t("client_users.failed_to_grant_access")}  ${record.firstName} ${
            record.lastName
          } (${record.email}).`
        );
      }
    });

    /*  confirm({
      title: "Confirm User Activation",
      icon: <ExclamationCircleOutlined />,
      content: `This action will grant access to ${record.firstName} ${record.lastName} (${record.email}).`,
      onOk() {
        store.activateUser(record.id).then((success) => {
          if (success) {
            refreshList(true);
            successMessage(
              `Access granted to ${record.firstName} ${record.lastName} (${record.email}).`
            );
          } else {
            errorMessage(
              `Failed to grant access to ${record.firstName} ${record.lastName} (${record.email}).`
            );
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    }); */
  };

  const successMessage = (msg) => {
    message.success(msg);
  };

  const errorMessage = (msg) => {
    message.error(msg);
  };

  const warningMessage = (msg) => {
    message.warning(msg);
  };
  const initialcolumns = [
    {
      title: t("client_users.name"),
      dataIndex: "id",
      width: 300,
      key: "id",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <a
          key={record.id}
          onClick={() => {
            selectUser(record.id);
          }}
        >
          <span
            key={record.id}
          >{`${record.firstName} ${record.lastName}`}</span>
        </a>
      ),
    },
    {
      title: t("client_users.email_add"),
      dataIndex: "email",
      width: 300,
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("client_users.position"),
      dataIndex: "position",
      width: 300,
      key: "position",
      sorter: (a, b) => a.position.localeCompare(b.position),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("client_users.phone_num"),
      dataIndex: "phoneNumber",
      width: 200,
      key: "phoneNumber",
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("client_users.mobile_num"),
      dataIndex: "mobileNumber",
      width: 200,
      key: "mobileNumber",
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("client_users.status"),
      dataIndex: "active",
      width: 100,
      key: "active",
      sorter: (a, b) => a.position.localeCompare(b.position),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <div key={record.id} align={"middle"}>
          <Switch
            key={record.id}
            size={"small"}
            checkedChildren={t("client_users.active")}
            unCheckedChildren={t("client_users.inactive")}
            checked={record.active}
            title={
              `${record.firstName} ${record.lastName} (${record.email}) ` +
              (record.active
                ? t("client_users.has")
                : t("client_users.does_not_have")) +
              t("client_users.access")
            }
            onClick={(e) => {
              record.active ? deactivateUser(record) : activateUser(record);
            }}
          />
        </div>
      ),
    },

    {
      title: "Action",
      key: "id",
      width: 200,
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            style={{ color: "red" }}
            placement="left"
            title={
              t("admin.delete") +
              ` ${record.firstName} ${record.lastName} (${record.email})`
            }
            onConfirm={() => deactivateUser(record, true)}
            okText={t("client_portfolio.yes")}
            cancelText={t("client_portfolio.no")}
          >
            <Button
              title={
                t("admin.delete") +
                ` ${record.firstName} ${record.lastName} (${record.email})`
              }
            >
              {t("admin.delete")}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const [columns, setColumns] = useState(initialcolumns);
  const generatePassword = () => {
    var randPassword = Array(10)
      .fill(
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz$&!*@+%_~?;"
      )
      .map(function(x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");
    form.setFieldsValue({ password: randPassword });
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  const layout2 = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const tailLayout = {
    style: { marginTop: "40px" },
    wrapperCol: { offset: 7, span: 18 },
  };

  const checkboxlist2Layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };

  const addNewUser = () => {
    setUserId(0);
    setIsFormMode(true);
  };

  const selectUser = (id) => {
    console.log(id)
    setUserId(id);
    setIsFormMode(true);
  };

  useEffect(() => {
    // if (mustRefresh === true) refreshList(true);
  }, [mustRefresh]);

  const handleResize = (index) => (e, { size }) => {
   
    const nextColumns = [...columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };

    setColumns(nextColumns);
  };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));


  return (
    <>
      <Row align="middle">
        <Col flex={"auto"}>
          <Title
            style={{
              display: "block !important",
              textAlign: "left",
              width: "100%",
            }}
            level={4}
          >
            {t("client_users.users")}
          </Title>
        </Col>
        <Col flex={"auto"} style={{ textAlign: "right" }}>
          {/* <Title level={4}>{selectedClient ? selectedClient.name : ""}</Title> */}
        </Col>
      </Row>

      <>
        <div style={{ marginBottom: 16, textAlign: "left" }}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                addNewUser();
              }}
            >
              <PlusOutlined /> {t("client_users.new_user")}
            </Button>
          </Space>
        </div>
        <Table
          dataSource={toJS(Users).map((pf) => {
            return { key: pf.id, ...pf };
          })}
          className={"table-resizeable"}
          components={components}
              columns={resizableColumns}
          size="small"
          bordered="true"
          /*rowSelection={IsEditMode}
              loading={IsLoading}
              hasData={HasData}*/
          style={{ minHeight: "260px" }}
          rowKey={"id"}
        />
      </>

      <Modal
        title={false}
        centered
        visible={IsFormMode}
        okText={t("client_users.submit")}
        closable={true}
        onCancel={() => {
          setIsFormMode(false);
        }}
        confirmLoading={confirmLoading}
        footer={false}
        className={"ant-custom"}
        width={800}
      >
        <Row type="flex" align="middle" gutter={[32, 20]}>
          <Title
            style={{
              display: "block !important",
              textAlign: "left",
              width: "100%",
              marginTop: "20px",
            }}
            level={3}
          >
            {userId > 0
              ? t("client_users.existing_user")
              : t("client_users.add_new_user")}
          </Title>
        </Row>
        <Form
          autocomplete="off"
          form={form}
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onClientUsersFormFinish}
          onFinishFailed={onClientUsersFormFinishFailed}
        >
          {/*  <Row type="flex" align="middle"></Row> */}
          <Row>
            <Col span={24}>
              <Form.Item
                label={t("client_users.first_name")}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t("client_users.please_enter_first_name"),
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder={t("client_users.first_name")} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("client_users.last_name")}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t("client_users.please_enter_last_name"),
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder={t("client_users.last_name")} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("client_users.email_add")}
                name="email"
                rules={[
                  {
                    required: true,
                    message: t("client_users.please_enter_email_add"),
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  },
                ]}
              >
                <Input
                  autoComplete={"new-password"}
                  placeholder={t("client_users.email_add")}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("client_users.phone_num")}
                name="phoneNumber"
                rules={[
                 
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      /*if (!value || value.indexOf("+") > -1) {
                        return Promise.resolve();
                      }*/
                      if (value) {
                        const phoneNumber = parsePhoneNumber(value, "CH");
                        if (phoneNumber && phoneNumber.isValid() === true) {
                          form.setFieldsValue({
                            phoneNumber: phoneNumber.number,
                          });
                          setPhonePrefix(phoneNumber.country);
                          return Promise.resolve();
                        } else {
                          setPhonePrefix(false);
                          return Promise.reject(
                            t("client_users.please_enter_phone")
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  addonBefore={phonePrefix}
                  placeholder={t("client_users.phone_num")}
                />
              </Form.Item>
            </Col>{" "}
            <Col span={24}>
              <Form.Item
                label={t("client_users.mobile_num")}
                name="mobileNumber"
                rules={[
                 
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      /*if (!value || value.indexOf("+") > -1) {
                        return Promise.resolve();
                      }*/
                      if (value ) {
                        const phoneNumber = parsePhoneNumber(value, "CH");
                        if (phoneNumber && phoneNumber.isValid() === true) {
                          form.setFieldsValue({
                            mobileNumber: phoneNumber.number,
                          });
                          setMobilePrefix(phoneNumber.country);
                          return Promise.resolve();
                        } else {
                          setMobilePrefix(false);
                          return Promise.reject(
                            t("client_users.please_enter_mobile")
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  addonBefore={mobilePrefix}
                  placeholder={t("client_users.mobile_num")}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("client_users.position")}
                name="position"
                rules={[
                  {
                    required: true,
                    message: t("client_users.please_enter_position"),
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder={t("client_users.position")} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("client_users.password")}
                name="password"
                rules={[
                  {
                    required: userId > 0 ? false : true,
                    min: 8,
                    message: () => {
                      
                      const _password = form.getFieldValue('password')
                      
                      if(_password === '' ) {
                        return t("client_users.please_enter_pass")
                      } 

                      if(_password.length < 8) {
                       
                        return t("client_users.password_length")
                      }
                    },
                    whitespace: true,
                  },
                ]}
              >
                <Input.Password
                  defaultValue={""}
                  autoComplete={"new-password"}
                  placeholder={t("client_users.password")}
                  addonAfter={
                    <ThunderboltOutlined
                      title={t("client_users.generate_random_pass")}
                      onClick={() => {
                        generatePassword();
                      }}
                    />
                  }
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("client_users.portfolio_access")}
                name="portfolioAccess"
                {...checkboxlist2Layout}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={
                    userRightsOptions.length === 0
                      ? t("client_users.no_portfolios")
                      : t("client_users.select_portfolios")
                  }
                  onChange={userRightsOptionsOnChange}
                >
                  {userRightsOptions.map((i) => (
                    <Option key={i.value} value={i.value} label={i.label}>
                      {i.label}
                    </Option>
                  ))}
                </Select>
                {/*  <Checkbox.Group onChange={userRightsOptionsOnChange}>
                  <Row>
                    {userRightsOptions.length > 0 &&
                      userRightsOptions.map((i) => (
                        <Col span={userRightsOptions.length > 1 ? 12 : 24}>
                          <Checkbox
                            style={radioStyle}
                            value={i.value}
                            checked={i.hasAccess}
                          >
                            {i.label}
                          </Checkbox>
                        </Col>
                      ))}
                    {userRightsOptions.length === 0 && (
                      <Col span={"24"} align={"middle"}>
                        <Empty />
                      </Col>
                    )}
                  </Row>
                </Checkbox.Group> */}
              </Form.Item>
            </Col>
            {(!userId || userId === 0) && (
              <Col span={24}>
                <Form.Item
                  {...layout2}
                  name="sendInviteEmail"
                  valuePropName="checked"
                >
                  <Checkbox>{t("client_users.send_invi_email")}</Checkbox>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Form.Item {...tailLayout}>
            <Button
              type="default"
              htmlType="button"
              style={{
                display: "inline-block",
                width: "calc(50% - 16px)",
                margin: "0 8px 0",
              }}
              onClick={() => {
                setIsFormMode(false);
              }}
            >
              {t("client_users.close")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                display: "inline-block",
                width: "calc(50% - 16px)",
                margin: "0 8px",
              }}
              onClick={(e) => {
                setConfirmLoading(true);
              }}
              loading={confirmLoading}
            >
              {t("client_users.submit")}
            </Button>
          </Form.Item>
        </Form>
        <div style={{ textAlign: "center" }}>{ModalMessage}</div>
      </Modal>
    </>
  );
};

export default observer(ClientUsersListing);
