import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import axios from "axios";
import XLSX from "xlsx";
import { toJS } from "mobx";
import { getPropertyRating } from "../../data/RatingCriteria";
import {
  Row,
  Col,
  Typography,
  Input,
  Button,
  Space,
  Table,
  Modal,
  Drawer,
  message,
  Popconfirm,
  Spin,
  Result,
  Upload,
  Tooltip,
} from "antd";

import {
  PlusOutlined,
  ImportOutlined,
  LoadingOutlined,
  DeleteOutlined,
  ToTopOutlined,
} from "@ant-design/icons";

import PropertyImportModal from "./PropertyImportModal";
import PortfolioDetailModal from "./PortfolioDetailModal";

import ClientPropertyDetailModal from "./ClientPropertyDetailModal";
import ClientPropertyDetailDrawer from "./ClientPropertyDetailDrawer";

import CompanyModel from "../../models/CompanyModel";
// import PortfolioModel from "../../models/PortfolioModel";

import {
  criteria,
  getPortfolioRating,
  getPortfolioTotalArea,
} from "../../data/RatingCriteria";
import PropertyTableRow from "./PropertyTableRow";
import ActualRatingsModal from "../Admin/Ratings/ActualRatingsModal";
//translation
import { useTranslation } from "react-i18next";
import { isStateTreeNode } from "mobx-state-tree";
// import PortfolioRatingValue from "./PortfolioRatingValue";
const PropertyAuditLog = (props) => {
  //activeClientId: types.optional(types.number, 0),
  //activeUserId: types.optional(types.number, 0),
  //activePortfolioId: types.optional(types.number, 0),

  const { store, portfolioId } = props;
  const [AuditLogs, setAuditLogs] = useState({ auditDate: "", comment: "" });

  const [Logs, setLogs] = useState([]);
  useEffect(() => {
    var pf = store.clients
      .find((c) => c.id === store.activeClientId)
      .portfolios.find((pf) => pf.id === portfolioId);
    var properties = pf.properties;
    var a = [];
    properties.forEach((p) => {
      /*  if (p.auditLogs.length === 0) {
        p.getAuditLogs().then((s) => {
          if (p.auditLogs.length > 0) {
            p.auditLogs.forEach((l) => {
              setLogs((prevLogs) => [
                ...prevLogs,
                { auditDate: l.auditDate, comment: l.comment },
              ]);
            });
          }
        });
      } else { */
      p.auditLogs.forEach((l) => {
        setLogs((prevLogs) => [
          ...prevLogs,
          { auditDate: l.auditDate, comment: l.comment },
        ]);
      });
      //}
    });

    return () => {
      setLogs([]);
    };
  }, [portfolioId, store.clientListUpdateOn]);

  useEffect(() => {
    var l = Logs;
    l = l.sort((x, y) => new Date(x.auditDate) - new Date(y.auditDate));
    setAuditLogs(
      l.length > 0 ? l[l.length - 1] : { auditDate: "", comment: "" }
    );
    return () => {
      setAuditLogs({ auditDate: "", comment: "" });
    };
  }, [Logs]);
  return (
    <Tooltip color={"#435c77"} title={AuditLogs.comment}>
      <div style={{ textAlign: "center" }}>
        {AuditLogs.auditDate.trim().length > 0
          ? new Date(AuditLogs.auditDate).toLocaleDateString("de", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          : ""}
      </div>
    </Tooltip>
  );
};
const { Title } = Typography;
const { confirm } = Modal;
const { Search } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const ClientPortfoliosDrawer = (props) => {
  const {
    store,
    isClientPortfoliosVisible,
    setIsClientPortfoliosVisible,
    /* clientId, */
    /*  selectedClient, */
    getCantonName,
  } = props;
  const { t } = useTranslation("common"); //translation

  const clientId = store.activeClientId;
  const [selectedClient, setSelectedClient] = useState(CompanyModel.create({}));
  const [portfolios, setPortfolios] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const handleRowExpand = (record) => {
    // if a row is expanded, collapses it, otherwise expands it
    this.setState((prevState) =>
      prevState.expandedRows.includes(record.key)
        ? {
            expandedRows: prevState.expandedRows.filter(
              (key) => key !== record.key
            ),
          }
        : { expandedRows: [...prevState.expandedRows, record.key] }
    );
  };

  const [importOnGoing, setImportState] = useState(false);

  //const portfolios = store.portfolios;

  const [searchKeyword, setSearchKeyword] = useState("");

  const [filteredPortfolios, setFilteredPortfolios] = useState([]);

  useEffect(() => {
    return () => {
      setSearchKeyword("");
      setFilteredPortfolios(portfolios);
    };
  }, []);
  useEffect(() => {
    if (searchKeyword.trim() === "") setFilteredPortfolios(portfolios);
    else {
      setFilteredPortfolios(
        portfolios.filter(
          (pf) =>
            pf.name
              .toLowerCase()
              .includes(searchKeyword.trim().toLowerCase()) ||
            pf.properties.filter(
              (pp) =>
                pp.name
                  .toLowerCase()
                  .includes(searchKeyword.trim().toLowerCase()) ||
                pp.address
                  .toLowerCase()
                  .includes(searchKeyword.trim().toLowerCase()) ||
                pp.city
                  .toLowerCase()
                  .includes(searchKeyword.trim().toLowerCase()) ||
                pp.canton
                  .toLowerCase()
                  .includes(searchKeyword.trim().toLowerCase()) ||
                pp.region
                  .toLowerCase()
                  .includes(searchKeyword.trim().toLowerCase())
            ).length > 0
        )
      );
    }

    return () => {
      /*  setSearchKeyword('');
      setFilteredPortfolios(portfolios); */
    };
  }, [portfolios, searchKeyword, store.clientListUpdateOn]);

  useEffect(() => {
    setExpandedRows(
      filteredPortfolios.map((e) => ({
        name: e.name,
        expanded: false,
        id: e.id,
      }))
    );
  }, [filteredPortfolios]);

  useEffect(() => {
    if (store.activeClientId === 0) {
      setSelectedClient(CompanyModel.create({}));
      setPortfolios([]);
    } else {
      setSelectedClient(
        store.clients.find((c) => c.id === store.activeClientId)
      );
      store.getPortfoliosByCompany(store.activeClientId).then((s) => {
        setPortfolios(
          store.clients.find((c) => c.id === store.activeClientId).portfolios
        );
        //store.getPropertiesByCompany(store.activeClientId).then((s) => {});
      });
    }
    return () => {
      setSelectedClient(CompanyModel.create({}));
    };
  }, [props.store.activeClientId, props.store.clientListUpdateOn]);

  const getAverageRating = (portfolio) => {
    if (portfolio.properties.length === 0) return 0;
    var sum = portfolio.properties.reduce(
      (accumulator, property, currentIndex, array) =>
        accumulator + getPropertyRating(property),
      0
    );
    return sum / portfolio.properties.length;
  };

  const refreshList = async (refresh = false) => {
    if (selectedClient && selectedClient.id > 0) {
      store.getPortfoliosByCompany(selectedClient.id).then((s) => {
        setPortfolios(
          store.clients.find((c) => c.id === selectedClient.id).portfolios
        );
        //store.getPropertiesByCompany(selectedClient.id);
      });
    }
  };

  useEffect(() => {
    //  refreshList();
    // setPortfolioData([]);
    if (importOnGoing === false) {
      if (selectedClient && selectedClient.id > 0) {
        //setProperties([]);
        //store.setActivePortfolioId(0);
        store.getPortfoliosByCompany(selectedClient.id).then((s) => {
          // setSelectedPortfolioId(0);
          //setSelectedPortfolio(PortfolioModel.create({}));
          setPortfolios(store.portfolios);
          // store.getPropertiesByCompany(selectedClient.id).then((t) => {});
          // setPortfolioData(store.portfolios);
        });
      } else {
        //store.setActivePortfolioId(0);
        //setSelectedPortfolio(PortfolioModel.create({}));
        // setPortfolioData([]);
        setPortfolios([]);
        store.clearPortfolios().then((s) => {});
      }
    }

    return () => {
      // store.setActivePortfolioId(0);
      // setSelectedPortfolio(PortfolioModel.create({}));
      // setPortfolioData([]);
      setPortfolios([]);
      store.clearPortfolios().then((s) => {
        //setPortfolios(store.portfolios);
      });
    };
  }, [importOnGoing]);

  useEffect(() => {
    if (props.isClientPortfoliosVisible) {
      refreshList();
      setPortfolioDetailModalVisible(false);
    }
  }, [props.isClientPortfoliosVisible]);

  const successMessage = (msg) => {
    message.success(msg);
  };

  const errorMessage = (msg) => {
    message.error(msg);
  };

  const warningMessage = (msg) => {
    message.warning(msg);
  };

  const getInvestmentTypeName = (id) => {
    var i = store.investmentTypes.find((x) => x.id === id);

    return i ? i.name : "";
  };
  const portfolioColumns = [
    {
      title: t("client_portfolio.portfolio_name"),
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <a
          key={record.id}
          href="#"
          onClick={() => {
            editPortfolio(record.id);
          }}
        >{`${record.name}`}</a>
      ),
      width: "19%",
    },
    {
      title: t("client_portfolio.investment_type"),
      dataIndex: "investmentType",
      key: "investmentType",
      sorter: (a, b) =>
        store.investmentTypes
          .find((x) => x.id === a.investmentType)
          .name.localeCompare(
            store.investmentTypes.find((x) => x.id === b.investmentType).name
          ),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <span key={record.id}>
          {t(`client_portfolio.investment_types.${record.investmentType}`)}
        </span>
      ),
      width: "20%",
    },
    {
      title: t("client_portfolio.rating"),
      dataIndex: "rating",
      key: "rating",
      sorter: (a, b) => a.rating.toFixed(2).localeCompare(b.rating.toFixed(2)),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => {
        const _portfolioRatingsInvalid = record.properties
          .map((_pp) => getPropertyRating(_pp))
          .includes(NaN);

        if (_portfolioRatingsInvalid) {
          return (
            <div style={{ textAlign: "center", color: "red" }}>
              <span key={record.id}>{`NaN`}</span>
            </div>
          );
        } else {
          return (
            <div style={{ textAlign: "center" }}>
              <span key={record.id}>{`${parseFloat(
                record.rating.toFixed(2)
              )}`}</span>
            </div>
          );
        }

        // <PortfolioRatingValue key={record.id} id={record.id} />
      },
      width: "10%",
    },
    {
      title: t("client_portfolio.no_properties"),
      dataIndex: "propertyCount",
      key: "propertyCount",
      sorter: (a, b) => a.propertyCount - b.propertyCount,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <div
          style={{ textAlign: "center" }}
          key={record.id}
        >{`${record.propertyCount}`}</div>
      ),
      width: "20%",
    },
    {
      title: (
        <span>
          m<sup>2</sup>
        </span>
      ),
      dataIndex: "area",
      key: "area",
      sorter: (a, b) => (a.area ? a.area : 0) - (b.area ? b.area : 0),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <div
          style={{ textAlign: "right" }}
          key={record.id}
        >{`${record.area.toLocaleString("de-ch", {
          minimumFractionDigits: 2,
        })}`}</div>
      ),
      width: "10%",
    },

    {
      title: t("audit.title"),
      dataIndex: "area",
      key: "area",
      sorter: (a, b) => (a.area ? a.area : 0) - (b.area ? b.area : 0),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <PropertyAuditLog
          store={store}
          portfolioId={record.id}
        ></PropertyAuditLog>
      ),
      width: "10%",
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          {t("client_portfolio.actions")}
        </div>
      ),

      width: "7%",
      // title: t("client_portfolio.actions"),
      key: "id",
      /* dataIndex: "active",
      key: "active",
      sorter: (a, b) => a.position.localeCompare(b.position),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"], */
      render: (text, record) => (
        <div key={record.id} align={"middle"}>
          {/* <Switch
            size={"small"}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={record.active}
            title={
              
              `${record.firstName} ${record.lastName} (${record.email}) ` + (record.active ? "has" : "does not have" ) + ' Access.'
            }
            onClick={(e) => {
              
              record.active ? deactivateUser(record) : activateUser(record);
            }}
          /> */}

          <Popconfirm
            title={t("client_portfolio.are_you_sure_you_want_add")}
            onConfirm={() => onConfirmAddToIndex(record.id)}
            onCancel={onCancelAddToIndex}
            okText={t("client_portfolio.yes")}
            cancelText={t("client_portfolio.no")}
          >
            <Button
              size="small"
              type={"text"}
              title={
                record.indexStatus == 0
                  ? "Not Indexed"
                  : "Index Date: " + record.indexDate
              }
              key={"Add_" + record.id}
              icon={<ToTopOutlined />}
            >
              {/*  {t("client_portfolio.add_to_index")} */}
            </Button>
          </Popconfirm>
          {/*  <Button
            size="small"
            icon={<PlusOutlined />}
            onClick={() => {
              addNewProperty(record.id);
            }}
          >
            New Property
          </Button> */}
          <Popconfirm
            title={t("client_portfolio.are_you_sure_you_want_remove")}
            onConfirm={() => onConfirmDelete(record.id)}
            okText={t("client_portfolio.yes")}
            cancelText={t("client_portfolio.no")}
          >
            {/* <DeleteOutlined key={"Remove_" + record.id} /> */}
            <Button
              size="small"
              type={"text"}
              title={t("client_portfolio.remove")}
              key={"Remove_" + record.id}
              icon={<DeleteOutlined />}
              // style={{ marginLeft: "10px" }}
            >
              {/*  {t("client_portfolio.remove")} */}
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const [propertyImportModalVisible, setPropertyImportModalVisible] =
    useState(false);

  const propertyImportModalProps = {
    store,
    visible: propertyImportModalVisible,
    setVisible: setPropertyImportModalVisible,
  };

  const [PortfolioDetailModalVisible, setPortfolioDetailModalVisible] =
    useState(false);

  const [portfolioLoading, setPortfolioLoading] = useState(false);

  const portfolioModalProps = {
    store,
    visible: PortfolioDetailModalVisible,
    setVisible: setPortfolioDetailModalVisible,
    /* id: selectedPortfolioId, */
    /*  clientId, */
    /* selectedPortfolio,
    selectedClient, */
    /*  portfolios, */
    refreshList,
    setPortfolioLoading,
    setExpandedRows,
    /*  updateCount,
    setUpdateCount, */
  };
  const createNewPortfolio = () => {
    store.setActivePortfolioId(0);

    setPortfolioDetailModalVisible(true);
  };
  const editPortfolio = (id) => {
    store.setActivePortfolioId(id);
    setPortfolioDetailModalVisible(true);
  };

  function onConfirmAddToIndex(portfolioId) {
    var portfolio = store.clients
      .find((c) => c.id === store.activeClientId)
      .portfolios.find((pf) => pf.id === portfolioId);

    const _portfolioRatingsInvalid = portfolio.properties
      .map((_pp) => getPropertyRating(_pp))
      .includes(NaN);

    if (portfolio.properties.length === 0) {
      message.error({
        content: t("portfolio contain no properties"),
        style: {
          fontSize: "18px",
          color: "red",
        },
      });
    } else if (_portfolioRatingsInvalid) {
      message.error({
        content: t("portfolio properties contain nan values"),
        style: {
          fontSize: "18px",
          color: "red",
        },
      });
    } else {
      if (portfolio) {
        //console.log(e);
        setAddToIndexStatus(2);
        setIsAddingToIndex(true);

        portfolio.addToIndex().then((success) => {
          portfolio.properties.forEach(property =>{
            property.resetIndexChanged([property.id])
            .then((res) => {
              //console.log(res)
            })
            .catch((err) => {
              console.log(err);
            })
          })

          setAddToIndexStatus(success ? 3 : 0);

          store.getPortfoliosByCompany(selectedClient.id).then((s) => {
            // setSelectedPortfolioId(0);
            //setSelectedPortfolio(PortfolioModel.create({}));
            // store.getPropertiesByCompany(selectedClient.id).then((t) => {});
            // setPortfolioData(store.portfolios);
          });

          var nportfolio = store /* .clients
            .find((c) => c.id === store.activeClientId) */.portfolios
            .find((pf) => pf.id === portfolioId);
          if (nportfolio) {
            nportfolio.setIndexDate(new Date().toDateString());
          }
          store.setClientListUpdateOn(new Date().getTime().toString());
        });
      }
    }
  }

  function onCancelAddToIndex(e) {
    console.log(e);
  }

  function onConfirmDelete(portfolioId) {
    var portfolio = store.clients
      .find((c) => c.id === store.activeClientId)
      .portfolios.find((pf) => pf.id === portfolioId);
    if (portfolio) {
      var pname = portfolio.name;
      store.clients
        .find((c) => c.id === store.activeClientId)
        .removePortfolioById(portfolioId)
        .then((success) => {
          if (success) {
            store.setClientListUpdateOn(new Date().getTime().toString());
            successMessage(
              `${t("client_portfolio.portfolio")} ${pname} ${t(
                "client_portfolio.removed"
              )}`
            );
          } else
            errorMessage(
              `${t("client_portfolio.portfolio")} ${pname} ${t(
                "client_portfolio.was_not_removed"
              )}`
            );
        });
    }
  }

  const [PropertyDetailModalVisible, setPropertyDetailModalVisible] =
    useState(false);
  const [PropertyDetailDrawerVisible, setPropertyDetailDrawerVisible] =
    useState(false);

  const [selectedPropertyId, setSelectedPropertyId] = useState(0);

  const propertyModalProps = {
    visible: PropertyDetailModalVisible,
    setVisible: setPropertyDetailModalVisible,
    id: selectedPropertyId,
    /*   clientId, */
    /* portfolioId: selectedPortfolioId, */

    refreshList,
    /*  selectedPortfolio, */
    getCantonName,
  };

  const propertyDrawerProps = {
    visible: PropertyDetailDrawerVisible,
    setVisible: setPropertyDetailDrawerVisible,
    modalVisible: PropertyDetailModalVisible,
    setModalVisible: setPropertyDetailModalVisible,
    /*   id: selectedPropertyId, */
    /*  clientId, */
    /*   portfolioId: selectedPortfolioId, */
    refreshList,
    /* selectedPortfolio, */
    getCantonName,
  };

  const addNewProperty = (portfolioId) => {
    store.setActivePropertyId(0);
    store.setActivePortfolioId(portfolioId);
    setPropertyDetailModalVisible(true);
  };
  const editProperty = (portfolioId, propertyId) => {
    //store.getPropertiesByCompany(clientId).then((s) => {
    store.setActivePortfolioId(portfolioId);
    store.setActivePropertyId(propertyId);
    //});

    setPropertyDetailDrawerVisible(true);
  };

  const saveInitialRatings = (currentProperty) => {
    //transform form fields to array of {key,value,comment}
    var defaults = [];
    if (currentProperty.propertyAttributes.length > 0) {
      defaults = store.propertyDefaultRatings.filter((def) =>
        currentProperty.propertyAttributes.includes(def.attribute_id)
      );
    }
    const getDefaultInitialValue = (key) => {
      var ret = 0;
      //console.log("Matching " + key + "...");
      if (currentProperty.propertyAttributes.length > 0) {
        // this assumes that there could be multiple matches
        var defaultKeyRating = defaults.filter(
          (def) => def.key === key && def.value > -1
        );
        if (defaultKeyRating && defaultKeyRating.length) {
          if (defaultKeyRating.length > 0) {
            // take the last element's .value
            var thisKeyRating = defaultKeyRating[defaultKeyRating.length - 1];
            ret = thisKeyRating.value;
            /*  console.log(
              "Matched:" +
                key +
                " = " +
                thisKeyRating.attribute_id +
                ":" +
                thisKeyRating.key +
                "=>" +
                thisKeyRating.value
            ); */
          }
        }
      }

      // age based rating calculation
      // if (ret == 0) {
      var thiskey = "";
      if (key.split(".".length == 3)) {
        thiskey = key.split(".").splice(0, 2).join(".");
      } else thiskey = key;

      const ageRatingsByCriteria = {
        "vor/um 1960": {
          106.2: 0,
          107.1: 0,
          108.1: 0,
          108.2: 0,
          202.1: 0,
          301.1: 3,
          301.2: 0,
          303.2: 0,
          303.3: 0,
        },
        "1960-1990": {
          106.2: 0,
          107.1: 0,
          108.1: 0,
          108.2: 0,
          202.1: 0,
          301.1: 2,
          301.2: 0,
          303.2: 0,
          303.3: 0,
        },
        "Gebäude ohne Abschattung": {
          106.1: 3,
        },
        "Gebäude mit Abschattung": { 106.1: 2 },
        "1988-2006": {
          106.2: 2,
        },
        "2006-2009": {
          106.1: 3,
          106.2: 3,
          204.2: 3,
        },
        "nach 2009": {
          103.3: 3,
          106.1: 3,
          106.2: 3,
          204.2: 3,
        },
      };

      //which age bracket does this property belong?
      var age_bracket = "";
      var this_age = currentProperty.age;
      if (this_age < 1960) age_bracket = "vor/um 1960";
      if (this_age >= 1960 && this_age <= 1990) age_bracket = "1960-1990";

      if (currentProperty.propertyAttributes.indexOf(13) > -1) {
        // Gebäude mit Abschattung
        if (this_age > 1995 && this_age <= 2006)
          age_bracket = "Gebäude mit Abschattung";
      } else {
        //Gebäude ohne Abschattung
        if (this_age > 1995 && this_age <= 2006)
          age_bracket = age_bracket = "Gebäude ohne Abschattung";
      }

      if (this_age > 1995 && this_age <= 2006) {
        if (currentProperty.propertyAttributes.indexOf(13) > -1)
          age_bracket = "Gebäude mit Abschattung";
        else age_bracket = "Gebäude ohne Abschattung";
      }

      if (this_age > 1988 && this_age <= 2006) age_bracket = "1988-2006";
      if (this_age > 2006 && this_age <= 2009) age_bracket = "2006-2009";
      if (this_age > 2009) age_bracket = "nach 2009";

      if (age_bracket !== "") {
        var bracket_criteria = ageRatingsByCriteria[age_bracket];
        if (key in bracket_criteria) {
          ret = bracket_criteria[key];
          //console.log("Matched:" + key + " = " + age_bracket + "=>" + ret);
        }
      }
      // }

      return ret;
    };

    var ratings = [];
    var p_id = currentProperty.id;

    // criteria.forEach((group) => {
    //   group.children.forEach((rating) => {
    //     if (rating.children) {
    //       rating.children.forEach((subrating) => {
    //         ratings.push({
    //           propertyId: p_id,
    //           key: subrating.key,
    //           value: getDefaultInitialValue(subrating.key),
    //           comment: "",
    //         });
    //       });
    //     } else {
    //       ratings.push({
    //         propertyId: p_id,
    //         key: rating.key,
    //         value: getDefaultInitialValue(rating.key),
    //         comment: "",
    //       });
    //     }
    //   });
    // });

    currentProperty.updateRatings(ratings).then((success) => {
      console.log("Default ratings applied to new poperty.");
    });
  };

  // Readnig excel file
  const importFileProcess = (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      setImportState(true);

      const data = new Uint8Array(e.target.result);

      const workBook = XLSX.read(data, {
        type: "array",
        raw: true,
        cellDates: true,
      });

      const sheetName = workBook.SheetNames[0];

      const workSheet = workBook.Sheets[sheetName];

      const _csvData = XLSX.utils.sheet_to_json(workSheet, {
        type: "string",
        raw: true,
        defval: "",
      });

      /**
       * Raffi 02242021
       * @desc adjust to remap import file with image
       */
      const [_head, ...rest] = _csvData;

      var _mappedCSV = [];

      _mappedCSV = rest.map((_r) => {
        return {
          [_head["__EMPTY"]]: _r["__EMPTY"],
          [_head["__EMPTY_1"]]: _r["__EMPTY_1"],
          [_head["__EMPTY_2"]]: _r["__EMPTY_2"],
          [_head["__EMPTY_3"]]: _r["__EMPTY_3"],
          [_head["__EMPTY_4"]]: _r["__EMPTY_4"],
          [_head["__EMPTY_5"]]: _r["__EMPTY_5"],
          [_head["__EMPTY_6"]]: _r["__EMPTY_6"],
          [_head["__EMPTY_7"]]: _r["__EMPTY_7"],
          [_head["__EMPTY_8"]]: _r["__EMPTY_8"],
          [_head["__EMPTY_9"]]: _r["__EMPTY_9"],
          [_head["__EMPTY_10"]]: _r["__EMPTY_10"],
          [_head["__EMPTY_11"]]: _r["__EMPTY_11"],
          [_head["__EMPTY_12"]]: _r["__EMPTY_12"],
          [_head["__EMPTY_13"]]: _r["__EMPTY_13"],
          [_head["__EMPTY_14"]]: _r["__EMPTY_14"],
          [_head["__EMPTY_15"]]: _r["__EMPTY_15"],
          [_head["__EMPTY_16"]]: _r["__EMPTY_16"],
          [_head["__EMPTY_17"]]: _r["__EMPTY_17"],
          [_head["__EMPTY_18"]]: _r["__EMPTY_18"],
          [_head["__EMPTY_19"]]: _r["__EMPTY_19"],
          [_head["__EMPTY_20"]]: _r["__EMPTY_20"],
          [_head["__EMPTY_21"]]: _r["__EMPTY_21"],
          [_head["__EMPTY_22"]]: _r["__EMPTY_22"],
          [_head["__EMPTY_23"]]: _r["__EMPTY_23"],
          [_head["__EMPTY_24"]]: _r["__EMPTY_24"],
          [_head["__EMPTY_25"]]: _r["__EMPTY_25"],

          [_head["__EMPTY_26"]]: _r["__EMPTY_26"],
          [_head["__EMPTY_27"]]: _r["__EMPTY_27"],
          [_head["__EMPTY_28"]]: _r["__EMPTY_28"],
          [_head["__EMPTY_29"]]: _r["__EMPTY_29"],
          [_head["__EMPTY_30"]]: _r["__EMPTY_30"],
          [_head["__EMPTY_31"]]: _r["__EMPTY_31"],
          [_head["__EMPTY_32"]]: _r["__EMPTY_32"],
          [_head["__EMPTY_33"]]: _r["__EMPTY_33"],
          [_head["__EMPTY_34"]]: _r["__EMPTY_34"],
          [_head["__EMPTY_35"]]: _r["__EMPTY_35"],
          [_head["__EMPTY_36"]]: _r["__EMPTY_36"],
          [_head["__EMPTY_37"]]: _r["__EMPTY_37"],
          [_head["__EMPTY_38"]]: _r["__EMPTY_38"],
          [_head["__EMPTY_39"]]: _r["__EMPTY_39"],
          [_head["__EMPTY_40"]]: _r["__EMPTY_40"],
          [_head["__EMPTY_41"]]: _r["__EMPTY_41"],
          [_head["__EMPTY_42"]]: _r["__EMPTY_42"],
          [_head["__EMPTY_43"]]: _r["__EMPTY_43"],
          [_head["__EMPTY_44"]]: _r["__EMPTY_44"],
          [_head["__EMPTY_45"]]: _r["__EMPTY_45"],
          [_head["__EMPTY_46"]]: _r["__EMPTY_46"],
          [_head["__EMPTY_47"]]: _r["__EMPTY_47"],
          [_head["__EMPTY_48"]]: _r["__EMPTY_48"],
          [_head["__EMPTY_49"]]: _r["__EMPTY_49"],
          [_head["__EMPTY_50"]]: _r["__EMPTY_50"],

          [_head["__EMPTY_51"]]: _r["__EMPTY_51"],
          [_head["__EMPTY_52"]]: _r["__EMPTY_52"],
          [_head["__EMPTY_53"]]: _r["__EMPTY_53"],
          [_head["__EMPTY_54"]]: _r["__EMPTY_54"],
          [_head["__EMPTY_55"]]: _r["__EMPTY_55"],
          [_head["__EMPTY_56"]]: _r["__EMPTY_56"],
          [_head["__EMPTY_57"]]: _r["__EMPTY_57"],
          [_head["__EMPTY_58"]]: _r["__EMPTY_58"],
          [_head["__EMPTY_59"]]: _r["__EMPTY_59"],
          [_head["__EMPTY_60"]]: _r["__EMPTY_60"],
          [_head["__EMPTY_61"]]: _r["__EMPTY_61"],
          [_head["__EMPTY_62"]]: _r["__EMPTY_62"],
          [_head["__EMPTY_63"]]: _r["__EMPTY_63"],
          [_head["__EMPTY_64"]]: _r["__EMPTY_64"],
          [_head["__EMPTY_65"]]: _r["__EMPTY_65"],
          [_head["__EMPTY_66"]]: _r["__EMPTY_66"],
          [_head["__EMPTY_67"]]: _r["__EMPTY_67"],
          [_head["__EMPTY_68"]]: _r["__EMPTY_68"],
          [_head["__EMPTY_69"]]: _r["__EMPTY_69"],
          [_head["__EMPTY_70"]]: _r["__EMPTY_70"],
          [_head["__EMPTY_71"]]: _r["__EMPTY_71"],
          [_head["__EMPTY_72"]]: _r["__EMPTY_72"],
          [_head["__EMPTY_73"]]: _r["__EMPTY_73"],
          [_head["__EMPTY_74"]]: _r["__EMPTY_74"],
          [_head["__EMPTY_75"]]: _r["__EMPTY_75"],

          [_head["__EMPTY_76"]]: _r["__EMPTY_76"],
          [_head["__EMPTY_77"]]: _r["__EMPTY_77"],
          [_head["__EMPTY_78"]]: _r["__EMPTY_78"],
          [_head["__EMPTY_79"]]: _r["__EMPTY_79"],
          [_head["__EMPTY_80"]]: _r["__EMPTY_80"],
          [_head["__EMPTY_81"]]: _r["__EMPTY_81"],
          [_head["__EMPTY_82"]]: _r["__EMPTY_82"],
          [_head["__EMPTY_83"]]: _r["__EMPTY_83"],
          [_head["__EMPTY_84"]]: _r["__EMPTY_84"],
          [_head["__EMPTY_85"]]: _r["__EMPTY_85"],
          [_head["__EMPTY_86"]]: _r["__EMPTY_86"],
          [_head["__EMPTY_87"]]: _r["__EMPTY_87"],
          [_head["__EMPTY_88"]]: _r["__EMPTY_88"],
          [_head["__EMPTY_89"]]: _r["__EMPTY_89"],
          [_head["__EMPTY_90"]]: _r["__EMPTY_90"],
          [_head["__EMPTY_91"]]: _r["__EMPTY_91"],
          [_head["__EMPTY_92"]]: _r["__EMPTY_92"],
          [_head["__EMPTY_93"]]: _r["__EMPTY_93"],
          [_head["__EMPTY_94"]]: _r["__EMPTY_94"],
          [_head["__EMPTY_95"]]: _r["__EMPTY_95"],
          [_head["__EMPTY_96"]]: _r["__EMPTY_96"],
          [_head["__EMPTY_97"]]: _r["__EMPTY_97"],
          [_head["__EMPTY_98"]]: _r["__EMPTY_98"],
          [_head["__EMPTY_99"]]: _r["__EMPTY_99"],
          [_head["__EMPTY_100"]]: _r["__EMPTY_100"],

          [_head["__EMPTY_101"]]: _r["__EMPTY_101"],
          [_head["__EMPTY_102"]]: _r["__EMPTY_102"],
          [_head["__EMPTY_103"]]: _r["__EMPTY_103"],
          [_head["__EMPTY_104"]]: _r["__EMPTY_104"],
          [_head["__EMPTY_105"]]: _r["__EMPTY_105"],
          [_head["__EMPTY_106"]]: _r["__EMPTY_106"],
          [_head["__EMPTY_107"]]: _r["__EMPTY_107"],
          [_head["__EMPTY_108"]]: _r["__EMPTY_108"],
          [_head["__EMPTY_109"]]: _r["__EMPTY_109"],
          [_head["__EMPTY_110"]]: _r["__EMPTY_110"],
          [_head["__EMPTY_111"]]: _r["__EMPTY_111"],
          [_head["__EMPTY_112"]]: _r["__EMPTY_112"],
          [_head["__EMPTY_113"]]: _r["__EMPTY_113"],
          [_head["__EMPTY_114"]]: _r["__EMPTY_114"],
          [_head["__EMPTY_115"]]: _r["__EMPTY_115"],
          [_head["__EMPTY_116"]]: _r["__EMPTY_116"],
          [_head["__EMPTY_117"]]: _r["__EMPTY_117"],
          [_head["__EMPTY_118"]]: _r["__EMPTY_118"],
          [_head["__EMPTY_119"]]: _r["__EMPTY_119"],
          [_head["__EMPTY_120"]]: _r["__EMPTY_120"],
          [_head["__EMPTY_121"]]: _r["__EMPTY_121"],
          [_head["__EMPTY_122"]]: _r["__EMPTY_122"],
          [_head["__EMPTY_123"]]: _r["__EMPTY_123"],
          [_head["__EMPTY_124"]]: _r["__EMPTY_124"],
          [_head["__EMPTY_125"]]: _r["__EMPTY_125"],
          [_head["__EMPTY_126"]]: _r["__EMPTY_126"],
          [_head["__EMPTY_127"]]: _r["__EMPTY_127"],
          [_head["__EMPTY_128"]]: _r["__EMPTY_128"],
          [_head["__EMPTY_129"]]: _r["__EMPTY_129"],
          [_head["__EMPTY_130"]]: _r["__EMPTY_130"],
          [_head["__EMPTY_131"]]: _r["__EMPTY_131"],
          [_head["__EMPTY_132"]]: _r["__EMPTY_132"],
          [_head["__EMPTY_133"]]: _r["__EMPTY_133"],
        };
      });

      // INclude Rating columns
      if (_head["__EMPTY_26"]) {
        _mappedCSV.forEach((m, i) => {
          for (var ix = 26; ix <= 133; ix++) {
            m[_head["__EMPTY_" + ix]] = rest[i]["__EMPTY_" + ix];
          }
        });
      }

      if (_mappedCSV.length > 0) {
        try {
          const res = await axios.post("/api/imports", {
            companyId: store.activeClientId,
            importData: _mappedCSV,
          });
          //check partial import. res.data.importData.length vs res.data._newProperties.length
          if (res.data._newProperties.length == 0) {
            message.error(t("client_portfolio.import_failed"));
          } else {
            if (res.data._newProperties.length < res.data.importData.length) {
              message.warning(
                res.data._newProperties.length +
                  t("client_portfolio.of") +
                  res.data.importData.length +
                  " " +
                  t("client.properties") +
                  " " +
                  t("client_portfolio.import_successful")
              );
            } else {
              message.success(t("client_portfolio.import_successful"));
            }
          }
        } catch (error) {
          message.error(t("client_portfolio.import_failed"));
        }
      }

      setImportState(false);

      //store.getPortfoliosByCompany(selectedClient.id).then((s) => {
      /*  store.getPropertiesByCompany(selectedClient.id).then(x=>{
          store.properties.forEach(pp => {
            store.clients.find((c) => c.id === store.activeClientId).portfolios.forEach(pf=>{
              if(pf.properties.findIndex(px=>px.id===pp.id)===-1){
                pf.insertProperty(pp);
              }
            })
          });
        }) */
      store.clients
        .find((c) => c.id === store.activeClientId)
        .portfolios.forEach((pf) => {
          pf.getProperties().then((s) => {
            store.setClientListUpdateOn(new Date().getTime().toString());
            pf.properties.forEach((pfp) => {
              // saveInitialRatings(pfp);
            });
          });
        });

      //});
    };

    reader.readAsArrayBuffer(file);

    return false;
  };

  const [IsAddingToIndex, setIsAddingToIndex] = useState(false);
  const [AddToIndexStatus, setAddToIndexStatus] = useState(0);
  const [AddToIndexStatusText, setAddToIndexStatusText] = useState("");
  useEffect(() => {
    setAddToIndexStatusText(
      ["Failed to Add", "Inactive", "Adding Portfolio to Index...", "Done"][
        AddToIndexStatus - 1
      ]
    );

    return () => {
      setAddToIndexStatusText(
        ["Failed to Add", "Inactive", "Adding to Index...", "Done"][
          AddToIndexStatus - 1
        ]
      );
    };
  }, [AddToIndexStatus]);

  useEffect(() => {
    if (PropertyDetailDrawerVisible === false) {
      refreshList();
    }
  }, [PropertyDetailDrawerVisible]);

  useEffect(() => {
    //if (PropertyDetailDrawerVisible === false) {
    refreshList();
    //}
  }, [store.clientListUpdateOn]);

  const onAddToIndexModalClosed = () => {
    if (AddToIndexStatus == 3) {
      // force list update
      store.setClientListUpdateOn(new Date().getTime().toString());
    }
  };

  const [actualRatingsModalVisible, setActualRatingsModalVisible] =
    useState(false);
  const [propertyId, setPropertyId] = useState(0);
  const actualRatingsModalProps = {
    store,
    id: propertyId,
    /* currentProperty, */
    visible: actualRatingsModalVisible,
    setVisible: setActualRatingsModalVisible,
    refreshList: () => {},
    setPropertyId: setPropertyId,
  };
  const showActualRatingsForm = (portfolioId, propertyId) => {
    store.setActivePortfolioId(portfolioId);
    store.setActivePropertyId(propertyId).then((y) => {
      setPropertyId(propertyId);
    });
  };
  useEffect(() => {
    if (propertyId > 0) setActualRatingsModalVisible(true);
    return () => {
      setActualRatingsModalVisible(false);
    };
  }, [propertyId]);

  return (
    <>
      {selectedClient && (
        <Drawer
          destroyOnClose
          title={false}
          placement="right"
          closable={true}
          visible={isClientPortfoliosVisible}
          getContainer={true}
          style={{ position: "absolute" }}
          width={"calc(100% - 350px)"}
          onClose={() => {
            //store.setActivePortfolioId(0);
            // setPortfolios([]);
            setSearchKeyword("");
            //setFilteredPortfolios([]);
            setIsClientPortfoliosVisible(false);
          }}
        >
          <Row align="middle">
            <Col flex={"auto"}>
              <Title
                style={{
                  display: "block !important",
                  textAlign: "left",
                  width: "100%",
                }}
                level={3}
              >
                {t("client_portfolio.portfolios_scolon")} {selectedClient.name}
              </Title>
            </Col>
            <Col flex={"auto"} style={{ textAlign: "right" }}>
              {/* <Title level={4}>{selectedClient ? selectedClient.name : ""}</Title> */}
            </Col>
          </Row>

          <Row style={{ minHeight: "calc(100% - 50px)" }}>
            <Col flex={"auto"}>
              <div style={{ marginBottom: 16, textAlign: "left" }}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      createNewPortfolio();
                    }}
                  >
                    <PlusOutlined /> {t("client_portfolio.new_portfolio")}
                  </Button>
                  {/* Import Button */}
                  <Upload
                    accept=".xlsx, .csv, .xlsm"
                    showUploadList={false}
                    beforeUpload={(file) => importFileProcess(file)}
                    name="files"
                  >
                    <Button loading={importOnGoing} icon={<ImportOutlined />}>
                      {t("client_portfolio.import_from_excel")}
                    </Button>
                  </Upload>

                  <Search
                    placeholder={t(
                      "client_portfolio.search_portfolio_name_property_id"
                    )}
                    onSearch={(value) => setSearchKeyword(value)}
                    style={{ width: 250 }}
                  />
                </Space>
              </div>
              <Table
                className={"portfolio_table"}
                dataSource={
                  /* portfolioData*/ filteredPortfolios.map((pf) => {
                    return {
                      key: pf.id,
                      ...pf,
                      rating: getPortfolioRating(pf),
                      area: getPortfolioTotalArea(pf),
                      propertyCount: pf.properties.length,
                    };
                  })
                }
                getInvestmentTypeName={getInvestmentTypeName}
                editPortfolio={editPortfolio}
                addNewProperty={addNewProperty}
                columns={portfolioColumns}
                size={"middle"}
                bordered={false}
                pagination={false}
                loading={portfolioLoading}
                scroll={{ y: "calc(100vh - 240px)" }}
                /*rowSelection={IsEditMode}
              loading={IsLoading}
              hasData={HasData}
               */
                expandedRowRender={(record) => (
                  <PropertyTableRow
                    searchKeyword={searchKeyword}
                    row={record}
                    portfolios={filteredPortfolios}
                    addNewProperty={addNewProperty}
                    editProperty={editProperty}
                    getCantonName={getCantonName}
                    showActualRatingsForm={showActualRatingsForm}
                  ></PropertyTableRow>
                )}
                expandable={{
                  defaultExpandAllRows: false,
                  expandedRowKeys: expandedRows
                    .filter((e) => e.expanded)
                    .map((e) => e.id),
                  onExpand: (expanded, record) => {
                    // console.log(expanded)
                    // console.log(record)
                    setExpandedRows((prev) => {
                      // console.log(
                      //   prev.map((e) =>
                      //     e.id == record.id ? { ...e, expanded } : e
                      //   )
                      // )
                      return prev.map((e) =>
                        e.id == record.id ? { ...e, expanded } : e
                      );
                    });
                  },
                }}
                footer={() =>
                  `${filteredPortfolios.length} ${t(
                    "client_portfolio.portfolio_found"
                  )}`
                }
                rowKey={"id"}
              />
            </Col>
          </Row>

          <PropertyImportModal
            {...propertyImportModalProps}
          ></PropertyImportModal>

          {PortfolioDetailModalVisible && (
            <PortfolioDetailModal
              {...portfolioModalProps}
            ></PortfolioDetailModal>
          )}

          {PropertyDetailDrawerVisible && store.activePropertyId > 0 && (
            <ClientPropertyDetailDrawer
              {...propertyDrawerProps}
            ></ClientPropertyDetailDrawer>
          )}
          {PropertyDetailModalVisible && (
            <ClientPropertyDetailModal
              {...propertyModalProps}
            ></ClientPropertyDetailModal>
          )}
        </Drawer>
      )}
      <Modal
        title={false}
        footer={false}
        centered
        visible={IsAddingToIndex}
        closable={false}
        afterClose={onAddToIndexModalClosed}
      >
        {AddToIndexStatus === 0 && (
          <Result
            status="error"
            title={t("client_portfolio.failed_add_portfolio")}
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  setIsAddingToIndex(false);
                }}
              >
                {t("client_portfolio.close")}
              </Button>,
            ]}
          />
        )}
        {AddToIndexStatus === 2 && (
          <Result
            icon={<Spin indicator={antIcon} />}
            title={t("client_portfolio.adding_portfolio_index")}
          />
        )}
        {AddToIndexStatus === 3 && (
          <Result
            status="success"
            title={t("client_portfolio.portfolio_added_index_successfully")}
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  setIsAddingToIndex(false);
                }}
              >
                {t("client_portfolio.close")}
              </Button>,
            ]}
          />
        )}
      </Modal>

      {actualRatingsModalVisible && propertyId > 0 && (
        <ActualRatingsModal {...actualRatingsModalProps}></ActualRatingsModal>
      )}
    </>
  );
};

export default inject("store")(observer(ClientPortfoliosDrawer));
