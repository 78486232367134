import React, { useState, useLayoutEffect } from "react";
// import axios from 'axios'
import { Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react";

// import FileSaver from 'file-saver'

import AdminMenu from "../ProtectedRoute/AdminMenu";
import ClientMenu from "../ProtectedRoute/ClientMenu";

import UserInfo from "../ProtectedRoute/UserInfo";
import DownloadExcelModal from "../ProtectedRoute/DownloadExcelModal";
/* import InstallPWA from "../ProtectedRoute/InstallPWA" */
import { Layout, Row, Col, Button, Affix } from "antd";
//translation
import { useTranslation } from "react-i18next";

// import { isMobile } from 'react-device-detect'

import { DownloadOutlined } from "@ant-design/icons";

import "../ipadCss/m_custom.css";

const { Content, Footer, Sider } = Layout;
// const myScrollbar = {
//   width: '100%',
// }

/* "Kontakt":"Contact",
    "Q&A SSREI":"Q&A SSREI",
    "Zürich":"Zürich",
    "Q&A Verifizierung (statt Fragen & Feedback zum Tool)":"Q&A Vérification",
     */
const ContactBox = (props) => {
  const { t } = props;
  return (
    <div
      style={{
        margin: "20px",
        fontWeight: "unset",
        fontSize: "smaller",
        maxHeight: "calc(35vh)",
        position: "fixed",
        bottom: 150,
      }}
    >
      <div style={{ marginBottom: "5px" }}>
        <strong style={{ fontSize: "larger" }}>{t("kontakt.Kontakt")}</strong>
      </div>
      <div
        style={{
          maxHeight: "calc(35vh)",
          maxWidth: "100%",
          display: "block",
          overflowY: "auto",
        }}
      >
        <p>
          {/* <strong>{t("kontakt.SSREI AG")}</strong> */}
          {t("kontakt.SSREI AG")}
          <br />
          Mainaustrasse 34
          <br />
          8008 {t("kontakt.Zürich")}
        </p>

        <p>
          <strong>{t("kontakt.Q&A SSREI")}</strong>
          <br />
          Elvira Bieri
          <br />
          <a href={"mailto://eb@ssrei.ch"}>eb@ssrei.ch</a>
          <br />
          +41 43 499 24 97
        </p>

        <p>
          <strong>{t("kontakt.Q&A TOOL")}</strong>
          <br />
          Remo Burri
          <br />
          <a href={"mailto://rb@mvinvest.ch"}>rb@mvinvest.ch</a>
          <br />
          +41 43 499 24 94
        </p>
      </div>
    </div>
  );
};

function ProtectedRoute(props) {
  const { t } = useTranslation("common"); //translation

  const { store, adminOnly } = props;
  const Component = props.component;
  const user = props.store.login;
  const company = props.store.company;
  const [IsReady, setIsReady] = useState(false);
  const [IsFormVisible, setIsFormVisible] = useState(false);
  const [IsDownloadModalVisible, setIsDownloadModalVisible] = useState(false);

  const [downloadingCSV /*setDownloadingState*/] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setIsReady(true);
      setTimeout(() => {
        setIsFormVisible(true);
        // setCollapsed(false);
      }, 400);
    }, 500);
  }, []);

  const [Collapsed, setCollapsed] = useState(true);

  const onCollapse = () => {
    console.log(Collapsed);
    setCollapsed(!Collapsed);
  };

  const DownloadModalProps = {
    visible: IsDownloadModalVisible,
    setVisible: setIsDownloadModalVisible,
  };

  const showDownloadModal = () => {
    setIsDownloadModalVisible(true);
  };
  const downloadCSV = async () => {
    showDownloadModal();
    /* try {
      setDownloadingState(true);

      const res = await axios({
        method: "post",
        url: "/api/exports",
        responseType: "arraybuffer",
        data: {
          company,
          user,
        },
      });

      const tempName = res.headers["content-disposition"];
      const fileName = tempName.split('"')[1];

      const blob = new Blob([res.data], { type: "application/octet-stream" });

      FileSaver.saveAs(blob, fileName);

      message.success(t("protected_route.download_successful"));
      setDownloadingState(false);
    } catch (error) {
      message.error(t("protected_route.download_failed"));
    } */
  };

  // const downloadTestCsv = () => {
  //   store.getClientsCSV();
  // };
  return (user.isLoggedIn && adminOnly == false) ||
    (adminOnly == true && user.isLoggedIn) ? (
    <>
      <div className={!IsReady ? "bg" : "bg cssblur"}> </div>
      <Layout
        style={{ background: "transparent", height: "100vh" }}
        className={!IsFormVisible ? "main-layout" : "main-layout visible"}
      >
        <Sider
          /*collapsible*/
          // collapsed={isMobile?Collapsed:false}
          onCollapse={onCollapse}
          collapsedWidth={0}
          className="sidenav__admin"
          style={{
            backgroundColor: "white",
            position: "fixed",
          }}
        >
          <Affix
            offsetTop={
              0
            } /*className={isMobile && Collapsed?'affix__wrapper':''}*/
          >
            <Affix offsetTop={0}>
              <div className="logo" />
            </Affix>

            {props.store.company.type === 0 && (
              <AdminMenu SelectedMenuKey={Component.displayName} />
            )}
            {props.store.company.type === 1 && (
              <ClientMenu SelectedMenuKey={Component.displayName} />
            )}
            <div className="sidebar-bottom-fix">
              <Row
              // style={{
              //   position: 'fixed',
              //   bottom: 150,
              // }}
              >
                <ContactBox t={t}></ContactBox>
              </Row>

              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  width: "200px",
                }}
              >
                <Row align="middle">
                  <div
                    style={{
                      width: "100%",
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    <strong> {t("protected_route.download")}&nbsp;</strong>
                    <br />
                    <Button
                      type="text"
                      loading={downloadingCSV}
                      onClick={downloadCSV}
                      icon={<DownloadOutlined />}
                    >
                      {" "}
                    </Button>
                  </div>
                  <DownloadExcelModal
                    {...DownloadModalProps}
                  ></DownloadExcelModal>
                </Row>

                <UserInfo
                  user={user}
                  company={company}
                  store={store}
                ></UserInfo>
              </div>
            </div>
          </Affix>
        </Sider>
        <Layout
          style={{ marginLeft: "200px", position: "relative" }}
          className={!IsReady ? "site-layout" : "site-layout"}
        >
          {/*  <Header className="site-layout-background" style={{ padding: 0 }} /> */}
          <Content
            style={{ margin: "0px 16px 16px 16px", background: "transparent" }}
          >
            {/*  <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Bill</Breadcrumb.Item>
                </Breadcrumb> */}
            <Row className="site-layout-background" style={{ height: "100%" }}>
              <Col flex={"auto"}>
                {(() => {
                  if (
                    ((props.store.company.type === 1 && props.adminOnly) ||
                      (props.store.company.type === 0 &&
                        props.adminOnly === false)) &&
                    props.path !== "/" &&
                    props.path !== "/Documents"
                  ) {
                    return (
                      <div style={{ marginTop: "20px" }}>
                        <h1 style={{ color: "white" }}>
                          {t("page_not_found")}
                        </h1>
                      </div>
                    );
                  } else {
                    return <Component {...props} />;
                  }
                })()}
                {/* <Component {...props} /> */}
                {/* <InstallPWA/> */}
              </Col>
            </Row>
          </Content>

          <Footer
            style={{
              textAlign: "center",
              color: "#ffffff",
              background: "#2e4f72ee",
              padding: "5px 50px",
              position: "fixed",
              zIndex: 1000,
              width: "100%",
              bottom: 0,
            }}
          >
            <small>
              {" "}
              {/* {store.appName} {store.appVersion} |{" "} */}
              {t("protected_route.by_mvinvest")}
            </small>
          </Footer>
        </Layout>
      </Layout>

      {/* {
        isMobile?
          <a href="#" class="float-menu" onClick={onCollapse} style={{left:Collapsed?'0px':'200px'}}>
            <span class="collaps-btn">
              { isMobile && Collapsed? ">" : "<"}
            </span>
          </a>
          :''
      } */}
    </>
  ) : (
    <Redirect to={{ pathname: "/login" }} />
  );
}

export default inject("store")(observer(ProtectedRoute));
