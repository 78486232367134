import { types, flow, getParent } from "mobx-state-tree";
import axios from "axios";
import { getPortfolioRating } from "../data/RatingCriteria";
import PropertyModel from "./PropertyModel";
import PropertyRatingModel from "./PropertyRatingModel";
import PropertyAuditLogModel from "./PropertyAuditLogModel";

const PortfolioModel = types
  .model("PortfolioModel", {
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    companyId: types.optional(types.number, 0),
    investmentType: types.optional(types.number, 0), //

    properties: types.optional(types.array(PropertyModel), []),

    /*   propertyCount: types.optional(types.number, 0),   */
    ratingCached: types.optional(types.number, 0),
    lastAuditDate: types.optional(types.string, "?"),
    indexStatus: types.optional(types.number, 0),
    indexDate: types.optional(types.string, ""),
  })
  .actions((self) => ({
    update: flow(function* (
      name,
      investmentType,
      companyId,
      usersWithAccess = []
    ) {
      const { id } = self;
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .put(`/api/portfolios/${id}`, {
          name,
          investmentType,
          companyId,
          usersWithAccess,
        })
        .then((response) => response)
        .catch((error) => error.response);

      if (responseStatus !== 200) return false;

      // update object's property?
      self.setName(name);
      self.setInvestmentType(investmentType);
      self.setCompanyId(companyId);

      return true;
    }),
    sortFn: function (a, b) {
      // console.log(a, "a");
      var ax = [],
        bx = [];
      a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
        ax.push([$1 || Infinity, $2 || ""]);
      });
      b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
        bx.push([$1 || Infinity, $2 || ""]);
      });
      while (ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
        if (nn) return nn;
      }
      return ax.length - bx.length;
    },
    remove: flow(function* () {
      var parent = getParent(self, 2);
      return parent.removePortfolio(self);
    }),

    getProperties: flow(function* () {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .get(`/api/properties/byportfolio/${self.id}`)

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.properties) {
        self.properties = [];
        result.properties.forEach((c) => {
          const {
            id,
            name,
            portfolioId,
            address,
            age,
            lastTotalRenovation,
            plz,
            city,
            canton,
            region,
            areaOffice,
            areaResidential,
            areaCommercial,
            areaBuilding,
            areaHealth,
            ratings,
            propertyAttributeDetails,
            auditLogs,
          } = c;
          var property = PropertyModel.create({
            id,
            name,
            portfolioId,
            address,
            age,
            lastTotalRenovation,
            plz,
            city,
            canton,
            region,
            areaOffice: parseFloat(areaOffice),
            areaResidential: parseFloat(areaResidential),
            areaCommercial: parseFloat(areaCommercial),
            areaBuilding: parseFloat(areaBuilding),
            areaHealth: parseFloat(areaHealth),
            ratings: ratings.map((r) => {
              return PropertyRatingModel.create({
                ...r,
                value: parseFloat(r.value),
              });
            }),
            propertyAttributes: propertyAttributeDetails
              ? propertyAttributeDetails.map((att) => att.attribute_id)
              : [],
            auditLogs: auditLogs
              ? auditLogs.map((r) =>
                  PropertyAuditLogModel.create({
                    id: r.id,
                    auditDate: r.auditDate,
                    comment: r.comment,
                  })
                )
              : [],
          });
          self.properties.push(property);
        });

        return true;
      } else return false;
    }),

    addProperty: flow(function* (values) {
      const {
        name,
        address,
        plz,
        city,
        canton,
        region,
        age,
        lastTotalRenovation,
        areaOffice,
        areaResidential,
        areaCommercial,
        areaBuilding,
        areaHealth,
        attributes,
      } = values;

      if (areaCommercial > 0) attributes.push(21);

      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post("/api/properties/", {
          name,
          portfolioId: self.id,
          address,
          age,
          lastTotalRenovation,
          plz,
          city,
          canton,
          region,
          areaOffice,
          areaResidential,
          areaCommercial,
          areaBuilding,
          areaHealth,
          attributes: attributes ? attributes : [],
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.property) {
        const {
          id,
          name,
          portfolioId,
          address,
          plz,
          city,
          canton,
          region,
          age,
          lastTotalRenovation,
          areaOffice,
          areaResidential,
          areaCommercial,
          areaBuilding,
          areaHealth,
          propertyAttributeDetails,
        } = result.property;
        var property = PropertyModel.create({
          id,
          name,
          portfolioId,
          address,
          plz,
          city,
          canton,
          region,
          age,
          lastTotalRenovation,
          areaOffice: parseFloat(areaOffice),
          areaResidential: parseFloat(areaResidential),
          areaCommercial: parseFloat(areaCommercial),
          areaBuilding: parseFloat(areaBuilding),
          areaHealth: parseFloat(areaHealth),
          propertyAttributes: propertyAttributeDetails
            ? propertyAttributeDetails.map((att) => att.attribute_id)
            : [],
        });
        self.properties.push(property);
        self.properties = self.properties.sort((a, b) =>
          self.sortFn(a.name, b.name)
        );

        return property;
      } else return false;
    }),

    removeProperty: flow(function* (property) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post(`/api/properties/delete`, { propertyId: property.id })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.success) {
        self.properties.splice(self.properties.indexOf(property), 1);

        return true;
      } else return false;
    }),

    insertProperty: function (property) {
      if (self.properties.find((pp) => pp.id === property.id)) return false;
      self.properties.push(property);
      return true;
    },
    addToIndex: flow(function* () {
      const { id } = self;
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post(`/api/portfolios/addtoindex`, {
          portfolioId: id,
        })
        .then((response) => response)
        .catch((error) => error.response);

      if (responseStatus !== 200) return false;

      // update object's property?
      self.setIndexStatus(1);
      self.setIndexDate(result.data.indexDate);

      return true;
    }),

    setIndexStatus(value) {
      self.indexStatus = value;
    },

    setIndexDate(value) {
      self.indexDate = value;
    },

    setName(value) {
      self.name = value;
    },
    setCompanyId(value) {
      self.companyId = value;
    },
    setInvestmentType(value) {
      self.investmentType = value;
    },

    /*  setPropertyCount(value) {
      self.propertyCount = value;
    },
 */
    setValues(values) {
      for (var p in values) {
        self[p] = values[p];
      }
    },
  }))
  .views((self) => {
    return {
      get key() {
        return self.id;
      },
      get propertyCount() {
        /*  const rootStore = getRoot(self);
        if (rootStore.properties) {
          return rootStore.properties.filter((p) => p.portfolioId === self.id)
            .length;
        } else return 0; */
        return self.properties.length;
      },

      get rating() {
        return getPortfolioRating(self);
        /*  if (self.properties.length === 0) return 0;
        try {
          var t = 0;
          var c = 0;
          self.properties.forEach((p) => {
            t += p.rating;
            c++;
          });

          return t / c;
        } catch (error) {
          console.log(`ERROR: Computing Portfolio#${self.id} Rating`);
          return 0;
        } */
      },
    };
  });

export default PortfolioModel;
