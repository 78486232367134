import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react"; //These functions make our components observable and be able to use the store

//Ant D
import { ConfigProvider } from "antd";
//Moment
import moment from "moment";
//translation
import de_DE from "antd/es/locale/de_DE";
import enUS from "antd/es/locale/en_US";
import fr_FR from "antd/es/locale/fr_FR";
import it_IT from "antd/es/locale/it_IT";

function GlobalProvider(props) {
  const lang = props.store.language;

  const [locale, setLocale] = useState(localStorage.getItem("locale") || de_DE);

  useEffect(() => {
    if (lang === "en") {
      setLocale(enUS);
      //  moment.locale("en");
    }
    if (lang === "de") {
      setLocale(de_DE);
      //  moment.locale("de");
    }
    if (lang === "fr") {
      setLocale(fr_FR);
      //    moment.locale("fr-ch");
    }
    if (lang === "it") {
      setLocale(it_IT);
      //  moment.locale("it-ch");
    }
    return () => {};
  }, [lang]);

  return <ConfigProvider locale={locale}>{props.children}</ConfigProvider>;
}

export default inject("store")(observer(GlobalProvider));
