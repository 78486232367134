import { types, flow, getRoot } from "mobx-state-tree";
import axios from "axios";

const LoginModel = types
  .model("LoginModel", {
    id: types.optional(types.number, 0),
    token: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    firstName: types.optional(types.string, ""),
    lastName: types.optional(types.string, ""),

    phoneNumber: types.optional(types.string, ""),
    mobileNumber: types.optional(types.string, ""),
    companyId: types.optional(types.number, 0),
    position: types.optional(types.string, ""),

    isLoggedIn: types.optional(types.boolean, false),
    active: types.optional(types.boolean, true),

    //company: CompanyModel,
  })
  .actions((self) => ({
    setId(id) {
      self.id = id;
    },

    setStatus(statusId) {
      self.isLoggedIn = statusId;
    },

    setToken(token) {
      self.token = token;
    },

    setEmail(email) {
      self.email = email;
    },

    setFirstName(firstName) {
      self.firstName = firstName;
    },

    setLastName(lastName) {
      self.lastName = lastName;
    },
    setCompanyId(companyId) {
      self.companyId = companyId;
    },

    setPosition(position) {
      self.position = position;
    },

    setActive(trueorfalse) {
      self.active = trueorfalse;
    },
    /*  setCompany(company) {
      self.company = company;
    }, */

    setPhoneNumber(value) {
      self.phoneNumber = value;
    },
    setMobileNumber(value) {
      self.mobileNumber = value;
    },

    login: flow(function* (email, password, rememberMe, fingerprint, language) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post("/api/auth", {
          email,
          password,
          rememberMe,
          fingerprint,
          language,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (responseStatus === 200 && result.suspended)
        return { success: false, suspended: true };

      if (result.new) {
        return { success: false, newDevice: true };
      }
      if (result.token) {
        axios.defaults.headers.common["X-Auth-Token"] = result.token;
        localStorage.removeItem("token");

        localStorage.setItem("token", result.token);

        self.setToken(result.token);
        self.setEmail(result.user.email);
        self.setId(parseInt(result.user.id));
        self.setFirstName(result.user.firstName);
        self.setLastName(result.user.lastName);
        self.setCompanyId(result.user.companyId);
        self.setPosition(result.user.position);
        self.setPhoneNumber(result.user.phoneNumber);
        self.setMobileNumber(result.user.mobileNumber);

        self.setStatus(true);

        const store = getRoot(self);
        store.setCompany(
          result.company.id,
          result.company.name,
          result.company.address,
          result.company.plz,
          result.company.city,
          result.company.canton,
          result.company.type
        );

        // store.setApiKey(self.apiKey)

        //axios.defaults.headers.common = {'Authorization': `bearer ${result.token}`}
        localStorage.setItem("token", result.token);
        // localStorage.setItem('apiKey', result.apiKey)
        localStorage.setItem("user", JSON.stringify(result.user));
        localStorage.setItem("company", JSON.stringify(result.company));
        return { success: true };
      } else return { success: false };
    }),

    logout: flow(function* () {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      self.setToken("");
      self.setEmail("");
      self.setId(0);
      self.setFirstName("");
      self.setLastName("");

      self.setStatus(false);
      self.setCompanyId(0);
      self.setPosition("");

      self.setPhoneNumber("");
      self.setMobileNumber("");

      const store = getRoot(self);
      store.setCompany(0, "", "", "", "", "", 0);
      // store.setApiKey('')
      localStorage.removeItem("apiKey");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("company");

      return true;
    }),

    update: flow(function* (
      password,
      email,
      firstName,
      lastName,
      phoneNumber,
      mobileNumber,
      position,
      companyId,
      portfoliosWithAccess = []
    ) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .put(`/api/users/${self.id}`, {
          password,
          email,
          firstName,
          lastName,
          phoneNumber,
          mobileNumber,
          position,
          companyId,
          portfoliosWithAccess,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) {
        console.log(statusText);
        return false;
      }
      var localUser = JSON.parse(localStorage.getItem("user"));
      if (self.id === localUser.id) {
        if (result.token) {
          self.setToken(result.token);
          axios.defaults.headers.common["X-Auth-Token"] = result.token;
          localStorage.removeItem("token");
          localStorage.setItem("token", result.token);
        }
      }
      if (result.passwordchanged) {
        self.logout();
        return true;
      }
      if (result.user) {
        if (self.id === localUser.id) {
          //self.setEmail(result.user.email);
          //self.setId(parseInt(result.user.id));
          self.setFirstName(result.user.firstName);
          self.setLastName(result.user.lastName);
          // self.setCompanyId(result.user.companyId);
          self.setPosition(result.user.position);
          self.setPhoneNumber(result.user.phoneNumber);
          self.setMobileNumber(result.user.mobileNumber);
          // self.setIsAdmin(result.user.isAdmin === "1");
          //self.setStatus(true);
          localStorage.setItem("user", JSON.stringify(result.user));
        }
        return true;
      } else return false;
    }),
    updateProfile: flow(function* (
      password,
      email,
      firstName,
      lastName,
      phoneNumber,
      mobileNumber,
      position,
      companyId
    ) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .put(`/api/users/${self.id}/profile`, {
          password,
          email,
          firstName,
          lastName,
          phoneNumber,
          mobileNumber,
          position,
          companyId,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) {
        console.log(statusText);
        return false;
      }
      var localUser = JSON.parse(localStorage.getItem("user"));
      if (self.id === localUser.id) {
        if (result.token) {
          self.setToken(result.token);
          axios.defaults.headers.common["X-Auth-Token"] = result.token;
          localStorage.removeItem("token");
          localStorage.setItem("token", result.token);
        }
      }
      if (result.passwordchanged) {
        self.logout();
        return true;
      }
      if (result.user) {
        if (self.id === localUser.id) {
          //self.setEmail(result.user.email);
          //self.setId(parseInt(result.user.id));
          self.setFirstName(result.user.firstName);
          self.setLastName(result.user.lastName);
          // self.setCompanyId(result.user.companyId);
          self.setPosition(result.user.position);
          self.setPhoneNumber(result.user.phoneNumber);
          self.setMobileNumber(result.user.mobileNumber);
          // self.setIsAdmin(result.user.isAdmin === "1");
          //self.setStatus(true);
          localStorage.setItem("user", JSON.stringify(result.user));
        }
        return true;
      } else return false;
    }),

    resetPassword: flow(function* (email, language) {
      //  return true;

      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post("/api/auth/resetpassword", {
          email,
          language,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.success) {
        return true;
      } else return false;
    }),

    loginOTP: flow(function* (email, password, rememberMe, fingerprint, pin) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post("/api/auth/logindevice", {
          email,
          password,
          rememberMe,
          fingerprint,
          pin,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.new) {
        return { success: false, newDevice: true };
      }
      if (result.token) {
        axios.defaults.headers.common["X-Auth-Token"] = result.token;
        localStorage.removeItem("token");

        localStorage.setItem("token", result.token);

        self.setToken(result.token);
        self.setEmail(result.user.email);
        self.setId(parseInt(result.user.id));
        self.setFirstName(result.user.firstName);
        self.setLastName(result.user.lastName);
        self.setCompanyId(result.user.companyId);
        self.setPosition(result.user.position);

        self.setStatus(true);

        /*  self.setCompany(
          CompanyModel.create({
            id: result.company.id,
            name: result.company.name,
            address: result.company.address,
            plz:result.company.plz,
            city:result.company.city,
            canton:result.company.canton,
            type: result.company.type,
          })
        ); */

        const store = getRoot(self);
        store.setCompany(
          result.company.id,
          result.company.name,
          result.company.address,
          result.company.plz,
          result.company.city,
          result.company.canton,
          result.company.type
        );

        //axios.defaults.headers.common = {'Authorization': `bearer ${result.token}`}
        localStorage.setItem("token", result.token);
        localStorage.setItem("user", JSON.stringify(result.user));
        localStorage.setItem("company", JSON.stringify(result.company));
        return { success: true };
      } else return { success: false };
    }),
  }));

export default LoginModel;
