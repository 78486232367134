import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const RatingCriteriaList = ({
  helpIntro,
  helpManual,
  helpAutomatic,
  helpFoto,
  helpFotoGoogle,
  helpFotoPlan,
  helpFotoGooglePlan,
  helpPlan,
  helpMessungen,
  helpWebLink,
  helpSonstige,
}) => {
  const { t } = useTranslation("common"); //translation

  return (
    <React.Fragment>
      {helpIntro != "" && (
        <div className={"rating-help-group"}>
          <p className="bold">{t("criteria.requirements")}</p>
          <p className="content">{parse(helpIntro)}</p>
        </div>
      )}

      {helpManual != "" && (
        <div className={"rating-help-group"}>
          <p className="bold">{t("criteria.manual")}</p>
          <p className="content">{parse(helpManual)}</p>
        </div>
      )}
      {helpAutomatic != "" && (
        <div className={"rating-help-group"}>
          <p className="bold">{t("criteria.automatic")}</p>
          <p className="content">{parse(helpAutomatic)}</p>
        </div>
      )}
      <div className={"rating-help-group"}>
        {(helpFoto != "" ||
          helpPlan != "" ||
          helpMessungen != "" ||
          helpWebLink != "" ||
          helpSonstige != "") && <p className="bold">{t("criteria.proof")}</p>}
        {/* {helpZertifikat != "" && (
        <div className={"rating-help-group"}>
          <p className="nachweise">{t("criteria.zertifikat")}</p>
          <p className="content">{parse(helpZertifikat)}</p>
        </div>
      )} */}
        {helpFoto != "" && (
          <div className={"rating-help-group"}>
            <p className="nachweise">{t("criteria.foto")}</p>
            <p className="content">{parse(helpFoto)}</p>
          </div>
        )}
        {helpFotoGoogle != "" && (
          <div className={"rating-help-group"}>
            <p className="nachweise">{t("criteria.fotoorgoogle")}</p>
            <p className="content">{parse(helpFotoGoogle)}</p>
          </div>
        )}
        {helpFotoPlan != "" && (
          <div className={"rating-help-group"}>
            <p className="nachweise">{t("criteria.fotoorplan")}</p>
            <p className="content">{parse(helpFotoPlan)}</p>
          </div>
        )}
        {helpFotoGooglePlan != "" && (
          <div className={"rating-help-group"}>
            <p className="nachweise">{t("criteria.fotoorgoogleorplan")}</p>
            <p className="content">{parse(helpFotoGooglePlan)}</p>
          </div>
        )}
        {helpPlan != "" && (
          <div className={"rating-help-group"}>
            <p className="nachweise">{t("criteria.plan")}</p>
            <p className="content">{parse(helpPlan)}</p>
          </div>
        )}
        {helpMessungen != "" && (
          <div className={"rating-help-group"}>
            <p className="nachweise">{t("criteria.messungen")}</p>
            <p className="content">{parse(helpMessungen)}</p>
          </div>
        )}

        {/* setting weblink  */}
        {helpWebLink != "" && (
          <div className={"rating-help-group"}>
            <p className="nachweise">{t("criteria.weblink")}</p>
            <p className="content">{parse(helpWebLink)}</p>
          </div>
        )}
        {helpSonstige != "" && (
          <div className={"rating-help-group"}>
            <p className="nachweise">{t("criteria.sonstige")}</p>
            <p className="content">{parse(helpSonstige)}</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default RatingCriteriaList;
