import React, { useState, useEffect } from "react";
import { Menu } from "antd";

import {
  AppstoreOutlined,
  ApartmentOutlined,
  FileOutlined,
  // TeamOutlined,
  // UserOutlined,
  // BarsOutlined,
} from "@ant-design/icons";

import { useHistory } from "react-router-dom";

//translation
import { useTranslation } from "react-i18next";

// const { SubMenu } = Menu;
function ClientMenu(props) {
  const { t } = useTranslation("common");

  const menuRoutes = {
    Dashboard: "/",
    Clients: "/clients",
    Portfolios: "/portfolios",
    Documents: "/documents",
  };
  let history = useHistory();
  const [SelectedMenuKey, setSelectedMenuKey] = useState(props.SelectedMenuKey);
  useEffect(() => {
    setSelectedMenuKey("Dashboard");
    return () => {
      setSelectedMenuKey("Dashboard");
    };
  }, []);

  const handleMenuClick = (e) => {
    console.log("navigate to: " + e.key);
    setSelectedMenuKey(e.key);
    history.push(menuRoutes[e.key]);
    //current: e.key,
  };

  return (
    <Menu
      defaultSelectedKeys={[SelectedMenuKey]}
      mode="inline"
      onClick={handleMenuClick}
    >
      <Menu.Item key="Dashboard" icon={<AppstoreOutlined />}>
        {t("client_menu.dashboard")}
      </Menu.Item>
      <Menu.Item key="Portfolios" icon={<ApartmentOutlined />}>
        {t("client_menu.portfolios")}
      </Menu.Item>
      <Menu.Item key="Documents" icon={<FileOutlined />}>
        {t("client_menu.documents")}
      </Menu.Item>
      {/* <SubMenu key="sub1" icon={<UserOutlined />} title="Users">
          <Menu.Item key="3">Tom</Menu.Item>
          <Menu.Item key="4">Bill</Menu.Item>
          <Menu.Item key="5">Alex</Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<TeamOutlined />} title="Properties">
          <Menu.Item key="6">Team 1</Menu.Item>
          <Menu.Item key="8">Team 2</Menu.Item>
        </SubMenu>
        <Menu.Item key="9" icon={<FileOutlined />} /> */}
    </Menu>
  );
}

export default ClientMenu;
