import React, { useState, useEffect } from "react";
import { Menu } from "antd";

import {
  // AppstoreOutlined,
  ApartmentOutlined,
  ClusterOutlined,
  DashboardOutlined,
  FileOutlined,
  // TeamOutlined,
  // UserOutlined,
  // BarsOutlined,
} from "@ant-design/icons";

import { useHistory } from "react-router-dom";
//translation
import { useTranslation } from "react-i18next";

// const { SubMenu } = Menu;
function AdminMenu(props) {
  const { t } = useTranslation("common");

  const menuRoutes = {
    Dashboard: "/",
    Dashboard2: "/Dashboard2",
    Management: "/Management",
    Clients: "/clients",
    Documents: "/documents",
  };
  let history = useHistory();
  const [SelectedMenuKey, setSelectedMenuKey] = useState(props.SelectedMenuKey);
  useEffect(() => {
    setSelectedMenuKey("Dashboard");
    return () => {
      setSelectedMenuKey("Dashboard");
    };
  }, []);

  const handleMenuClick = (e) => {
    setSelectedMenuKey(e.key);
    history.push(menuRoutes[e.key]);
  };

  return (
    <Menu
      defaultSelectedKeys={[SelectedMenuKey]}
      mode="inline"
      onClick={handleMenuClick}
    >
      <Menu.Item key="Dashboard" icon={<DashboardOutlined />}>
        {t("admin_menu.dashboard")}
      </Menu.Item>

      <Menu.Item key="Clients" icon={<ApartmentOutlined />}>
        {t("admin_menu.clients")}
      </Menu.Item>
      <Menu.Item key="Documents" icon={<FileOutlined />}>
        {t("admin_menu.documents")}
      </Menu.Item>
      <Menu.Item key="Management" icon={<ClusterOutlined />}>
        {t("admin_menu.management")}
      </Menu.Item>
    </Menu>
  );
}

export default AdminMenu;
