import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form, InputNumber, Tooltip } from "antd";

//translation
import { useTranslation } from "react-i18next";
import { COLORS_SERIES } from "../../../data/constants";

// const { TextArea } = Input;

const RatingRow = ({
  criteria,
  setHelpDescription,
  form,
  indexedRatings,
  groupName,
  index,
  blackOrWhiteText,
  computeTotalAverageRating,
  setIsFormChanged,
  currentProperty,
}) => {
  const { t } = useTranslation("common"); //translation

  // const COLORS_SERIES = { G: '#ff5555', U: '#aacc88', W: '#99ccff' }
  const row_code = groupName[0] + (index + 1);
  const code_color = COLORS_SERIES[groupName[0]];
  // const [rows, setRows] = useState(1);
  const [inputRows, setInputRows] = useState("1");
  const [initialRating, setInitialRating] = useState(0);
  const [initialComment, setInitialComment] = useState("");

  // eslint-disable-next-line
  useEffect(() => {
    setTimeout(() => {
      setInitialRating(
        parseFloat(form.getFieldValue(criteria.key + "_rating"))
      );
      setInitialComment(form.getFieldValue(criteria.key + "_comment"));
    }, 1000);
  }, [currentProperty]);

  const checkForRatingChanges = () => {
    const newRating = parseFloat(form.getFieldValue(criteria.key + "_rating"));
    console.log(initialRating, " = initialRating |", newRating, " = newRating");
    if (isNaN(initialRating) && isNaN(newRating)) {
      // setIsFormChanged(false);
      console.log("unchanged");
    } else if (initialRating !== newRating) {
      console.log("value changed!");
      setIsFormChanged(true);
    } else {
      console.log("unchanged");
      // setIsFormChanged(false);
    }
  };
  const checkForCommentChanges = () => {
    const newComment = form.getFieldValue(criteria.key + "_comment");

    console.log(
      initialComment,
      " = initialComment |",
      newComment,
      " = newComment"
    );
    if (initialComment !== newComment) {
      console.log("value changed");
      setIsFormChanged(true);
    } else {
      console.log("unchanged");
    }
  };
  const updateRows = (row) => {
    setInputRows(row);
  };

  const isIndexed = (key) => {
    if (!indexedRatings || indexedRatings.length === 0) return true;

    var this_rating = parseFloat(form.getFieldValue(key + "_rating"));
    var found = false;
    var matchRating = indexedRatings.find((ir) => {
      return (
        ir.key === key + ".1" &&
        parseFloat(ir.value) === parseFloat(this_rating)
      );
    });

    if (matchRating) {
      found = true;
    } else {
      found = false;
    }
    return found;
  };
  return (
    <tr key={criteria.key} className="ant-table-row ant-table-row-level-0">
      <td
        className="ant-table-cell"
        style={{
          textAlign: "center",
          maxWidth: "50px",
          backgroundColor: code_color,
          color: "white",
          // color: `${blackOrWhiteText(code_color)}`,
        }}
      >
        {row_code}
      </td>
      <td
        className="ant-table-cell"
        onClick={() => {
          setHelpDescription(criteria.key);
        }}
        onFocus={() => {
          setHelpDescription(criteria.key);
        }}
      >
        {t("criteria." + criteria.key.split(".").join("_") + ".title")}
      </td>
      <td className="ant-table-cell" style={{ textAlign: "center" }}>
        <Form.Item
          key={criteria.key + "_f_rating"}
          style={{ marginBottom: "0px" }}
          name={criteria.key + "_rating"}

          // Raffi 24.03.2021
          // rules={[
          //   {
          //     required: true,
          //     message: t("admin_rating.please_enter_value"),
          //   },
          // ]}
        >
          <InputNumber
            min={0}
            max={3}
            formatter={(value) =>
              `${
                value.toString().length == 0
                  ? ""
                  : isNaN(Math.round(value * 100) / 100)
                  ? ""
                  : Math.round(value * 100) / 100
              }`
            }
            step={1}
            className={
              "text-center" +
              (!isIndexed(criteria.key) ? " rating_not_indexed" : "")
            }
            onChange={() => {
              checkForRatingChanges();
              computeTotalAverageRating();
            }}
            onFocus={() => {
              setHelpDescription(criteria.key);
            }}
          />
        </Form.Item>
      </td>
      <td
        className="ant-table-cell"
        onClick={() => {
          setHelpDescription(criteria.key);
        }}
      >
        <Tooltip
          placement="right"
          overlayStyle={{ whiteSpace: "pre-line" }}
          title={form.getFieldValue(criteria.key + "_comment")}
        >
          <Form.Item
            style={{ marginBottom: "0px" }}
            name={criteria.key + "_comment"}
          >
            {/* <TextArea
          autoSize={{ minRows: inputRows, maxRows: inputRows }}
          onBlur={() => updateRows("1")}
          tabIndex={"-1"}
        /> */}
            <textarea
              rows={inputRows}
              className="textarea"
              onClick={() => updateRows("10")}
              onBlur={() => updateRows("1")}
              onChange={() => checkForCommentChanges()}
              tabIndex={-1}
            ></textarea>
          </Form.Item>
        </Tooltip>
      </td>
      <td className="ant-table-cell"></td>
      {/* <td className="ant-table-cell">{criteria.key}</td> */}
    </tr>
  );
};

export default observer(RatingRow);
