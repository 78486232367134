import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { observer } from "mobx-react";
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  Button,
  Select,
  Statistic,
  Empty,
  TreeSelect,
  Tooltip,
  Affix,
  Spin,
  Modal,
  Result,
  Popconfirm,
  Popover,
  Avatar,
} from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  ApartmentOutlined,
  LoadingOutlined,
  UndoOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";

import { Chart, Line, Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { toGenerator } from "mobx-state-tree";
//translation
import { useTranslation } from "react-i18next";

const COLORS_SERIES1 = ["#4f81bd", "#ffb281"];
const COLORS_SERIES2 = ["#4f81bd", "#FF6492"];
const COLOR1 = "#7A77FF";
const COLORS_SERIES_SETA = [
  "#4f81bd",
  "#6597cb",
  "#7da2ce",
  "#98c3e4",
  "#b4d9f1",
  "#a2bddc",
  "#c7d7ea",
  "#ecf2f8",
];
const COLORS_SERIES_SETB = [
  "#ff6492",
  "#ff7fa7",
  "#ff97ba",
  "#ffafcc",
  "#ffc5dc",
  "#ffdbea",
];
const COLORS_SERIES_BENCHMARK = [
  "#ff7f42",
  "#FF8F5C",
  "#FF9D70",
  "#FFAB85",
  "#ffb281",
  "#ffcaa4",
  "#ffe0c9",
  "#FFF3EB",
];

const COLORS_SERIES_BENCHMARK2x = [
  "#86C67C",
  "#53AF85",
  "#2A9689",
  "#1B7C82",
  "#276171",
  "#2F4858",
];
const COLORS_SERIES_BENCHMARK2 = [
  /* "##eff7ed", */
  "#cee8ca",
  "#add8a6",
  "#8cc983",
  "#6bb95f",
  "#52a046",
  "#407c36",
  "#2d5927",
  "#1b3517",
];
const COLORS_SERIES_SETA1 = [
  "#50A6C2",
  "#ADD8E6",
  "#1874CD",
  "#009ACD",
  "#00B7FF",
  "#6CA6CD",
  "#BFEFFF",
];
const COLORS_SERIES_RGB = ["#FF6492", "#86C67C", "#1874CD"];

const { SHOW_CHILD } = TreeSelect;
const { Option } = Select;

const RatingBox = (props) => {
  const { t } = useTranslation("common");
  const { BenchMarkData } = props;

  var bb = [...BenchMarkData];
  if (bb.length > 2) bb.pop();
  bb = bb.reverse();
  return (
    <>
      <div className={"ant-table-wrapper"} style={{ width: "450px" }}>
        <div className={"ant-table ant-table-bordered ant-table-small"}>
          <div className={"ant-table-container"}>
            <div className={"ant-table-content"}>
              <table style={{ tableLayout: "auto" }}>
                <thead className={"ant-table-thead"}>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    {t("admin_dashboard.period")}
                  </th>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    {" "}
                    {t("admin_dashboard.rating")}
                  </th>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    {t("admin_dashboard.properties")}
                  </th>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    m<sup>2</sup>
                  </th>
                </thead>
                <tbody className={"ant-table-tbody"}>
                  {bb.map((b) => {
                    return (
                      <>
                        <tr className={"ant-table-row ant-table-row-level-0"}>
                          <td align={"center"}>
                            {b.category2 + "." + b.category}
                          </td>
                          <td align={"center"}>
                            {parseFloat(b.rating).toFixed(2)}
                          </td>
                          <td align={"center"}>{b.property_count}</td>
                          <td align={"center"}>
                            {parseFloat(b.totalArea).toLocaleString("de-ch", {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DashboardFilterBox = (props) => {
  const {
    t,
    ClientOptions,
    setName,
    treeSelectSetPlaceHolder,
    setColor,
    Color,
    selectedKeys,
    setSelectedKeys,
    selectionMode,
    setSelectionMode,
    store,
  } = props;

  const [PortfolioSetRating, setPortfolioSetRating] = useState(0);

  const [PortfolioSetPropertyCount, setPortfolioSetPropertyCount] = useState(0);

  const [PortfolioSetTotalArea, setPortfolioSetTotalArea] = useState(0.0);

  const [SelectedNames, setSelectedNames] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState(selectedKeys);

  const [InternalSelectionMode, setInternalSelectionMode] =
    useState(selectionMode);
  const [benchMarkData, setBenchMarkData] = useState([
    {
      dataset: "Benchmark",
      category: 0,
      category2: 0,
      rating: "0",
      totalArea: 0,
    },
  ]);

  const [activeBenchmarkDataIndex, setActiveBenchmarkDataIndex] = useState(0);
  const PortfolioTreeSelectOnChange = (value) => {
    console.log(value);
    if (value.length === 1) {
      if (value[0].toString().includes("-")) {
        setSelectedRowKeys(value);
      }
    } else {
      setSelectedRowKeys(value);
    }
  };

  const tPropsB = {
    treeData: ClientOptions.map((_cp) => {
      const _children = _cp.children.map((_c) => {
        return {
          ..._c,
          title: _c.title.slice(0, 15),
        };
      });

      return {
        ..._cp,
        children: _children,
      };
    }),
    value: selectedRowKeys,
    onChange: PortfolioTreeSelectOnChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    maxTagCount: 3,

    listHeight: 700,
    style: {
      minWidth: "180px",
      width: "65%",
    },
  };

  const handleComparisonTypeChange = (value) => {
    setInternalSelectionMode(value);

    setActiveBenchmarkDataIndex(0);
  };
  const resetFilters = () => {
    //setCompareToBenchMarkB(true);

    setSelectedRowKeys([] /* allRowKeys */);
  };
  //RatingByThreeYears
  const requestThreeYearRatings = async () => {
    var d = new Date();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();
    //RatingByThreeYearsPortfolioType
    if (InternalSelectionMode === "benchmark") {
      const {
        data: resultBenchmark,
        status: responseStatusBenchmark,
        statusTextBenchmark,
      } = await axios
        .post(`/api/dashboard/RatingByThreeYearsBenchmark`, {})

        .then((response) => response)
        .catch((error) => error.response);

      //Benchmark Ratings
      var benchmarkInfo = resultBenchmark
        .filter((d) => d.dataset === "Benchmark" && d.rating)
        .sort(
          (a, b) =>
            parseFloat(a.category) - parseFloat(b.category) ||
            parseFloat(a.category2) - parseFloat(b.category2)
        );

      setBenchMarkData(benchmarkInfo);
      setActiveBenchmarkDataIndex(benchmarkInfo.length - 1);
    } else if (
      ["pf-1", "pf-2", "pf-3", "pf-4"].indexOf(InternalSelectionMode) > -1
    ) {
      const {
        data: resultBenchmark,
        status: responseStatusBenchmark,
        statusTextBenchmark,
      } = await axios
        .post(`/api/dashboard/RatingByThreeYearsPortfolioType`, {
          id: InternalSelectionMode.split("-")[1],
        })

        .then((response) => response)
        .catch((error) => error.response);

      //Benchmark Ratings
      if (resultBenchmark.length && resultBenchmark.length > 0) {
        var benchmarkInfo = resultBenchmark
          .map((x) => {
            return {
              dataset: x.dataset,
              category: x.category,
              category2: x.category2,
              property_count: x.property_count ? x.property_count : 0,
              rating: x.rating ? x.rating : 0,
              totalArea: x.totalArea ? x.totalArea : 0,
            };
          })
          /*  .filter((d) => d.dataset === "Benchmark") */
          .sort(
            (a, b) =>
              parseFloat(a.category) - parseFloat(b.category) ||
              parseFloat(a.category2) - parseFloat(b.category2)
          );

        setBenchMarkData(benchmarkInfo);
        setActiveBenchmarkDataIndex(benchmarkInfo.length - 1);
      } else {
        setBenchMarkData([
          {
            dataset: "Benchmark",
            category: 0,
            category2: 0,
            rating: "0",
            totalArea: 0,
          },
        ]);
        setActiveBenchmarkDataIndex(0);
      }
    } else if (InternalSelectionMode === "set" && selectedRowKeys.length > 0) {
      const {
        data: resultA,
        status: responseStatusA,
        statusTextA,
      } = await axios
        .post(`/api/dashboard/RatingByThreeYearsSetWithName`, {
          portfolioIds: selectedRowKeys.map((v) => {
            return parseInt(v.split("-")[1]);
          }) || [0],
          setName: setName,
        })

        .then((response) => response)
        .catch((error) => error.response);

      var item = resultA.filter(
        (s) =>
          s.dataset === setName && s.category === year && s.category2 === month
      );

      //SetA
      if (item) {
        /*  setPortfolioSetRating(parseFloat(item.rating ? item.rating : 0).toFixed(2));
          setPortfolioSetPropertyCount(item.property_count);
          setPortfolioSetTotalArea(item.totalArea); */

        setBenchMarkData(item);
        setActiveBenchmarkDataIndex(item.length - 1); /*  */
      }
    }
  };

  useEffect(() => {
    setInternalSelectionMode(selectionMode);
  }, [selectionMode]);

  useEffect(() => {
    var selections = [];
    ClientOptions.forEach((opt) => {
      opt.children.forEach((c) => {
        selections.push(c.value);
      });
    });
    setSelectedRowKeys(selections);
    return () => {
      setSelectedRowKeys([]);
    };
  }, [ClientOptions]);

  useEffect(() => {
    //setSelectedNames([])
    var list = [];
    selectedRowKeys.forEach((a) => {
      var ids = a.split("-");
      var c0 = ClientOptions.find((cl) => cl.value == ids[0]);
      var c1 = c0.children.find((ch) => ch.value == a);
      if (!list[c0.title]) {
        list[c0.title] = { text: c0.title, children: [] };
      }
      list[c0.title].children.push(c1.title);
    });

    var s = "";
    var ci = 0;
    for (var c in list) {
      if (ci > 0) s += " |";
      s += c + ": ";
      list[c].children.forEach((child, cindex, clist) => {
        if (cindex > 0) s += ", ";
        s += child;
      });

      ci++;
    }
    setSelectedNames(
      s.split("|").map((ss) => (
        <>
          <div>
            {ss.split(":").map((p, i) => {
              return (
                <div
                  style={{
                    marginLeft: i == 0 ? "0px" : "10px",
                    fontWeight: i == 0 ? "bold" : "normal",
                  }}
                  key={i}
                >
                  {p.indexOf(",") > -1
                    ? p.split(",").map((pp) => <div>- {pp}</div>)
                    : (i == 0 ? "" : "- ") + p}
                </div>
              );
            })}
          </div>
        </>
      ))
    );
    setSelectedKeys(selectedRowKeys);
    return () => {
      setSelectedNames("");
    };
  }, [selectedRowKeys]);

  useEffect(() => {
    setSelectionMode(InternalSelectionMode);
    return () => {};
  }, [InternalSelectionMode]);

  useEffect(() => {
    requestThreeYearRatings();
    return () => {};
  }, [InternalSelectionMode, selectedKeys]);
  return (
    <Card className={"ant-card-shadow ant-card-statistic"}>
      <Row gutter={[0, 25]}>
        <Col
          xl={22}
          lg={22}
          sm={22}
          md={22}
          flex="auto"
          style={{ textAlign: "center" }}
        >
          {/* <Space>
          <Row></Row> */}

          <Select
            defaultValue="benchmark"
            style={{
              minWidth: 170,
              textAlign: "left",
              width: InternalSelectionMode === "set" ? "" : "90%",
            }}
            onChange={handleComparisonTypeChange}
            value={InternalSelectionMode}
          >
            <Option value="benchmark" title={t("admin_dashboard.benchmark")}>
              {t("admin_dashboard.benchmark")}
            </Option>

            {/* <Option value="pf-1">{t("admin_dashboard.portfoliotype_1")}</Option>
            <Option value="pf-2">{t("admin_dashboard.portfoliotype_2")}</Option>
            <Option value="pf-3">{t("admin_dashboard.portfoliotype_3")}</Option>
            <Option value="pf-4">{t("admin_dashboard.portfoliotype_4")}</Option> */}
            <Option value="set">{setName}</Option>
          </Select>
          {InternalSelectionMode === "set" && (
            <>
              <Tooltip title={SelectedNames} placement={"left"} color={Color}>
                <TreeSelect className={"dashbboard-treeselect"} {...tPropsB} />
              </Tooltip>
            </>
          )}
        </Col>
        <Col xl={2} lg={2} sm={2} md={2}>
          <Tooltip title={t("admin_dashboard.reset_filters")}>
            <Button
              // style={{
              //   marginLeft: '5px',
              // }}
              shape="circle"
              icon={<UndoOutlined />}
              onClick={resetFilters}
            />
          </Tooltip>
          {/* </Space> */}
        </Col>
      </Row>
      <Row>
        {InternalSelectionMode !== "set" && (
          <>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={6}
              key={
                benchMarkData[activeBenchmarkDataIndex].dataset +
                "_" +
                benchMarkData[activeBenchmarkDataIndex].category +
                "_" +
                benchMarkData[activeBenchmarkDataIndex].category2
              }
            >
              <Statistic
                title={
                  <>
                    <strong style={{ color: "#333" }}>
                      {t("admin_dashboard.rating")}
                    </strong>
                  </>
                }
                value={parseFloat(
                  benchMarkData[activeBenchmarkDataIndex].rating
                ).toFixed(2)}
                precision={2}
                valueStyle={{ color: Color }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={6}>
              <Statistic
                title={
                  <>
                    <strong style={{ color: "#333" }}>
                      {t("admin_dashboard.properties")}
                    </strong>
                  </>
                }
                value={benchMarkData[activeBenchmarkDataIndex].property_count}
                precision={0}
                groupSeparator={"'"}
                valueStyle={{ color: Color }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={6}>
              <Statistic
                title={
                  <>
                    <strong style={{ color: "#333", marginRight: "10px" }}>
                      m<sup>2</sup>
                    </strong>
                  </>
                }
                value={benchMarkData[activeBenchmarkDataIndex].totalArea}
                precision={0}
                groupSeparator={"'"}
                valueStyle={{ color: Color }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={6}>
              {benchMarkData.length > 1 && (
                <Statistic
                  title={
                    <>
                      <div>
                        <strong style={{ color: "#333" }}>
                          {t("admin_dashboard.previous_month")}
                        </strong>
                      </div>
                      <Popover
                        placement="right"
                        title={false}
                        content={
                          <RatingBox BenchMarkData={benchMarkData}></RatingBox>
                        }
                        trigger="click"
                      >
                        <Avatar
                          size={40}
                          icon={<CalendarOutlined />}
                          style={{
                            marginTop: "10px",
                            backgroundColor: Color,
                            cursor: "pointer",
                          }}
                        />
                      </Popover>
                    </>
                  }
                  value={""}
                  precision={0}
                  valueStyle={{ color: "#ff6026", display: "none" }}
                />
              )}
            </Col>
          </>
        )}
        {InternalSelectionMode === "set" && (
          <>
            {[benchMarkData[benchMarkData.length - 1]].map((benchmark) => (
              <>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={6}
                  key={
                    benchmark.dataset +
                    "_" +
                    benchmark.category +
                    "_" +
                    benchmark.category2
                  }
                >
                  <Statistic
                    title={
                      <>
                        <strong style={{ color: "#333" }}>
                          {t("admin_dashboard.rating")}

                          <span className={"rating-prefix"}>
                            {benchmark.category2 + "." + benchmark.category}
                          </span>
                        </strong>
                      </>
                    }
                    value={
                      selectedRowKeys.length > 0
                        ? parseFloat(benchmark.rating).toFixed(2)
                        : 0
                    }
                    precision={2}
                    valueStyle={{ color: Color }}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                  <Statistic
                    title={
                      <>
                        <strong style={{ color: "#333" }}>
                          {t("admin_dashboard.properties")}
                        </strong>
                      </>
                    }
                    value={
                      selectedRowKeys.length > 0 ? benchmark.property_count : 0
                    }
                    precision={0}
                    valueStyle={{ color: Color }}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                  <Statistic
                    title={
                      <>
                        <strong style={{ color: "#333", marginRight: "10px" }}>
                          m<sup>2</sup>
                        </strong>
                      </>
                    }
                    value={selectedRowKeys.length > 0 ? benchmark.totalArea : 0}
                    precision={0}
                    groupSeparator={"'"}
                    valueStyle={{ color: Color }}
                  />
                </Col>
              </>
            ))}
          </>
        )}
      </Row>
    </Card>
  );
};

export {
  COLORS_SERIES1,
  COLORS_SERIES2,
  COLORS_SERIES_SETA,
  COLORS_SERIES_SETB,
  COLORS_SERIES_RGB,
  COLORS_SERIES_BENCHMARK,
  COLORS_SERIES_BENCHMARK2,
  COLORS_SERIES_SETA1,
  RatingBox,
  DashboardFilterBox,
};
