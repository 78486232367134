import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import RootStore from "./models/RootStore";
import LoginModel from "./models/LoginModel";
import CompanyModel from "./models/CompanyModel";
import GlobalProvider from "./components/GlobalProvider";

import "./index.css";

import { I18nextProvider, initReactI18next } from "react-i18next";
import i18next from "i18next";
import HttpBackend from "i18next-http-backend";

import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

//TEST production build on local
/* import axios from "axios"
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
console.log("API URL set to " + axios.defaults.baseURL );
 */
const defLanguage = localStorage.getItem("lang")
  ? localStorage.getItem("lang")
  : localStorage.setItem("lang", "de");

const store = RootStore.create({
  language: localStorage.getItem("lang"),
  login: LoginModel.create({
    id: 0,
    token: localStorage.getItem("token") || "",
    email: "",
    firstName: "",
    lastName: "",
    companyId: 0,
    position: "",
    isLoggedIn: localStorage.getItem("token") ? true : false,
    isAdmin: false,
  }),

  /*  ,selectedClient:  CompanyModel.create({
      id: '0',
      name: "",
      address: "",
      plz: "",
      city: "",
      canton: "",
      type: -1,
      status:''
    }) */
});
store.initialize();

i18next
  .use(HttpBackend)
  .use(initReactI18next)
  .init(
    {
      // lng: store.language,
      lng: localStorage.getItem("lang") || "de",
      fallbackLng: "de",
      preload: ["de", "fr"],
      ns: ["translations"],
      defaultNS: "translations",
      backend: {
        loadPath: "/public/translations/{{lng}}/translation.json",
      },
      react: {
        useSuspense: false,
      },
    },
    (err, t) => {
      if (err) return console.error(err);
    }
  );

ReactDOM.render(
  <Provider store={store}>
    <GlobalProvider>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </GlobalProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.unregister();
