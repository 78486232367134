import { types /*  flow, getRoot  */ } from "mobx-state-tree";
/* import axios from "axios"; */

const PropertyAttributeModel = types.model("PropertyAttributeModel", {
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ""),
  attributeCategoryId: types.optional(types.number, 0),
  active: types.optional(types.boolean, true),
});

export default PropertyAttributeModel;
