import React, { useState, useEffect, useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import PropertyTableRow from "../Admin/PropertyTableRow";
import ActualRatingsModal from "../Client/Ratings/ActualRatingsModal";
import {
  Layout,
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  Switch,
  AutoComplete,
  Tooltip,
  Space,
  Table,
  Modal,
  Drawer,
  message,
  Popconfirm,
  Affix,
} from "antd";

import { PlusOutlined, ImportOutlined } from "@ant-design/icons";

import ClientPropertyDetailModal from "./Portfolios/ClientPropertyDetailModal";

import ClientPropertyDetailDrawer from "./Portfolios/ClientPropertyDetailDrawer";
import CompanyModel from "../../models/CompanyModel";
import PropertyModel from "../../models/PropertyModel";

//translation
import { useTranslation } from "react-i18next";

import {
  // postal_codes,
  // cities,
  // cantons,
  // regions,
  getCantonName,
} from "../../data/AddressBook";
import {
  getPropertyRating,
  // getPortfolioRating,
  getPortfolioTotalArea,
} from "../../data/RatingCriteria";
// const getAreaPercentage = (value, self) => {
//   var total = self.areaOffice + self.areaResidential + self.areaCommercial;
//   if (total === 0) return 0;
//   return value / total;
// };

// const getAreaTotal = (self) => {
//   return self.areaOffice + self.areaResidential + self.areaCommercial;
// };
const { Title } = Typography;
// const { confirm } = Modal;
const { Search } = Input;

const PortfolioRating = (props) => {
  //activeClientId: types.optional(types.number, 0),
  //activeUserId: types.optional(types.number, 0),
  //activePortfolioId: types.optional(types.number, 0),

  const { store, portfolioId } = props;
  const [FinalRate, setFinalRate] = useState(0);
  const [totalArea, setTotalArea] = useState(0);
  useEffect(() => {
    if (
      store.portfolios.find((pf) => pf.id == portfolioId).properties.length > 0
    ) {
      var properties = store.portfolios.find(
        (pf) => pf.id == portfolioId
      ).properties;

      var mtotalArea = 0;
      properties.forEach((p) => {
        mtotalArea +=
          p.areaCommercial +
          p.areaOffice +
          p.areaResidential +
          p.areaBuilding +
          p.areaHealth;
      });
      setTotalArea(mtotalArea);
      ////
      var PortfolioTotalArea = mtotalArea;

      var sumRating = 0.0;
      var propertyCount = 0.0;

      if (properties.length > 0) {
        properties.forEach((pp) => {
          propertyCount += 1;
          var rating = getPropertyRating(pp);
          var weight =
            (pp.areaCommercial +
              pp.areaOffice +
              pp.areaResidential +
              pp.areaBuilding +
              pp.areaHealth) /
            PortfolioTotalArea;
          var rw = rating * weight;
          sumRating += rw;
        });
      }

      var finalRating = sumRating;
      setFinalRate(finalRating);

      ////
    }
    return () => {
      setFinalRate(0);
    };
  }, [portfolioId]);

  if (isNaN(FinalRate)) {
    return <span style={{ color: "red" }}>NaN</span>;
  } else {
    return <span>{FinalRate.toFixed(2)}</span>;
  }
};
const PropertyAuditLog = (props) => {
  //activeClientId: types.optional(types.number, 0),
  //activeUserId: types.optional(types.number, 0),
  //activePortfolioId: types.optional(types.number, 0),

  const { store, portfolioId } = props;
  const [AuditLogs, setAuditLogs] = useState({ auditDate: "", comment: "" });

  const [Logs, setLogs] = useState([]);
  useEffect(() => {
    var pf = store.portfolios.find((pf) => pf.id === portfolioId);
    var properties = pf.properties;
    var a = [];
    properties.forEach((p) => {
      /* if (p.auditLogs.length === 0) {
        p.getAuditLogs().then((s) => {
          if (p.auditLogs.length > 0) {
            p.auditLogs.forEach((l) => {
              setLogs((prevLogs) => [
                ...prevLogs,
                { auditDate: l.auditDate, comment: l.comment },
              ]);
            });
          }
        });
      } else {
        p.auditLogs.forEach((l) => {
          setLogs((prevLogs) => [
            ...prevLogs,
            { auditDate: l.auditDate, comment: l.comment },
          ]);
        });
      } */

      p.auditLogs.forEach((l) => {
        setLogs((prevLogs) => [
          ...prevLogs,
          { auditDate: l.auditDate, comment: l.comment },
        ]);
      });
    });

    return () => {
      setLogs([]);
    };
  }, [portfolioId, props]);

  useEffect(() => {
    var l = Logs;
    l = l.sort((x, y) => new Date(x.auditDate) - new Date(y.auditDate));
    setAuditLogs(
      l.length > 0 ? l[l.length - 1] : { auditDate: "", comment: "" }
    );
    return () => {
      setAuditLogs({ auditDate: "", comment: "" });
    };
  }, [Logs]);
  return (
    <Tooltip color={"#435c77"} title={AuditLogs.comment}>
      <div style={{ textAlign: "center" }}>
        {AuditLogs.auditDate.trim().length > 0
          ? new Date(AuditLogs.auditDate).toLocaleDateString("de", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          : ""}
      </div>
    </Tooltip>
  );
};
const ClientPortfoliosDrawer = (props) => {
  const { t } = useTranslation("common"); //translation

  const { store } = props;

  const login = store.login;
  const generateKey = () => {
    var randKey = Array(10)
      .fill(
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz$&!*@+%_~?;"
      )
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");
    return randKey;
  };

  const getAverageRating = (portfolio) => {
    if (portfolio.properties.length === 0) return 0;
    var sum = portfolio.properties.reduce(
      (accumulator, property, currentIndex, array) =>
        accumulator + getPropertyRating(property),
      0
    );
    return sum / portfolio.properties.length;
  };

  const [propertyDataKey, setPropertyDataKey] = useState(generateKey());

  const [clientId, setClientId] = useState(0);

  const portfolios = store.portfolios;
  const [client, setClient] = useState(CompanyModel.create({}));
  const [properties, setProperties] = useState(store.properties);
  const [portfolioData, setPortfolioData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const [popConfirmRatingvisible, setPopConfirmRatingvisible] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (login.companyId > 0) {
      setClientId(store.login.companyId);
    }
    return () => {};
  }, [login]);

  useEffect(() => {
    setIsLoading(true);
    if (clientId === 0) {
      setClient(CompanyModel.create({}));
      setIsLoading(false);
    } else {
      store.getClient(clientId).then((client) => {
        setClient(client);

        // setProperties(store.properties);
        store.getPortfoliosByUser(login.id).then((x) => {
          //setProperties(store.properties);

          refreshList(true);
          setIsLoading(false);
        });
        // refreshList(true);
      });
    }
  }, [clientId]);

  const [searchKeyword, setSearchKeyword] = useState("");

  const [filteredPortfolios, setFilteredPortfolios] = useState([]);

  useEffect(() => {
    if (searchKeyword.trim() === "") setFilteredPortfolios(toJS(portfolioData));
    else {
      setFilteredPortfolios(
        toJS(
          portfolioData.filter(
            (pf) =>
              pf.name
                .toLowerCase()
                .includes(searchKeyword.trim().toLowerCase()) ||
              pf.properties.filter(
                (pp) =>
                  pp.name
                    .toLowerCase()
                    .includes(searchKeyword.trim().toLowerCase()) ||
                  pp.address
                    .toLowerCase()
                    .includes(searchKeyword.trim().toLowerCase()) ||
                  pp.city
                    .toLowerCase()
                    .includes(searchKeyword.trim().toLowerCase()) ||
                  pp.canton
                    .toLowerCase()
                    .includes(searchKeyword.trim().toLowerCase()) ||
                  pp.region
                    .toLowerCase()
                    .includes(searchKeyword.trim().toLowerCase())
              ).length > 0
          )
        )
      );
    }
    return () => {};
  }, [portfolioData, searchKeyword]);

  useEffect(() => {
    setExpandedRows(
      filteredPortfolios.map((e) => ({ name: e.name, expanded: true }))
    );
  }, [filteredPortfolios]);

  //const [portfolios, setPortfolios] = useState([]);
  const refreshList = async (refresh = true) => {
    if (clientId && clientId > 0) {
      /* if (refresh === true) {
        store.getPortfoliosByUser(login.id).then((s) => {
          const x = portfolios.map((p) => {
            return {
              key: p.id,
              id: p.id,
              name: p.name,
              investmentType: p.investmentType,
              rating: p.rating,
              propertyCount: p.propertyCount,
              properties: p.properties,
              rating: p.rating ,
            };
          });
          setPortfolioData(x);
        });
      } else { */
      //setPortfolios(store.portfolios.find((p) => p.companyId === clientId));
      const x = portfolios.map((p) => {
        return {
          key: p.id,
          id: p.id,
          name: p.name,
          investmentType: p.investmentType,
          rating: p.rating,
          propertyCount: p.propertyCount,
          properties: p.properties,
          rating: p.rating /* getAverageRating(p) */,
        };
      });
      setPortfolioData(x);
      /*  } */
    }
  };
  useEffect(() => {
    refreshList();
  }, [clientId, portfolios]);

  //isClientPortfoliosVisible

  /*   useEffect(() => {
    
    store.getPortfoliosByUser(login.id).then((x) => {
      setProperties(store.properties);
    });
    
  }, [clientId]); */

  useEffect(() => {
    refreshList();
  }, []);

  useEffect(() => {
    refreshList();
    return () => {};
  }, [portfolios]);

  // const successMessage = (msg) => {
  //   message.success(msg);
  // };

  // const errorMessage = (msg) => {
  //   message.error(msg);
  // };

  // const warningMessage = (msg) => {
  //   message.warning(msg);
  // };

  // const getInvestmentTypeName = (id) => {
  //   var i = store.investmentTypes.find((x) => x.id === id);

  //   return i ? i.name : "";
  // };

  // function onConfirmDeleteProperty(row) {
  //   var property = portfolios
  //     .find((pf) => pf.id === row.portfolioId)
  //     .properties.find((pp) => pp.id === row.id);
  //   if (property)
  //     property.remove().then((success) => {
  //       if (success) {
  //         store.setClientListUpdateOn(new Date().getTime().toString());
  //         message.success(t("property.property_deleted"));
  //       } else {
  //         message.success(t("property.failed_delete_property"));
  //       }
  //     });
  //   else {
  //     message.success(t("property.property_not_found"));
  //   }
  // }
  // function onCancelDeleteProperty(e) {
  //   console.log(e);
  // }

  const portfolioColumns = [
    {
      title: t("client.portfolio_name"),
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => <span>{`${record.name}`}</span>,
      width: 415,
    },
    {
      title: t("client.investment_type"),
      dataIndex: "investmentType",
      key: "investmentType",
      sorter: (a, b) =>
        store.investmentTypes
          .find((x) => x.id === a.investmentType)
          .name.localeCompare(
            store.investmentTypes.find((x) => x.id === b.investmentType).name
          ),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <span key={record.id}>
          {" "}
          {t(`client_portfolio.investment_types.${record.investmentType}`)}
        </span>
      ),
      width: "200px",
    },
    {
      title: t("client.rating"),
      dataIndex: "rating",
      key: "rating",
      sorter: (a, b) => a.rating - b.rating,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => {
        const _portfolioRatingsInvalid = record.properties
          .map((_pp) => getPropertyRating(_pp))
          .includes(NaN);

        if (_portfolioRatingsInvalid) {
          return (
            <div style={{ textAlign: "center", color: "red" }}>
              <span key={record.id}>{`NaN`}</span>
            </div>
          );
        } else {
          return (
            <div style={{ textAlign: "center" }}>
              <PortfolioRating
                store={store}
                portfolioId={record.id}
              ></PortfolioRating>
            </div>
          );
        }
      },
      width: "100px",
    },
    {
      title: t("client.no_properties"),
      dataIndex: "propertyCount",
      key: "propertyCount",
      sorter: (a, b) => a.propertyCount - b.propertyCount,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>
          <span key={record.id}>{`${record.propertyCount}`}</span>
        </div>
      ),
      width: "150px",
    },
    {
      title: (
        <span>
          m<sup>2</sup>
        </span>
      ),
      dataIndex: "area",
      key: "area",
      sorter: (a, b) => (a.area ? a.area : 0) - (b.area ? b.area : 0),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <div
          style={{ textAlign: "right" }}
          key={record.id}
        >{`${record.area.toLocaleString("de-ch", {
          minimumFractionDigits: 2,
        })}`}</div>
      ),
      width: 150,
    },
    {
      title: t("audit.title"),
      dataIndex: "area",
      key: "area",

      render: (text, record) => (
        <PropertyAuditLog
          store={store}
          portfolioId={record.id}
        ></PropertyAuditLog>
      ),
      width: 150,
    },
  ];

  // const [propertyImportModalVisible, setPropertyImportModalVisible] =
  //   useState(false);

  // const [PortfolioDetailModalVisible, setPortfolioDetailModalVisible] =
  //   useState(false);
  const [selectedPortfolioId, setSelectedPortfolioId] = useState(0);

  const [PropertyDetailModalVisible, setPropertyDetailModalVisible] =
    useState(false);
  const [PropertyDetailDrawerVisible, setPropertyDetailDrawerVisible] =
    useState(false);

  const [selectedPropertyId, setSelectedPropertyId] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState(
    PropertyModel.create({})
  );

  const propertyModalProps = {
    store,
    visible: PropertyDetailModalVisible && selectedPropertyId === 0,
    setVisible: setPropertyDetailModalVisible,
    id: selectedPropertyId,
    clientId,
    propertyDataKey,

    popConfirmRatingvisible,
    setPopConfirmRatingvisible,
    setPropertyDataKey,
    portfolioId: selectedPortfolioId,
    refreshList,
    properties,
    getCantonName,
  };
  const propertyDrawerProps = {
    store,
    visible: PropertyDetailDrawerVisible,
    setVisible: setPropertyDetailDrawerVisible,
    modalVisible: PropertyDetailModalVisible,
    setModalVisible: setPropertyDetailModalVisible,
    id: selectedPropertyId,
    setPropertyId: setSelectedPropertyId,
    currentProperty: selectedProperty,
    clientId,
    propertyDataKey,
    setPropertyDataKey,
    portfolioId: selectedPortfolioId,
    refreshList,
    properties,
    getCantonName,
  };

  useEffect(() => {
    // console.log(selectedPropertyId);
    setSelectedProperty(properties.find((p) => p.id === selectedPropertyId));
    // return () => {
    //   setSelectedProperty(PropertyModel.create({}));
    // };
  }, [selectedPropertyId]);

  const [viewMode, setViewMode] = useState({
    propertyId: 0,
    portfolioId: 0,
    detailVisible: false,
    new: false,
  });

  const addNewProperty = (clientId, portfolioId) => {
    setSelectedPropertyId(0);
    setSelectedPortfolioId(portfolioId);
    setPropertyDetailModalVisible(true);
  };
  const editProperty = (portfolioId, propertyId) => {
    // console.log("edit property");
    // console.log(propertyId);
    setProperties(
      store.portfolios.find((p) => p.id === portfolioId).properties
    );
    setTimeout(() => {
      // console.log("MUTATE STATE");
      setSelectedPropertyId(propertyId);
      setSelectedPortfolioId(portfolioId);
      setPropertyDetailDrawerVisible(true);
    }, 250);
  };

  useEffect(() => {
    if (!PropertyDetailDrawerVisible) setSelectedPropertyId(0);
  }, [PropertyDetailDrawerVisible]);

  /* useEffect(() => {
    if (selectedPortfolioId > 0)
      setProperties(
        store.portfolios.find((p) => p.id === selectedPortfolioId).properties
      );
    return () => {};
  }, [selectedPortfolioId]); */

  useEffect(() => {
    setSelectedPortfolioId(viewMode.portfolioId);
    setSelectedPropertyId(viewMode.new ? 0 : viewMode.propertyId);

    return () => {};
  }, [viewMode]);

  const [headerAffixClassName, setHeaderAffixClassName] = useState(
    "page-title title-affix"
  );
  const [isAffixed, setIsAffixed] = useState(false);

  useLayoutEffect(() => {
    setIsAffixed(false);
    return () => {
      setIsAffixed(false);
    };
  }, []);

  const [actualRatingsModalVisible, setActualRatingsModalVisible] =
    useState(false);
  const [propertyId, setPropertyId] = useState(0);
  const [propertyForRating, setPropertyForRating] = useState(
    PropertyModel.create({})
  );
  const actualRatingsModalProps = {
    store,
    id: propertyId,
    currentProperty: propertyForRating,
    visible: actualRatingsModalVisible,
    setVisible: setActualRatingsModalVisible,
    refreshList: () => {},
    setPropertyId: setPropertyId,
  };
  const showActualRatingsForm = (portfolioId, propertyId) => {
    store.setActivePortfolioId(portfolioId);
    store.setActivePropertyId(propertyId).then((y) => {
      setPropertyId(propertyId);
    });
  };
  useEffect(() => {
    if (propertyId > 0) {
      if (store.portfolios && store.portfolios.length) {
        var p = store.portfolios
          .find((pf) => pf.id === store.activePortfolioId)
          .properties.find((x) => x.id === propertyId);
        if (p) {
          setPropertyForRating(p);
          setActualRatingsModalVisible(true);
        }
      }
    }
    return () => {
      setActualRatingsModalVisible(false);
    };
  }, [propertyId]);

  useEffect(() => {
    if (!PropertyDetailModalVisible) {
      // console.log(PropertyDetailModalVisible);
      setPopConfirmRatingvisible(false);
    }
  }, [PropertyDetailModalVisible]);

  return (
    <>
      <div style={{ minHeight: "calc(100% )" }}>
        <Affix
          style={{ position: "absolute" }}
          offsetTop={0}
          onChange={(affixed) => {
            // console.log(affixed);
            setIsAffixed(affixed);
            setHeaderAffixClassName(
              affixed
                ? "page-title title-affix title-with-bg"
                : "page-title title-affix"
            );
          }}
        ></Affix>
        <Affix offsetTop={0}>
          <Row
            className={"page-title"}
            gutter={[32, 24]}
            style={{
              backgroundColor: isAffixed
                ? "rgba(255,255,255,0.8)"
                : "rgba(255,255,255,0.0)",
              boxShadow: isAffixed
                ? "10px 1px 15px rgba(128,128,128,0.5)"
                : "none",
            }}
          >
            <Col flex={"auto"}>
              <Title
                style={{
                  display: "block !important",
                  textAlign: "left",
                  width: "100%",
                }}
                className={isAffixed ? "page-title" : ""}
              >
                {t("client.portfolios")}
              </Title>
            </Col>
            <Col flex={"auto"} style={{ textAlign: "right" }}>
              <Title level={2}> {store.company.name}</Title>
            </Col>
          </Row>
        </Affix>
        <Row style={{ minHeight: "calc(100vh - 150px )" }}>
          <Col flex={"auto"}>
            <div style={{ marginBottom: 16, textAlign: "left" }}>
              <Space>
                {/*  <Button
                  type="primary"
                  onClick={() => {
                    createNewPortfolio();
                  }}
                >
                  <PlusOutlined /> New Portfolio
                </Button>
                <Button type="default" onClick={() => {}}>
                  <ImportOutlined /> Import From Excel
                </Button> */}
                <Search
                  placeholder={t("client.search_portfolio_name_property_id")}
                  onSearch={(value) => setSearchKeyword(value)}
                  style={{ width: 250 }}
                />
              </Space>
            </div>
            <Table
              className={"client_portfolio_table"}
              dataSource={
                /* portfolioData*/ filteredPortfolios.map((pf) => {
                  return {
                    key: pf.id,
                    ...pf,
                    rating: 0,
                    area: getPortfolioTotalArea(pf),
                    propertyCount: pf.properties.length,
                  };
                })
              }
              columns={portfolioColumns}
              size={"middle"}
              bordered="true"
              pagination={false}
              loading={IsLoading}
              /*rowSelection={IsEditMode}
              
              hasData={HasData}*/

              /*expandedRowRender={PropertyTableRow}
              expandable={{ defaultExpandAllRows: true }}*/
              expanded={true}
              expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: (record) => (
                  <PropertyTableRow
                    searchKeyword={searchKeyword}
                    row={record}
                    portfolios={filteredPortfolios}
                    addNewProperty={addNewProperty}
                    editProperty={editProperty}
                    getCantonName={getCantonName}
                    showActualRatingsForm={showActualRatingsForm}
                  ></PropertyTableRow>
                ),
              }}
              footer={() =>
                `${portfolioData.length}   ${t("client.portfolio_found")}`
              }
              rowKey={"name"}
              scroll={{ y: "calc(100vh - 240px)" }}
            />
          </Col>
        </Row>
      </div>

      {selectedProperty && (
        <ClientPropertyDetailDrawer
          {...propertyDrawerProps}
        ></ClientPropertyDetailDrawer>
      )}
      {selectedPropertyId === 0 && (
        <ClientPropertyDetailModal
          {...propertyModalProps}
        ></ClientPropertyDetailModal>
      )}

      {actualRatingsModalVisible && propertyId > 0 && (
        <ActualRatingsModal {...actualRatingsModalProps}></ActualRatingsModal>
      )}
    </>
  );
};

export default observer(ClientPortfoliosDrawer);
