import React from "react";
import { observer } from "mobx-react";
import { Input, Tooltip } from "antd";

function formatNumber(value) {
  if (isNaN(value)) return "";

  value = parseInt(value).toString();
  if (value.length > 4)
    value = value
      .split("")
      .slice(-4)
      .join("");

  return value;
  /* value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`; */
}

class YearInput extends React.Component {
  onChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if(value.trim().length==0) this.props.onChange(value);
    else
    if (
      !isNaN(value) &&
      value.length < 5 &&
      /* parseInt(value) > 999 && */
      parseInt(value) <= new Date().getFullYear()
    ) {
      this.props.onChange(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (
      isNaN(valueTemp) ||
     
      parseInt(valueTemp) < 999 ||
      parseInt(valueTemp) > new Date().getFullYear()
    ) {
      valueTemp='';
    }

   /*  if (value.charAt(value.length - 1) === "." || value === "-") {
      valueTemp = value.slice(0, -1);
    } */

    onChange(valueTemp/* .replace(/0*(\d+)/, "$1") */);
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const { value } = this.props;
    const title = value ? (
      <span className="numeric-input-title">
        {value !== "-" ? formatNumber(value) : "-"}
      </span>
    ) : (
      "Input a number"
    );
    return (
     
        <Input
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          maxLength={this.props.maxLength||4}
        />
     
    );
  }
}

export default observer(YearInput);
