import React from "react";
import { TinyMCE } from "./";
import { useTranslation } from "react-i18next";
import FotoSelection from "./FotoSelection";

const TextEditor = ({
  setHelpIntro,
  helpIntro,
  setHelpManual,
  helpManual,
  setHelpAutomatic,
  helpAutomatic,
  setHelpFoto,
  helpFoto,
  setHelpFotoGoogle,
  helpFotoGoogle,
  setHelpFotoPlan,
  helpFotoPlan,
  setHelpFotoGooglePlan,
  helpFotoGooglePlan,
  setHelpPlan,
  helpPlan,
  setHelpMessungen,
  helpMessungen,
  setHelpWebLink,
  helpWebLink,
  setHelpSonstige,
  helpSonstige,
  setSelectedFoto,
  selectedFoto,
}) => {
  const { t } = useTranslation("common"); //translation

  React.useEffect(() => {
    if (helpFoto) setSelectedFoto("foto");
    if (helpFotoGoogle) setSelectedFoto("fotoorgoogle");
    if (helpFotoGooglePlan) setSelectedFoto("fotoorgoogleorplan");
    if (helpFotoPlan) setSelectedFoto("fotoorplan");

    return () => setSelectedFoto("");
  }, []);

  return (
    <>
      <div className={"rating-help-group"}>
        <p className="bold">{t("criteria.requirements")}</p>
        <p className="content">
          <TinyMCE set={setHelpIntro} data={helpIntro} />
        </p>
      </div>

      <div className={"rating-help-group"}>
        <p className="bold">{t("criteria.manual")}</p>
        <p className="content">
          <TinyMCE set={setHelpManual} data={helpManual} />
        </p>
      </div>

      <div className={"rating-help-group"}>
        <p className="bold">{t("criteria.automatic")}</p>
        <p className="content">
          <TinyMCE set={setHelpAutomatic} data={helpAutomatic} />
        </p>
      </div>

      <div className={"rating-help-group"}>
        <p className="bold">{t("criteria.proof")}</p>

        {/* <div className={"rating-help-group"}>
          <p className="nachweise">{t("criteria.zertifikat")}</p>
          <p className="content">
            <TinyMCE set={setHelpZertifikat} data={helpZertifikat} />
          </p>
        </div> */}

        {/* {helpFoto != "" && ( */}
        <div className={"rating-help-group"}>
          <FotoSelection
            selectedFoto={selectedFoto}
            setSelectedFoto={setSelectedFoto}
          />
          {/* <p className="nachweise">{selectedFoto}</p> */}
          {/* {selectedFoto ? (
              <p className="nachweise">{t(`criteria.${selectedFoto}`)}</p>
            ) : (
              <p className="nachweise">{t("criteria.foto")}</p>
            )} */}
          {/* <p className="nachweise">{t("criteria.foto")}</p> */}
          <p className="content">
            {(selectedFoto == "foto" || !selectedFoto) && (
              <TinyMCE set={setHelpFoto} data={helpFoto} />
            )}
            {selectedFoto == "fotoorgoogle" && (
              <TinyMCE set={setHelpFotoGoogle} data={helpFotoGoogle} />
            )}
            {selectedFoto == "fotoorgoogleorplan" && (
              <TinyMCE set={setHelpFotoGooglePlan} data={helpFotoGooglePlan} />
            )}
            {selectedFoto == "fotoorplan" && (
              <TinyMCE set={setHelpFotoPlan} data={helpFotoPlan} />
            )}
          </p>
        </div>
        {/* )} */}
        {/* {helpFotoGoogle != "" && (
          <div className={"rating-help-group"}>
            <p className="nachweise">{t("criteria.fotoorgoogle")}</p>
            <p className="content">
              <TinyMCE set={setHelpFotoGoogle} data={helpFotoGoogle} />
            </p>
          </div>
        )} */}
        {/* {helpFotoPlan != "" && (
          <div className={"rating-help-group"}>
            <p className="nachweise">{t("criteria.fotoorplan")}</p>
            <p className="content">
              <TinyMCE set={setHelpFotoPlan} data={helpFotoPlan} />
            </p>
          </div>
        )} */}
        {/* {helpFotoGooglePlan != "" && (
          <div className={"rating-help-group"}>
            <p className="nachweise">{t("criteria.fotoorgoogleorplan")}</p>
            <p className="content">
              <TinyMCE set={setHelpFotoGooglePlan} data={helpFotoGooglePlan} />
            </p>
          </div>
        )} */}

        <div className={"rating-help-group"}>
          <p className="nachweise">{t("criteria.plan")}</p>
          <p className="content">
            <TinyMCE set={setHelpPlan} data={helpPlan} />
          </p>
        </div>

        <div className={"rating-help-group"}>
          <p className="nachweise">{t("criteria.messungen")}</p>
          <p className="content">
            <TinyMCE set={setHelpMessungen} data={helpMessungen} />
          </p>
        </div>

        <div className={"rating-help-group"}>
          <p className="nachweise">{t("criteria.weblink")}</p>
          <p className="content">
            <TinyMCE set={setHelpWebLink} data={helpWebLink} />
          </p>
        </div>

        <div className={"rating-help-group"}>
          <p className="nachweise">{t("criteria.sonstige")}</p>
          <p className="content">
            <TinyMCE set={setHelpSonstige} data={helpSonstige} />
          </p>
        </div>
      </div>
    </>
  );
};

export default TextEditor;
