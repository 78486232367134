import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import {
  Layout,
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  AutoComplete,
  Space,
  Table,
  Modal,
  Radio,
  message,
} from "antd";
import PortfolioModel from "../../models/PortfolioModel";
import PortfolioUserAccessModel from "../../models/PortfolioUserAccessModel";
import CompanyModel from "../../models/CompanyModel";
//translation
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const PortfolioDetailModal = (props) => {
  const { t } = useTranslation("common"); //translation

  const {
    store,
    visible,
    setVisible,
    /* id, */
    /* clientId, */
    /* selectedClient, */
    selectedPortfolio,
    refreshList,
    /* portfolios, */
    /* updateCount,
    setUpdateCount, */
  } = props;

  const clientId = store.activeClientId;
  const id = store.activePortfolioId;
  const portfolios = store.clients.find((c) => c.id === clientId).portfolios;
  const [selectedClient, setSelectedClient] = useState(CompanyModel.create({}));

  useEffect(() => {
    if (clientId === 0) {
      setSelectedClient(CompanyModel.create({}));
    } else {
      setSelectedClient(store.clients.find((c) => c.id === clientId));
    }
    return () => {
      setSelectedClient(CompanyModel.create({}));
    };
  }, [clientId]);

  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const successMessage = (msg) => {
    message.success(msg);
  };

  const errorMessage = (msg) => {
    message.error(msg);
  };

  const warningMessage = (msg) => {
    message.warning(msg);
  };

  const [portfolioData, setPortfolioData] = useState(PortfolioModel.create({}));

  const [userRightsOptions, setUserRightsOptions] = useState([]);

  /*   const userRightsOptions = [
    { label: 'Apple', value: '1' },
    { label: 'Pear', value: '2' },
    { label: 'Orange', value: '3' },
  ]; */

  const [uroDefaultValues, setUroDefaultValues] = useState([]);

  useEffect(() => {
    if (id === undefined || id === 0) {
      setPortfolioData(PortfolioModel.create({}));
      store.getUsersByCompanyId(clientId).then((success) => {
        if (success) {
          var pua_options = [];
          store.users.forEach((user) => {
            pua_options.push({
              label: `${user.firstName} ${user.lastName} (${user.email})`,
              value: user.id,
              hasAccess: false,
            });
          });
          setUserRightsOptions(pua_options);
          setUroDefaultValues([]);
          form.setFieldsValue({ userAccess: [] });
        }
      });
    } else {
      setPortfolioData(portfolios.find((p) => p.id === id));
      //Prepare data for User Access List
      store.getUsersByCompanyId(clientId).then((success) => {
        //if (success) {
        store.getPortfolioUserAccessByPortfolioId(id).then((success2) => {
          if (success2) {
            //PortfolioUserAccessModel
            var pua_options = [];
            store.users.forEach((user) => {
              pua_options.push({
                label: `${user.firstName} ${user.lastName} (${user.email})`,
                value: user.id,
                hasAccess: store.portfolioUsersAccessList.find(
                  (pua) => pua.userId === user.id
                )
                  ? true
                  : false,
              });
            });
            setUserRightsOptions(pua_options);
            setUroDefaultValues(
              pua_options
                .filter((ua) => ua.hasAccess === true)
                .map((ub) => ub.value)
            );
            form.setFieldsValue({
              userAccess: store.portfolioUsersAccessList.map((u) => u.userId),
            });
          }
        });
        //}
      });
    }
  }, [id]);

  useEffect(() => {
    if (id === 0) {
      form.setFieldsValue({
        portfolioName: "",
        investmentType: null,
      });

      //store.getUsersByCompanyId(clientId).then((success) => {
      //selectedClient.getUsers().then((success) => {
      //if (success) {
      var pua_options = [];
      store.users.forEach((user) => {
        pua_options.push({
          label: `${user.firstName} ${user.lastName} (${user.email})`,
          value: user.id,
          hasAccess: false,
        });
      });
      setUserRightsOptions(pua_options);
      setUroDefaultValues([]);
      form.setFieldsValue({ userAccess: [] });
      //}
      // });
    } else {
      form.setFieldsValue({
        portfolioName: portfolioData.name,
        investmentType: portfolioData.investmentType,
        /* userAccess:portfolioData.userAccessList.map(u=>u.userId) */
      });
      //Prepare data for User Access List
      // store.getUsersByCompanyId(clientId).then((success) => {
      //if (success) {
      store.getPortfolioUserAccessByPortfolioId(id).then((success2) => {
        if (success2) {
          //PortfolioUserAccessModel
          var pua_options = [];
          store.users.forEach((user) => {
            pua_options.push({
              label: `${user.firstName} ${user.lastName} (${user.email})`,
              value: user.id,
              hasAccess: store.portfolioUsersAccessList.find(
                (pua) => pua.userId === user.id
              )
                ? true
                : false,
            });
          });
          setUserRightsOptions(pua_options);
          setUroDefaultValues(
            pua_options
              .filter((ua) => ua.hasAccess === true)
              .map((ub) => ub.value)
          );
          form.setFieldsValue({
            userAccess: store.portfolioUsersAccessList.map((u) => u.userId),
          });
        }
      });
      //}
      // });
    }
  }, [portfolioData]);

  const onFormFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFormFinish = (values) => {

    props.setPortfolioLoading(true)
    if (id === 0) {
      //check first if portfolio name exists
      var thisClient = store.clients.find((c) => c.id === store.activeClientId);
      if (
        thisClient.portfolios.find((pf) => {
          return pf.name === values.portfolioName;
        })
      ) {
        errorMessage(t("portfolio.could_not_add_portfolio_exists"));
      } else if (values.investmentType === "") {
        errorMessage(t("portfolio.could_not_add"));
      }
      // add new record
      else
        thisClient
          .addPortfolio(
            values.portfolioName,
            values.investmentType,
            selectedClient.id,
            values.userAccess
          )
          .then((result) => {
            if (result.id) {
              store.setClientListUpdateOn(new Date().getTime().toString());
              form.setFieldsValue({
                portfolioName: "",
                investmentType: 1,
              });
              /* setUpdateCount(updateCount); */
              refreshList();

              successMessage(t("portfolio.new_porfolio"));
              setVisible(false);
            } else errorMessage(t("portfolio.could_not_add"));
            
            props.setPortfolioLoading(false)

            props.setExpandedRows(prevState => {

              prevState = [...prevState, { name: values.portfolioName, expanded: false, id: result.id}]

              return prevState
            })
          });
    } else {
      /* console.log(values);
      return; */
      portfolioData.setName(values.portfolioName);
      portfolioData.setInvestmentType(values.investmentType);
      portfolioData.setCompanyId(selectedClient.id);
      portfolioData
        .update(
          values.portfolioName,
          values.investmentType,
          store.activeClientId,
          values.userAccess
        )
        .then((result) => {
          if (result === true) {
            form.setFieldsValue({
              portfolioName: portfolioData.name,
              investmentType: portfolioData.investmentType,
            });

            refreshList();
            console.log(portfolios.find((p) => p.id === portfolioData.id));
            successMessage(t("portfolio.porfolio_updated"));
          } else errorMessage(t("portfolio.could_not_updated"));
          
          props.setPortfolioLoading(false)
        });
    }
  };
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
    marginLeft: "10px",
  };

  const tailLayout = {
    wrapperCol: {
      offset: 6,
      span: 16,
    },
  };
  const tailLayout2 = {
    wrapperCol: {
      offset: 6,
      span: 16,
    },
  };

  const investmentTypes = store.investmentTypes;

  const investmentTypeOnChange = (e) => {
    console.log("radio checked", e.target.value);
    /*  this.setState({
      value: e.target.value,
    }); */
  };

  function userRightsOptionsOnChange(checkedValues) {
    console.log("checked = ", checkedValues);
  }

  return (
    <Modal
      destroyOnClose
      title={false}
      centered
      visible={visible}
      okText={t("")}
      closable={true}
      onCancel={() => {
        setVisible(false);
      }}
      footer={false}
      onOk={() => {
        setVisible(false);
      }}
      width={700}
    >
      <Title>
        {id < 1
          ? t("portfolio.new_portfolio")
          : t("portfolio.update_portfolio")}
      </Title>

      <Form
        form={form}
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFormFinish}
        onFinishFailed={onFormFinishFailed}
      >
        <Form.Item
          label={t("portfolio.portfolio_name")}
          name="portfolioName"
          rules={
            (
            [
              ({ getFieldValue }) => ({
                validator(_, value) {
                  
                  if (value.trim() == "") {
                    return Promise.reject(
                      t("portfolio.please_enter_portfolio")
                    );
                  }

                  /*if (!value || value.indexOf("+") > -1) {
                        return Promise.resolve();
                      }*/
                  if (value) {
                    var thisClient = store.clients.find(
                      (c) => c.id === store.activeClientId
                    );
                    if (
                      !thisClient.portfolios.find((pf) => {
                        return pf.name === value && pf.id!==id;
                      })
                    ) {
                      return Promise.resolve();
                    } else {
                      if (value.length === 0) {
                        return Promise.reject(
                          t("portfolio.please_enter_portfolio")
                        );
                      } else {
                        return Promise.reject(
                          t("portfolio.could_not_add_portfolio_exists")
                        );
                      }
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ])
          }
        >
          <Input placeholder={t("portfolio.portfolio_name")} />
        </Form.Item>
        <Form.Item
          label={t("portfolio.investment_type")}
          name="investmentType"
          rules={[
            {
              required: true,
              message: t("portfolio.please_enter_investment_type"),
            },
          ]}
        >
          <Radio.Group onChange={investmentTypeOnChange} value={1}>
            {investmentTypes.map((i) => (
              <Radio key={i.id} style={radioStyle} value={i.id}>
                {t(`portfolio.investment_types.${i.id}`)}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item label={t("portfolio.user_access")} name="userAccess">
          <Checkbox.Group onChange={userRightsOptionsOnChange}>
            {userRightsOptions.map((i) => (
              <Checkbox
                key={i.value}
                style={radioStyle}
                value={i.value}
                checked={i.hasAccess}
              >
                {i.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            type="default"
            htmlType="button"
            style={{
              display: "inline-block",
              width: "calc(50% - 16px)",
              margin: "0 8px 0",
            }}
            onClick={() => {
              setVisible(false);
            }}
          >
            {t("portfolio.dismiss")}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            style={{
              display: "inline-block",
              width: "calc(50% - 16px)",
              margin: "0 8px",
            }}
          >
            {t("portfolio.save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(PortfolioDetailModal);
