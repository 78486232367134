import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import {
  Row,
  Col,
  Button,
  Modal,
  Typography,
  Card,
  Spin,
  message,
  Popconfirm,
  Input,
} from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import axios from "axios";
import parse from "html-react-parser";
import { criteria } from "../../data/RatingCriteria";

import { RatingRow, RatingRowPerUsage, TextEditor } from "./Criteria";
import RatingCriteriaList from "../Admin/Ratings/RatingCriteriaList";
import RatingsModalController from "../../controllers/RatingsModalController";
import PropertyModel from "../../models/PropertyModel";

//translation
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const CriteriaSettings = (props) => {
  var { t } = useTranslation("common"); //translation
  const {
    setHelpDescription,
    selectedFoto,
    setSelectedFoto,
    selectedKey,
    helpTextTitle,
    setHelpTextTitle,
    helpGroup,
    helpChild,
    helpIntro,
    setHelpIntro,
    helpManual,
    setHelpManual,
    helpAutomatic,
    setHelpAutomatic,
    helpCardStyle,
    helpFoto,
    setHelpFoto,
    helpFotoGoogle,
    setHelpFotoGoogle,
    helpFotoPlan,
    setHelpFotoPlan,
    helpFotoGooglePlan,
    setHelpFotoGooglePlan,
    helpMessungen,
    setHelpMessungen,
    helpPlan,
    setHelpPlan,
    helpWebLink,
    setHelpWebLink,
    helpSonstige,
    setHelpSonstige,
    isEditing,
    setIsEditing,
    isChanged,
    setIsChanged,
    isReverted,
    setIsReverted,
  } = RatingsModalController({ criteria, t });
  // const [criteria, setCriteria] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(true);

  const { store, visible, setVisible, id, setPropertyId } = props;
  const currentProperty = id
    ? store.clients
        .find((c) => c.id === store.activeClientId)
        .portfolios.find((pf) => pf.id === store.activePortfolioId)
        .properties.find((pp) => pp.id === id)
    : PropertyModel.create({});

  useEffect(() => {
    // fetchCriteria().then((res) => {
    //   setCriteria(res);
    // });

    return () => setIsEditing(false);
  }, []);

  // const fetchCriteria = async () => {
  //   const { data } = await axios.get("/api/translation/criteria");

  //   return data;
  // };

  const updateText = (changed = false) => {
    setIsLoading(true);
    setTimeout(() => {
      const formattedDash =
        '<p class="MsoListParagraph" style="text-indent: -.25in; mso-list: l0 level2 lfo1; margin: 0in 0in 2.0pt .75in;"><!-- [if !supportLists]--><span lang="DE-CH" style="font-size: 11.0pt; font-family: RegulatorNova-Light; mso-fareast-font-family: RegulatorNova-Light; mso-bidi-font-family: RegulatorNova-Light; mso-ansi-language: DE-CH;"><span style="mso-list: Ignore;">-<span style="font: 7.0pt \'Times New Roman\';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="DE-CH" style="font-size: 9.0pt; font-family: RegulatorNova-Light; mso-ansi-language: DE-CH;"> </span></p>';
      if (changed) {
        if (selectedFoto === "foto" || !selectedFoto) {
          setHelpFotoGoogle("");
          setHelpFotoGooglePlan("");
          setHelpFotoPlan("");
        } else if (selectedFoto === "fotoorgoogle") {
          setHelpFoto("");
          setHelpFotoGooglePlan("");
          setHelpFotoPlan("");
        } else if (selectedFoto === "fotoorgoogleorplan") {
          setHelpFoto("");
          setHelpFotoGoogle("");
          setHelpFotoPlan("");
        } else if (selectedFoto === "fotoorplan") {
          setHelpFoto("");
          setHelpFotoGoogle("");
          setHelpFotoGooglePlan("");
        }

        if (helpIntro === "") setHelpIntro(formattedDash);
        if (helpManual === "") setHelpManual(formattedDash);
        if (helpAutomatic === "") setHelpAutomatic(formattedDash);
        if (
          helpFoto === "" &&
          helpFotoGoogle === "" &&
          helpFotoGooglePlan === "" &&
          helpFotoPlan === ""
        )
          setHelpFoto(formattedDash);
        if (helpMessungen === "") setHelpMessungen(formattedDash);
        if (helpPlan === "") setHelpPlan(formattedDash);
        if (helpWebLink === "") setHelpWebLink(formattedDash);
        if (helpSonstige === "") setHelpSonstige(formattedDash);
      }
      setIsEditing(!isEditing);
      setIsLoading(false);
      setIsChanged(changed);
    }, 300);
  };

  const saveChanges = async (
    title,
    help,
    manual,
    automatic,
    foto,
    fotoorgoogle,
    fotoorgoogleorplan,
    fotoorplan,
    plan,
    messungen,
    weblink,
    sonstige
  ) => {
    const { data } = await axios.post(
      `/api/translation/criteria/${localStorage.getItem("lang")}`,
      {
        key: selectedKey,
        title: `${helpChild ? helpChild : title}`,
        help,
        manual,
        automatic,
        foto,
        fotoorgoogle,
        fotoorgoogleorplan,
        fotoorplan,
        plan,
        messungen,
        weblink,
        sonstige,
      }
    );

    if (data.success) {
      message.success(t(`${data.message}`));
      setIsSaved(true);
      setIsChanged(false);
    }
  };
  const revertChanges = async () => {
    const { data } = await axios.post(
      `/api/translation/criteria/revert/${localStorage.getItem("lang")}`
    );
    if (data.success) {
      message.success(t(`${data.message}`));
      setIsReverted(true);
    } else {
      message.error(t(`${data.message}`));
    }
  };

  const groupsHeaderStyle = ["t1", "t2", "t3"];

  return (
    <Modal
      style={{ maxHeight: "100vh" }}
      destroyOnClose
      key={"actualRating"}
      width={1600}
      title={false}
      centered
      visible={visible}
      okText={t("admin_rating.submit")}
      closable={true}
      onCancel={() => {
        setVisible(false);
        if (setPropertyId) setPropertyId(0);
      }}
      footer={false}
    >
      <Row type="flex" align="left">
        <Title
          style={{
            display: "block !important",
            textAlign: "left",
            width: "100%",
          }}
          level={3}
        >
          {currentProperty.id > 0 ? currentProperty.name + " : " : ""}
          {t("AppSettings_#CriteriaSettings")}
        </Title>
      </Row>
      <Row gutter={16}>
        <Col span={13}>
          <Scrollbars
            style={{ height: 700 }}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
          >
            <div
              className="ant-table ant-table-small ant-table-bordered"
              style={{
                borderRight: "solid 1px rgb(240,240,240)",
              }}
            >
              <div className="ant-table-container">
                <table style={{ tableLayout: "auto" }}>
                  <colgroup>
                    <col style={{ width: "50px" }}></col>
                    <col style={{ width: "300px" }}></col>
                    <col style={{ width: "175px" }}></col>
                  </colgroup>
                  {criteria &&
                    criteria.map((group, i) => {
                      return (
                        <React.Fragment key={group.groupName}>
                          <thead
                            key={group.groupName + "_thead"}
                            className={
                              "ant-table-thead " + groupsHeaderStyle[i]
                            }
                          >
                            <tr key={group.groupName + "_tr"}>
                              <th
                                className="ant-table-cell"
                                align={"center"}
                                colSpan={2}
                              >
                                <Title level={4} style={{ margin: "0px" }}>
                                  {t("criteria.group." + i.toString())}
                                </Title>
                              </th>
                              <th
                                className="ant-table-cell"
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "nowrap",
                                }}
                              ></th>
                              <th
                                className="ant-table-cell"
                                align={"center"}
                                colSpan={2}
                              ></th>
                            </tr>
                          </thead>
                          <tbody
                            key={group.groupName + "_tbody"}
                            className={
                              "ant-table-tbody " + groupsHeaderStyle[i]
                            }
                          >
                            {group.children.map((criteria, cindex) => {
                              return criteria.children ? (
                                <RatingRowPerUsage
                                  criteria={criteria}
                                  key={criteria.key}
                                  setHelpDescription={setHelpDescription}
                                  groupName={group.groupName}
                                  index={cindex}
                                  currentProperty={currentProperty}
                                ></RatingRowPerUsage>
                              ) : (
                                <RatingRow
                                  criteria={criteria}
                                  key={criteria.key}
                                  setHelpDescription={setHelpDescription}
                                  groupName={group.groupName}
                                  index={cindex}
                                ></RatingRow>
                              );
                            })}
                          </tbody>
                        </React.Fragment>
                      );
                    })}
                </table>
              </div>
            </div>
          </Scrollbars>
        </Col>
        <Col span={11}>
          <Row>
            <Col flex={"auto"}>
              <Card
                title={
                  isEditing ? (
                    <Input
                      size="small"
                      style={{ width: "500px" }}
                      disabled={helpChild}
                      addonBefore={helpGroup}
                      addonAfter={helpChild && helpChild}
                      onChange={(e) => {
                        setHelpTextTitle(e.target.value);
                      }}
                      value={helpTextTitle}
                    />
                  ) : (
                    helpGroup &&
                    parse(
                      `<strong class="${helpCardStyle}">${helpGroup}</strong> ${helpTextTitle} ${
                        helpChild && `- ${helpChild.toLocaleUpperCase()}`
                      }`
                    )
                  )
                }
                extra={
                  helpTextTitle && (
                    <Button
                      onClick={() => updateText(isEditing && true)}
                      type="secondary"
                      size="small"
                      className={helpCardStyle}
                    >
                      {isEditing
                        ? t("CriteriaSettings_#Confirm")
                        : t("CriteriaSettings_#UpdateText")}
                    </Button>
                  )
                }
                className={`${helpCardStyle}-criteria`}
              >
                <Spin spinning={isLoading}>
                  <Scrollbars
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    autoHeight
                    autoHeightMin={100}
                    autoHeightMax={550}
                  >
                    {isEditing ? (
                      <TextEditor
                        isEditing={isEditing}
                        setHelpIntro={setHelpIntro}
                        helpIntro={helpIntro}
                        setHelpManual={setHelpManual}
                        helpManual={helpManual}
                        setHelpAutomatic={setHelpAutomatic}
                        helpAutomatic={helpAutomatic}
                        setHelpFoto={setHelpFoto}
                        helpFoto={helpFoto}
                        setHelpFotoGoogle={setHelpFotoGoogle}
                        helpFotoGoogle={helpFotoGoogle}
                        setHelpFotoPlan={setHelpFotoPlan}
                        helpFotoPlan={helpFotoPlan}
                        setHelpFotoGooglePlan={setHelpFotoGooglePlan}
                        helpFotoGooglePlan={helpFotoGooglePlan}
                        setHelpPlan={setHelpPlan}
                        helpPlan={helpPlan}
                        setHelpMessungen={setHelpMessungen}
                        helpMessungen={helpMessungen}
                        setHelpWebLink={setHelpWebLink}
                        helpWebLink={helpWebLink}
                        setHelpSonstige={setHelpSonstige}
                        helpSonstige={helpSonstige}
                        setSelectedFoto={setSelectedFoto}
                        selectedFoto={selectedFoto}
                      />
                    ) : (
                      <RatingCriteriaList
                        helpIntro={helpIntro}
                        helpManual={helpManual}
                        helpAutomatic={helpAutomatic}
                        helpFoto={helpFoto}
                        helpFotoGoogle={helpFotoGoogle}
                        helpFotoPlan={helpFotoPlan}
                        helpFotoGooglePlan={helpFotoGooglePlan}
                        helpPlan={helpPlan}
                        helpMessungen={helpMessungen}
                        helpWebLink={helpWebLink}
                        helpSonstige={helpSonstige}
                      />
                    )}
                  </Scrollbars>
                </Spin>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}></Col>
        <Col span={8}>
          <div align={"right"} style={{ margin: "20px" }}>
            <Button
              type="default"
              htmlType="button"
              style={{
                display: "inline-block",
                width: "calc(33% - 16px)",
                margin: "0 8px 0",
              }}
              onClick={() => {
                setVisible(false);
                if (setPropertyId) setPropertyId(0);
              }}
            >
              {t("admin_rating.close")}
            </Button>
            {!isEditing && !isReverted ? (
              <Popconfirm
                title={t("CriteriaSettings_#UndoSave?")}
                onConfirm={() => {
                  revertChanges();
                }}
              >
                <Button
                  type="default"
                  htmlType="button"
                  style={{
                    display: "inline-block",
                    width: "calc(33% - 16px)",
                    margin: "0 8px 0",
                  }}
                >
                  {t("CriteriaSettings_#UndoSave")}
                </Button>
              </Popconfirm>
            ) : (
              <Button
                type="default"
                htmlType="button"
                style={{
                  display: "inline-block",
                  width: "calc(33% - 16px)",
                  margin: "0 8px 0",
                }}
                disabled={true}
              >
                {t("CriteriaSettings_#UndoSave")}
              </Button>
            )}

            {!isSaved || isChanged ? (
              <Popconfirm
                title={t("CriteriaSettings_#SaveChanges?")}
                onConfirm={() => {
                  saveChanges(
                    helpTextTitle,
                    helpIntro,
                    helpManual,
                    helpAutomatic,
                    helpFoto,
                    helpFotoGoogle,
                    helpFotoGooglePlan,
                    helpFotoPlan,
                    helpPlan,
                    helpMessungen,
                    helpWebLink,
                    helpSonstige
                  );
                }}
              >
                <Button
                  type="primary"
                  style={{
                    display: "inline-block",
                    width: "calc(33% - 16px)",
                    margin: "0 8px",
                  }}
                >
                  {t("CriteriaSettings_#Save")}
                </Button>
              </Popconfirm>
            ) : (
              <Button
                type="primary"
                disabled={true}
                style={{
                  display: "inline-block",
                  width: "calc(33% - 16px)",
                  margin: "0 8px",
                }}
              >
                {t("CriteriaSettings_#Save")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default observer(CriteriaSettings);
