//ClientPropertyDetailDrawer
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { getPropertyRating } from "../../../data/RatingCriteria";
import {
  // Layout,
  Row,
  Col,
  Card,
  Typography,
  // Form,
  // Input,
  Button,
  // Checkbox,
  // Switch,
  // AutoComplete,
  Tooltip,
  Space,
  Table,
  // Modal,
  Drawer,
  Descriptions,
  // message,
  Statistic,
} from "antd";
import PropertyModel from "../../../models/PropertyModel";
import { getAgeText } from "../../../data/AddressBook";

import ClientPropertyDetailModal from "./ClientPropertyDetailModal";
import ActualRatingsModal from "../Ratings/ActualRatingsModal";
import PotentialRatingsModal from "../Ratings/PotentialRatingsModal";
import PropertyAuditList from "../../Audit/PropertyAuditList";
//translation
import { useTranslation } from "react-i18next";

const { Title } = Typography;
function ClientPropertyDetailDrawer(props) {
  var { t } = useTranslation("common"); //translation

  const {
    store,
    visible,
    setVisible,

    id,
    setPropertyId,
    clientId,
    portfolioId,
    refreshList,
    properties,
    getCantonName,
  } = props;

  const [currentProperty, setCurrentProperty] = useState(
    PropertyModel.create({})
  );
  const [AuditLogs, setAuditLogs] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const [popConfirmRatingvisible, setPopConfirmRatingvisible] = useState(false);

  useEffect(() => {
    if (id === 0) {
      setCurrentProperty(PropertyModel.create({}));
    } else {
      setCurrentProperty(properties.find((p) => p.id === id));
    }
    return () => {
      setCurrentProperty(PropertyModel.create({}));
    };
  }, [props.id]);

  // const refreshDetail = () => {
  //   setTimeout(() => {
  //     setCurrentProperty(properties.find((p) => p.id === id));
  //   }, 1000);
  // };

  const propertyModalProps = {
    store,
    visible: modalVisible,
    setVisible: setModalVisible,
    id,
    /*  selectedProperty: currentProperty, */
    clientId,
    portfolioId,
    selectedPortfolio: store.portfolios.find((pf) => pf.id === portfolioId),
    refreshList,
    popConfirmRatingvisible,
    setPopConfirmRatingvisible,
    properties,
    getCantonName,
  };
  const [actualRatingsModalVisible, setActualRatingsModalVisible] =
    useState(false);
  const [potentialRatingsModalVisible, setPotentialRatingsModalVisible] =
    useState(false);
  const actualRatingsModalProps = {
    store,
    /* id:currentProperty.id, */
    currentProperty,

    visible: actualRatingsModalVisible,
    setVisible: setActualRatingsModalVisible,
    setPropertyId,
  };
  const potentialRatingsModalProps = {
    store,
    /*  id:currentProperty.id, */
    currentProperty,

    visible: potentialRatingsModalVisible,
    setVisible: setPotentialRatingsModalVisible,
  };
  const [areas, setAreas] = useState([
    {
      areaOffice: 0,
      areaResidential: 0,
      areaCommercial: 0,
      areaHealth: 0,
      areaTotal: 0,
      areaTotal: 0,
    },
  ]);

  const areaOffice = currentProperty.areaOffice || 0;
  const areaResidential = currentProperty.areaResidential || 0;
  const areaCommercial = currentProperty.areaCommercial || 0;
  const areaBuilding = currentProperty.areaBuilding || 0;
  const areaHealth = currentProperty.areaHealth || 0;
  useEffect(() => {
    setAreas([
      {
        areaOffice: currentProperty.areaOffice,
        areaResidential: currentProperty.areaResidential,
        areaCommercial: currentProperty.areaCommercial,
        areaBuilding: currentProperty.areaBuilding,
        areaHealth: currentProperty.areaHealth,
        areaTotal:
          currentProperty.areaOffice +
          currentProperty.areaResidential +
          currentProperty.areaCommercial +
          currentProperty.areaBuilding +
          currentProperty.areaHealth,
      },
    ]);
  }, [
    /* currentProperty,
    currentProperty.areaOffice,
    currentProperty.areaResidential,
    currentProperty.areaCommercial ,*/
    areaOffice,
    areaResidential,
    areaCommercial,
    areaBuilding,
    areaHealth,
  ]);

  const [computedAge, setComputedAge] = useState(0);
  useEffect(() => {
    setComputedAge(getAgeText(currentProperty.age, t));
  }, [currentProperty.age]);

  useEffect(() => {
    if (currentProperty && currentProperty.id > 0) {
      currentProperty.getAuditLogs().then((s) => {
        //sort desc, recent first
        setAuditLogs(
          currentProperty.auditLogs.sort(
            (a, b) =>
              new Date(b.auditDate).getTime() - new Date(a.auditDate).getTime()
          )
        );
      });
    }
    return () => {
      setAuditLogs([]);
    };
  }, [currentProperty, store.clientListUpdateOn]);

  const areaUsageColumns = [
    {
      title: t("client_portfolio.wohnen"),
      dataIndex: "areaResidential",
      key: "areaResidential",
      align: "center",
      width: "16.7%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaResidential).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
    {
      title: t("client_portfolio.büro"),
      dataIndex: "areaOffice",
      key: "areaOffice",
      align: "center",
      width: "16.7%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaOffice).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
    {
      title: t("client_portfolio.gewerbe"),
      dataIndex: "areaCommercial",
      key: "areaCommercial",
      align: "center",
      width: "16.7%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaCommercial).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
    {
      title: t("client_property.bildung"),
      dataIndex: "areaBuilding",
      key: "areaBuilding",
      align: "center",
      width: "16.7%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaBuilding).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
    {
      title: t("client_property.gesundheit"),
      dataIndex: "areaHealth",
      key: "areaHealth",
      align: "center",
      width: "16.7%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaHealth).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
    {
      title: t("client_portfolio.total"),
      dataIndex: "areaTotal",
      key: "areaTotal",
      align: "center",
      width: "16.7%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaTotal).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!modalVisible) {
      // console.log(modalVisible);
      setPopConfirmRatingvisible(false);
    }
  }, [modalVisible]);
  return (
    <>
      <Drawer
        destroyOnClose
        title={false}
        placement="right"
        closable={true}
        visible={visible}
        getContainer={true}
        style={{ position: "absolute" }}
        width={"calc(100% - 300px)"}
        onClose={() => {
          setVisible(false);
          setCurrentProperty(PropertyModel.create({}));
        }}
      >
        <Row>
          <Col span={24}>
            <Title
              style={{
                display: "block !important",
                textAlign: "left",
                width: "100%",
              }}
              level={3}
            >
              {t("client_portfolio.property")} {currentProperty.name}
            </Title>
          </Col>
        </Row>
        <Row style={{ textAlign: "left", marginBottom: "16px" }}>
          <Col span={24}>
            <Space>
              {/* <Title level={4}>{selectedClient ? selectedClient.name : ""}</Title> */}

              {/*  <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  console.log("Remove Property");
                }}
              >
                {t("client_portfolio.remove")}
              </Button> */}
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  setActualRatingsModalVisible(true);
                }}
              >
                {t("client_portfolio.view_actual_ratings")}
              </Button>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  setPotentialRatingsModalVisible(true);
                }}
              >
                {t("client_portfolio.view_potential_ratings")}
              </Button>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                {t("client_portfolio.edit")}
              </Button>
            </Space>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col flex={"auto}"} span={18}>
            <Descriptions
              bordered
              size={"default"}
              className={"description-label-fix"}
            >
              {/* <Descriptions.Item label="Name" span={3}>
              {currentProperty.name}
            </Descriptions.Item> */}
              <Descriptions.Item label={t("client_portfolio.address")} span={3}>
                {currentProperty.address}
              </Descriptions.Item>

              <Descriptions.Item label={t("client_portfolio.plz")} span={1}>
                {currentProperty.plz}
              </Descriptions.Item>
              <Descriptions.Item label={t("client_portfolio.city")} span={2}>
                {currentProperty.city}
              </Descriptions.Item>
              <Descriptions.Item label={t("client_portfolio.canton")} span={1}>
                {currentProperty.canton}
              </Descriptions.Item>
              <Descriptions.Item label={t("client_portfolio.region")} span={1}>
                {currentProperty.region}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Tooltip title={t("client_property.year_about")}>
                    {t("client_property.year")}
                  </Tooltip>
                }
                span={1}
              >
                <Tooltip title={currentProperty.age == 0 ? "?" : computedAge}>
                  {currentProperty.age == 0 ? "?" : currentProperty.age}
                </Tooltip>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col flex={"auto}"} span={4}>
            <Card>
              <Statistic
                title={t("client_portfolio.rating")}
                value={(
                  Math.round(
                    parseFloat(getPropertyRating(currentProperty)) * 100
                  ) / 100
                ).toFixed(2)}
                precision={2}
                valueStyle={{ zoom: 2.4, textAlign: "center" }}
              />
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "40px" }}>
          <Col flex={"auto}"} span={18}>
            {/* <Descriptions title="Area per Usage Type" bordered>
            <Descriptions.Item label="Wohnen" span={1}>
              {currentProperty.areaOffice} m<sup>2</sup>
            </Descriptions.Item>

            <Descriptions.Item label="Büro" span={1}>
              {currentProperty.areaResidential} m<sup>2</sup>
            </Descriptions.Item>
            <Descriptions.Item label="Gewerbe" span={1}>
              {currentProperty.areaCommercial} m<sup>2</sup>
            </Descriptions.Item>
            <Descriptions.Item label="Total" span={1}>
              {currentProperty.areaOffice +
                currentProperty.areaResidential +
                currentProperty.areaCommercial}{" "}
              m<sup>2</sup>
            </Descriptions.Item>
          </Descriptions> */}
            <Title level={4} style={{ margin: "10px" }}>
              {t("client_portfolio.area_per_usage_type")}
            </Title>
            <Table
              title={false}
              dataSource={areas}
              columns={areaUsageColumns}
              size={"middle"}
              bordered="true"
              pagination={false}
              rowKey={"areaTotal"}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "40px" }}>
          <Col flex={"auto}"} span={18}>
            <Title level={4} style={{ margin: "10px" }}>
              {t("client_portfolio.audit_history")}
            </Title>
            <PropertyAuditList AuditLogs={AuditLogs}></PropertyAuditList>
          </Col>
        </Row>
      </Drawer>
      <ClientPropertyDetailModal
        {...propertyModalProps}
      ></ClientPropertyDetailModal>
      <ActualRatingsModal {...actualRatingsModalProps}></ActualRatingsModal>
      <PotentialRatingsModal
        {...potentialRatingsModalProps}
      ></PotentialRatingsModal>
    </>
  );
}

export default observer(ClientPropertyDetailDrawer);
