import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import {
  Row,
  Col,
  Modal,
  Typography,
  Button,
  Checkbox,
  message,
  Form,
  Select,
  TreeSelect,
  Spin,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import FileSaver from "file-saver";

import moment from "moment";

//translation
import { useTranslation } from "react-i18next";
const { Title } = Typography;
const { Option } = Select;

const CheckboxGroup = Checkbox.Group;

// const indexOptions = ['Verifiziert', 'Nicht verifiziert']

const defaultIndex = [];

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

function DownloadExcelModal(props) {
  const forceUpdate = useForceUpdate();
  const { visible, setVisible, store } = props;
  const { t } = useTranslation("common"); //translation
  const user = props.store.login;
  const company = props.store.company;
  const [downloadingCSV, setDownloadingState] = useState(false);

  const [AvailablePeriods, setAvailablePeriods] = useState([]);
  const [selectedPeriods, setSelectedPeriods] = useState([]);

  const [checkedList, setCheckedList] = useState(defaultIndex);

  const [selectedPortfolios, setSelectedPortfolios] = useState(
    store.downloadPortfolios.map((portfolio) => {
      return portfolio.id;
    })
  );

  const indexOptions = [
    { label: t("Verifiziert"), value: "Verifiziert" },
    { label: t("Nicht verifiziert"), value: "Nicht verifiziert" },
  ];

  const [form] = Form.useForm();

  const [PeriodsLoading, setPeriodsLoading] = useState(true);

  useEffect(() => {
    store.getDownloadPortfolios();
    // console.log("object");
  }, [visible]);

  useEffect(() => {
    if (visible) {
      setPeriodsLoading(true);
      axios({
        method: "post",
        url: "/api/exports/periods",
        responseType: "json",
        data: {},
      })
        .then((result) => {
          if (result.status == 200) {
            if (result.data.periods) {
              setAvailablePeriods(
                result.data.periods.map((x) => {
                  return {
                    date: x.month + "." + x.year,
                    portfolio_id: x.portfolio_id,
                  };
                })
              );
            }
          }

          setPeriodsLoading(false);
        })
        .catch((err) => {
          setVisible(false);
          setPeriodsLoading(false);
        });
    }
  }, [visible]);

  const downloadCSV = async () => {
    const _indexSelection = {
      indexed: checkedList.includes("Verifiziert"),
      nonIndexed: checkedList.includes("Nicht verifiziert"),
    };

    if (!_indexSelection.indexed && !_indexSelection.nonIndexed) {
      message.error(t("Wählen Sie Verifiziert oder Nicht verifiziert"));
      return;
    }
    if (selectedPortfolios.length == 0) {
      message.error(t("client_users.select_portfolios"));
      return;
    }
    if (selectedPeriods.length == 0 && _indexSelection.indexed) {
      message.error(t("Verifiziert ist aktiviert, bitte wählen Sie ein Datum"));
      return;
    }

    // const _ps = selectedPortfolios.filter((_p) => _p !== "-0");

    // if (_ps.length === 1) {
    //   let _noCount = false;

    //   for (const _pPs of _ps) {
    //     const _countHigh = await axios.get(
    //       `/api/properties/check/available-ratings/${_pPs}`
    //     );
    //     console.log("ps", _countHigh);
    //     if (_countHigh.data.error) {
    //       message.error("No Property Ratings found");
    //       _noCount = true;
    //     }
    //   }

    //   if (_noCount) return;
    // }

    try {
      setDownloadingState(true);

      const _ps = selectedPortfolios.filter((_p) => _p !== "-0");

      const res = await axios({
        method: "post",
        url: "/api/exports",
        responseType: "arraybuffer",
        data: {
          company,
          user,
          lang: store.language,
          periods: selectedPeriods,
          selectedPortfolios: _ps,
          indexSelection: {
            indexed: checkedList.includes("Verifiziert"),
            nonIndexed: checkedList.includes("Nicht verifiziert"),
          },
        },
      });

      if (res.status === 200) {
        const tempName = res.headers["content-disposition"];
        const fileName = tempName.split('"')[1];

        const blob = new Blob([res.data], {
          type: "application/octet-stream",
        });

        FileSaver.saveAs(blob, fileName);

        message.success(t("protected_route.download_successful"));
      } else {
        message.error(t("protected_route.download_failed"));
        setDownloadingState(false);
      }
      setDownloadingState(false);
    } catch (error) {
      message.error(t("protected_route.download_failed"));
      setDownloadingState(false);
    }
  };

  const onFormFinish = (values) => {
    downloadCSV();
  };

  const onFormFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFormValuesChange = (changedValues, allValues) => {
    if (changedValues.periods) {
      setSelectedPeriods(allValues.periods);
    }
    if (changedValues.portfolios) {
      if (changedValues.portfolios.includes("-0")) {
        const _indexSelection = {
          indexed: checkedList.includes("Verifiziert") ? 1 : 0,
          nonIndexed: checkedList.includes("Nicht verifiziert") ? 1 : 0,
        };

        const _ps = store.downloadPortfolios.reduce((_arr, portfolio) => {
          if (
            _indexSelection.indexed === 1 &&
            _indexSelection.nonIndexed === 0
          ) {
            if (portfolio.indexStatus === 1) {
              _arr = [
                ..._arr,
                {
                  title: portfolio.name,
                  value: portfolio.id,
                  indexStatus: portfolio.indexStatus,
                },
              ];
            }
          }

          if (_indexSelection.nonIndexed === 1) {
            _arr = [
              ..._arr,
              {
                title: portfolio.name,
                value: portfolio.id,
                indexStatus: portfolio.indexStatus,
              },
            ];
          }

          return _arr;
        }, []);

        setSelectedPortfolios(_ps);
      } else {
        setSelectedPortfolios(allValues.portfolios);
      }
    }
  };

  const treeSelectDataPeriods = () => {
    let arrayPeriodsSelection = AvailablePeriods.reduce((_a, _pr) => {
      if (selectedPortfolios.includes(_pr.portfolio_id)) {
        return [
          ..._a,
          {
            title:
              t("protected_route.m" + _pr.date.split(".")[0]) +
              " " +
              _pr.date.split(".")[1],
            value: _pr.date,
          },
        ];
      }

      return _a;
    }, []);

    const _arrayPeriodsSelection = [
      ...new Map(
        arrayPeriodsSelection.map((item) => [`${item["value"]}`, item])
      ).values(),
    ];
    return _arrayPeriodsSelection;
  };

  const onChangeSelectedPortfolios = (value, label, extra) => {
    if (store.downloadPortfolios.length === 0) return;

    if (value.includes("-0")) {
      const _indexSelection = {
        indexed: checkedList.includes("Verifiziert") ? 1 : 0,
        nonIndexed: checkedList.includes("Nicht verifiziert") ? 1 : 0,
      };

      const _ps = store.downloadPortfolios.reduce((_arr, portfolio) => {
        if (_indexSelection.indexed === 1 && _indexSelection.nonIndexed === 0) {
          if (portfolio.indexStatus === 1) {
            _arr = [
              ..._arr,
              {
                title: portfolio.name,
                value: portfolio.id,
                indexStatus: portfolio.indexStatus,
              },
            ];
          }
        }

        if (_indexSelection.nonIndexed === 1) {
          _arr = [
            ..._arr,
            {
              title: portfolio.name,
              value: portfolio.id,
              indexStatus: portfolio.indexStatus,
            },
          ];
        }

        return _arr;
      }, []);

      const _sp = store.downloadSnapshots.reduce((_arr, portfolio) => {
        if (_indexSelection.indexed === 1 && _indexSelection.nonIndexed === 0) {
          // console.log(_arr, "arrsnaps");
          if (portfolio.indexStatus === 1) {
            _arr = [
              ..._arr,
              {
                title: portfolio.name,
                value: portfolio.id,
                indexStatus: portfolio.indexStatus,
              },
            ];
          }
        }

        if (_indexSelection.nonIndexed === 1) {
          _arr = [
            ..._arr,
            {
              title: portfolio.name,
              value: portfolio.id,
              indexStatus: portfolio.indexStatus,
            },
          ];
        }

        return _arr;
      }, []);

      let _results = [..._ps, ..._sp];
      const res = _results.filter(
        (result, index, self) =>
          index === self.findIndex((r) => r.value === result.value)
      );

      form.setFieldsValue({ portfolios: res });

      setSelectedPortfolios((prevState) => {
        prevState = _ps.map((__ps) => __ps.value);

        return prevState;
      });
    } else {
      setSelectedPortfolios((prevState) => {
        return [...value];
      });
    }

    form.setFieldsValue({ periods: [] });
    setSelectedPeriods([]);

    // const filteredAvailblePeriods = AvailablePeriods.filter(
    //   (e) => e.portfolio_id === extra.triggerValue
    // );

    // const dateFilteredAvailblePeriods = filteredAvailblePeriods.map((e) => {
    //   return e.date;
    // });
    // const newselectedPeriods = [...selectedPeriods];

    // dateFilteredAvailblePeriods.map((e, i) => {
    //   const index = newselectedPeriods.indexOf(e);
    //   if (index > -1) {
    //     return newselectedPeriods.splice(index, 1);
    //   }
    // });

    // setSelectedPeriods(newselectedPeriods);
    // if (selectedPeriods.includes(moment().format("L")))
    //   form.setFieldsValue({
    //     periods: [...newselectedPeriods, moment().format("L")],
    //     portfolios: _ps,
    //   });
    // if (!selectedPeriods.includes(moment().format("L")))
  };

  const onIndexSelectionChange = (list) => {
    form.resetFields();

    setSelectedPeriods([]);
    setSelectedPortfolios([]);

    setCheckedList(list);
  };

  return (
    <Modal
      title={false}
      centered
      visible={visible}
      okText={false}
      closable={true}
      onCancel={() => {
        if (!downloadingCSV) {
          setVisible(false);
          setVisible(false);
          setSelectedPeriods([]);
          form.resetFields();
          setSelectedPortfolios([]);
        }
      }}
      footer={false}
      onOk={() => {
        setVisible(false);
      }}
      width={700}
    >
      <Title>{t("protected_route.title")}</Title>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{ period: undefined }}
        onFinish={onFormFinish}
        onFinishFailed={onFormFinishFailed}
        onValuesChange={onFormValuesChange}
        preserve={false}
      >
        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Form.Item style={{ marginLeft: "20px" }} labelCol={24}>
              <CheckboxGroup
                options={indexOptions}
                value={checkedList}
                onChange={onIndexSelectionChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              style={{ marginLeft: "20px" }}
              labelCol={24}
              label={t("protected_route.portfolios")}
              name="portfolios"
            >
              <TreeSelect
                className={"treeselect"}
                allowClear
                treeData={[
                  {
                    title: t("Alle auswählen"),
                    value: "-0",
                    key: "-0",
                  },
                  ...store.checkboxOptionsPortfolio(checkedList),
                ]}
                treeCheckable={true}
                onChange={onChangeSelectedPortfolios}
                // defaultValue={selectedPortfolios}
                filterTreeNode={(search, item) => {
                  let _clients = store.clients.map((x) => ({
                    companyName: x.name,
                    companyId: x.id,
                  }));
                  let _searchClients = _clients.filter(
                    (x) =>
                      x.companyName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) >= 0
                  );
                  let _searchClientIds = _searchClients
                    .filter((y) => y.companyId === item.companyId)
                    .map((z) => z.companyId);

                  return (
                    item.title.toLowerCase().indexOf(search.toLowerCase()) >=
                      0 || _searchClientIds.includes(item.companyId)
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {checkedList.includes("Verifiziert") ? (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                style={{ marginLeft: "20px" }}
                labelCol={24}
                // allowClear
                label={`${t("protected_route.select_periods")} (Verifiziert)`}
                name="periods"
              >
                {PeriodsLoading ? (
                  <Spin size="small" />
                ) : AvailablePeriods.length !== 0 ? (
                  <TreeSelect
                    className={"treeselect"}
                    treeData={treeSelectDataPeriods()}
                    treeCheckable={true}
                    filterTreeNode={(search, item) => {
                      return (
                        item.title
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                      );
                    }}
                  />
                ) : (
                  <TreeSelect className={"treeselect"} treeCheckable={true} />
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row gutter={[16, 0]} style={{ marginTop: "10px" }}>
          <Col span={4}></Col>
          <Col span={20}>
            <Form.Item>
              <Button
                type="default"
                htmlType="button"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 16px)",
                  margin: "0 8px 0",
                }}
                onClick={() => {
                  if (!downloadingCSV) {
                    setVisible(false);
                    setSelectedPeriods([]);
                    form.resetFields();
                    setSelectedPortfolios([]);
                  }
                }}
              >
                {t("protected_route.cancel")}
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 16px)",
                  margin: "0 8px",
                }}
                icon={<DownloadOutlined />}
                loading={downloadingCSV}
              >
                {t("protected_route.download")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default inject("store")(observer(DownloadExcelModal));
