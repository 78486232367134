import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import ClientJS from "clientjs";

import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  // Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  Menu,
  Dropdown,
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  Modal,
} from "antd";

import { UserOutlined } from "@ant-design/icons";

//translation
import { useTranslation } from "react-i18next";
//Flag
import engFlag from "../../brand/flag/eng-flag.png";
import germanFlag from "../../brand/flag/ger-flag.png";
// import italyFlag from '../../brand/flag/italy-flag.png'
import franceFlag from "../../brand/flag/france-flag.png";

//const { Header, Footer, Sider, Content } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

/* const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}; */

function Login(props) {
  const { t, i18n } = useTranslation("common");
  const { setLanguage, language } = props.store;

  const [IsReady, setIsReady] = useState(false);
  const [IsFormVisible, setIsFormVisible] = useState(false);

  const [Message, setMessage] = useState("");
  const [DeviceFingerPrint, setDeviceFingerPrint] = useState("");
  const [isOTPVisible, setIsOTPVisible] = useState(false);
  const [flag, setFlag] = useState(localStorage.getItem("lang") || "de");

  const [form] = Form.useForm();
  //const [otpform] = Form.useForm();

  //for flags
  useEffect(() => {
    if (language === "en") {
      setFlag(engFlag);
    }
    if (language === "de") {
      setFlag(germanFlag);
    }
    if (language === "fr") {
      setFlag(franceFlag);
    }

    return () => {};
  }, [language]);

  useEffect(() => {
    const client = new ClientJS();
    const windowClient = new window.ClientJS();

    //const fingerPrint = windowClient.getFingerprint()

    var ua = windowClient.getBrowserData().ua;
    var canvasPrint = windowClient.getCanvasPrint();
    setIsOTPVisible(false);
    var fingerprint = windowClient
      .getCustomFingerprint(ua, canvasPrint)
      .toString();
    setDeviceFingerPrint(fingerprint);

    setTimeout(() => {
      setIsReady(true);
      setTimeout(() => {
        setIsFormVisible(true);
        setMessage(t("login.enter_your_email_and_pass_msg"));
      }, 400);
    }, 500);

    return () => {
      setMessage("");
      setIsOTPVisible(false);
    };
  }, []);

  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/" } };

  const user = props.store.login;
  const [email, setEmail] = useState(user.email);
  const [password, setpassword] = useState("");
  const [remember, setremember] = useState(false);

  const [IsLoginMode, setIsLoginMode] = useState(true);

  const showLoginForm = () => {
    setMessage(t("login.enter_your_email_and_pass_msg"));
    setIsLoginMode(true);
  };
  const showResetForm = () => {
    setIsLoginMode(false);
    setMessage(t.apply("login.change_your_pass_msg"));
  };

  const logout = () => {
    user.logout("test", "pwd").then(() => {
      history.push("/");
    });
  };

  const resetpassword = () => {
    history.push("/resetpassword");
  };

  const onLoginFinish = (values) => {
    //console.log("Success:", values); //{email: "valerie", password: "12345678$", remember: true}
    setMessage(t("login.loggin_in_msg"));
    user
      .login(
        values.email,
        values.password,
        values.remember ? 1 : 0,
        DeviceFingerPrint,
        language
      )
      .then((result) => {
        if (result.success === true) {
          //setMessage('Logging in...')
          history.push("/");
        } else {
          if (result.suspended) {
            setMessage(t("login.your_account_msg"));
            return;
          }
          if (result.newDevice === true) {
            setEmail(values.email);
            setpassword(values.password);
            setremember(values.remember ? 1 : 0);

            setMessage(t("login.using_a_new_device_msg"));
            setIsOTPVisible(true);
          } else setMessage(t("login.invalid_email_pass_msg"));
        }
      });
  };

  const onLoginFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onOTPFinish = (values) => {
    //console.log("Success:", values); //{email: "valerie", password: "12345678$", remember: true}
    setMessage(t("login.loggin_in_msg"));
    user
      .loginOTP(
        email,
        password,
        remember ? 1 : 0,
        DeviceFingerPrint,
        values.pin
      )
      .then((result) => {
        if (result.success === true) {
          setMessage(t("login.device_registered_msg"));
          setEmail("");
          setpassword("");
          setremember(0);
          setTimeout(() => {
            history.push("/");
          }, 1000);
        } else {
          setMessage(t("login.invalid_pin_msg"));
        }
      });
  };

  const onOTPFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onResetFinish = (values) => {
    // console.log("Success:", values); //{email: "valerie", password: "12345678$", remember: true}
    setMessage(t("login.please_wait_msg"));

    user.resetPassword(values.email, language).then((success) => {
      if (success) {
        setMessage(t("login.temp_pass_send_msg"));
        //history.push("/");
        showLoginForm();
      } else {
        setMessage(t("login.could_not_send_msg"));
      }
    });
  };

  const onResetFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const changeLanguage = (locale) => {
    setLanguage(locale);
    i18n.changeLanguage(locale);
  };
  const usermenu = (
    <Menu>
      {process.env.NODE_ENV === "development" && (
        <Menu.Item
          onClick={() => {
            changeLanguage("en");
          }}
        >
          <img
            src={engFlag}
            style={{ marginRight: "5px" }}
            height="11"
            width="16"
            alt="logo"
          />
          English
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          changeLanguage("de");
        }}
      >
        <img
          src={germanFlag}
          style={{ marginRight: "5px" }}
          height="11"
          width="16"
          alt="logo"
        />{" "}
        {t("user_info.DE")}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          changeLanguage("fr");
        }}
      >
        <img
          src={franceFlag}
          style={{ marginRight: "5px" }}
          height="11"
          width="16"
          alt="logo"
        />{" "}
        {t("user_info.FR")}
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {<div className={IsReady ? "bg " : "bg cssblur"}> </div>}
      {IsFormVisible && (
        <Row type="flex" align="middle" style={{ height: "100vh" }}>
          <Col span={12} align={"middle"}>
            <img src={"/images/logo/SSREI_LOGO_400.png"}></img>
          </Col>
          <Col span={12}>
            {IsLoginMode && (
              <Card
                style={{ width: 450 }}
                className="see-thru ant-custom ant-card-dialog"
              >
                <div
                  style={{ position: "absolute", right: 5, marginTop: `-14%` }}
                >
                  <Dropdown overlay={usermenu} placement="topRight" arrow>
                    <img src={flag} height="11" width="16" alt="logo" />
                  </Dropdown>
                </div>
                <Row type="flex" align="middle">
                  <Title
                    style={{
                      display: "block !important",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {t("login.title")}
                  </Title>
                </Row>
                <div
                  style={{
                    display: "block !important",
                    textAlign: "center",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  {t(Message)}
                </div>
                <Form
                  form={form}
                  layout="vertical"
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onLoginFinish}
                  onFinishFailed={onLoginFinishFailed}
                >
                  <Form.Item
                    label={t("login.email")}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t("login.please_input_email_msg"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label={t("login.password")}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t("login.please_input_pass"),
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>{t("login.remember_me")}</Checkbox>
                  </Form.Item>

                  <Form.Item>
                    <a
                      className="login-form-forgot"
                      href="#"
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                      onClick={() => showResetForm()}
                    >
                      {t("login.forgot_password")}
                    </a>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      {t("login.submit")}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            )}
            {!IsLoginMode && (
              <Card
                style={{ width: 450 }}
                className="see-thru ant-custom ant-card-dialog"
              >
                <Row type="flex" align="middle">
                  <Title
                    style={{
                      display: "block !important",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {t("login.reset_pass")}
                  </Title>
                </Row>
                <div
                  style={{
                    display: "block !important",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <small
                    style={{
                      display: "block !important",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {" "}
                    {Message}
                  </small>
                </div>
                <Form
                  form={form}
                  layout="vertical"
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onResetFinish}
                  onFinishFailed={onResetFinishFailed}
                >
                  <Form.Item
                    label={t("login.email")}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t("login.please_input_email_msg"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item>
                    <a
                      className="login-form-forgot"
                      href="#"
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                      onClick={() => showLoginForm()}
                    >
                      {t("login.title")}
                    </a>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      {t("login.submit")}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            )}
          </Col>
        </Row>
      )}
      <Modal
        title={false}
        centered
        visible={isOTPVisible}
        okText={t("login.submit")}
        closable={false}
        footer={false}
        className={"ant-custom"}
      >
        <Form
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onOTPFinish}
          onFinishFailed={onOTPFinishFailed}
        >
          <Title level={2} style={{ textAlign: "center" }}>
            {t("login.enter_otp")}
          </Title>

          <Form.Item name="pin">
            <Input
              size="large"
              placeholder={t("login.pin_code")}
              prefix={<UserOutlined />}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="default"
              htmlType="button"
              onClick={(e) => {
                e.preventDefault();
                setIsOTPVisible(false);
              }}
              style={{ display: "inline-block", width: "calc(50% - 16px)" }}
            >
              {t("login.cancel")}
            </Button>{" "}
            <Button
              type="primary"
              htmlType="submit"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 0px 0 8px",
              }}
            >
              {t("login.submit")}
            </Button>
          </Form.Item>
          <div
            style={{
              display: "block !important",
              textAlign: "center",
              width: "100%",
            }}
          >
            {Message}
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default observer(Login);
