import React, { useState, useEffect } from "react";
import {
  Menu,
  Row,
  Col,
  Dropdown,
  Modal,
  Typography,
  Form,
  Input,
  Button,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { CriteriaSettings } from "../Settings/";
//translation
import { useTranslation } from "react-i18next";
// import de_DE from "antd/es/locale/de_DE";
// import enUS from "antd/es/locale/en_US";
// import fr_FR from "antd/es/locale/fr_FR";
// import it_IT from "antd/es/locale/it_IT";

import "moment/locale/de";
//Flag
import engFlag from "../../brand/flag/eng-flag.png";
import germanFlag from "../../brand/flag/ger-flag.png";
// import italyFlag from "../../brand/flag/italy-flag.png";
import franceFlag from "../../brand/flag/france-flag.png";

const { SubMenu } = Menu;
const { Title } = Typography;

function UserInfo(props) {
  const { user, company } = props;
  const { setLanguage, /*setLocale,*/ language } = props.store;
  const { t, i18n } = useTranslation("common");
  const logout = () => {
    user.logout();
  };
  const [showUserAccountForm, setShowUserAccountForm] = useState(false);
  const [showCriteriaSettingsModal, setShowCriteriaSettingsModal] =
    useState(false);
  const [flag, setFlag] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (language === "en") {
      setFlag(engFlag);
      // setLocale(enUS);
    }
    if (language === "de") {
      setFlag(germanFlag);
      //   setLocale(de_DE);
    }
    if (language === "fr") {
      setFlag(franceFlag);
      //  setLocale(fr_FR);
    }
    return () => {};
  }, [language]);
  useEffect(() => {
    form.setFieldsValue({
      email: user.email,
      firstName: user.firstName,

      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      mobileNumber: user.mobileNumber,
      position: user.position,
    });
    return () => {
      form.resetFields();
    };
  }, [user]);
  const onFinish = (values) => {
    // console.log("Success:", values); //{email: "valerie", password: "12345678$", remember: true}
    user
      .updateProfile(
        values.password,
        user.email,
        values.firstName,
        values.lastName,
        values.phoneNumber,
        values.mobileNumber,
        values.position,
        user.companyId
      )
      .then((success) => {
        if (success) setShowUserAccountForm(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const changeLanguage = (locale) => {
    setLanguage(locale);
    i18n.changeLanguage(locale);
    window.location.reload();
  };
  const usermenu = (
    <Menu>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShowUserAccountForm(true);
          }}
        >
          {t("user_info.acc_settings")}
        </a>
      </Menu.Item>
      {props.store.company.type === 0 && (
        <SubMenu
          title={t("AppSettings_#AppSettings")}
          // title={t("user_info.app_settings")}
        >
          <Menu.Item
            onClick={() => {
              setShowCriteriaSettingsModal(true);
            }}
          >
            {t("AppSettings_#CriteriaSettings")}
            {/* {t("user_info.criteria_settings")} */}
          </Menu.Item>
        </SubMenu>
      )}
      <SubMenu title={t("user_info.change_lang")}>
        {process.env.NODE_ENV === "development" && (
          <Menu.Item
            onClick={() => {
              changeLanguage("en");
            }}
          >
            English
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            changeLanguage("de");
          }}
        >
          {t("user_info.DE")}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            changeLanguage("fr");
          }}
        >
          {t("user_info.FR")}
        </Menu.Item>
        {/*  <Menu.Item
          onClick={() => {
            changeLanguage("it");
          }}
        >
          Italian
        </Menu.Item> */}
      </SubMenu>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
        >
          {t("user_info.logout")}
        </a>
      </Menu.Item>
    </Menu>
  );
  // const [lastName, setLastName] = useState(user.lastName);
  // const [firstName, setFirstName] = useState(user.firstName);
  // const [password1, setPassword1] = useState("");
  // const [password2, setPassword2] = useState("");
  const onChange = (e) => {
    console.log(form.getFieldValue("firstName"));
  };
  return (
    <>
      <Row className="user-info" align="middle">
        {/* <Col flex="40px">
        <Avatar
          style={{ backgroundColor: "#87d068" }}
          icon={<UserOutlined />}
        />
      </Col> */}
        <Col flex="auto">
          <Dropdown overlay={usermenu} placement="topRight" arrow>
            {/*  <Space> */}
            <Row>
              <Col flex="auto">
                <strong style={{ color: "#e76b82" }}>
                  {user.firstName + " " + user.lastName}
                </strong>
              </Col>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Col flex="16px">
                  <DownOutlined style={{ color: "#a9a4a4" }} />
                </Col>
              </a>
            </Row>
            {/*  </Space> */}
          </Dropdown>
          <small style={{ color: "#a9a4a4" }}>{user.position}</small> <br />
          <small style={{ color: "#a9a4a4" }}>
            {company.name.slice(0, 20)} {company.name.length > 20 ? "..." : ""}
          </small>
          <span style={{ marginLeft: "5px", float: "right" }}>
            {" "}
            <img src={flag} height="11" width="16" alt="logo" />
          </span>
        </Col>
      </Row>
      <CriteriaSettings
        visible={showCriteriaSettingsModal}
        setVisible={setShowCriteriaSettingsModal}
      />
      <Modal
        forceRender={true}
        title={false}
        centered
        visible={showUserAccountForm}
        onOk={() => setShowUserAccountForm(false)}
        onCancel={() => setShowUserAccountForm(false)}
        okText={t("user_info.save_changes")}
        closable={false}
        footer={false}
        className={"ant-custom"}
      >
        <Form
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Title level={2} style={{ textAlign: "center" }}>
            {t("user_info.acc_details")}
          </Title>

          <Form.Item
            label={t("user_info.email_add")}
            name="email"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <span style={{ color: "#a9a4a4" }}>{user.email}</span>
          </Form.Item>
          <Form.Item
            label={t("user_info.company")}
            name="company"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <span style={{ color: "#a9a4a4" }}>{company.name}</span>
          </Form.Item>
          <Form.Item
            label={t("user_info.first_name")}
            name="firstName"
            rules={[
              {
                required: true,
                message: t("user_info.please_f_name_msg"),
              },
            ]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
            initialValue={user.firstName}
          >
            <Input onChange={onChange} autoComplete="off" />
          </Form.Item>

          <Form.Item
            label={t("user_info.last_name")}
            name="lastName"
            rules={[
              { required: true, message: t("user_info.please_l_name_msg") },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
            initialValue={user.lastName}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            label={t("user_info.phone_num")}
            name="phoneNumber"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <Input autoComplete="chrome-off" />
          </Form.Item>
          <Form.Item
            label={t("user_info.mobile_num")}
            name="mobileNumber"
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input autoComplete={"new-password"} />
          </Form.Item>
          <Form.Item
            label={t("user_info.position")}
            name="position"
            rules={[
              {
                required: true,
                message: t("user_info.please_com_position_msg"),
              },
            ]}
            initialValue={user.position}
          >
            <Input autoComplete="chrome-off" />
          </Form.Item>

          <Form.Item label={t("user_info.change_pass")} name="password">
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="default"
              htmlType="button"
              onClick={(e) => {
                e.preventDefault();
                setShowUserAccountForm(false);
              }}
              style={{ display: "inline-block", width: "calc(50% - 16px)" }}
            >
              {t("user_info.cancel")}
            </Button>{" "}
            <Button
              type="primary"
              htmlType="submit"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 0px 0 8px",
              }}
            >
              {t("user_info.save")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default observer(UserInfo);
